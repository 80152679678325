import {Component, OnInit, TemplateRef, ViewChild} from "@angular/core";
import {FormArray, FormBuilder, FormGroup} from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {HttpClient} from "@angular/common/http";
import { ClaimDocumentsComponent } from "../../claim-documents/claim-documents.component";
import { SideMenuService } from "src/app/services/side-menu.service";
import { ClaimService } from "src/app/services/claim.service";
import { DocumentHelperService } from "src/app/services/document-helper.service";
import { DocumentFormFactoryService } from "src/app/services/document-form-factory.service";
import { SpinnerService } from "src/app/core/spinner/spinner.service";
import { TransactionInfoService } from "src/app/services/transaction-info.service";
import { ActivatedRoute, Router } from "@angular/router";
import { TransactionInfo } from "src/app/model/transaction-info.model";
import { DocumentField } from "src/app/model/document-field";
import { TranslateService } from "@ngx-translate/core";
import { UtilitiesService } from "src/app/utilities/utilities.service";

@Component({
  selector: 'app-ec-upload-document',
  templateUrl: './ec-upload-document.component.html',
  styleUrls: ['./ec-upload-document.component.css']
})

export class ECUploadDocumentComponent extends ClaimDocumentsComponent implements OnInit {

    public upLoadFileModel: FormGroup;
    showTotalBox = false;
    showForm2DownloadLinks = false;
    transactionInfo: TransactionInfo;
    translateService: TranslateService;
    listOfUnuploadedDocs: any;
    requiredDocuments: DocumentField[];
    @ViewChild('alert_unuploaded_docs', {static: true}) docAlertModal: TemplateRef<any>;
    docAlertModalRef: any;
    constructor(private fb: FormBuilder,
                public sideMenuService: SideMenuService, 
                claimService: ClaimService,
                documentHelperService: DocumentHelperService,
                transactionInfoService: TransactionInfoService,
                http: HttpClient,
                documentFormFactoryService: DocumentFormFactoryService, 
                spinnerService: SpinnerService, 
                modalService: NgbModal, 
                private activatedRoute: ActivatedRoute,
                private router: Router,
                private translate: TranslateService,) {
        super(claimService, documentHelperService, transactionInfoService, http, documentFormFactoryService, spinnerService, modalService);
        this.setCurrentClaimType(this.claimService.getClaim().getSelectedClaimTypesList()[0]);
        this.transactionInfo = transactionInfoService.getTransactionInfo();
        this.translateService = translate;

    }

    ngOnInit() {
        this.upLoadFileModel = this.fb.group(
            {
                airTicket: [],
                carrierCertificate: [],
                otherItems: this.fb.array([this.createItem()])

            });
            super.ngOnInit();
            if(this.formBuilder != null && this.formBuilder.getRequiredDocuments() != null && this.formBuilder.getRequiredDocuments().findIndex((doc)=>{return doc.getId() === "ECFORMTWO"}) > -1){
                this.showForm2DownloadLinks = true;
            }
            //Modify required documents upload
          this.requiredDocuments = this.formBuilder.getRequiredDocuments();
    }

    addFile() {
        let items = this.upLoadFileModel.get('otherItems') as FormArray;
        items.push(this.createItem());
    }

    createItem(): FormGroup {
        return this.fb.group({
            otherFile: [],
        });
    }

    showModal() {
        this.showTotalBox = true;
    }

    getData(msg) {
        this.showTotalBox = msg;
    }

    goToNext() {
        this.listOfUnuploadedDocs = "";
        let stpDocIdList = this.requiredDocuments
            .reduce((result, { id, stpRequired }) => stpRequired == true ? result.push(id) && result : result, []);
        let stpAlertMessage = this.buildSTPAlertMessage(this.claimService.getClaim(), this.translate, stpDocIdList);

        if(!UtilitiesService.isEmpty(stpAlertMessage)){
            this.showDocAlertModal();
        }else{
            let claimTypeItem = this.transactionInfo.getClaimTypeByCode('current');
            let route = this.transactionInfo.getRouteByCurrentUrl('/supportingDoc', false);
            this.sideMenuService.emitClaimComplete({claimTypeId: claimTypeItem?.claimTypeId, subMenuIndex: route?.routeIndex});
            this.sideMenuService.emitProcess(1, 0);
            this.router.navigate(["/claimform/anotherInsurance"], {
                relativeTo: this.activatedRoute
            });
        }
    }

    showDocAlertModal() {
        this.docAlertModalRef = this.modalService.open(this.docAlertModal, {size: 'lg',
            backdrop: 'static',
            keyboard: false
        });
    }

    goBack() {
        let route = this.transactionInfo.getRouteByCurrentUrl('/supportingDoc', false);
        this.router.navigate([route.routeUrl], {
            relativeTo: this.activatedRoute
        });
    }
    continueToNext(){
        let claimTypeItem = this.transactionInfo.getClaimTypeByCode('current');
        let route = this.transactionInfo.getRouteByCurrentUrl('/supportingDoc', false);
        this.sideMenuService.emitClaimComplete({claimTypeId: claimTypeItem?.claimTypeId, subMenuIndex: route?.routeIndex});
        this.sideMenuService.emitProcess(1, 0);
        this.router.navigate(["/claimform/anotherInsurance"], {
            relativeTo: this.activatedRoute
        });
        this.docAlertModalRef.close();
     }
}
