import {Component, OnInit, Injector, ViewChild, TemplateRef, Input, EventEmitter, Output} from '@angular/core';
import { ClaimsBaseComponent } from '../claim-pages/claim-base.component';
import { NgOtpInputConfig } from 'ng-otp-input';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'otp-box',
    templateUrl: './otp-box.component.html',
    styleUrls: ['./otp-box.component.css']
})
export class OtpBoxComponent extends ClaimsBaseComponent implements OnInit {
    @ViewChild('ngOtpInput', { static: false }) 
    ngOtpInput: any;

    @ViewChild('modal_otp', {static: true}) 
    private otpModal: TemplateRef<any>;

    @ViewChild('modal_otp_locked', {static: true}) 
    private otpLockedModal: TemplateRef<any>;

    @Input() otp;
    @Input() otpSms;
    @Input() maskedEmailAddress;
    @Input() showOtpError:  boolean;
    @Input() showCloseIcon: boolean;
    @Input() showResendOtpLink: boolean;
    @Output() onCloseOtpModalEmit = new EventEmitter();
    @Output() onVerifyOtpEmit = new EventEmitter<string>();
    @Output() onSendOtpEmit = new EventEmitter<any>();

    config : NgOtpInputConfig = { 
        allowNumbersOnly: true, 
        length: 6, 
        isPasswordInput: false, 
        disableAutoFocus: false, 
        placeholder: "", 
        inputStyles: {  margin: "0 12px",
                        padding: "6px 0",
                        width: "50px !important",
                        fontSize: "28px",
                        lineHeight: "41px",
                        color: "#0A1F44",
                        border: 0,
                        outline: "none",
                        borderBottom: "2px solid #C4CAE0",
                    }
    };

    showOtpComponent = true;
    timeLeft: number = 120;
    interval;

    constructor(private injector : Injector,
                private modalService: NgbModal) {
        super(injector);
    }

    open() {
        this.resetTimer();
        if (!this.modalService.hasOpenModals()) {
            this.modalService.open(this.otpModal, {size: 'md', backdrop: 'static', keyboard: false});
        }
        this.timer();
    }

    openOtpLockedModal() {
        this.modalService.open(this.otpLockedModal, {size: 'md', backdrop: 'static', keyboard: false});
    }

    dismiss() {
        this.modalService.dismissAll();
    }

    onCloseOtpModal() {
        this.onCloseOtpModalEmit.emit();
    }

    onOtpChange(otp) { 
        this.otp = otp;
    } 
    
    setVal(val) { 
        this.ngOtpInput.setValue(val);
    } 
    
    onConfigChange() { 
        this.showOtpComponent = false; 
        this.otp = null; 
        setTimeout(() => { this.showOtpComponent = true; }, 0); 
    } 

    timer() {
        clearInterval(this.interval);
        this.interval = setInterval(() => {
            if(this.timeLeft > 0) {
              this.timeLeft--;
            }
        },1000);
    }

    resetTimer() {
        this.showOtpError = false;
        clearInterval(this.interval);
        this.timeLeft = 120;
    }

    onVerifyOtp() {
        this.onVerifyOtpEmit.emit(this.otp);
    }

    onSendOtp() {
        this.onSendOtpEmit.emit();
    }
}
