import {OnInit, ViewChild, TemplateRef, Component} from '@angular/core';
import { DocumentHelperService } from '../../services/document-helper.service';
import {FileUploader, ParsedResponseHeaders, FileItem, FileLikeObject} from 'ng2-file-upload';
import {FormGroup, FormControl} from '@angular/forms';
import {HttpHeaders, HttpClient} from '@angular/common/http';
import { throwError, catchError } from 'rxjs';
import { DocumentForm } from '../../model/document-form.model';
import { TransactionInfoService } from '../../services/transaction-info.service';
import { ClaimService } from '../../services/claim.service';
import { DocumentTypes } from '../../utilities/enums/document-types.enum';
import { DocumentDetails } from '../../model/document-details.model';
import { DocumentFormFactoryService } from '../../services/document-form-factory.service';
import { DocumentFormBuilderComponent } from '../document-form-builder/document-form-builder.component';
import { DocumentField } from '../../model/document-field';
import JsonMapUtils from '../../utilities/utils/json-map.util';
import { Notifications } from '../../utilities/components/notification-messages/notifications.model';
import { SpinnerService } from '../../core/spinner/spinner.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Claimant } from 'src/app/model/claimant.model';
import { UtilitiesService } from 'src/app/utilities/utilities.service';
import Compressor from 'compressorjs';

declare var $: any;
@Component({
  selector: 'app-multi-claim-documents',
  templateUrl: './multi-claim-documents.component.html',
  styleUrls: ['./multi-claim-documents.component.css']
})
export class MultiClaimDocumentsComponent implements OnInit {

    uploader: FileUploader;
    documentForm: DocumentForm;
    documentsForm: Map<string, FormGroup> = new Map<string, FormGroup>();
    documentType: DocumentField;
    documentTypes = new DocumentTypes();
    currentFileName: string;
    currentRemoveButton: string;
    showAddDoc = false;
    showAddDocs = new Map<string, string>();
    otherUploadedDocs: DocumentDetails[];
    otherUploadedDocsClaimant = new Map<string, DocumentDetails[]>();
    additionalDocs = new Map<string, DocumentDetails[]>();
    additionalDocsClaimant = new Map<string, DocumentDetails[]>();
    formBuilder: DocumentFormBuilderComponent;
    currentClaimType: string;
    totalFile = 0;
    isUploading: boolean = false;
    notifications: Notifications = new Notifications;
    @ViewChild('replace_duplicate', {static: true}) replaceDocModal: TemplateRef<any>;
    replaceDocModalRef: any;
    duplicateFile: FileItem;
    multiClaimant: Claimant[] = [];
    currentClaimant: Claimant;
    isFileError: boolean = false;
    fileAction: string = '';
    fileTarget: any;
    removeButtonInternetError = '';

    constructor(public claimService: ClaimService, private documentHelperService: DocumentHelperService,
                private transactionInfoService: TransactionInfoService,
                public http: HttpClient,
                private documentFormFactoryService: DocumentFormFactoryService,
                private spinnerService: SpinnerService,
                public modalService: NgbModal) {
        this.setDocumentForm(new DocumentForm());
    }

    setupAdditionalDocsTriggers(docList: DocumentField[]){
        for(let i = 0; i < docList.length; i++){
            this.showAddDocs.set(docList[i].id, 'false');
            this.additionalDocs.set(docList[i].id, []);
        }
    }

    setupAdditionalDocsTriggersClaimant(docList: DocumentField[], claimantList: Claimant[]){
        for(let h = 0; h < claimantList.length; h++){
            for(let i = 0; i < docList.length; i++){
                this.showAddDocs.set(docList[i].id + '_' + claimantList[h].getClaimantIdentifier(), 'false');
                this.additionalDocsClaimant.set(docList[i].id + '_' + claimantList[h].getClaimantIdentifier(), []);
            }
            this.showAddDocs.set("OTHERS" + claimantList[h].getClaimantIdentifier(), 'false');
        }
    }

    setDocTypeIndexed(type: DocumentField, index: number) {
        //console.log("isUploading: " + this.isUploading + " type:" + type.id + " index: "+ index);
        if (!this.isUploading) {
            this.documentType = this.documentHelperService.getIndexedDocumentField(type, index);
            this.resetCurrentClaimant();
        }
    }

    setDocTypeIndexedClaimant(type: DocumentField, index: number, claimant: Claimant) {
        //console.log("isUploading: " + this.isUploading + " type:" + type.id + " index: "+ index);
        if (!this.isUploading) {
            this.documentType = this.documentHelperService.getIndexedDocumentFieldClaimant(type, index, claimant);            
            this.setCurrentClaimant(claimant);
        }
    }

     fileDropIndexed(file: any, docType: DocumentField, index: number) {
        this.setDocType(this.documentHelperService.getIndexedDocumentField(docType, index));
        //console.log('file drop indexed', file);
        if (file && file[0]) {
            console.log('file.target.files[0].name', file[0].name);
            this.setCurrentFileName(file[0].name.replace(/[?*<>|:"]+/g, ''));
            this.setCurrentRemoveButton('[name="' + docType.getId() + index + 'rmv' + '"]');
        }
    }

    fileDropIndexedClaimant(file: any, docType: DocumentField, index: number, claimant: Claimant) {
        this.setDocTypeClaimant(this.documentHelperService.getIndexedDocumentFieldClaimant(docType, index, claimant), claimant);
        //console.log('file drop claimant', file);
        if (file && file[0]) {
            this.setCurrentFileName(file[0].name.replace(/[?*<>|:"]+/g, ''));

            let id = claimant.getClaimantIdentifier().replace(/\(/, '\\(');
            id = id.replace(/\)/, '\\)');
            this.setCurrentRemoveButton('[name="' + docType.getId() + index + 'rmv_' + id + '"]');
        }
    }

    setClaimantDocTypeIndexed(type: DocumentField, index: number, claimant: Claimant) {
        //console.log("isUploading: " + this.isUploading + " type:" + type.id + " index: "+ index);
        if (!this.isUploading) {
            this.documentType = this.documentHelperService.getIndexedDocumentField(type, index);
            this.resetCurrentClaimant();
        }
    }

    fileUpload(file: any, type: string) {
        //console.log('file upload', file);
        if (file.target.files && file.target.files[0]) {
            this.fileAction = 'fileUpload';
            this.fileTarget = file.target;
            this.setCurrentFileName(file.target.files[0].name.replace(/[?*<>|:"]+/g, ''));

            if (this.currentClaimant == undefined) {
                this.setCurrentRemoveButton('[name="' + type + 'rmv' + '"]');
            } else {
                let subId = '';
                let subClaimant = '';

                if(type.indexOf("_")!== -1){                    
                    subId = type.split("_")[0];
                    subClaimant = type.split("_")[1].replace(/\(/, '\\(');
                    subClaimant = subClaimant.replace(/\)/, '\\)');
                    this.setCurrentRemoveButton('[name="' + subId + 'rmv_' + subClaimant + '"]');
                } else {
                    subClaimant = this.currentClaimant.getClaimantIdentifier().replace(/\(/, '\\(');
                    subClaimant = subClaimant.replace(/\)/, '\\)');
                    this.setCurrentRemoveButton('[name="' + type + 'rmv_' + subClaimant + '"]');
                }
            }

            this.applyRemoveDocBtnInitCss();
            $(this.getCurrentRemoveButton()).css('display', 'block');
            if (this.isFileError) {
                $(file.target).siblings('.form-control').html('<i class="fas fa-exclamation"></i>' + ' ' + file.target.files[0].name.replace(/[?*<>|:"]+/g, ''));
                $(file.target).siblings('.form-control').css({'background-color': '#f2dede', 'color': '#ff0000', 'border': '2px solid #ff0000', 'word-break': 'break-all'});
                $(file.target).siblings('.form-control').addClass('hasFile');
                $(file.target).siblings('.form-control').addClass('fileTypeError');
                $(this.getCurrentRemoveButton()).addClass('removeDocFailBtn');
            } else {
                $(file.target).siblings('.form-control').html('<i class="fas fa-check"></i> ' + file.target.files[0].name.replace(/[?*<>|:"]+/g, ''));
                $(file.target).siblings('.form-control').css({'background-color': '#0064c1', 'color': '#ffffff', 'border': 0, 'word-break': 'break-all'});
                $(file.target).siblings('.form-control').addClass('hasFile');
                $(this.getCurrentRemoveButton()).addClass('removeDocBtn');
            }
        }
        file.srcElement.value = '';
    }

    dropFile(file: any, docId?: string) {
        //console.log('drop file', file);
        if (file) {
            this.fileAction = 'dropFile';
            this.fileTarget = file.target;
            this.applyRemoveDocBtnInitCss();
            $(this.getCurrentRemoveButton()).css('display', 'block');
            if (this.isFileError) {
                $(file.target).html('<i class="fas fa-exclamation"></i>' + ' ' + this.getCurrentFileName());
                $(file.target).css({'background-color': '#f2dede', 'color': '#ff0000', 'border': '2px solid #ff0000', 'word-break': 'break-all'});
                $(this.getCurrentRemoveButton()).addClass('removeDocFailBtn');
            } else {
                if (docId) {
                    $('[name="' + docId + '"]').html('<i class="fas fa-check drop-file"></i> ' + this.getCurrentFileName());
                    $('[name="' + docId + '"]').css({'background-color': '#0064c1', 'color': '#ffffff', 'border': 0, 'word-break': 'break-all'});
                    $('[name="' + docId + '"]').addClass('hasFile');
                } else {
                    $(file.target).html('<i class="fas fa-check"></i> ' + this.getCurrentFileName());
                    $(file.target).css({'background-color': '#0064c1', 'color': '#ffffff', 'border': 0, 'word-break': 'break-all'});
                }
                $(this.getCurrentRemoveButton()).removeClass('removeDocFailBtn');
                $(this.getCurrentRemoveButton()).addClass('removeDocBtn');
            }
        }
        this.setCurrentFileName('');
        this.setCurrentRemoveButton('');
    }

    fileDrop(file: any, type: DocumentField) {
        this.setDocType(type);
        //console.log('file drop', file);
        if (file && file[0]) {
            console.log('file.target.files[0].name', file[0].name);
            this.setCurrentFileName(file[0].name.replace(/[?*<>|:"]+/g, ''));
            this.setCurrentRemoveButton('[name="' + type.getId() + 'rmv' + '"]');
        }
    }

    fileDropClaimant(file: any, type: DocumentField, claimant: Claimant) {
        this.setDocTypeClaimant(type, claimant);
        //console.log('file drop claimant', file);
        if (file && file[0]) {
            this.setCurrentFileName(file[0].name.replace(/[?*<>|:"]+/g, ''));
            
            let id = claimant.getClaimantIdentifier().replace(/\(/, '\\(');
            id = id.replace(/\)/, '\\)');
            this.setCurrentRemoveButton('[name="' + type.getId() + 'rmv_' + id + '"]');
        }
    }

    fileDropOthers(file: any, index: number) {

        this.setDocType(this.documentHelperService.getOtherDocumentField(index));
        //console.log('file drop others', file);
        if (file && file[0]) {
            this.setCurrentFileName(file[0].name.replace(/[?*<>|:"]+/g, ''));
            this.setCurrentRemoveButton('[name="' + 'OTHERS' + index + 'rmv' + '"]');
        }
    }

    fileDropOthersClaimant(file: any, index: number, claimant: Claimant) {

        this.setDocTypeClaimant(this.documentHelperService.getOtherDocumentFieldClaimant(index, claimant), claimant);
        //console.log('file drop others claimant', file);
        if (file && file[0]) {
            this.setCurrentFileName(file[0].name.replace(/[?*<>|:"]+/g, ''));

            let id = claimant.getClaimantIdentifier().replace(/\(/, '\\(');
            id = id.replace(/\)/, '\\)');
            this.setCurrentRemoveButton('[name="' + 'OTHERS' + index + 'rmv_' + id + '"]');
        }
    }

    uploadDrop(file: any) {
        //console.log('upload drop', file);
        if (file && file[0]) {
            this.fileAction = 'uploadDrop';
            this.fileTarget = file;
            if (this.isFileError) {
                $(file).html('<i class="fas fa-exclamation"></i>' + ' ' + file[0].name.replace(/[?*<>|:"]+/g, ''));
                $(file).siblings('.form-control').css({'background-color': '#f2dede', 'color': '#ff0000', 'border': '2px solid #ff0000', 'word-break': 'break-all'});
            } else {
                $(file).html('<i class="fas fa-check"></i> ' + file[0].name.replace(/[?*<>|:"]+/g, ''));
                $(file).siblings('.form-control').css({'background-color': '#0064c1', 'color': '#ffffff', 'border': 0, 'word-break': 'break-all'});
            }
        }
    }

    setDocType(type: DocumentField) {
        //console.log("isUploading: " + this.isUploading + " type:" + type.id);
        if (!this.isUploading) {
            this.documentType = type;
            this.resetCurrentClaimant();
        }
    }

    setDocTypeOthers(index: number) {
        //console.log("isUploading: " + this.isUploading);
        if (!this.isUploading) {
            this.documentType = this.documentHelperService.getOtherDocumentField(index);
            this.resetCurrentClaimant();
        }
    }

    setDocTypeClaimant(type: DocumentField, claimant: Claimant) {
        //console.log("isUploading: " + this.isUploading + " type:" + type.id);
        if (!this.isUploading) {
            this.documentType = this.documentHelperService.getDocumentFieldClaimant(type, claimant);
            this.setCurrentClaimant(claimant);
        }
    }

    setDocTypeOthersClaimant(index: number, claimant: Claimant) {
        //console.log("isUploading: " + this.isUploading);
        if (!this.isUploading) {
            this.documentType = this.documentHelperService.getOtherDocumentFieldClaimant(index, claimant);
            this.setCurrentClaimant(claimant);
        }
    }

    getDocType(): DocumentField {
        return this.documentType;
    }

    setCurrentClaimType(claimType: string) {
        this.currentClaimType = claimType;
    }

    getCurrentClaimType(): string {
        return this.currentClaimType;
    }

    setCurrentFileName(currentFileName: string) {
        this.currentFileName = currentFileName;
    }

    getCurrentFileName() {
        return this.currentFileName;
    }

    setCurrentRemoveButton(currentRemoveButton: string) {
        this.currentRemoveButton = currentRemoveButton;
        if(currentRemoveButton != ''){
            this.setRemoveButtonInternetError(currentRemoveButton);
        }
    }

    getCurrentRemoveButton() {
        return this.currentRemoveButton;
    }

    setRemoveButtonInternetError(removeButtonInternetError: string){
        this.removeButtonInternetError = removeButtonInternetError;
    }

    getRemoveButtonInternetError(): string{
        return this.removeButtonInternetError;
    }

    setDuplicateFile(duplicateFile: FileItem){
        this.duplicateFile = duplicateFile;
    }

    getDuplicateFile(): FileItem{
        return this.duplicateFile;
    }

    addAdditionalFile() {
        this.pushGAUploadMoreDocuments();
        this.otherUploadedDocs.push(new DocumentDetails());
    }

    addAdditionalFiles(docId: string) {
        this.pushGAUploadMoreDocuments();
        this.additionalDocs.get(docId).push(new DocumentDetails());
    }

    addAdditionalFileClaimant(claimant: Claimant) {
        this.pushGAUploadMoreDocuments();
        this.otherUploadedDocsClaimant.get('OTHERS' + claimant.getClaimantIdentifier()).push(new DocumentDetails());
    }

    addAdditionalFilesClaimant(docId: string, claimant) {
        this.pushGAUploadMoreDocuments();
        this.additionalDocsClaimant.get(docId + '_' + claimant.getClaimantIdentifier()).push(new DocumentDetails());
    }

    uploadFile(file: any, type: string) {
        this.uploader.uploadItem(file.target.files[0]);
    }

    ngOnInit(): void {
        const self = this;
        const integrationToken = this.transactionInfoService.getTransactionInfo().getIntegrationToken();
        //console.log('integ token: ' + integrationToken);
        this.uploader = new FileUploader(
            {
                url: '/ms/claims-service/rest/documents/upload',
                headers: [
                    {name: "Authorization", value: "Bearer " + integrationToken}
                ],
                maxFileSize: this.documentHelperService.getMaxDocFileSize(),
                autoUpload: false
            }
        );
        this.documentHelperService.addDocumentFormBuilder(this.getCurrentClaimType());
        this.formBuilder = this.documentHelperService.getDocumentFormBuilder(this.getCurrentClaimType());
        this.formBuilder.setRequiredDocuments();
        this.setupAdditionalDocsTriggers(this.formBuilder.getRequiredDocuments());
        if(this.claimService.getClaim().getClaimantList() != undefined && this.claimService.getClaim().getClaimantList().length > 0){
            this.setupAdditionalDocsTriggersClaimant(this.formBuilder.getRequiredDocuments(), this.claimService.getClaim().getClaimantList());
        }
        this.totalFile = this.getTotalRequiredFiles();
        this.otherUploadedDocs = this.getOtherUploadedDocuments();
        this.otherUploadedDocsClaimant = this.getOtherUploadedDocumentsClaimant(this.multiClaimant);
        this.additionalDocs = this.getAdditionalUploadedDocuments(this.additionalDocs);
        this.additionalDocsClaimant = this.getAdditionalUploadedDocumentsClaimant(this.additionalDocsClaimant, this.multiClaimant);
        this.uploader.onBuildItemForm = function (fileItem, form) {
            form.append('documentType', self.documentType.getSmartqField());
            form.append('identifier', self.claimService.getClaim().getDocumentForm().identifier);
            return {fileItem, form}
        };

        this.uploader.onAfterAddingFile = (fileItem) => {
            this.isUploading = true;
            //console.log('adding file', fileItem);

        if(this.documentHelperService.validateFile(fileItem.file, this.notifications, this.documentType)){
            this.isFileError = true;
            self.abortUpload();
        } else {
            this.isFileError = false;
            if(self.hasDuplicateDocument(self.getDocType().getId(), self.getCurrentClaimType(), fileItem.file.name, self.currentClaimant)){
                self.setDuplicateFile(fileItem);
                this.replaceDocModalRef = this.modalService.open(this.replaceDocModal, {size: 'sm',
                    backdrop: 'static',
                    keyboard: false
                });
            } else {
                if(this.documentHelperService.isImage(fileItem._file.name)){
                    self.startUpload(this.getCompressedImageFile(fileItem));
                } else {
                    self.startUpload(fileItem);
                }
            }
        }
        /* if(this.target) this.target.value = ''; */
        };

        this.uploader.onBeforeUploadItem = function (fileItem) {
            self.isUploading = true;
            //console.log("checking if existing file exists");
            if(self.currentClaimant == undefined){
                self.replaceExistingDocument(self.getDocType().getId(), self.getCurrentClaimType());
            } else {
                self.replaceExistingDocumentClaimant(self.getDocType().getId(), self.getCurrentClaimType(), self.currentClaimant);
            }
            
        };

        this.uploader.onCompleteItem = (item: FileItem, response: string, status: number, headers: ParsedResponseHeaders) => {
            //console.log("this.uploader.onCompleteItem");
            this.isUploading = false;
            try {
                if (response && JSON.parse(response).docID) {
                    console.log(this.documentHelperService.mapDocumentDetails(JSON.parse(response)));
                    let documentDetails: DocumentDetails = this.documentHelperService.mapDocumentDetails(JSON.parse(response));
                    documentDetails.docClaimType = this.getCurrentClaimType();
                    if(this.currentClaimant != undefined){
                        documentDetails.claimant = this.currentClaimant;
                        if(this.getDocType().getId().indexOf("_")==-1){
                            documentDetails.docSubTypeId = this.getDocType().getId() + "_" + this.currentClaimant.getClaimantIdentifier();
                        } else {
                            documentDetails.docSubTypeId = this.getDocType().getId();
                        }
                    } else {
                        documentDetails.docSubTypeId = this.getDocType().getId();
                    }
                    this.claimService.getClaim().getDocumentForm().documents.push(documentDetails);
                } else {
                    this.showFileUploadErrorMessage(item, this.notifications, this.getDocType().getId());
                }
            } catch {
                this.showFileUploadErrorMessage(item, this.notifications, this.getDocType().getId());
            }
            this.spinnerService.displayLoader(false);
        };

        this.uploader.onErrorItem = ((item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any => {
            let notificationMessages = null;
            try {
                notificationMessages = JsonMapUtils.deserialize(Notifications, JSON.parse(response)).errorMessages;
            } catch {
                notificationMessages = [];
                this.documentHelperService.getFileUploadingErrorMessage(item.file.name, this.notifications, this.getDocType().getId());
                this.isFileError = true;
                self.abortUpload();
                window.scroll(0, 0);
                this.applyErrorStyle(item);
            }
            for (const notificationMessage of notificationMessages) {
                if (!this.notifications.isMessageExisting(notificationMessage)) {
                    this.notifications.addMessage(notificationMessage);
                }
            }
            this.notifications.showPreamble = false;
            this.spinnerService.displayLoader(false);
        });

        this.uploader.onWhenAddingFileFailed = (item: FileLikeObject, filter: any, options: any) => {
            console.log(item);
            this.documentHelperService.validateFile(item, this.notifications, this.documentType);
            this.isFileError = true;
            this.abortUpload();
            this.spinnerService.displayLoader(false);
            return {item, filter, options};
        }


    }

    ngAfterViewInit() {
        this.populateUploadedDocumentFields();
    }

    showFileUploadErrorMessage(item, notifications, id) {
        this.documentHelperService.getFileUploadingErrorMessage(item.file.name, notifications, id);
        this.isFileError = true;
        this.abortUpload();
        window.scroll(0, 0);
        this.applyErrorStyle(item);
    }

    getDocumentForm() {
        return this.documentForm;
    }

    setDocumentForm(data) {
        this.documentForm = data;
    }

    getDocumentFormGroup(key: string) {
        if (UtilitiesService.isNullOrUndefined(this.documentsForm.get(key))) {
            this.documentsForm.set(key,
                new FormGroup({
                    documentType: new FormControl()
                })
            );
        }
        return this.documentsForm.get(key);
    }

    populateUploadedDocumentFields() {
        this.claimService.getClaim().getDocumentForm().documents.forEach(function (uploadedDocument) {
            if (uploadedDocument.docClaimType == this.getCurrentClaimType()) {
                let subId = uploadedDocument.docSubTypeId;
                let subClaimant = "";
                let rmvBtnId = "";
                if(subId.indexOf("_")!== -1){
                    subClaimant = subId.split("_")[1];
                    subId = subId.split("_")[0];
                    rmvBtnId = subId + 'rmv_' + subClaimant;
                } else {
                    rmvBtnId = uploadedDocument.docSubTypeId + 'rmv';
                }
                const lastChar = subId.substr(subId.length - 1);
                if (!isNaN(+lastChar)) {     
                    if ($('[id="' + uploadedDocument.docSubTypeId + '"]') != undefined) {
                        $('[id="' + uploadedDocument.docSubTypeId + '"]').nextAll(':lt(2)').html('<i class="fas fa-check"></i> ' + uploadedDocument.docFileName.replace(/[?*<>|:"]+/g, ''));
                        $('[id="' + uploadedDocument.docSubTypeId + '"]').nextAll(':lt(2)').css({'background-color': '#0064c1', 'color': '#ffffff', 'border': 0, 'word-break': 'break-all'});
                        $('[name="' + rmvBtnId + '"]').css({'display': 'block'});
                        $('[name="' + rmvBtnId + '"]').addClass('removeDocBtn');
                    }
                } else { 
                    if ($('[name="' + uploadedDocument.docSubTypeId + '"]') != undefined) {
                        $('[name="' + uploadedDocument.docSubTypeId + '"]').html('<i class="fas fa-check"></i> ' + uploadedDocument.docFileName.replace(/[?*<>|:"]+/g, ''));
                        $('[name="' + uploadedDocument.docSubTypeId + '"]').css({'background-color': '#0064c1', 'color': '#ffffff', 'border': 0, 'word-break': 'break-all'});
                        $('[name="' + rmvBtnId + '"]').css({'display': 'block'});
                        $('[name="' + rmvBtnId + '"]').addClass('removeDocBtn');
                    }
                }
            }
        }, this);
    }

    getOtherUploadedDocuments(): DocumentDetails[] {
        let otherUploadedDocuments: DocumentDetails[] = [];
        let index = 0;
        this.claimService.getClaim().getDocumentForm().documents.forEach(function (uploadedDocument) {
            if (uploadedDocument.docClaimType == this.getCurrentClaimType()) {
                if (uploadedDocument.docSubTypeId.startsWith('OTHERS') && uploadedDocument.claimant == undefined) {
                    this.showAddDoc = true;
                    uploadedDocument.docSubTypeId = 'OTHERS' + index;
                    otherUploadedDocuments.push(uploadedDocument);
                    index++;
                }
            }
        }, this);
        /*  if(otherUploadedDocuments.length == 0){
         otherUploadedDocuments.push('');
         } */
        return otherUploadedDocuments;
    }

    getOtherUploadedDocumentsClaimant(claimantList:Claimant[]): Map<string, DocumentDetails[]> {
        let otherUploadedDocuments = new Map<string, DocumentDetails[]>();
        let index = 0;
        const self = this;
        claimantList.forEach(function(claimant){
            index = 0;
            otherUploadedDocuments.set("OTHERS" + claimant.getClaimantIdentifier(), []);
            self.claimService.getClaim().getDocumentForm().documents.forEach(function (uploadedDocument) {
                if (uploadedDocument.docClaimType == self.getCurrentClaimType()) {
                    if (uploadedDocument.docSubTypeId.startsWith('OTHERS') && 
                        !UtilitiesService.isNullOrUndefined(uploadedDocument.claimant) &&
                        uploadedDocument.claimant.getClaimantIdentifier() == claimant.getClaimantIdentifier()) {
                        self.showAddDocs.set('OTHERS' + claimant.getClaimantIdentifier(), 'true');
                        uploadedDocument.docSubTypeId = 'OTHERS' + index + '_' +claimant.getClaimantIdentifier();
                        otherUploadedDocuments.get("OTHERS" + claimant.getClaimantIdentifier()).push(uploadedDocument);
                        index++;
                    }
                }
            }, this);
        });
       
        return otherUploadedDocuments;
    }

    getAdditionalUploadedDocuments(additionalDocs: Map<string, DocumentDetails[]>): Map<string, DocumentDetails[]> {
        const self = this;
        this.claimService.getClaim().getDocumentForm().documents.forEach(function (uploadedDocument) {
            if (uploadedDocument.docClaimType == this.getCurrentClaimType() && uploadedDocument.claimant == undefined) {
                if (!uploadedDocument.docSubTypeId.startsWith('OTHERS') && 
                !isNaN(+uploadedDocument.docSubTypeId.substr(uploadedDocument.docSubTypeId.length - 1))) {
                    this.formBuilder.getRequiredDocuments().forEach(function (reqDoc) {
                        if(uploadedDocument.docSubTypeId.startsWith(reqDoc.id)){
                            additionalDocs.get(reqDoc.id).push(uploadedDocument);
                            self.showAddDocs.set(reqDoc.id, 'true');
                            return;
                        }
                    });
                }
            }
        }, this);
        additionalDocs.forEach((uploadedDocs: DocumentDetails[], key: string) => {
            if (uploadedDocs.length > 0) {
                let baseDocExists = self.checkIfBaseDocExists(key, undefined);
                if (baseDocExists) {
                    uploadedDocs.forEach((uploadedDoc: DocumentDetails, index: number) => {
                        if (uploadedDoc.claimant == undefined) {
                            uploadedDoc.docSubTypeId = key + index;
                        }
                    });
                } else {
                    if (self.claimService.getClaim().getDocumentForm().documents != undefined) {
                        for (let i = 0; i < self.claimService.getClaim().getDocumentForm().documents.length; i++) {
                            let uploadedDocument = self.claimService.getClaim().getDocumentForm().documents[i];
                            if (uploadedDocument.docClaimType == self.getCurrentClaimType() &&
                                uploadedDocument.claimant == undefined &&
                                uploadedDocument.docSubTypeId == uploadedDocs[0].docSubTypeId &&
                                uploadedDocument.docFileName == uploadedDocs[0].docFileName) {
                                uploadedDocument.docSubTypeId = key;
                                break;
                            }
                        }
                        const i = uploadedDocs.indexOf(uploadedDocs[0], 0);

                        if (i > -1) {
                            uploadedDocs.splice(i, 1);
                        }

                        uploadedDocs.forEach((uploadedDoc: DocumentDetails, index: number) => {
                            uploadedDoc.docSubTypeId = key + index;
                        });
                    }



                }
            }
        });
        additionalDocs.forEach((uploadedDocs: DocumentDetails[], key: string) => {
            uploadedDocs.forEach((uploadedDoc: DocumentDetails, index: number) => {
                if(uploadedDoc.claimant == undefined){
                    uploadedDoc.docSubTypeId = key + index;  
                }
            });
        });

        return additionalDocs;
    }
    
    checkIfBaseDocExists(key, claimant): boolean {
        let baseDocExists = false;
        this.claimService.getClaim().getDocumentForm().documents.forEach(function (uploadedDocument) {
            if(uploadedDocument.docClaimType == this.getCurrentClaimType() && this.compareClaimants(uploadedDocument.claimant, claimant) && uploadedDocument.docSubTypeId == key){
                baseDocExists = true;
                return;
            }
        }, this);
        return baseDocExists;
    }

    compareClaimants(claimant1, claimant2): boolean {
        if((claimant1 == undefined && claimant2 == undefined) || (claimant1 != undefined && claimant2 != undefined && claimant1.getClaimantIdentifier() == claimant2.getClaimantIdentifier())){
            return true;
        }
        return false;
    }

    getAdditionalUploadedDocumentsClaimant(additionalDocs: Map<string, DocumentDetails[]>, claimantList: Claimant[]): Map<string, DocumentDetails[]> {
        const self = this;
        claimantList.forEach(function (claimant) {
            self.claimService.getClaim().getDocumentForm().documents.forEach(function (uploadedDocument) {
                let docSubTypeId = this.getDocSubTypeId(uploadedDocument.docSubTypeId);
                if (uploadedDocument.docClaimType == self.getCurrentClaimType() && 
                !UtilitiesService.isNullOrUndefined(uploadedDocument.claimant) &&
                uploadedDocument.claimant.getClaimantIdentifier() == claimant.getClaimantIdentifier()) {
                    if (!docSubTypeId.startsWith('OTHERS') && 
                    !isNaN(+docSubTypeId.substr(docSubTypeId.length - 1))) {
                        self.formBuilder.getRequiredDocuments().forEach(function (reqDoc) {
                            if(docSubTypeId.startsWith(reqDoc.id)){
                                if(reqDoc.id.indexOf("_")!==-1){
                                    additionalDocs.get(reqDoc.id).push(uploadedDocument);
                                    self.showAddDocs.set(reqDoc.id, 'true');
                                } else {
                                    additionalDocs.get(reqDoc.id + "_" + claimant.getClaimantIdentifier()).push(uploadedDocument);
                                    self.showAddDocs.set(reqDoc.id + "_" + claimant.getClaimantIdentifier(), 'true');
                                }
                                return;
                            }
                        });
                    }
                }
            }, self);
            additionalDocs.forEach((uploadedDocs: DocumentDetails[], key: string) => {
                if (uploadedDocs.length > 0 && uploadedDocs[0].claimant.getClaimantIdentifier() == claimant.getClaimantIdentifier()) {
                    let baseDocExists = self.checkIfBaseDocExists(key, claimant);
                    if (baseDocExists) {
                        uploadedDocs.forEach((uploadedDoc: DocumentDetails, index: number) => {
                            if (self.compareClaimants(uploadedDoc.claimant, claimant)) {
                                uploadedDoc.docSubTypeId = key.split("_")[0] + index + '_' + claimant.getClaimantIdentifier();
                            }
                        });
                    } else {
                        if (self.claimService.getClaim().getDocumentForm().documents != undefined) {

                            for (let i = 0; i < self.claimService.getClaim().getDocumentForm().documents.length; i++) {
                                let uploadedDocument = self.claimService.getClaim().getDocumentForm().documents[i];
                                if (uploadedDocument.docClaimType == self.getCurrentClaimType() &&
                                    self.compareClaimants(uploadedDocument.claimant, claimant) &&
                                    uploadedDocument.docSubTypeId == uploadedDocs[0].docSubTypeId &&
                                    uploadedDocument.docFileName == uploadedDocs[0].docFileName) {
                                    uploadedDocument.docSubTypeId = key.split("_")[0] + "_" + claimant.getClaimantIdentifier();
                                    break;
                                }
                            }

                            const i = uploadedDocs.indexOf(uploadedDocs[0], 0);

                            if (i > -1) {
                                uploadedDocs.splice(i, 1);
                            }

                            let ctr = 0;
                            uploadedDocs.forEach((uploadedDoc: DocumentDetails, index: number) => {
                                if(self.compareClaimants(uploadedDoc.claimant, claimant)){
                                    uploadedDoc.docSubTypeId = key.split("_")[0] + ctr + "_" + claimant.getClaimantIdentifier();
                                    ctr++;
                                }
                            });
                        }
                    }
                }
            });
            additionalDocs.forEach((uploadedDocs: DocumentDetails[], key: string) => {
                uploadedDocs.forEach((uploadedDoc: DocumentDetails, index: number) => {
                    if(uploadedDoc.docSubTypeId.split("_")[1] == claimant.getClaimantIdentifier()){
                        uploadedDoc.docSubTypeId = key.split("_")[0] + index + "_" + claimant.getClaimantIdentifier();  
                    }
                });
            });
        })
        
        /*  if(otherUploadedDocuments.length == 0){
         otherUploadedDocuments.push('');
         } */
        return additionalDocs;
    }

    getUploadedFileName(documentType: string, claimType: string): string {
        let uploadedFileName: string = '';
        this.claimService.getClaim().getDocumentForm().documents.forEach(function (uploadedFile) {
            if (this.locateDocument(documentType, claimType, uploadedFile)) {
                uploadedFileName = uploadedFile.docFileName;
                return;
            }
        }, this);
        return uploadedFileName;
    }

    replaceExistingDocument(documentType: string, claimType: string) {
        this.claimService.getClaim().getDocumentForm().documents.forEach(function (uploadedFile, index) {

            if ((documentType != 'OTHERS') && this.locateDocument(documentType, claimType, uploadedFile)
                || ((documentType == 'OTHERS') && this.locateAdditionalDocument(claimType, this.getCurrentFileName(), uploadedFile))) {
                if (this.removeDocument(uploadedFile.docID)) {
                    console.log("Replacing documentType " + documentType);
                    this.claimService.getClaim().getDocumentForm().documents.splice(index, 1);
                    console.log("Document updated, updated list: ", this.claimService.getClaim().getDocumentForm().documents);
                    return;
                }
            }

        }, this);
    }

    replaceExistingDocumentClaimant(documentType: string, claimType: string, claimant: Claimant) {
        this.claimService.getClaim().getDocumentForm().documents.forEach(function (uploadedFile, index) {

            if (((documentType != 'OTHERS') && this.locateDocument(documentType, claimType, uploadedFile)
                || ((documentType == 'OTHERS') && this.locateAdditionalDocument(claimType, this.getCurrentFileName(), uploadedFile)))&&
                uploadedFile.claimant != undefined && claimant.getClaimantIdentifier() == uploadedFile.claimant.getClaimantIdentifier()) {
                if (this.removeDocument(uploadedFile.docID)) {
                    console.log("Replacing documentType " + documentType);
                    this.claimService.getClaim().getDocumentForm().documents.splice(index, 1);
                    console.log("Document updated, updated list: ", this.claimService.getClaim().getDocumentForm().documents);
                    return;
                }
            }

        }, this);
    }

    hasDuplicateDocument(documentType: string, claimType: string, filename: string, claimant: Claimant): boolean {
        let hasDuplicate: boolean = false;
        this.claimService.getClaim().getDocumentForm().documents.forEach(function (uploadedFile, index) {

            if ((claimant == undefined && this.locateDuplicateDocument(documentType, claimType, uploadedFile,filename)) ||
            (claimant != undefined && this.locateDuplicateDocumentClaimant(documentType, claimType, uploadedFile,filename, claimant))
            ) {
                    hasDuplicate= true;
                    return;
                }
        }, this);
        return hasDuplicate;
    }

    locateDuplicateDocument(documentType: string, claimType: string, uploadedFile: DocumentDetails, filename: string): boolean {
        if ((!UtilitiesService.isNullOrUndefined(uploadedFile.docSubTypeId) && uploadedFile.docSubTypeId == documentType) &&
            (!UtilitiesService.isNullOrUndefined(uploadedFile.docClaimType) && uploadedFile.docClaimType == claimType) &&
            (!UtilitiesService.isNullOrUndefined(uploadedFile.docFileName) && uploadedFile.docFileName.toLowerCase() == filename.toLowerCase())) {
            return true;
        }
        return false;
    }

    locateDuplicateDocumentClaimant(documentType: string, claimType: string, uploadedFile: DocumentDetails, filename: string, claimant: Claimant): boolean {
        if ((!UtilitiesService.isNullOrUndefined(uploadedFile.claimant) && uploadedFile.claimant.getClaimantIdentifier() == claimant.getClaimantIdentifier()) &&
            (!UtilitiesService.isNullOrUndefined(uploadedFile.docSubTypeId) && uploadedFile.docSubTypeId.startsWith(documentType)) &&
            (!UtilitiesService.isNullOrUndefined(uploadedFile.docClaimType) && uploadedFile.docClaimType == claimType) &&
            (!UtilitiesService.isNullOrUndefined(uploadedFile.docFileName) && uploadedFile.docFileName.toLowerCase() == filename.toLowerCase())) {
            return true;
        }
        return false;
    }

    locateDocument(documentType: string, claimType: string, uploadedFile: DocumentDetails): boolean {
        if ((!UtilitiesService.isNullOrUndefined(uploadedFile.docSubTypeId) && uploadedFile.docSubTypeId == documentType) &&
            (!UtilitiesService.isNullOrUndefined(uploadedFile.docClaimType) && uploadedFile.docClaimType == claimType)) {
            return true;
        }
        return false;
    }

    locateAdditionalDocument(claimType: string, currentFileName: string, uploadedFile: DocumentDetails): boolean {
        if ((!UtilitiesService.isNullOrUndefined(uploadedFile.docClaimType) && uploadedFile.docClaimType == claimType) &&
            (!UtilitiesService.isNullOrUndefined(uploadedFile.docFileName) && uploadedFile.docFileName == currentFileName)) {
            return true;
        }
        return false;
    }

    removeDocument(documentId: string): boolean {
        const self = this;
        let removalSuccessful: boolean = true;
        let indexRemove = 0;
        let documentForm: DocumentDetails = new DocumentDetails();

        this.claimService.claim.getDocumentForm().documents.forEach(function(uploadedDoc, index){
            if(uploadedDoc.docClaimType == self.getCurrentClaimType()){
                if(uploadedDoc.docSubTypeId == documentId){
                    indexRemove = index;
                    documentForm.docID = uploadedDoc.docID;
                    documentForm.hasAutoSaveId = uploadedDoc.hasAutoSaveId;
                }
            }
        });
        if(documentForm.docID !== ''){
            this.claimService.claim.getDocumentForm().documents.splice(indexRemove, 1);
            let integrationToken = this.transactionInfoService.getTransactionInfo().getIntegrationToken();
            const httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + integrationToken
                })
            };

            let url: string = '/ms/claims-service/rest/documents/remove';

            this.http.post(url, JSON.stringify(documentForm), httpOptions).subscribe(res => {
                //console.log(res);
                if (res == true || res == "true") {
                    removalSuccessful = true;
                }
                return res;
            }),
                //...errors if any
                catchError(this.handleError);
        }
        return removalSuccessful;
    }

    removeDoc(documentId: string, isMobile: boolean){
        if(this.removeDocument(documentId)){
            this.notifications.removeFileErrorMessage(documentId);
            let isMox = this.claimService.getClaim().isMox();
            let defaultText = this.documentHelperService.getDefaultLabel(isMobile, isMox);
            let rmvBtnId = '';
            if(documentId.indexOf("_")!== -1){
                rmvBtnId = documentId.split("_")[0] + 'rmv_' + documentId.split("_")[1];
            } else {
                rmvBtnId = documentId + 'rmv';
            }

            if (!isNaN(+documentId)) {     
                if ($('[id="' + documentId + '"]') != undefined) {
                    $('[id="' + documentId + '"]').siblings().html(defaultText);
                    $('[id="' + documentId + '"]').siblings().css({'background-color': '#f1f1f1', 'color': '#495057', 'border': 0});
                    $('[id="' + documentId + '"]').siblings().removeClass('hasFile');
                    $('[id="' + documentId + '"]').siblings().removeClass('fileTypeError');
                    $('[id="' + rmvBtnId + '"]').siblings().removeClass('removeDocBtn');
                    $('[id="' + rmvBtnId + '"]').siblings().removeClass('removeDocFailBtn');
                    $('[id="' + rmvBtnId + '"]').siblings().css({'display': 'none'});
                }
            } else { 
                if ($('[name="' + documentId + '"]') != undefined) {
                    $('[name="' + documentId + '"]').html(defaultText);
                    $('[name="' + documentId + '"]').css({'background-color': '#f1f1f1', 'color': '#495057', 'border': 0});
                    $('[name="' + documentId + '"]').removeClass('hasFile');
                    $('[name="' + documentId + '"]').removeClass('fileTypeError');
                    $('[name="' + rmvBtnId + '"]').removeClass('removeDocBtn');
                    $('[name="' + rmvBtnId + '"]').removeClass('removeDocFailBtn');
                    $('[name="' + rmvBtnId + '"]').css({'display': 'none'});
                }
            }
        }
    }

    private handleError(error: Response | any) {
        this.spinnerService.displayLoader(false);
        // In a real world app, you might use a remote logging infrastructure
        let errMsg: string;
        if (error instanceof Response) {
            errMsg = `${error.status} - ${error.statusText || ''}`;
        } else {
            errMsg = error.message ? error.message : error.toString();
        }
        errMsg = 'documentservice handleError' + errMsg;
        return throwError(() => new Error(errMsg));
    }

    //START - Google Analytics
    pushGAUploadMoreDocuments() {
        (<any>window).dataLayer.push({'event': 'uploadmorefile'});
    }

    //END - Google Analytics

    startUpload(fileItem:FileItem){
        this.spinnerService.displayLoader(true);
        this.uploader.uploadItem(fileItem);
    }

    proceedReplace(){
        if(!UtilitiesService.isNullOrUndefined(this.getDuplicateFile())){
            this.startUpload(this.getDuplicateFile());
        }
        this.replaceDocModalRef.close();
    }

    abortReplace(){
        this.abortUpload();
        this.replaceDocModalRef.close();
    }

    abortUpload(){
        this.setDuplicateFile(null);
        this.isUploading = false;
    }

    isUploaded(documentId: string): boolean{
        let isUploaded = false;
        let self = this;
        this.claimService.getClaim().getDocumentForm().documents.forEach(function (uploadedDocument) {
            if(documentId == uploadedDocument.docSubTypeId && 
                uploadedDocument.docClaimType == self.getCurrentClaimType() &&
                uploadedDocument.claimant == undefined ){
                isUploaded = true;
                return;
            }
        });
        return isUploaded;
    }

    isUploadedClaimant(documentId: string, claimant: Claimant): boolean{
        let isUploaded = false;
        let self = this;
        this.claimService.getClaim().getDocumentForm().documents.forEach(function (uploadedDocument) {
            if(documentId == uploadedDocument.docSubTypeId && 
                uploadedDocument.docClaimType == self.getCurrentClaimType() && 
                !UtilitiesService.isNullOrUndefined(uploadedDocument.claimant) &&
                uploadedDocument.claimant.getClaimantIdentifier() == claimant.getClaimantIdentifier()){
                isUploaded = true;
                return;
            }
        });
        return isUploaded;
    }

    setupMultiClaimant(){
        const self = this;
        if(this.claimService.getClaim().getClaimantList() != undefined 
        && this.claimService.getClaim().getClaimantList().length > 0){
            this.claimService.getClaim().getClaimantList().forEach(function (claimant) {
                if(claimant.getSelectedClaimTypesList().indexOf(self.getCurrentClaimType()) !== -1){
                    self.multiClaimant.push(claimant);
                }
            });
        }
    }

    setCurrentClaimant(claimant: Claimant){
        console.log("claimant! "+ claimant);
        this.currentClaimant = claimant;
    }

    getCurrentClaimant(): Claimant{
        return this.currentClaimant;
    }

    resetCurrentClaimant(){
        console.log("claimant reset! ");
        this.currentClaimant = undefined;
    }

    getDocSubTypeId(uploadedDocSubTypeId: String): String {
        let docSubTypeId = uploadedDocSubTypeId;
        if(docSubTypeId.indexOf("_")!==-1){
            docSubTypeId = docSubTypeId.split("_")[0];
        }
        return docSubTypeId;
    }

    getMultiClaimantDocId(docId: string, claimantId: string, index: string): string {
        return docId + index + '_' + claimantId;
    }

    showMinorDocument(documentField: DocumentField, dateOfBirth: any): boolean {
        return !documentField.id.startsWith('PROOFOFREL') || (documentField.id.startsWith('PROOFOFREL') && UtilitiesService.isMinor(dateOfBirth));
    }
    
    
    getTotalRequiredFiles(): number{
        let totalRequiredFiles = (1 + this.multiClaimant.length) * this.formBuilder.getRequiredDocuments().length;
        let self = this;
        
        if(this.multiClaimant.length > 0){
            this.formBuilder.getRequiredDocuments().forEach(function(doc){
                if(doc.getId().startsWith('PROOFOFREL')){
                    if(!UtilitiesService.isMinor(self.claimService.getClaim().getClaimantDateOfBirth())){
                        totalRequiredFiles = totalRequiredFiles - 1;
                    }
                    self.multiClaimant.forEach(function(claimant){
                        if(!UtilitiesService.isMinor(claimant.getClaimantDateOfBirth())){
                            totalRequiredFiles = totalRequiredFiles - 1;
                        }
                    });
                }
            });
        }
        
        return totalRequiredFiles;
    }

    applyRemoveDocBtnInitCss() {
        $(this.getCurrentRemoveButton()).css({'display': 'none'});
        $(this.getCurrentRemoveButton()).removeClass('removeDocFailBtn');
        $(this.getCurrentRemoveButton()).removeClass('removeDocBtn');
    }

    applyErrorStyle(file: FileItem){
        if(this.fileAction == 'fileUpload') {
            this.setCurrentRemoveButton(this.removeButtonInternetError);
            $(this.fileTarget).siblings('.form-control').html('<i class="fas fa-exclamation"></i>' + ' ' + file.file.name.replace(/[?*<>|:"]+/g, ''));
            $(this.fileTarget).siblings('.form-control').css({'background-color': '#f2dede', 'color': '#ff0000', 'border': '2px solid #ff0000', 'word-break': 'break-all'});
            $(this.getCurrentRemoveButton()).addClass('removeDocFailBtn');
        } else if (this.fileAction == 'dropFile') {
            this.setCurrentRemoveButton(this.removeButtonInternetError);
            $(this.fileTarget).html('<i class="fas fa-exclamation"></i>' + ' ' + file.file.name.replace(/[?*<>|:"]+/g, ''));
            $(this.fileTarget).css({'background-color': '#f2dede', 'color': '#ff0000', 'border': '2px solid #ff0000', 'word-break': 'break-all'});
            $(this.getCurrentRemoveButton()).addClass('removeDocFailBtn');
        } else if (this.fileAction == 'uploadDrop') {
            this.setCurrentRemoveButton(this.removeButtonInternetError);
            $(this.fileTarget).html('<i class="fas fa-exclamation"></i>' + ' ' + file.file.name.replace(/[?*<>|:"]+/g, ''));
            $(this.fileTarget).siblings('.form-control').css({'background-color': '#f2dede', 'color': '#ff0000', 'border': '2px solid #ff0000', 'word-break': 'break-all'});
            $(this.getCurrentRemoveButton()).addClass('removeDocFailBtn');
        }
        this.fileAction = '';
        this.fileTarget = null;
        this.setRemoveButtonInternetError('');
    }

    getCompressedImageFile(fileItem: FileItem) {
        new Compressor(fileItem._file, {
            quality: 0.6,
            success(result) {
                console.log("Image compression success!", result);
                var b: any = result;
                let oldFileSize = fileItem._file.size;
                if(b.size < oldFileSize){
                    b.lastModifiedDate = new Date();
                    b.name = fileItem._file.name;
                    fileItem._file = <File>b;
                }
            },
            error(error) {
                console.log("Error in compression, uploading original file instead", error);
            }
        });
        return fileItem;
    }
}
