import {Component, Injector, OnInit} from "@angular/core";
import {FormArray, FormBuilder, FormGroup} from "@angular/forms";
import {SideMenuService} from "../../../../services/side-menu.service";
import {ClaimsBaseComponent} from "../../../claim-base.component";
import {ActivatedRoute, Router} from "@angular/router";
import {DamagePersonalItem} from "../../../../model/travel/damage-personal-item.model";
import {DamagePersonalItemDetail} from "../../../../model/travel/damage-personal-detail.model";
import {takeUntil} from "rxjs";
import { UtilitiesService } from "src/app/utilities/utilities.service";
import { DateService } from "src/app/ui/ui-datepicker/date.service";
declare var $: any;

@Component({
  selector: 'app-detail-of-damage-items',
  templateUrl: './detail-of-damage-items.component.html',
  styleUrls: ['./detail-of-damage-items.component.css']
})
export class DetailOfDamageItemsComponent extends ClaimsBaseComponent implements OnInit {

    damageDetailForm: FormGroup;
    items: any[] = [];
    selectType: string;
    showTotalBox = false;
    showCheckboxError = false;
    detailsOfDamageItemsArray: DamagePersonalItemDetail[] = [];

    constructor(private fb: FormBuilder,
                public sideMenuService: SideMenuService,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                private injector : Injector,
                private dateService: DateService) {
            super(injector);
    }

    ngOnInit() {
        this.pushGAViewDamageItemDescription();
        this.detailsOfDamageItemsArray = this.claim.getClaimTravel().getDamagePersonalItem().getDetailsOfDamageItemsArray();
        this.damageDetailForm = this.fb.group({
            items: this.fb.array(this.buildStoredItems(this.detailsOfDamageItemsArray))
        });

        this.initializeArrays();
    }

    ngAfterViewInit() {
        this.damageDetailForm.valueChanges.subscribe(data => {
            this.detailsOfDamageItemsArray = [];
            for (let i = 0; i < data.items.length; i++) {

                let itemDetail : DamagePersonalItemDetail = new DamagePersonalItemDetail();
                itemDetail.setLossType(data.items[i].lossType);
                itemDetail.setLossTypeDesc(super.getIndexedTextInComponent('lossType', i));
                itemDetail.setLossTypeDetail(data.items[i].lossTypeDetail);
                itemDetail.setPurchaseDate(this.dateService.stringToDate(data.items[i].purchaseDate, 'dd/MM/yyyy'));
                itemDetail.setPurchasePriceType(data.items[i].purchasePriceType);
                itemDetail.setPurchasePrice(data.items[i].purchasePrice);
                itemDetail.setRepairCostType(data.items[i].repairCostType);
                itemDetail.setRepairCost(data.items[i].repairCost);
                itemDetail.setOtherTypeBriefDesc(data.items[i].otherTypeBriefDesc);

                if (this.claim.isHKGorHGI()) {
                    itemDetail.setHasBrokenHandle(data.items[i].hasBrokenHandle);
                    itemDetail.setHasDamagedWheel(data.items[i].hasDamagedWheel);
                    itemDetail.setHasDamagedZip(data.items[i].hasDamagedZip);
                    itemDetail.setHasScratches(data.items[i].hasScratches);
                    itemDetail.setHasCrushedCrackedTorn(data.items[i].hasCrushedCrackedTorn);
                    itemDetail.setHasNoDamagedParts(data.items[i].hasNoDamagedParts);
                    if (this.isNotNullOrUndefined(data.items[i].hasOriginalPurchasedReceipt)) {
                        itemDetail.setHasOriginalPurchasedReceipt(data.items[i].hasOriginalPurchasedReceipt == 'true');
                    }
                    if (this.isNotNullOrUndefined(data.items[i].hasRepairOrQuotationReceipt)) {
                        itemDetail.setHasRepairOrQuotationReceipt(data.items[i].hasRepairOrQuotationReceipt == 'true');
                    }
                    itemDetail.setDamageDetail(data.items[i].damageDetail);
                }

                if (this.isNotNullOrUndefined(data.items[i].wasWatchBeingStored)) {
                    itemDetail.setWasWatchBeingStored(data.items[i].wasWatchBeingStored == 'true');
                }
                this.detailsOfDamageItemsArray.push(itemDetail);
            }
            this.claim.getClaimTravel().getDamagePersonalItem().setDetailsOfDamageItemsArray(this.detailsOfDamageItemsArray);
            
            setTimeout(() => {
                this.validateClaimDetailsComplete();
            }, 200);
        });

        // Stop validation of fields when not required.
        let formArray : FormArray =  <FormArray> this.damageDetailForm.controls["items"];
        for (let i = 0; i < formArray.controls.length; i++) {
            formArray.controls[i].get('lossType').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
                this.damageTypeChanged(data, formArray.controls[i]);
            });
            formArray.controls[i].get('hasBrokenHandle').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
               this.hasDamagedPartsChanged(data, formArray.controls[i]);
            });
            formArray.controls[i].get('hasDamagedWheel').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
               this.hasDamagedPartsChanged(data, formArray.controls[i]);
            });
            formArray.controls[i].get('hasDamagedZip').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
               this.hasDamagedPartsChanged(data, formArray.controls[i]);
            });
            formArray.controls[i].get('hasScratches').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
               this.hasDamagedPartsChanged(data, formArray.controls[i]);
            });
            formArray.controls[i].get('hasCrushedCrackedTorn').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
                this.hasDamagedPartsChanged(data, formArray.controls[i]);
            });
            formArray.controls[i].get('hasNoDamagedParts').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
                this.hasNoDamagedPartsChanged(data, formArray.controls[i]);
            });
            formArray.controls[i].get('hasRepairOrQuotationReceipt').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
                this.hasRepairOrQuotationChanged(data, formArray.controls[i]);
            });
        }
    }

    initializeArrays() {
        if (UtilitiesService.isNullOrUndefined(this.detailsOfDamageItemsArray)) {
            this.detailsOfDamageItemsArray = [];
            this.claim.getClaimTravel().getDamagePersonalItem().setDetailsOfDamageItemsArray(this.detailsOfDamageItemsArray);
        }

        if (this.detailsOfDamageItemsArray.length === 0) {
            this.detailsOfDamageItemsArray.push(new DamagePersonalItemDetail());
        }
    }

    damageTypeChanged(lossType, formGroup) {
        formGroup.get('lossTypeDetail').disable({onlySelf: false, emitEvent: false});
        formGroup.get('otherTypeBriefDesc').disable({onlySelf: false, emitEvent: false});
        formGroup.get('wasWatchBeingStored').disable({onlySelf: false, emitEvent: false});
        formGroup.get('damageDetail').disable({onlySelf: false, emitEvent: false});
        formGroup.get('hasRepairOrQuotationReceipt').disable({onlySelf: false, emitEvent: false});

        if (lossType === 'Others') {
            formGroup.get('lossTypeDetail').enable({onlySelf: false, emitEvent: false});
            formGroup.get('otherTypeBriefDesc').enable({onlySelf: false, emitEvent: false});
        } else if(lossType == 'Watch'){
            if(this.claim.getCountry().toLowerCase() == "hkg" || this.claim.getCountry().toLowerCase() == "sgp"){
                formGroup.get('wasWatchBeingStored').enable({onlySelf: false, emitEvent: false});
            }
        } else if (lossType == 'Mobile Phone') {
            formGroup.get('lossTypeDetail').enable({onlySelf: false, emitEvent: false});
        } else if (lossType == 'Luggage / Trunk' || lossType == 'Suitcase / Case') {
            formGroup.get('hasRepairOrQuotationReceipt').enable({onlySelf: false, emitEvent: false});
        }

        formGroup.get('purchaseDate').enable({onlySelf: false, emitEvent: false});
        formGroup.get('purchasePriceType').enable({onlySelf: false, emitEvent: false});
        formGroup.get('purchasePrice').enable({onlySelf: false, emitEvent: false});
        formGroup.get('repairCostType').enable({onlySelf: false, emitEvent: false});
        formGroup.get('repairCost').enable({onlySelf: false, emitEvent: false});
        formGroup.get('hasOriginalPurchasedReceipt').enable({onlySelf: false, emitEvent: false});
    }

    hasNoDamagedPartsChanged(data, formGroup) {
        if (data == true) {
            formGroup.get('damageDetail').reset();
            formGroup.get('damageDetail').enable({onlySelf: false, emitEvent: false});
            formGroup.get('hasBrokenHandle').disable({onlySelf: false, emitEvent: false});
            formGroup.get('hasDamagedWheel').disable({onlySelf: false, emitEvent: false});
            formGroup.get('hasDamagedZip').disable({onlySelf: false, emitEvent: false});
            formGroup.get('hasScratches').disable({onlySelf: false, emitEvent: false});
            formGroup.get('hasCrushedCrackedTorn').disable({onlySelf: false, emitEvent: false});
        } else if (data == false) {
            formGroup.get('damageDetail').reset();
            formGroup.get('damageDetail').disable({onlySelf: false, emitEvent: false});
            formGroup.get('hasBrokenHandle').enable({onlySelf: false, emitEvent: false});
            formGroup.get('hasDamagedWheel').enable({onlySelf: false, emitEvent: false});
            formGroup.get('hasDamagedZip').enable({onlySelf: false, emitEvent: false});
            formGroup.get('hasScratches').enable({onlySelf: false, emitEvent: false});
            formGroup.get('hasCrushedCrackedTorn').enable({onlySelf: false, emitEvent: false});
        }
    }

    hasDamagedPartsChanged(data, formGroup) {
        if (formGroup.get('hasNoDamagedParts')?.value != true) {
            if (formGroup.get('hasBrokenHandle')?.value == true || formGroup.get('hasDamagedWheel')?.value == true ||
                 formGroup.get('hasDamagedZip')?.value == true || formGroup.get('hasScratches')?.value == true ||
                   formGroup.get('hasCrushedCrackedTorn')?.value == true) {
                formGroup.get('hasNoDamagedParts').disable({onlySelf: false, emitEvent: false});
            } else if (formGroup.get('hasBrokenHandle')?.value != true && formGroup.get('hasDamagedWheel')?.value != true &&
                         formGroup.get('hasDamagedZip')?.value != true && formGroup.get('hasScratches')?.value != true &&
                           formGroup.get('hasCrushedCrackedTorn')?.value != true) {
                formGroup.get('hasNoDamagedParts').enable({onlySelf: false, emitEvent: false});
            }
        } else {
            formGroup.get('hasBrokenHandle').disable();
            formGroup.get('hasDamagedWheel').disable();
            formGroup.get('hasDamagedZip').disable();
            formGroup.get('hasScratches').disable();
            formGroup.get('hasCrushedCrackedTorn').disable();
        }
    }


    hasRepairOrQuotationChanged(data, formGroup) {
        if (data && data == 'true') {
            formGroup.get('repairCost').reset();
            formGroup.get('repairCost').enable({onlySelf: false, emitEvent: false});
        } else {
            formGroup.get('repairCost').reset();
            formGroup.get('repairCost').disable({onlySelf: false, emitEvent: false});
        }
    }

    buildStoredItems(detailsOfDamageItemsArray: DamagePersonalItemDetail[]): FormGroup[] {
        let formGroupArray : FormGroup[] = [];

        if(detailsOfDamageItemsArray != null && detailsOfDamageItemsArray.length > 0 ) {

            for (let i = 0; i < detailsOfDamageItemsArray.length; i++) {
                let rowFormGroup = this.fb.group({
                    lossType: [detailsOfDamageItemsArray[i].getLossType()],
                    lossTypeDesc: [detailsOfDamageItemsArray[i].getLossTypeDesc()],
                    lossTypeDetail: [detailsOfDamageItemsArray[i].getLossTypeDetail()],
                    purchaseDate: [detailsOfDamageItemsArray[i].getPurchaseDate()],
                    purchasePriceType: [detailsOfDamageItemsArray[i].getPurchasePriceType()],
                    purchasePrice: [detailsOfDamageItemsArray[i].getPurchasePrice()],
                    repairCostType: [detailsOfDamageItemsArray[i].getRepairCostType()],
                    repairCost: [detailsOfDamageItemsArray[i].getRepairCost()],
                    otherTypeBriefDesc: [detailsOfDamageItemsArray[i].getOtherTypeBriefDesc()],
                    wasWatchBeingStored: [super.getBooleanString(detailsOfDamageItemsArray[i].getWasWatchBeingStored())],
                    hasBrokenHandle: [{value: detailsOfDamageItemsArray[i].getHasBrokenHandle(), disabled: detailsOfDamageItemsArray[i].getHasNoDamagedParts()}],
                    hasDamagedWheel: [{value: detailsOfDamageItemsArray[i].getHasDamagedWheel(), disabled: detailsOfDamageItemsArray[i].getHasNoDamagedParts()}],
                    hasDamagedZip: [{value: detailsOfDamageItemsArray[i].getHasDamagedZip(), disabled: detailsOfDamageItemsArray[i].getHasNoDamagedParts()}],
                    hasScratches: [{value: detailsOfDamageItemsArray[i].getHasScratches(), disabled: detailsOfDamageItemsArray[i].getHasNoDamagedParts()}],
                    hasCrushedCrackedTorn: [{value: detailsOfDamageItemsArray[i].getHasCrushedCrackedTorn(), disabled: detailsOfDamageItemsArray[i].getHasNoDamagedParts()}],
                    hasNoDamagedParts: [{value: detailsOfDamageItemsArray[i].getHasNoDamagedParts(), disabled: detailsOfDamageItemsArray[i].getHasBrokenHandle() ||
                            detailsOfDamageItemsArray[i].getHasDamagedWheel() || detailsOfDamageItemsArray[i].getHasDamagedZip() ||
                            detailsOfDamageItemsArray[i].getHasScratches() || detailsOfDamageItemsArray[i].getHasCrushedCrackedTorn()}],
                    hasOriginalPurchasedReceipt: [super.getBooleanString(detailsOfDamageItemsArray[i].getHasOriginalPurchasedReceipt())],
                    damageDetail: [detailsOfDamageItemsArray[i].getDamageDetail()],
                    hasRepairOrQuotationReceipt: [super.getBooleanString(detailsOfDamageItemsArray[i].getHasRepairOrQuotationReceipt())]
                });

                formGroupArray.push(rowFormGroup);
            }
        }
        else {
            formGroupArray.push(this.createDefaultItems());
        }

        return formGroupArray;
    }

    createDefaultItems(): FormGroup {
        let currency = UtilitiesService.getCountryCurrency(this.claim.getCountry());
        return this.fb.group({
            lossType: null,
            lossTypeDesc: '',
            lossTypeDetail: '',
            purchaseDate: '',
            purchasePriceType: currency,
            purchasePrice: '',
            repairCostType: currency,
            repairCost: '',
            otherTypeBriefDesc: '',
            wasWatchBeingStored: '',
            hasBrokenHandle: null,
            hasDamagedWheel: null,
            hasDamagedZip: null,
            hasScratches: null,
            hasCrushedCrackedTorn: null,
            hasNoDamagedParts: null,
            hasOriginalPurchasedReceipt: null,
            hasRepairOrQuotationReceipt: null,
            damageDetail: ''
        });

    }

    addItem(): void {
        let self = this;
        setTimeout(function () {
            let items = self.damageDetailForm.get('items') as FormArray;
            let newItem : FormGroup = self.createDefaultItems();
            items.push(newItem);

            newItem.get('lossType').valueChanges.pipe(takeUntil(self.ngUnsubscribe)).subscribe(data => {
                self.damageTypeChanged(data, newItem);
            });
            newItem.get('hasBrokenHandle').valueChanges.pipe(takeUntil(self.ngUnsubscribe)).subscribe(data => {
                self.hasDamagedPartsChanged(data, newItem);
            });
            newItem.get('hasDamagedWheel').valueChanges.pipe(takeUntil(self.ngUnsubscribe)).subscribe(data => {
                self.hasDamagedPartsChanged(data, newItem);
            });
            newItem.get('hasDamagedZip').valueChanges.pipe(takeUntil(self.ngUnsubscribe)).subscribe(data => {
                self.hasDamagedPartsChanged(data, newItem);
            });
            newItem.get('hasScratches').valueChanges.pipe(takeUntil(self.ngUnsubscribe)).subscribe(data => {
                self.hasDamagedPartsChanged(data, newItem);
            });
            newItem.get('hasCrushedCrackedTorn').valueChanges.pipe(takeUntil(self.ngUnsubscribe)).subscribe(data => {
                self.hasDamagedPartsChanged(data, newItem);
            });
            newItem.get('hasNoDamagedParts').valueChanges.pipe(takeUntil(self.ngUnsubscribe)).subscribe(data => {
                self.hasNoDamagedPartsChanged(data, newItem);
            });
            newItem.get('hasRepairOrQuotationReceipt').valueChanges.pipe(takeUntil(self.ngUnsubscribe)).subscribe(data => {
              self.hasRepairOrQuotationChanged(data, newItem);
            });

        }, 10);
    }

    removeItem(i) {
        let items = this.damageDetailForm.get('items') as FormArray;
        items.removeAt(i);

        items.value.forEach(formItem =>{
            this.detailsOfDamageItemsArray.forEach(item =>{
                if (item.getLossType() === formItem.lossType) {
                    item.setLossTypeDesc(formItem.lossTypeDesc);
                }
            });
        });
    }

    goToNext() {
        if (this.validateForm()) {
            this.sideMenuService.emitProcess(1, 0);
            this.sideMenuService.emitClaimComplete({claimTypeId: 2, subMenuIndex: 1});

            this.router.navigate(["/claimform/damagePersonalItem/reportTheDamage"], {
                relativeTo: this.activatedRoute
            });
        }
    }

    back() {
        this.router.navigate(["/claimform/damagePersonalItem/detailOfDamage"], {
            relativeTo: this.activatedRoute
        });
    }

    validateForm(): boolean {
        this.validatePurchasePriceRepairCost();
        let validateDamagedPartsSelection = this.validateDamageItemSelection();
        let result = super.validateForm(this.damageDetailForm) && validateDamagedPartsSelection;
        if (!result) {
            this.enablePurchasePriceRepairCost();
        }

        this.showCheckboxError = !validateDamagedPartsSelection;

        return result;
    }

    validatePurchasePriceRepairCost() {
        if (!this.claim.isHKGorHGI()) {
            let damagePersonalItem: DamagePersonalItem = this.claim.getClaimTravel().getDamagePersonalItem();
            let data: DamagePersonalItemDetail[] = damagePersonalItem.getDetailsOfDamageItemsArray();
            if (data != null && data.length > 0) {
                for (let i = 0; i < data.length; i++) {
                    if (this.isNotNullOrUndefinedNum(data[i].getPurchasePrice())
                        || this.isNotNullOrUndefinedNum(data[i].getRepairCost())) {
                        let formArray: FormArray = <FormArray>this.damageDetailForm.controls["items"];
                        for (let j = 0; j < formArray.controls.length; j++) {
                            let isPurchasePriceEmpty: boolean = !this.isNotNullOrUndefinedNum(data[j].getPurchasePrice());
                            let isRepairCostEmpty: boolean = !this.isNotNullOrUndefinedNum(data[j].getRepairCost());
                            if (isRepairCostEmpty) {
                                formArray.controls[i].get('repairCost').disable({onlySelf: false, emitEvent: false});
                            }
                            if (isPurchasePriceEmpty) {
                                formArray.controls[i].get('purchasePrice').disable({onlySelf: false, emitEvent: false});
                            }
                        }
                    }
                }
            }
        }
    }

    enablePurchasePriceRepairCost() {
        let damagePersonalItem: DamagePersonalItem = this.claim.getClaimTravel().getDamagePersonalItem();
        let data: DamagePersonalItemDetail[] = damagePersonalItem.getDetailsOfDamageItemsArray();
        if (data != null && data.length > 0) {
            for (let i = 0; i < data.length; i++) {
                if (this.isNotNullOrUndefinedNum(data[i].getPurchasePrice()) || this.isNotNullOrUndefinedNum(data[i].getRepairCost())) {
                    let formArray : FormArray =  <FormArray> this.damageDetailForm.controls["items"];
                    for (let i = 0; i < formArray.controls.length; i++) {
                        formArray.controls[i].get('repairCost').enable({onlySelf: false, emitEvent: false});
                        formArray.controls[i].get('purchasePrice').enable({onlySelf: false, emitEvent: false});
                    }
                }
            }
        }
    }

    validateMoneyFields(): boolean {
        let damagePersonalItem: DamagePersonalItem = this.claim.getClaimTravel().getDamagePersonalItem();
        let data: DamagePersonalItemDetail[] = damagePersonalItem.getDetailsOfDamageItemsArray();
        for (let i = 0; i < data.length; i++) {
            let formArray : FormArray =  <FormArray> this.damageDetailForm.controls["items"];
            for (let i = 0; i < formArray.controls.length; i++) {

                if ((UtilitiesService.isNullOrUndefined(formArray.controls[i].get('repairCost').value) || formArray.controls[i].get('repairCost').value == "") &&
                    (UtilitiesService.isNullOrUndefined(formArray.controls[i].get('purchasePrice').value) || formArray.controls[i].get('purchasePrice').value == "")) {
                    return true;
                }

            }

        }
        return false;
    }

    validateClaimDetailsComplete() {
        let isFormValidOnChange: boolean = super.validateFormOnChange(this.damageDetailForm);
        let isMoneyFieldsPopulated : boolean = this.validateMoneyFields();
        if(isFormValidOnChange && !isMoneyFieldsPopulated){
            this.sideMenuService.emitProcess(1, 0);
            this.sideMenuService.emitClaimComplete({claimTypeId: 2, subMenuIndex: 1});
        } else {
            this.sideMenuService.emitProcess(1, 0);
            this.sideMenuService.emitClaimIncomplete({claimTypeId: 2, subMenuIndex: 1});
        }
    }

    validateDamageItemSelection() {
        if (this.claim.isHKGorHGI()) {
            let damagePersonalItem: DamagePersonalItem = this.claim.getClaimTravel().getDamagePersonalItem();
            let data: DamagePersonalItemDetail[] = damagePersonalItem.getDetailsOfDamageItemsArray();
            if (data && data.length > 0) {
                for (let i = 0; i < data.length; i++) {
                    let formArray : FormArray =  <FormArray> this.damageDetailForm.controls["items"];
                    for (let i = 0; i < formArray.controls.length; i++) {
                        if (formArray.controls[i].get('lossType').value == 'Luggage / Trunk'
                            || formArray.controls[i].get('lossType').value == 'Suitcase / Case') {
                            if (formArray.controls[i].get('hasBrokenHandle')?.value !== true
                                && formArray.controls[i].get('hasDamagedWheel')?.value !== true
                                && formArray.controls[i].get('hasDamagedZip')?.value !== true
                                && formArray.controls[i].get('hasScratches')?.value !== true
                                && formArray.controls[i].get('hasCrushedCrackedTorn')?.value !== true
                                && formArray.controls[i].get('hasNoDamagedParts')?.value !== true) {
                                return false;
                            }

                        }
                    }
                }
            }

        }
        return true;
    }

    showModal() {
        this.showTotalBox = true;
    }

    getData(msg) {
        this.showTotalBox = msg;
    }

    //Google Analytics
    pushGAViewDamageItemDescription() {
        (<any>window).dataLayer.push({
            'pageStep': 'Damage Item – Item Details',
            'vPath': '/claim/damage-item/items',
            'event': 'vpageview',
        });
    }

}
