<div>
  <div class="prev-bar">
    <a (click)="back()" style="color: #0064c1" class="btn new-btn btn-secondary"><em class="fas fa-arrow-left"></em> {{ 'claimSection.generalLabels.generalButtonLabels.back' | translate}} </a>
  </div>
    
  <app-hospital-cash-details-details #hospitalCashDetails></app-hospital-cash-details-details>
  
  <br/><div class="divider"></div><br/>
  
  <div class="upload-doc-body">
    <div class="col-12">
      <div><h5>{{ 'claimSection.generalLabels.uploadDocuments.uploadYourDocuments' | translate }}</h5></div>
      <p>{{ 'claimSection.generalLabels.uploadDocuments.documentsNeeded1' | translate}}&nbsp;{{ 'claimSection.generalLabels.uploadDocuments.documentsNeeded2' | translate}}&nbsp;{{ 'pageSummaryClaim.claimItem.pleaseRetain' | translate}}</p>
    </div>
    <br>
    
    <app-pa-upload-doc></app-pa-upload-doc>

    <div class="col-lg-3 col-xl-3 col-md-12"> &nbsp; </div>
  </div>
  
  
  <div class="submit-bar">
    <abandon-button></abandon-button>
    <a href="javascript:;" (click)="showModal()" class="Tertiary" [hidden]="claim.isMox()"> {{ 'claimSection.generalLabels.generalButtonLabels.save' | translate}}</a>
    <button (click)="goToNext()" class="btn btn-primary float-right new-btn">&nbsp;{{ 'claimSection.generalLabels.generalButtonLabels.next' | translate}}&nbsp;<em class="fas fa-arrow-right"></em></button>
  </div>
  <app-save-box [showTotalBox]="showTotalBox" (childEvent)="getData($event)"></app-save-box>
</div>
  