<div class="form-group section terms-section"  [formGroup]="declarationTermsForm">
    <div class="section-header">
        <label class="section-title" [innerHtml]="'pageSummaryClaim.summaryDeclarations.title' | translate"></label>
    </div>
    <div class="section-content">
        <div class="form-check" *ngIf="!this.transactionInfo.isMotorProduct()">
           <span> <input #termsAndConditions type="checkbox" class="form-check-input" id="termsAndConditions" formControlName="termsAndConditions" [checked]="claim.getTermsAndConditionsCheckbox()" >
              <label for="termsAndConditions" class="summary-dec-sub-section"  [innerHtml]="'pageSummaryClaim.summaryDeclarations.termsAndConditions' | translate"></label>
           </span>
            <div class="text-danger" *ngIf="(declarationTermsForm.get('termsAndConditions').hasError('required') && (declarationTermsForm.get('termsAndConditions').dirty || declarationTermsForm.get('termsAndConditions').touched))">
                {{'pageSummaryClaim.termsConditions.requiredMsg1' | translate}}
            </div>
        </div>
        <div class="form-check" *ngIf="this.transactionInfo.isMotorProduct()">
           <span> <input #termsAndConditions type="checkbox" class="form-check-input" id="motorTermsAndConditions" formControlName="termsAndConditions" [checked]="claim.getTermsAndConditionsCheckbox()" >
              <label for="motorTermsAndConditions" class="summary-dec-sub-section" [innerHtml]="'pageSummaryClaim.summaryDeclarations.hkfiTermsAndConditions' | translate"></label>
           </span>
            <div class="text-danger" *ngIf="(declarationTermsForm.get('termsAndConditions').hasError('required') && (declarationTermsForm.get('termsAndConditions').dirty || declarationTermsForm.get('termsAndConditions').touched))">
                {{'pageSummaryClaim.termsConditions.requiredMsg2' | translate}}
            </div>
        </div>
         <div>
              <ul class="terms_conditions_list">
                  <li *ngIf="this.transactionInfo.getRealCountry() === 'HKG'"><a href="javascript:void(0);" (click)="openTermsAndCondition(PICS, 'PICS')">{{'pageSummaryClaim.summaryDeclarations.picsHkg' | translate }}</a></li>
                  <li *ngIf="this.transactionInfo.getRealCountry() !== 'HKG'"><a href="javascript:void(0);" (click)="openTermsAndCondition(PICS, 'PICS')">{{'pageSummaryClaim.summaryDeclarations.pics' | translate }}</a></li>
                  <li><a href="javascript:void(0);" (click)="openTermsAndCondition(DECLARATIONS, 'declaration')">{{'pageSummaryClaim.summaryDeclarations.declarationAndAuthorisation'  | translate }}</a></li>
              </ul>
          </div>
      </div>
  </div>

<!--  Personal Information Collection Statement Acknowledgement -->
<ng-template #PICS let-c="close" let-d="dismiss" class="white-popup mfp-hide mox-style">
    <div [ngClass]="{'mox-style': claim.isMox()}">
        <div class="modal-header">
            <h5 class="modal-title">{{'pageSummaryClaim.summaryDeclarations.pics' | translate}}</h5>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body policy-content modal_content" [innerHTML]="getPicsContent() | translate"></div>
        <div class="modal-footer">
            <button type="button" class="btn btn-light" (click)="c('Close click')">{{'completePage.body.generalButtonLabels.close' | translate}}</button>
        </div>
    </div>
</ng-template>

<ng-template #DECLARATIONS let-c="close" let-d="dismiss">
    <div [ngClass]="{'mox-style': claim.isMox()}">
        <div class="modal-header">
            <h5 class="modal-title">{{'pageSummaryClaim.summaryDeclarations.declarationAndAuthorisation' | translate}}</h5>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body policy-content modal_content" [innerHTML]="getDeclarationContent() | translate"></div>
        <div class="modal-footer">
            <button type="button" class="btn btn-light" (click)="c('Close click')">{{'completePage.body.generalButtonLabels.close' | translate}}</button>
        </div>
    </div>
</ng-template>

