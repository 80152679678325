<div class="acknowledgement-container" [ngClass]="{'mox-style': claim.isMox()}">
  <div class="acknowledgement-header-block">
    <div class="download-icon"></div>
    <h3>{{ 'paClaimMox.acknowledgement.welcomeToQBEEclaim' | translate}}</h3>
    <p class="header-description">{{ 'paClaimMox.acknowledgement.weAreAboutToStart' | translate}}</p>
  </div>
  <div class="policy-details-block">
    <div class="policy-details-row">
      <span class="detail-title">{{ 'paClaim.acknowledgement.policyNumber' | translate}}</span>
      <span class="detail-desc">{{claim.getPolicyNumber()}}</span>
    </div>
    <div class="policy-details-row">
      <span class="detail-title">{{ 'paClaim.acknowledgement.policyHolder' | translate}}</span>
      <span class="detail-desc">{{policyHolder}}</span>
    </div>
  </div>
  <div class="acknowledgement-block-splitter">
    <div class="splitter-excess"></div>
  </div>
  <div class="acknowledgement-cta policy-details-block">
    <div>
      <p>{{ 'paClaim.acknowledgement.content' | translate}}</p>
    </div>
    <div class="submit-bar">
      <button (click)="goToNext()" class="btn btn-primary new-btn">{{ 'paClaim.acknowledgement.acknowledgeContinue' | translate}}</button>
      <a (click)="cancel()" class="btn btn-cancel btn-secondary">{{ 'claimSection.generalLabels.generalButtonLabels.cancel' | translate }}</a>
    </div>
  </div>
</div>

