import { InsuredPerson } from "./insured-person.model";
import { Product } from "./product.model";
import { ClaimTypeItem } from "./claim-type-item.model";
import { DocumentField } from "./document-field";
import { DynamicRouteModel } from "./dynamic-route.model";
import { NavigationMenu } from "./navigation-menu.model";
import { ClaimTypeSubMenu } from "./claim-type-submenu.model";
import {ClaimTypes} from './claim-type.model';

export class TransactionInfo {
    public static readonly AGENT_CHANNEL = "AGENT";
    public static readonly PUBLIC_CHANNEL = "PUBLIC";
    public static readonly EFNOL_CHANNEL = "EFNOL";
    public static readonly PUBLIC_CLAIM_ACTION = "PublicClaim";
    public static readonly PUBLIC_CLAIM_NO_CODE_ACTION = "PublicClaimnoCode";
    public static readonly PUBLIC_SEARCH_CLAIM = "PublicSearchClaim";
    public static readonly AUTO_SAVE_RESUME = "AutoSaveResume";
    public static readonly TEST_MODE = "testMode";
    public static readonly EBUSINESS = "ebusiness";
    public static readonly CUSTOMER_PORTAL = "eService";
    public static readonly CLAIM_STATUS = "claimstatus";
    public static readonly REGISTER = "register";
    public static readonly RESET_PASSWORD = "resetPassword";
    public static readonly HK_EC_RISKS: ReadonlyArray<string> = ["BWC", "OWC", "MWC", "WCA"];
    public static readonly HKG_FPS_NOT_ELIGIBLE: ReadonlyArray<string> = [ClaimTypes[ClaimTypes.CLAIM_TYPE_MED_DTH],
        ClaimTypes[ClaimTypes.CLAIM_TYPE_DMH_DEATH], ClaimTypes[ClaimTypes.CLAIM_TYPE_MV_THEFT], ClaimTypes[ClaimTypes.CLAIM_TYPE_EC_FORM2],
        ClaimTypes[ClaimTypes.CLAIM_TYPE_EC_FORM2A], ClaimTypes[ClaimTypes.CLAIM_TYPE_GENERIC]];

    private integrationToken: string;
    private iv: string;
    private transactionType: string = "";
    private claimFinished: boolean = false;
    private customerVerified: boolean = false;
    private autoSaveEnabled: boolean = false;
    private securityCodeRequired: boolean = true;
    private isTransactionOrigin: boolean = false;
    private policyNumber: string = "";
    private name: string = "";
    private dateOfBirth: Date;
    private hkidPassportNumber: string = "";
    private authIdNumber: string = "";
    private country = "HKG";
    private product = new Product();
    private insuredPersonsList: InsuredPerson[] = [];
    private channel: string = TransactionInfo.AGENT_CHANNEL;
    private action: string = "";
    private numberOfRisks = 0;
    private hasMoreClaimType: string;
    private currentClaimType: string;
    private claimTypeItemList: ClaimTypeItem[] = [];
    private navigationMenu: NavigationMenu = new NavigationMenu();
    private entryUrl: string;
    private returnUrl: string;
    private origin: string = "";
    private genDocList: DocumentField[] = [];
    private documentCategory: string = "";
    private claimNumber: string = "";
    private matchedInsuredPerson: InsuredPerson = null;
    private policyHolderType: string = "";
    private dynamicRoutes: DynamicRouteModel[];
    private isClaimDetailsLabel: boolean = false;
    private isFromGeneric: boolean = false;
    private gaPageStep: string = "";
    private gaVPath: string = "";
    private saveEmail: string = "";
    private transactionApplicationOrigin = "";
    private hasOverseasBankIntermediary: boolean = false;
    private serviceUnit: string = "";
    private autoSaveId: string;
    private routeToGeneric: boolean;
    private productTypes: string[] = [];
    private catalogueCode: string;
    private riskType: string;
    private genericClaimType: string;
    private isRepairer: boolean = false;
    private policyStartDate: Date;
    private policyEndDate: Date;

    // For server logging.
    private loggingId: string = "SES" + Math.floor(Math.random() * 101) +
        new Date().getHours() + new Date().getMinutes() + new Date().getSeconds();

    setReturnUrl(value: string) {
        this.returnUrl = value;
    }

    getReturnUrl(): string {
        return this.returnUrl;
    }

    setIsTransactionOrigin(value: boolean) {
        this.isTransactionOrigin = value;
    }

    getIsTransactionOrigin(): boolean {
        return this.isTransactionOrigin;
    }

    setNavigationMenu(value: NavigationMenu) {
        this.navigationMenu = value;
    }

    getNavigationMenu(): NavigationMenu {
        return this.navigationMenu;
    }

    setClaimTypeItemList(value: ClaimTypeItem[]) {
        this.claimTypeItemList = value;
    }

    getClaimTypeItemList(): ClaimTypeItem[] {
        return this.claimTypeItemList;
    }

    setCurrentClaimType(value: string) {
        this.currentClaimType = value;
    }

    getCurrentClaimType(): string {
        return this.currentClaimType;
    }

    getHasMoreClaimType(): string {
        return this.hasMoreClaimType;
    }

    setHasMoreClaimType(value: string) {
        this.hasMoreClaimType = value;
    }

    public setProductByString(value: string) {
        this.product = new Product();
        this.product.setProductType(value);
    }

    public getProduct(): Product {
        return this.product;
    }

    public setProduct(value: Product) {
        this.product = value;
    }

    public getIntegrationToken(): string {
        return this.integrationToken;
    }

    public setIntegrationToken(value: string) {
        this.integrationToken = value;
    }

    public getIv(): string {
        return this.iv;
    }

    public setIv(value: string) {
        this.iv = value;
    }

    public getTransactionType(): string {
        return this.transactionType;
    }

    public setTransactionType(transactionType: string) {
        this.transactionType = transactionType;
    }

    public getClaimFinished(): boolean {
        return this.claimFinished;
    }

    public setClaimFinished(value: boolean) {
        this.claimFinished = value;
    }

    public getPolicyNumber(): string {
        return this.policyNumber;
    }

    public setPolicyNumber(value: string) {
        this.policyNumber = value;
    }

    getCustomerVerified(): boolean {
        return this.customerVerified;
    }

    setCustomerVerified(value: boolean) {
        this.customerVerified = value;
    }

    getCountry(): string {
        return this.country;
    }

    setCountry(value: string) {
        this.country = value;
    }

    getInsuredPersonsList(): InsuredPerson[] {
        return this.insuredPersonsList;
    }

    setInsuredPersonsList(value: InsuredPerson[]) {
        this.insuredPersonsList = value;
    }

    getChannel(): string {
        return this.channel;
    }

    setChannel(value: string) {
        this.channel = value;
    }

    getAction(): string {
        return this.action;
    }

    setAction(value: string) {
        this.action = value;
    }

    getAutoSaveEnabled(): boolean {
        return this.autoSaveEnabled;
    }

    setAutoSaveEnabled(value: boolean) {
        this.autoSaveEnabled = value;
    }

    getNumberOfRisks(): number {
        return this.numberOfRisks;
    }

    setNumberOfRisks(value: number) {
        this.numberOfRisks = value;
    }

    getSecurityCodeRequired(): boolean {
        return this.securityCodeRequired;
    }

    setSecurityCodeRequired(value: boolean) {
        this.securityCodeRequired = value;
    }

    getName(): string {
        return this.name;
    }

    setName(value: string) {
        this.name = value;
    }

    getHkidPassportNumber(): string {
        return this.hkidPassportNumber;
    }

    setHkidPassportNumber(value: string) {
        this.hkidPassportNumber = value;
    }

    getAuthIdNumber(): string {
        return this.authIdNumber;
    }

    setAuthIdNumber(value: string) {
        this.authIdNumber = value;
    }

    getDateOfBirth(): Date {
        return this.dateOfBirth;
    }

    setDateOfBirth(value: Date) {
        this.dateOfBirth = value;
    }

    getEntryUrl(): string {
        return this.entryUrl;
    }

    setEntryUrl(value: string) {
        this.entryUrl = value;
    }

    getLoggingId(): string {
        return this.loggingId;
    }

    setOrigin(value: string) {
        this.origin = value;
    }

    getOrigin(): string {
        return this.origin;
    }

    getDocumentCategory(): string {
        return this.documentCategory;
    }

    setDocumentCategory(value: string) {
        this.documentCategory = value;
    }

    getGenDocList(): DocumentField[] {
        return this.genDocList;
    }

    setGenDocList(value: DocumentField[]) {
        this.genDocList = value;
    }

    getClaimNumber(): string {
        return this.claimNumber;
    }

    setClaimNumber(value: string) {
        this.claimNumber = value;
    }

    getMatchedInsuredPerson(): InsuredPerson {
        return this.matchedInsuredPerson;
    }

    setMatchedInsuredPerson(value: InsuredPerson) {
        this.matchedInsuredPerson = value;
    }

    getPolicyHolderType(): string {
        return this.policyHolderType;
    }

    setPolicyHolderType(value: string) {
        this.policyHolderType = value;
    }

    setDynamicRoutes(dynamicRoutes: DynamicRouteModel[]) {
        this.dynamicRoutes = dynamicRoutes;
    }

    getDynamicRoutes(): DynamicRouteModel[] {
        return this.dynamicRoutes;
    }

    getIsClaimDetailsLabel(): boolean {
        return this.isClaimDetailsLabel;
    }

    setIsClaimDetailsLabel(value: boolean) {
        this.isClaimDetailsLabel = value;
    }

    getIsFromGeneric(): boolean {
        return this.isFromGeneric;
    }

    setIsFromGeneric(value: boolean) {
        this.isFromGeneric = value;
    }

    getGaPageStep(): string {
        return this.gaPageStep;
    }

    setGaPageStep(value: string) {
        this.gaPageStep = value;
    }

    getGaVPath(): string {
        return this.gaVPath;
    }

    setGaVPath(value: string) {
        this.gaVPath = value;
    }

    getSaveEmail(): string {
        return this.saveEmail;
    }

    setSaveEmail(value: string) {
        this.saveEmail = value;
    }
    getTransactionApplicationOrigin() {
        return this.transactionApplicationOrigin;
    }

    setTransactionApplicationOrigin(value: string) {
        this.transactionApplicationOrigin = value;
    }

    getHasOverseasBankIntermediary(): boolean {
        return this.hasOverseasBankIntermediary;
    }

    setHasOverseasBankIntermediary(value: boolean) {
        this.hasOverseasBankIntermediary = value;
    }

    getServiceUnit() {
        return this.serviceUnit;
    }

    setServiceUnit(value: string) {
        this.serviceUnit = value;
    }

    getAutoSaveId(): string {
        return this.autoSaveId;
    }

    setAutoSaveId(value: string) {
        this.autoSaveId = value;
    }

    getRouteToGeneric(): boolean {
        return this.routeToGeneric;
    }

    setRouteToGeneric(value: boolean) {
        this.routeToGeneric = value;
    }

    getProductTypes(): string[] {
        return this.productTypes;
    }

    setProductTypes(value: string[]) {
        this.productTypes = value;
    }

    getCatalogueCode(): string {
        return this.catalogueCode;
    }

    setCatalogueCode(value: string) {
        this.catalogueCode = value;
    }

    getRiskType(): string {
        return this.riskType;
    }

    setRiskType(value: string) {
        this.riskType = value;
    }

    getGenericClaimType(): string {
        return this.genericClaimType;
    }

    setGenericClaimType(value: string) {
        this.genericClaimType = value;
    }

    getIsRepairer(): boolean {
        return this.isRepairer;
    }
    
    setIsRepairer(value: boolean){
        this.isRepairer = value;
    }

    getRealCountry(): string {
        if ("HGI" == this.country) {
            return "HKG";
        } else {
            return this.country;
        }
    }

    getPolicyStartDate(): Date {
        return this.policyStartDate;
    }

    setPolicyStartDate(value: Date) {
        this.policyStartDate = value;
    }

    getPolicyEndDate(): Date {
        return this.policyEndDate;
    }

    setPolicyEndDate(value: Date) {
        this.policyEndDate = value;
    }

    isGenericClaim(): boolean {
        return (!this.isTravelProduct() && !this.isDomesticHelperProduct() && !this.isMotorProduct() &&
            !this.isHomeProduct() && !this.isAccidentProduct() && !this.isEmployeeCompensationProduct());
    }

    isTravelProduct(): boolean {
        return (this.isProduct("PIT", ["HKG", "HGI", "SGP", "MYS"]) ||
            this.isProduct("PTR", ["HKG", "HGI", "SGP"]) ||
            this.isProduct("PPT", ["HKG", "HGI"]) ||
            this.isProduct("PSA", ["HKG"]));
    }

    isDomesticHelperProduct(): boolean {
        return this.isProduct("DMH", ["HKG", "HGI"]);
    }

    isMotorProduct(): boolean {
        return this.isProduct("MVA", ["HKG", "HGI"]) && !this.getRouteToGeneric();
    }

    isHomeProduct(): boolean {
        return ((this.isProduct("HPK", ["HKG", "HGI"]) ||
                this.isProduct("HHH", ["HKG", "HGI"])) && !this.getRouteToGeneric());
    }

    isAccidentProduct(): boolean {
        return (this.isProduct("PAN", ["HKG", "HGI", "SGP"]) ||
            this.isProduct("PAD", ["HKG", "HGI", "SGP"]) ||
            (this.isProduct("PAM", ["HKG", "HGI"]) && this.serviceUnit !== "GP"));
    }

    isGroupAccidentProduct(): boolean {
        return this.isProduct("PAD", ["HKG", "HGI", "SGP"]);
    }

    isPersonalLineProduct(): boolean {
        //If there are any new personal line products, add their codes here
        if (["PIT", "PTR", "PPT", "PSA", "DMH", "MVA", "HPK", "HHH", "PAN"].indexOf(
            this.getProduct().getProductType()) != -1) {
            return true;
        }
        return false;
    }

    isGroupProduct(): boolean {
        return (this.isProduct("GMB", ["SGP", "MYS"]) ||
            this.isProduct("PAD", ["SGP", "MYS"]));
    }

    isEmployeeCompensationProduct(): boolean {
        return this.isProduct("WCA", ["HKG", "HGI"]) ||
            (TransactionInfo.HK_EC_RISKS.indexOf(this.riskType) !== -1 && ["HKG", "HGI"].indexOf(this.country) !== -1);
    }

    isTravelCorporatePolicyHolder(): boolean {
        return (this.isTravelProduct() && this.policyHolderType === "C" &&
            this.matchedInsuredPerson === null);
    }

    isTravelCorporateInsuredPerson(): boolean {
        return (this.isTravelProduct() && this.policyHolderType === "C" &&
            this.matchedInsuredPerson !== null);
    }

    isTravelPersonal(): boolean {
        return this.isTravelProduct() && this.policyHolderType === "P";
    }

    isResidentialFire(): boolean {
        return (this.isProduct("HHH", ["HKG"]) &&
            (this.catalogueCode === "HHHMOF" || this.catalogueCode === "HHHQOF"));
    }

    isLiabilityProduct() : boolean {
        return this.isProduct("PLB", ["HKG", "HGI"]);
    }

    hasSingleInsured(): boolean {
        return (this.isProduct("PAN", ["HKG", "HGI"]) ||
            this.isProduct("PAM", ["HKG", "HGI"])) &&
            this.insuredPersonsList?.length === 1;
    }

    getRoute(claimTypeId: number, subMenuIndex: number, nextRoute: boolean) {
        let claimTypeDetailMenu = this.navigationMenu.getClaimDetailMenu();
        let subMenus: ClaimTypeSubMenu[] =
            claimTypeDetailMenu[claimTypeId]["subMenus"];
        let subMenu = null;

        if (nextRoute) {
            for (let i = subMenuIndex + 1; i < subMenus.length; i++) {
                if (!subMenus[i]["hidden"]) {
                    subMenu = subMenus[i];
                    break;
                }
            }
        } else {
            for (let i = subMenuIndex - 1; i >= 0; i--) {
                if (!subMenus[i]["hidden"]) {
                    subMenu = subMenus[i];
                    break;
                }
            }
        }

        return subMenu;
    }

    getRouteByCurrentUrl(currentUrl: string, nextRoute: boolean): DynamicRouteModel {
        let claimTypeMenu= this.navigationMenu.getClaimDetailMenu().filter(claimTypeDetailMenu =>
            claimTypeDetailMenu.claimTypeId == this.getClaimTypeByCode('current')?.claimTypeId);
        let subMenu: any;

        if (claimTypeMenu) {
            let subMenus = claimTypeMenu[0]["subMenus"];
            let currentRouteId = subMenus.filter(subMenus => subMenus.url.endsWith(currentUrl))?.[0].id;
            if (nextRoute) {
                for (let i = currentRouteId + 1 ; i <= subMenus.length ; i++) {
                    if (!subMenus[i].hidden) {
                        subMenu = subMenus[i];
                        break;
                    }
                }
            } else {
                for (let i = currentRouteId - 1; i >= 0; i--) {
                    if (!subMenus[i].hidden) {
                        subMenu = subMenus[i];
                        break;
                    }
                }
            }
            if (subMenu) {
                return new DynamicRouteModel(claimTypeMenu[0].claimTypeId, currentRouteId, subMenu.url);
            }
        }

        return null;
    }

    getClaimTypeByCode(claimTypeCode: string): ClaimTypeItem {
        let claimTypeItem;
        if (this.claimTypeItemList) {
            if (claimTypeCode == 'current') {
                claimTypeCode = this.currentClaimType;
            }
            this.claimTypeItemList.forEach(function (claimType) {
                if (claimType.claimTypeCode == claimTypeCode) {
                    claimTypeItem = claimType;
                }
            });
        }
        return claimTypeItem;
    }

    getPolicyType(): string {
        if (this.isTravelProduct()) {
            return "travel";
        } else if (this.isDomesticHelperProduct()) {
            return "domesticHelper";
        } else if (this.isMotorProduct()) {
            return "motor";
        } else if (this.isHomeProduct()) {
            return "home";
        } else if (this.isEmployeeCompensationProduct()) {
            return "ec";
        } else if (this.isGenericClaim()) {
            return "generic";
        } else if (this.isAccidentProduct()) {
            return "accident";
        } else {
            return "unknown";
        }
    }

    private isProduct(productCode: string, countriesAllowed: string[]): boolean {
        let productType = this.getProduct().getProductType();

        if (productType && productType.toUpperCase().endsWith(productCode) &&
            countriesAllowed.indexOf(this.country) > -1) {
            return true;
        } else {
            return false;
        }
    }

    public getTransactionOrigin(): string {
        if (this.origin) {
            if (this.origin.toLowerCase().startsWith("internaluser")) {
                return "internal user";
            } else if (this.origin.toLowerCase().startsWith("qnectinternal")) {
                return "staff";
            } else if (this.origin.toLowerCase().startsWith("qnect")) {
                return "agent";
            }
        }
        return "customer";
    }

    static jsonConvertTransInfo(transactionInfo: TransactionInfo): TransactionInfo {
        transactionInfo = Object.assign(new TransactionInfo(), transactionInfo);

        if (transactionInfo.getInsuredPersonsList() != null) {
            let insuredPersonsList: InsuredPerson[] = [];
            for (const insuredPerson of transactionInfo.getInsuredPersonsList()) {
                let item = Object.assign(new InsuredPerson(), insuredPerson);
                item = InsuredPerson.jsonConvert(item);
                insuredPersonsList.push(item);
            }
            transactionInfo.setInsuredPersonsList(insuredPersonsList);
        }

        if (transactionInfo.getDynamicRoutes() != null) {
            let routesList: DynamicRouteModel[] = [];
            for (const dynamicRoute of transactionInfo.getDynamicRoutes()) {
                let routeItem = Object.assign(
                    new DynamicRouteModel(
                        dynamicRoute.claimIndex,
                        dynamicRoute.routeIndex,
                        dynamicRoute.routeUrl
                    ),
                    dynamicRoute
                );
                routesList.push(routeItem);
            }
            transactionInfo.setDynamicRoutes(routesList);
        }

        //convert claim type item list object
        if (transactionInfo.getClaimTypeItemList()) {
            let claimTypeItemLists: ClaimTypeItem[] = [];
            for (const claimTypeItemData of transactionInfo.getClaimTypeItemList()) {
                let claimTypeItem = Object.assign(
                    new ClaimTypeItem(),
                    claimTypeItemData
                );
                claimTypeItem = ClaimTypeItem.jsonConvert(claimTypeItem);
                claimTypeItemLists.push(claimTypeItem);
            }
            transactionInfo.setClaimTypeItemList(claimTypeItemLists);
        }

        //convert navigation menu object
        if (transactionInfo.getNavigationMenu()) {
            transactionInfo.setNavigationMenu(
                NavigationMenu.jsonConvertNavigationMenu(
                    transactionInfo.getNavigationMenu()
                )
            );
        }

        if (transactionInfo.getGenDocList()) {
            let docList: DocumentField[] = [];
            for (const docField of transactionInfo.getGenDocList()) {
                let docItem = Object.assign(
                    new DocumentField(
                        docField.id,
                        docField.description,
                        docField.smartqField,
                        docField.isRequired
                    ),
                    docField
                );
                docList.push(docItem);
            }
            transactionInfo.setGenDocList(docList);
        }

        if (transactionInfo.getPolicyStartDate() != null) {
            transactionInfo.setPolicyStartDate(new Date(transactionInfo.getPolicyStartDate()));
        }

        if (transactionInfo.getPolicyEndDate() != null) {
            transactionInfo.setPolicyEndDate(new Date(transactionInfo.getPolicyEndDate()));
        }

        return transactionInfo;
    }
}
