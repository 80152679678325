<div class="progress" [ngClass]="{'mox-style': claim.isMox(), 'lang-zh': (translate.currentLang === 'zh')}">
  <div class="progress-bar" role="progressbar" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100" [ngStyle]="{'width':pctOfProcess + '%'}">
  </div>
</div>
<main [ngClass]="{'mox-style': claim.isMox(), 'lang-zh': (translate.currentLang === 'zh')}">
  <div class="row container-wrapper">
    <div class="col-lg-3 col-xl-3 col-md-12 bg-light-blue text-left container-outter container-left">
      <app-sidemenu></app-sidemenu>
    </div>
    <div class="col-lg-9 col-xl-8 col-md-12 container-outter container-center text-left ">
      <router-outlet></router-outlet>
    </div>
    <div class="col-lg-1 col-xl-1 col-md-12 container-outter container-right "></div>
  </div>
</main>
<div class="tooltip_body" [style.left.px]="mouseX" [style.top.px]="mouseY" *ngIf="tooltipText">{{tooltipText}}</div>
