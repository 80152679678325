import {DocumentFormBuilderComponent} from './document-form-builder.component';
import {OnInit} from '@angular/core';
import {Claim} from '../../model/claim.model';
import {DocumentField} from '../../model/document-field';
import {SmartqDocuments} from '../../model/smartq-documents.model';
import {ClaimTypes} from '../../model/claim-type.model';

export class ECFormBuilder extends DocumentFormBuilderComponent implements OnInit {

    constructor(claim: Claim, claimType: string){
        super(claim);
        this.documentFormClaimType = claimType;
    }

    setRequiredDocuments(){
        let requiredDocuments: DocumentField[] = [];

        if (this.documentFormClaimType == ClaimTypes[ClaimTypes.CLAIM_TYPE_EC_FORM2B]) {
            requiredDocuments.push(new DocumentField('EMPCONTRACT', 'ecClaim.supportingDocuments.empContract', SmartqDocuments.PA_OTHER, true));
            requiredDocuments.push(this.addDocumentWithSTP(new DocumentField('SLCERT', 'ecClaim.supportingDocuments.slCertificate', SmartqDocuments.PA_MEDICAL, true), true,'Original Certificate (Sick Leave, Death)' ));
            requiredDocuments.push(this.addDocumentWithSTP(new DocumentField('MEDRCPT', 'ecClaim.supportingDocuments.medicalReceipt', SmartqDocuments.PAYMENTDOCUMENT, true), true,'Medical Receipts' ));
            requiredDocuments.push(new DocumentField('PAYRCPT', 'ecClaim.supportingDocuments.paymentReceipt', SmartqDocuments.PA_INVOICE, true));

        } else if (this.documentFormClaimType == ClaimTypes[ClaimTypes.CLAIM_TYPE_EC_FORM2A] || this.documentFormClaimType == ClaimTypes[ClaimTypes.CLAIM_TYPE_EC_FORM2]) {
            let salaryRecord = this.documentFormClaimType == ClaimTypes[ClaimTypes.CLAIM_TYPE_EC_FORM2A] ?
                'ecClaim.supportingDocuments.salaryRecordPrevToIncapacity' : 'ecClaim.supportingDocuments.salaryRecord';
            requiredDocuments.push(new DocumentField('EMPCONTRACT', 'ecClaim.supportingDocuments.empContract', SmartqDocuments.PA_OTHER, true));
            requiredDocuments.push(new DocumentField('SLRYREC', salaryRecord, SmartqDocuments.PA_OTHER, true));
            requiredDocuments.push(this.addDocumentWithSTP(new DocumentField('SLCERT', 'ecClaim.supportingDocuments.slCertificate', SmartqDocuments.PA_MEDICAL, true), this.isSTPDocumentRequired(),'Original Certificate (Sick Leave, Death)'));
            requiredDocuments.push(this.addDocumentWithSTP(new DocumentField('MEDRCPT', 'ecClaim.supportingDocuments.medicalReceipt', SmartqDocuments.PAYMENTDOCUMENT, true), this.isSTPDocumentRequired(),'Medical Receipts'));
            requiredDocuments.push(new DocumentField('PAYRCPT', 'ecClaim.supportingDocuments.paymentReceipt', SmartqDocuments.PA_INVOICE, true));
            requiredDocuments.push(new DocumentField('INCREP', 'ecClaim.supportingDocuments.incidentReport', SmartqDocuments.PA_REPORT, true));
            requiredDocuments.push(new DocumentField('HOSPDC', 'ecClaim.supportingDocuments.dischargeSummary', SmartqDocuments.PA_MEDICAL, true));
            requiredDocuments.push(new DocumentField('SKETCH', 'ecClaim.supportingDocuments.sketches', SmartqDocuments.PA_OTHER, true));
        }

        this.requiredDocuments = requiredDocuments;
    }
    private isSTPDocumentRequired(){
        if(this.documentFormClaimType == ClaimTypes[ClaimTypes.CLAIM_TYPE_EC_FORM2A] ){
            return false;
        }
        return true;
    }
}