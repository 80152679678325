<div class="accident-details-container" [ngClass]="{'mox-style': claim.isMox()}">
    <div class="prev-bar" *ngIf="!transactionInfo.hasSingleInsured()">
        <a (click)="back()" style="color: #0064c1" class="btn new-btn btn-secondary"><em class="fas fa-arrow-left"></em> {{ 'claimSection.generalLabels.generalButtonLabels.back' | translate}} </a>
        <a (click)="back()" class="back-chevron"></a>
    </div>
    <div class="col-12">
        <h5>{{ 'paClaim.accidentDetails.title' | translate }}</h5>
        <br *ngIf="claim.isMox()" />
        <p>{{ 'paClaim.accidentDetails.subTitle' | translate }}</p>
        <br *ngIf="claim.isMox()" />
        <p class="formNote" *ngIf="claim.isMox()">{{ 'requiredField' | translate }}</p>
    </div>
    <br/>
    <div class="row col-12 main-form">
        <div class="col-lg-9 col-md-12">
            <form>
                <div class="form-group">
                    <qnect-ui #PA_OCCUPATION [fieldId]="'PA_OCCUPATION'" [formGroup]="accidentDetailsForm" name="occupation"></qnect-ui>
                </div>
                <div class="form-group" *ngIf="claimPA.getAccidentDetails().getOccupation() == 'Others'">
                    <qnect-ui #PA_OCCUPATION_OTHERS [fieldId]="'PA_OCCUPATION_OTHERS'" [formGroup]="accidentDetailsForm" name="occupationOthers"></qnect-ui>
                </div>
                <div class="form-group">
                    <qnect-ui #PA_ACCIDENT_DATE [fieldId]="'PA_ACCIDENT_DATE'" [formGroup]="accidentDetailsForm" name="accidentDate"></qnect-ui>
                </div>
                <div class="form-group">
                    <qnect-ui #PA_ACCIDENT_DESCRIPTION [fieldId]="'PA_ACCIDENT_DESCRIPTION'" [formGroup]="accidentDetailsForm" name="accidentDescription"
                        [dynamicPlaceholder]="'paClaim.accidentDetails.accidentDescPlaceholder'"></qnect-ui>
                </div>                
            </form>
        </div>
        <div class="col-lg-3 col-xl-3 col-md-12 hide"> &nbsp; </div>
    </div>

    <br/><div class="divider"></div><br/>

    <div class="row col-12 body-parts-block">
        <div class="col-lg-9 col-md-12">
            <div><h5>{{ 'paClaim.injuryDetails.title' | translate }}</h5></div><br>
            <form>
                <app-pa-body-parts></app-pa-body-parts>

                <div class="form-group">
                    <qnect-ui #PA_HAS_ENCOUNTERED_SIMILAR [fieldId]="'PA_HAS_ENCOUNTERED_SIMILAR'" [formGroup]="injuryDetailsForm" name="hasEncounteredSimilarIncident"></qnect-ui>
                </div>
                <div *ngIf="this.claimPA.getInjuryDetails().getHasEncounteredSimilarIncident()" class="form-group">
                    <qnect-ui #PA_PLACE_OF_INCIDENT [fieldId]="'PA_PLACE_OF_INCIDENT'" [formGroup]="injuryDetailsForm" name="placeOfIncident"></qnect-ui>
                </div>
            </form>
        </div>
        <div class="col-lg-3 col-xl-3 col-md-12">
            &nbsp;
        </div>
    </div>
    
    <div class="submit-bar">
        <abandon-button></abandon-button>
        <a href="javascript:;" (click)="showModal()" class="Tertiary" [hidden]="claim.isMox()">{{ 'claimSection.generalLabels.generalButtonLabels.save' | translate}}</a>
        <button (click)="goToNext()" class="btn btn-primary float-right new-btn">&nbsp;{{ 'claimSection.generalLabels.generalButtonLabels.next' | translate}}&nbsp;<em class="fas fa-arrow-right"></em></button>
    </div>
    <app-save-box [showTotalBox]="showTotalBox" (childEvent)="getData($event)"></app-save-box>

</div>

<ng-template #confirm_delete_claim_type let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h5 class="modal-title">{{'homeProduct.deleteClaim.header' | translate }}</h5>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>{{'homeProduct.deleteClaim.question1' | translate }}</p>
        <span><p>{{'homeProduct.deleteClaim.question2' | translate }}</p></span>
    </div>
    <div class="modal-footer">
        <div class="col-md-6 text-right">
            <button type="button" class="btn btn-primary btn-sm" (click)="deleteClaimType()">{{'homeProduct.deleteClaim.yes' | translate }}</button>
        </div>
        <div class="col-md-6 text-left">
            <button type="button" class="btn btn-default btn-sm" (click)="c('Close click')">{{'homeProduct.deleteClaim.no' | translate }}</button>
        </div>
    </div>
</ng-template>
  
