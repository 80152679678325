import {Component, Injector, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormGroup} from '@angular/forms';
import { ClaimsBaseComponent } from 'src/app/claim-pages/claim-base.component';
import {takeUntil} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {OutpatientTreatment} from '../../../model/employee-compensation/outpatient-treatment.model';

@Component({
    selector: 'app-outpatient-treatment',
    templateUrl: './outpatient-treatment.component.html'
})
export class OutpatientTreatmentComponent extends ClaimsBaseComponent implements OnInit {

    showTotalBox = false;
    treatmentForm: FormGroup;
    outpatientTreatments: OutpatientTreatment[];
    incidentDate: Date;

    constructor(private fb: FormBuilder,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                private injector: Injector) {

        super(injector);
        this.incidentDate = this.claim.getClaimEmployeeCompensation().getAccidentDetails().getAccidentDate();
        this.outpatientTreatments = this.claim.getClaimEmployeeCompensation().getOutpatientTreatments();
    }

    ngOnInit() {
        this.treatmentForm = this.fb.group({
            items: this.fb.array(this.buildVisitDetails(this.outpatientTreatments))
        });
    }

    ngAfterViewInit() {
        this.treatmentForm.valueChanges.pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((data) => {
                let outpatientTreatments: OutpatientTreatment[] = [];

                for (let i = 0; i < data.items.length; i++) {

                    let visitDetail: OutpatientTreatment = new OutpatientTreatment();
                    visitDetail.setTreatmentDate(super.getIndexedValueInComponent("treatmentDate", i));
                    visitDetail.setMedicalExpense(data.items[i].medicalExpense);
                    visitDetail.setIsUnderChineseMedPractitioner(data.items[i].isUnderChineseMedPractitioner == 'true');

                    outpatientTreatments.push(visitDetail);
                }

                this.claim.getClaimEmployeeCompensation().setOutpatientTreatments(outpatientTreatments);
            });
    }

    buildVisitDetails(outpatientTreatments: OutpatientTreatment[]): FormGroup[] {
        let formGroupArray: FormGroup[] = [];

        if (outpatientTreatments !== null && outpatientTreatments.length > 0) {
            for (let outpatientTreatment of outpatientTreatments) {
                let rowFormGroup = this.fb.group({
                    treatmentDate: [outpatientTreatment.getTreatmentDate()],
                    medicalExpense: [outpatientTreatment.getMedicalExpense()],
                    isUnderChineseMedPractitioner: [super.getBooleanString(outpatientTreatment.getIsUnderChineseMedPractitioner())]
                });
                formGroupArray.push(rowFormGroup);
            }
        } else {
            formGroupArray.push(this.createDefaultVisitDetails());
        }

        return formGroupArray;
    }

    createDefaultVisitDetails(): FormGroup {
        return this.fb.group({
            treatmentDate: null,
            medicalExpense: '',
            isUnderChineseMedPractitioner: null
        });
    }

    addVisit(): void {
        let self = this;
        setTimeout(function () {
            let visits = self.treatmentForm.get('items') as FormArray;
            if (visits.length < 5) {
                visits.push(self.createDefaultVisitDetails());
            }
        }, 10);
    }

    removeVisit(i) {
        let visits = this.treatmentForm.get('items') as FormArray;
        visits.removeAt(i);
        this.outpatientTreatments.splice(i, 1);
    }

    validateForm(): boolean {
        return super.validateForm(this.treatmentForm);
    }

}
