import {Component, Injector, OnInit, ViewEncapsulation} from '@angular/core';
import { ClaimsBaseComponent } from '../../claim-base.component';
import { ClaimPersonalAccident } from 'src/app/model/claim-personal-accident.model';
import { ClaimService } from 'src/app/services/claim.service';
import { UtilitiesService } from 'src/app/utilities/utilities.service';
import { PersonalAccidentHelperService } from 'src/app/services/personal-accident-helper.service';

declare const $: any;

@Component({
    selector: 'app-pa-body-parts',
    templateUrl: './pa-body-parts.component.html',
    styleUrls: ['./pa-body-parts.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class PaBodyPartsComponent extends ClaimsBaseComponent implements OnInit {
    
    claimPA: ClaimPersonalAccident;
    paHelperService: PersonalAccidentHelperService;

    selectedBodyParts: string[] = [];
    selectedBodyPartsDesc: string[] = [];
    right: string = 'Right';
    left: string = 'Left';
    backBodyPartsList: string[] = ['Right Ankle', 'Left Ankle', 'Back', 'Right Elbow', 'Left Elbow'];
    selectBodyPartStatus: string = 'init';
    bodyPartsList = [];
    internalOrgan: string = '';
    internalOrganDesc: string = '';

    constructor(private claimService: ClaimService,
                private injector : Injector) {

        super(injector);
        this.claim = this.claimService.getClaim();
        this.claimPA = this.claim.getClaimPersonalAccident();
        this.paHelperService = this.injector.get(PersonalAccidentHelperService);
        this.right = this.translate.instant('paClaim.injuryDetails.right');
        this.left = this.translate.instant('paClaim.injuryDetails.left');
    }

    ngAfterViewInit() {
        
        this.selectBodyPartStatus = 'init';
        this.buildExistingInjuredBodyList();
        this.setRightLeftLabel();
    }

    flipImage() {
        const modelWrapper = document.querySelector('.model');
        if (modelWrapper.classList.contains('front')) {
            modelWrapper.classList.remove('front');
            modelWrapper.classList.add('back');

            $('#backDiv').css('display', 'block');
            $('#frontDiv').css('display', 'none');

            this.setSelectedBackBodyParts();
        } else {
            modelWrapper.classList.remove('back');
            modelWrapper.classList.add('front');

            $('#backDiv').css('display', 'none');
            $('#frontDiv').css('display', 'block');
        }
    }

    setSelectedBackBodyParts() {
        //highlight back parts if previously selected
        let selectedBodyPartsDesc = this.claimPA.getInjuryDetails().getInjuredPartsDescList();
        if (!UtilitiesService.isNullOrUndefined(selectedBodyPartsDesc) && selectedBodyPartsDesc.length > 0) {
            for(let desc of this.selectedBodyPartsDesc){
                if (this.backBodyPartsList.indexOf(desc) > -1) {
                    let backPart = this.paHelperService.geBodyPartByDesc(desc);
                    if (!UtilitiesService.isNullOrUndefined(backPart)) {
                        $('.back-container svg #' + backPart.id + ' path').css('fill', '#00a8f3');
                    }
                }
            }
        }
    }

    selectBodyPart(id: string) {
        let bodyPart = this.paHelperService.geBodyPartById(id);
        let name = '';
        let desc = '';
        if (!UtilitiesService.isNullOrUndefined(bodyPart)) {
            name = this.translate.instant(bodyPart.name);
            desc = bodyPart.description;
        }

        if (this.exists(name, this.selectedBodyParts)) {
            this.selectedBodyParts.splice(this.selectedBodyParts.indexOf(name), 1);
            this.selectedBodyPartsDesc.splice(this.selectedBodyPartsDesc.indexOf(desc), 1);
            this.highlightSelectedBodyPart(id, 'none')
        } else {
            this.selectedBodyParts.push(name);
            this.selectedBodyPartsDesc.push(desc);
            this.highlightSelectedBodyPart(id, '#00a8f3');
        }

        if (this.selectedBodyParts.length > 0) {
            this.selectBodyPartStatus = 'selected';
        } else {
            this.selectBodyPartStatus = 'none';
        }

        if (!UtilitiesService.isNullOrUndefined(this.selectedBodyParts) && this.selectedBodyParts.length > 0) {
            this.selectedBodyParts.sort((a, b) => a.localeCompare(b));
            this.claimPA.getInjuryDetails().setInjuredPartsList(this.selectedBodyParts);
        }
        if (!UtilitiesService.isNullOrUndefined(this.selectedBodyPartsDesc) && this.selectedBodyPartsDesc.length > 0) {
            this.selectedBodyPartsDesc.sort((a, b) => a.localeCompare(b));
            this.claimPA.getInjuryDetails().setInjuredPartsDescList(this.selectedBodyPartsDesc);
        }
    }

    exists(name: string, selected: string[]) {
        let isExists: boolean = false;
        if (!UtilitiesService.isNullOrUndefined(selected)) {
            isExists = selected.indexOf(name) > -1;
        }
        return isExists;
    }

    highlightSelectedBodyPart(id: string, fillColor: string) {
        let pathId = '.back-container svg #' + id + ' path';
        if ($('#frontDiv').css('display') ==='block') {
            pathId = '.front-container svg #' + id + ' path';
        }
        $(pathId).css('fill', fillColor);
        $(pathId).css('fill-opacity', 1);

        // Add or remove a CSS class based on the fillColor
        if (fillColor !== 'none') {
            $(pathId).addClass('selected-body-part'); // Add class for selected body parts
        } else {
            $(pathId).removeClass('selected-body-part'); // Remove class if deselected
        }
    }

    buildExistingInjuredBodyList() {
        this.bodyPartsList = this.paHelperService.getInjuredBodyParts();
        this.selectedBodyParts = this.claimPA.getInjuryDetails().getInjuredPartsList();
        this.selectedBodyPartsDesc = this.claimPA.getInjuryDetails().getInjuredPartsDescList();
        if (!UtilitiesService.isNullOrUndefined(this.selectedBodyPartsDesc) && this.selectedBodyPartsDesc.length > 0) {
            this.selectBodyPartStatus = 'selected';
            for (let injuredBodyPart of this.selectedBodyPartsDesc) {
                for(let bodyPart of this.bodyPartsList){
                    if(injuredBodyPart === bodyPart['description']) {
                        this.highlightSelectedBodyPart(bodyPart['id'], '#00a8f3');
                    }
                }
            }
        } else {
            this.resetSelectedBodyParts();
        }
    }

    resetSelectedBodyParts() {
        this.selectedBodyParts = [];
        this.selectedBodyPartsDesc = [];
        this.claimPA.getInjuryDetails().setInjuredPartsList([]);
        this.claimPA.getInjuryDetails().setInjuredPartsDescList([]);
    }

    setRightLeftLabel() {
        let self = this;
        setTimeout(function () {
            let bodyPartDiv = document.getElementById('bodyPartDiv');
            if (!UtilitiesService.isNullOrUndefined(bodyPartDiv)) {
                bodyPartDiv.setAttribute('data-right', self.right);
                bodyPartDiv.setAttribute('data-left', self.left);
            }}
        ,10);
    }

    checkSelectBodyPartStatus() {
        if (this.claimPA.getInjuryDetails().getHasBeenInjured() && this.selectBodyPartStatus === 'init') {
            this.selectBodyPartStatus = 'none';
        }
    }

    addInternalDamage() {
        if (!this.exists(this.internalOrgan, this.selectedBodyParts)) {
            this.selectedBodyParts.push(this.internalOrgan);
            this.selectedBodyPartsDesc.push(this.internalOrganDesc);
        }
    }

    removeInternalDamage() {
        if (this.exists(this.internalOrgan, this.selectedBodyParts)) {
            this.selectedBodyParts.splice(this.selectedBodyParts.indexOf(this.internalOrgan), 1);
            this.selectedBodyPartsDesc.splice(this.selectedBodyPartsDesc.indexOf(this.internalOrganDesc), 1);
        }
    }
}
