import { DatePipe } from '@angular/common';
import {Component, Injector, OnInit, ViewEncapsulation} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ClaimsBaseComponent } from 'src/app/claim-pages/claim-base.component';
import { TransactionInfo } from 'src/app/model/transaction-info.model';
import { StoredDataService } from 'src/app/services/stored-data-service';
import { TransactionInfoService } from 'src/app/services/transaction-info.service';

@Component({
    selector: 'app-acknowledgement',
    templateUrl: './acknowledgement.component.html',
    styleUrls: ['./acknowledgement.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class AcknowledgementComponent extends ClaimsBaseComponent implements OnInit {

    transactionInfo : TransactionInfo;
    policyHolder: string;

    constructor(private activatedRoute: ActivatedRoute,
                private router: Router,
                private injector : Injector,
                private transactionInfoService: TransactionInfoService,
                private storedDataService : StoredDataService) {
        super(injector);
        this.transactionInfo = this.transactionInfoService.getTransactionInfo();
    }

    ngOnInit() {
        this.policyHolder = this.claim.getClaimantName() ? this.claim.getClaimantName() : this.claim.getPolicyHolderName();
    }

    goToNext() {
        if (this.transactionInfo.hasSingleInsured()) {
            this.router.navigate(["/claimform/paDetailsOfAccident"], { relativeTo: this.activatedRoute });
        } else {
            this.router.navigate(["/claimform/selectInsured"], { relativeTo: this.activatedRoute });
        }
    }

    cancel() {
        this.storedDataService.clearSession();
        window.location.href = decodeURIComponent(this.claim.getCallbackUrl());
    }

    getDate(dt: Date) {
        if (dt && dt != null) {
            return new DatePipe('en-US').transform(dt, "dd MMM yyyy");
        } else {
            return "";
        }
    }
}
