import {Component, Injector, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from "@angular/router";
import {takeUntil} from "rxjs/operators";
import { ClaimsBaseComponent } from 'src/app/claim-pages/claim-base.component';
import { SideMenuService } from 'src/app/services/side-menu.service';
import { TransactionInfoService } from 'src/app/services/transaction-info.service';
import {CompensationDetails} from '../../../model/employee-compensation/compensation-details.model';
import {ClaimTypes} from '../../../model/claim-type.model';
import {PeriodOfSickLeaveComponent} from '../period-of-sick-leave/period-of-sick-leave.component';
import {TransactionInfo} from '../../../model/transaction-info.model';
import {OutpatientTreatmentComponent} from '../outpatient-treatment/outpatient-treatment.component';

@Component({
    selector: 'app-ec-compensation-details',
    templateUrl: './ec-compensation-details.component.html'
})
export class ECCompensationDetailsComponent extends ClaimsBaseComponent implements OnInit {

    compensationDetailsForm: FormGroup;
    compensationDetails: CompensationDetails;
    showTotalBox = false;
    currency: string;
    transactionInfo: TransactionInfo;
    currentRoute: string;
    isForm2b: boolean = false;
    hasOutpatientTreatment: boolean = false;
    showOutpatientTreatment: boolean = false;
    @ViewChild('periodofsickleave', {static: false}) periodOfSickLeaveComponent: PeriodOfSickLeaveComponent;
    @ViewChild('outpatientTreatment', {static: false}) outpatientTreatment: OutpatientTreatmentComponent;

    constructor(private fb: FormBuilder,
                private transactionInfoService: TransactionInfoService,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                private injector : Injector,
                private sideMenuService: SideMenuService) {
        super(injector);
        this.compensationDetails = this.claim.getClaimEmployeeCompensation().getCompensationDetails();
        this.currency = this.claim.getClaimEmployeeCompensation().getCurrency();
        this.transactionInfo = this.injector.get(TransactionInfoService).getTransactionInfo();
        this.isForm2b = this.transactionInfo.getCurrentClaimType() == ClaimTypes[ClaimTypes.CLAIM_TYPE_EC_FORM2B];
        this.currentRoute = this.isForm2b ? '/compensationDetails' : '/directSettlement';
        this.hasOutpatientTreatment = this.claim.getCountry()?.toLowerCase() == 'hkg' && this.claim.getClaimEmployeeCompensation()
            .getAccidentDetails()?.getTreatmentType()?.find(items => items.value?.toLowerCase() == 'out-patient treatment');
        this.showOutpatientTreatment = this.hasOutpatientTreatment && (this.isForm2b || (this.transactionInfo.getCurrentClaimType() == ClaimTypes[ClaimTypes.CLAIM_TYPE_EC_FORM2]
            && this.claim.getClaimEmployeeCompensation().getIsSickLeaveContinuing() == false));
    }

    ngOnInit() {
        this.compensationDetailsForm = this.fb.group({
            monthlyEarnings: [this.compensationDetails.getMonthlyEarnings()],
            compensationAmount: [this.compensationDetails.getCompensationAmount()],
            paymentStatus: [this.compensationDetails.getPaymentStatus()],
            paymentDate: [this.compensationDetails.getPaymentDate()]
        });
    }

    ngAfterViewInit() {
        this.compensationDetailsForm.valueChanges.pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(() => {
                this.compensationDetails.setMonthlyEarnings(this.compensationDetailsForm.get('monthlyEarnings').value);
                this.compensationDetails.setCompensationAmount(this.compensationDetailsForm.get('compensationAmount').value);
                this.compensationDetails.setPaymentStatus(this.compensationDetailsForm.get('paymentStatus').value);
                this.compensationDetails.setPaymentStatusDesc(super.getSelectedRadioText('paymentStatus'));
                if(super.getValueInComponent('paymentDate') != null){
                    this.compensationDetails.setPaymentDate(super.getValueInComponent('paymentDate'));
                }
            });

        this.compensationDetailsForm.get('paymentStatus').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
            this.validatePaymentDate();
        });

        this.periodOfSickLeaveComponent.sickLeaveForm.get('isSickLeaveContinuing').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data) => {
            this.showHideOutpatientTreatmentForm(data);
        });
    }

    validatePaymentDate() {
        if (this.toBePaid(this.compensationDetailsForm.get('paymentStatus').value)) {
            this.compensationDetailsForm.get('paymentDate').enable({onlySelf: false, emitEvent: false});
        } else {
            this.compensationDetailsForm.get('paymentDate').reset();
            this.compensationDetailsForm.get('paymentDate').disable({onlySelf: false, emitEvent: false})
        }
    }

    showHideOutpatientTreatmentForm(data) {
       if (this.transactionInfo.getCurrentClaimType() == ClaimTypes[ClaimTypes.CLAIM_TYPE_EC_FORM2] && this.hasOutpatientTreatment && data == 'false') {
           this.showOutpatientTreatment = true;
       } else {
           this.showOutpatientTreatment = false;
           if (this.claim.getClaimEmployeeCompensation().getOutpatientTreatments()?.length > 0) {
               this.claim.getClaimEmployeeCompensation().setOutpatientTreatments([]);
           }
       }
    }

    toBePaid(value: any) : boolean {
        return value == 'To be paid';
    }

    validateForm(): boolean {
        return super.validateForm(this.compensationDetailsForm);
    }
    
    goToNext() {
        let isSickLeaveFormValid = this.periodOfSickLeaveComponent.validateForm();
        let outpatientFormValid = this.showOutpatientTreatment ? this.outpatientTreatment.validateForm() : true;
        if (this.validateForm() && isSickLeaveFormValid && outpatientFormValid) {
            let route = this.transactionInfo.getRouteByCurrentUrl(this.currentRoute, true);
            if (route) {
                this.sideMenuService.emitClaimComplete({claimTypeId: route.claimIndex, subMenuIndex: route.routeIndex});
                this.sideMenuService.emitProcess(1, 0);
                this.router.navigate([route.routeUrl], {
                    relativeTo: this.activatedRoute
                })
            }
        }

    }

    back() {
        let route = this.transactionInfo.getRouteByCurrentUrl(this.currentRoute, false);
        this.router.navigate([route.routeUrl], {
            relativeTo: this.activatedRoute
        });
    }

    showModal() {
        this.showTotalBox = true;
    }
  
    getData(msg) {
        this.showTotalBox = msg;
    }
}
