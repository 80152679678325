export class DamagePersonalItemDetail {
    private lossType: string;
    private lossTypeDesc: string;
    private lossTypeDetail: string;
    private hasBrokenHandle: boolean;
    private hasDamagedWheel: boolean;
    private hasDamagedZip: boolean;
    private hasScratches: boolean;
    private hasCrushedCrackedTorn: boolean;
    private hasNoDamagedParts: boolean;
    private hasOriginalPurchasedReceipt: boolean;
    private hasRepairOrQuotationReceipt: boolean;
    private damageDetail: string;
    private purchaseDate: Date;
    private purchasePriceType: string;
    private purchasePrice: number;
    private repairCostType: string;
    private repairCost: number;
    private otherTypeBriefDesc: string;
    private wasWatchBeingStored: boolean;
    private depreciationRate: string;
    private excessDepreciation: number;

    constructor() {
      this.lossType = '';
      this.lossTypeDesc = '';
      this.lossTypeDetail = '';
      this.purchaseDate = null;
      this.purchasePriceType = '';
      this.purchasePrice = null;
      this.repairCostType = '';
      this.repairCost = null;
      this.otherTypeBriefDesc = '';
      this.wasWatchBeingStored = null;
      this.depreciationRate = 'NA';
      this.excessDepreciation = 0;
      this.damageDetail = '';
      this.hasBrokenHandle = null;
      this.hasDamagedWheel = null;
      this.hasDamagedZip = null;
      this.hasScratches = null;
      this.hasCrushedCrackedTorn = null;
      this.hasNoDamagedParts = null;
      this.hasOriginalPurchasedReceipt = null;
      this.hasRepairOrQuotationReceipt = null;
  }

  getLossType(): string {
    return this.lossType;
  }

  setLossType(value: string){
    this.lossType = value;
  }

  getLossTypeDesc(): string {
    return this.lossTypeDesc;
  }

  setLossTypeDesc(value: string){
    this.lossTypeDesc = value;
  }

  getLossTypeDetail(): string {
    return this.lossTypeDetail;
  }

  setLossTypeDetail(value: string){
    this.lossTypeDetail = value;
  }

  getPurchaseDate(): Date {
    return this.purchaseDate;
  }

  setPurchaseDate(value: Date){
    this.purchaseDate = value;
  }

  getPurchasePriceType(): string {
    return this.purchasePriceType;
  }

  setPurchasePriceType(value: string){
    this.purchasePriceType = value;
  }

  getPurchasePrice(): number {
    return this.purchasePrice;
  }

  setPurchasePrice(value: number){
    this.purchasePrice = value;
  }

  getRepairCostType(): string {
    return this.repairCostType;
  }

  setRepairCostType(value: string){
    this.repairCostType = value;
  }

  getRepairCost(): number {
    return this.repairCost;
  }

  setRepairCost(value: number){
    this.repairCost = value;
  }
  getOtherTypeBriefDesc(): string {
    return this.otherTypeBriefDesc;
  }

  setOtherTypeBriefDesc(value: string){
    this.otherTypeBriefDesc = value;
  }

  setWasWatchBeingStored(value: boolean) {
    this.wasWatchBeingStored = value;
  }

  getWasWatchBeingStored(): boolean {
    return this.wasWatchBeingStored;
  }

  getDepreciationRate(): string {
      return this.depreciationRate;
  }

  setDepreciationRate(value: string){
      this.depreciationRate = value;
  }

  getExcessDepreciation(): number {
        return this.excessDepreciation;
  }

  setExcessDepreciation(value: number){
        this.excessDepreciation = value;
  }

  getHasBrokenHandle(): boolean {
    return this.hasBrokenHandle;
  }

  setHasBrokenHandle(value: boolean) {
    this.hasBrokenHandle = value;
  }

  getHasDamagedWheel(): boolean {
    return this.hasDamagedWheel;
  }

  setHasDamagedWheel(value: boolean) {
    this.hasDamagedWheel = value;
  }

  getHasDamagedZip(): boolean {
    return this.hasDamagedZip;
  }

  setHasDamagedZip(value: boolean) {
    this.hasDamagedZip = value;
  }

  getHasScratches(): boolean {
    return this.hasScratches;
  }

  setHasScratches(value: boolean) {
    this.hasScratches = value;
  }

  getHasCrushedCrackedTorn(): boolean {
     return this.hasCrushedCrackedTorn;
  }

  setHasCrushedCrackedTorn(value: boolean) {
    this.hasCrushedCrackedTorn = value;
  }

  getHasNoDamagedParts(): boolean {
    return this.hasNoDamagedParts;
  }

  setHasNoDamagedParts(value: boolean) {
    this.hasNoDamagedParts = value;
  }


  getDamageDetail(): string {
    return this.damageDetail;
  }

  setDamageDetail(value: string) {
    this.damageDetail = value;
  }

  getHasOriginalPurchasedReceipt(): boolean {
    return this.hasOriginalPurchasedReceipt;
  }

  setHasOriginalPurchasedReceipt(value: boolean) {
    this.hasOriginalPurchasedReceipt = value;
  }

  getHasRepairOrQuotationReceipt(): boolean {
    return this.hasRepairOrQuotationReceipt;
  }

  setHasRepairOrQuotationReceipt(value: boolean) {
    this.hasRepairOrQuotationReceipt = value;
  }

  static jsonConvert(damagePersonalItemDetail: DamagePersonalItemDetail): DamagePersonalItemDetail {

    damagePersonalItemDetail = Object.assign(new DamagePersonalItemDetail(), damagePersonalItemDetail);

    if (damagePersonalItemDetail.getPurchaseDate() != null) {
      damagePersonalItemDetail.setPurchaseDate(new Date(damagePersonalItemDetail.getPurchaseDate()));
      }


    return damagePersonalItemDetail;
  }
}
