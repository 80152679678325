import {Component, Injector, OnInit} from "@angular/core";
import {FormArray, FormBuilder, FormGroup} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {takeUntil} from "rxjs";
import { ClaimsBaseComponent } from "src/app/claim-pages/claim-base.component";
import { LostPersonalItemDetail } from "src/app/model/travel/lost-personal-detail.model";
import { SideMenuService } from "src/app/services/side-menu.service";
import { LostPersonalItem } from "src/app/model/travel/lost-personal-item.model";
import { TransactionInfoService } from "src/app/services/transaction-info.service";
import { UtilitiesService } from "src/app/utilities/utilities.service";
import { DateService } from "src/app/ui/ui-datepicker/date.service";

@Component({
    selector: 'app-lost-items-details',
    templateUrl: './lost-items-details.component.html',
    styleUrls: ['./lost-items-details.component.css']
})
export class LostItemsDetailsComponent extends ClaimsBaseComponent implements OnInit {

    lossDetailForm: FormGroup;
    items: FormArray[];
    selectType: string;
    showTotalBox = false;
    arrayItems: any;
    detailsOfLostItemsArray: LostPersonalItemDetail[] = [];
    currency: string;
    country: string;
    lostPersonalitem: LostPersonalItem;

    constructor(private fb: FormBuilder, 
                public sideMenuService: SideMenuService,
                private activatedRoute: ActivatedRoute,
                private transactionInfoService: TransactionInfoService,
                private router: Router,
                private injector : Injector,
                private dateService: DateService) {

            super(injector);
            this.lostPersonalitem = this.claim.getClaimHome().getHomeDetails().getLostPersonalItem();
    }

    ngOnInit() {

        this.pushGALostDetailsView();

        this.country = this.claim.getCountry().toLowerCase();
        this.currency = UtilitiesService.getCountryCurrency(this.claim.getCountry());

        this.detailsOfLostItemsArray = this.lostPersonalitem.getDetailsOfLostItemsArray();
        this.lossDetailForm = this.fb.group({
            items: this.fb.array(this.buildStoredItems(this.detailsOfLostItemsArray))
        });

        this.initializeArrays();
    }

    ngAfterViewInit() {
        this.lossDetailForm.valueChanges.subscribe(data => {
            this.detailsOfLostItemsArray = [];
            for (let i = 0; i < data.items.length; i++) {

                let itemDetail : LostPersonalItemDetail = new LostPersonalItemDetail();

                itemDetail.setLossType(data.items[i].lossType);
                itemDetail.setLossTypeDesc(super.getIndexedTextInComponent("lossType", i));
                itemDetail.setLossTypeDetail(data.items[i].lossTypeDetail);
                itemDetail.setPurchaseDate(this.dateService.stringToDate(data.items[i].purchaseDate, 'dd/MM/yyyy'));
                itemDetail.setPurchasePriceType(data.items[i].purchasePriceType);
                itemDetail.setPurchasePrice(data.items[i].purchasePrice);
                itemDetail.setCostOfRepalceCostType(data.items[i].costOfRepalceCostType);
                itemDetail.setCostOfRepalceCost(data.items[i].costOfRepalceCost);
                itemDetail.setLostMoneyType(data.items[i].lostMoneyType);
                itemDetail.setLostMoneyAmount(data.items[i].lostMoneyAmount);
                itemDetail.setUnauthorisedMoneyLossType(data.items[i].unauthorisedMoneyLossType);
                itemDetail.setUnauthorisedMoneyLoss(data.items[i].unauthorisedMoneyLoss);
                itemDetail.setOtherTypeBriefDesc(data.items[i].otherTypeBriefDesc);
                this.detailsOfLostItemsArray.push(itemDetail);
            }
            this.lostPersonalitem.setDetailsOfLostItemsArray(this.detailsOfLostItemsArray);
        });

        // Stop validation of fields when not required.
        let formArray : FormArray =  <FormArray> this.lossDetailForm.controls["items"];
        for (let i = 0; i < formArray.controls.length; i++) {
            formArray.controls[i].get('lossType').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
                this.lossTypeChanged(data, formArray.controls[i]);
            });
        }
    }

    initializeArrays() {
        if (UtilitiesService.isNullOrUndefined(this.detailsOfLostItemsArray)) {
            this.detailsOfLostItemsArray = [];
            this.lostPersonalitem.setDetailsOfLostItemsArray(this.detailsOfLostItemsArray);
        }

        if (this.detailsOfLostItemsArray.length === 0) {
            this.detailsOfLostItemsArray.push(new LostPersonalItemDetail());
        }
    }

    lossTypeChanged(lossType, formGroup) {
        formGroup.get('lossTypeDetail').disable({onlySelf: false, emitEvent: false});
        formGroup.get('purchaseDate').disable({onlySelf: false, emitEvent: false});
        formGroup.get('purchasePriceType').disable({onlySelf: false, emitEvent: false});
        formGroup.get('purchasePrice').disable({onlySelf: false, emitEvent: false});
        formGroup.get('costOfRepalceCostType').disable({onlySelf: false, emitEvent: false});
        formGroup.get('costOfRepalceCost').disable({onlySelf: false, emitEvent: false});
        formGroup.get('lostMoneyType').disable({onlySelf: false, emitEvent: false});
        formGroup.get('lostMoneyAmount').disable({onlySelf: false, emitEvent: false});
        formGroup.get('unauthorisedMoneyLossType').disable({onlySelf: false, emitEvent: false});
        formGroup.get('unauthorisedMoneyLoss').disable({onlySelf: false, emitEvent: false});
        formGroup.get('otherTypeBriefDesc').disable({onlySelf: false, emitEvent: false});

        if (lossType == 'Passport / HKID / China Re-entry card' || lossType == 'Passport / NRIC / FIN') {
            formGroup.get('costOfRepalceCostType').enable({onlySelf: false, emitEvent: false});
            formGroup.get('costOfRepalceCost').enable({onlySelf: false, emitEvent: false});
        }
        else if (lossType == 'Personal Money') {
            formGroup.get('lostMoneyType').enable({onlySelf: false, emitEvent: false});
            formGroup.get('lostMoneyAmount').enable({onlySelf: false, emitEvent: false});
        }
        else if (lossType == 'Credit Card') {
            formGroup.get('unauthorisedMoneyLossType').enable({onlySelf: false, emitEvent: false});
            formGroup.get('unauthorisedMoneyLoss').enable({onlySelf: false, emitEvent: false});
        }
        else if (lossType == 'Others') {
            formGroup.get('lossTypeDetail').enable({onlySelf: false, emitEvent: false});
            formGroup.get('otherTypeBriefDesc').enable({onlySelf: false, emitEvent: false});
            formGroup.get('purchaseDate').enable({onlySelf: false, emitEvent: false});
            formGroup.get('purchasePriceType').enable({onlySelf: false, emitEvent: false});
            formGroup.get('purchasePrice').enable({onlySelf: false, emitEvent: false});
        }
        else if (lossType == 'Watch'){
            formGroup.get('purchaseDate').enable({onlySelf: false, emitEvent: false});
            formGroup.get('purchasePriceType').enable({onlySelf: false, emitEvent: false});
            formGroup.get('purchasePrice').enable({onlySelf: false, emitEvent: false});
        } 
        else if (lossType == 'Mobile Phone') {
            formGroup.get('lossTypeDetail').enable({onlySelf: false, emitEvent: false});
            formGroup.get('purchaseDate').enable({onlySelf: false, emitEvent: false});
            formGroup.get('purchasePriceType').enable({onlySelf: false, emitEvent: false});
            formGroup.get('purchasePrice').enable({onlySelf: false, emitEvent: false});
        } 
        else {
            formGroup.get('purchaseDate').enable({onlySelf: false, emitEvent: false});
            formGroup.get('purchasePriceType').enable({onlySelf: false, emitEvent: false});
            formGroup.get('purchasePrice').enable({onlySelf: false, emitEvent: false});
        }
    }

    buildStoredItems(detailsOfLostItemsArray: LostPersonalItemDetail[]): FormGroup[] {

        let formGroupArray : FormGroup[] = [];

        if(detailsOfLostItemsArray != null && detailsOfLostItemsArray.length > 0 ) {

            for (let i = 0; i < detailsOfLostItemsArray.length; i++) {

                let rowFormGroup = this.fb.group({
                    lossType: [detailsOfLostItemsArray[i].getLossType()],
                    lossTypeDesc: [detailsOfLostItemsArray[i].getLossTypeDesc()],
                    lossTypeDetail: [detailsOfLostItemsArray[i].getLossTypeDetail()],
                    purchaseDate: [detailsOfLostItemsArray[i].getPurchaseDate()],
                    purchasePriceType: [detailsOfLostItemsArray[i].getPurchasePriceType()],
                    purchasePrice: [detailsOfLostItemsArray[i].getPurchasePrice()],
                    costOfRepalceCostType: [detailsOfLostItemsArray[i].getCostOfRepalceCostType()],
                    costOfRepalceCost: [detailsOfLostItemsArray[i].getCostOfRepalceCost()],
                    lostMoneyType: [detailsOfLostItemsArray[i].getLostMoneyType()],
                    lostMoneyAmount: [detailsOfLostItemsArray[i].getLostMoneyAmount()],
                    unauthorisedMoneyLossType: [detailsOfLostItemsArray[i].getUnauthorisedMoneyLossType()],
                    unauthorisedMoneyLoss: [detailsOfLostItemsArray[i].getUnauthorisedMoneyLoss()],
                    otherTypeBriefDesc: [detailsOfLostItemsArray[i].getOtherTypeBriefDesc()],
                });

                formGroupArray.push(rowFormGroup);
            }
        }
        else {
            formGroupArray.push(this.createDefaultItems());
        }

        return formGroupArray;
    }

    createDefaultItems(): FormGroup {
        return this.fb.group({
            lossType: null,
            lossTypeDesc: '',
            lossTypeDetail: '',
            purchaseDate: null,
            purchasePriceType: this.currency,
            purchasePrice: '',
            costOfRepalceCostType: this.currency,
            costOfRepalceCost: '',
            lostMoneyType: this.currency,
            lostMoneyAmount: '',
            unauthorisedMoneyLossType: this.currency,
            unauthorisedMoneyLoss: '',
            otherTypeBriefDesc: ''
        });
    }

    addItem(): void {

        let self = this;
        setTimeout(function () {
            let items = self.lossDetailForm.get('items') as FormArray;
            let newItem : FormGroup = self.createDefaultItems();
            items.push(newItem);

            newItem.get('lossType').valueChanges.pipe(takeUntil(self.ngUnsubscribe)).subscribe(data => {
                self.lossTypeChanged(data, newItem);
            });
        }, 10);
    }

    removeItem(i) {
        let items = this.lossDetailForm.get('items') as FormArray;
        items.removeAt(i);

        items.value.forEach(formItem =>{
            this.detailsOfLostItemsArray.forEach(item =>{
                if (item.getLossType() === formItem.lossType) {
                    item.setLossTypeDesc(formItem.lossTypeDesc);
                }
            });
        });
    }
    goToNext() {
        if (this.validateForm()) {
            this.transactionInfoService.getDynamicRoutingService().goToNextRoute(this.transactionInfoService.getTransactionInfo().getDynamicRoutes(), this.router, this.activatedRoute);
        }
    }

    back() {
        this.transactionInfoService.getDynamicRoutingService().goToPreviousRoute(this.transactionInfoService.getTransactionInfo().getDynamicRoutes(), this.router, this.activatedRoute);
    }

    validateForm(): boolean {
        return super.validateForm(this.lossDetailForm);
    }

    showModal() {
        this.showTotalBox = true;
    }
    getData(msg) {
        this.showTotalBox = msg;
    }

    pushGALostDetailsView() {
        let pageStep = this.transactionInfoService.getTransactionInfo().getGaPageStep() + ' - Details of Lost Item';
        let vPath = '/claim/' + this.transactionInfoService.getTransactionInfo().getGaVPath() + '/details-of-lost-item';
    
        (<any>window).dataLayer = (<any>window).dataLayer || [];
        (<any>window).dataLayer.push({
          'pageStep': pageStep,
          'vPath': vPath,
          'event': 'vpageview',
        });
    }
}
