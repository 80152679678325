<div class="section form-group">
    <div class="section-header sub-section-header">
        <label class="section-title" *ngIf="claimPA.getHasEligibleClaimTypes()">
            <span *ngIf="!claim.isMox()">{{'paClaim.others.title' | translate}}</span>
            <span *ngIf="claim.isMox()">{{'paClaim.others.title-mox' | translate}}</span>
        </label>
        <label class="section-title" *ngIf="!claimPA.getHasEligibleClaimTypes()">{{'claimDetails.body.claimDetails' | translate}}</label>
    </div>
    <div class="section-content">
        <div class="sub-section">
            <ng-conntainer *ngIf="!claim.isHKGorHGI()">
                <label>{{'paClaim.others.detailsOfTheIncident' | translate}}</label>
                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{'claimDetails.generic.descOfIncident' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5-word-break content">
                        {{this.otherDetails.getDescIncident()}}
                    </div>
                </div>
            </ng-conntainer>
            <div class="row amount-claimed">
                <div class="col col-xs-12 col-lg-7">
                    {{'claimDetails.generic.estAmtLoss' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{this.otherDetails.getEstAmountLossCurrency()}}
                    {{this.otherDetails.getEstAmountLoss() | number : '1.2-2'}}
                </div>
            </div>
        </div>

        <div class="section-header sub-section-header">
            <span class="section-header-desc">{{'paClaim.others.authorityReport' | translate}}</span>
        </div>
        <div class="sub-section adjust-top1">
            <label class="section-header-desc">{{'paClaim.others.authorityReport' | translate}}</label>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{'homeProduct.authorityReport.isIncidentReportedToPolice' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    <span>{{returnYesOrNo(this.policeReport.getHasReportedIncidentToPolice())}}</span>
                </div>
            </div>
            <ng-container *ngIf="this.policeReport.getHasReportedIncidentToPolice()">
                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{'homeProduct.authorityReport.dateOfReport' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{getDate(this.policeReport.getDateOfReport())}}
                    </div>
                </div>
                <div class="row adjusted-row-alignment">
                    <div class="col col-xs-12 col-lg-7">
                        {{'homeProduct.authorityReport.reportRefNo' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5-word-break content forced-left">
                        {{this.policeReport.getPoliceReportNumber()}}
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>

<ng-container *ngIf="country === 'SGP'">
    <another-insurance-summary></another-insurance-summary>
</ng-container>

<div class="form-group section attached-file-section">
    <div class="section-header sub-section-header">
        <label [ngClass]="{'custom-summary-title':claim.isHKGorHGI()}">{{'paClaim.others.supportDoc' | translate}}</label>
    </div>
    <div class="row documents-row" *ngFor="let item of this.listOfDocuments;" [ngClass]="{'first-row-item': item.description}">
        <div class="col col-xs-12 col-lg-12" [ngClass]="{'header-file-desc': item.description}">{{item.description | translate}}</div>
        <ng-container *ngIf="item.uploadedFileName!=undefined && item.uploadedFileName !=''; else documentNotUploaded">
            <div class="col col-xs-12 col-lg-12 file-uploaded-summary">
                {{ item.uploadedFileName }}
            </div>
        </ng-container>
    </div>
    <div class="sub-section other-documents-container" *ngIf="this.listOfOtherDocuments.length > 0">
        <div class="section-header sub-section-header">
            <label [ngClass]="{'custom-summary-title':claim.isHKGorHGI()}">{{ 'pageSummaryClaim.claimItem.sectionOther.subSectionSuppDocs.others' | translate }}</label>
        </div>
        <div class="row documents-row" *ngFor="let item of this.listOfOtherDocuments;">
            <div class="col col-xs-12 col-lg-12" [ngClass]="{'header-file-desc': item.description}">{{item.description | translate}}</div>
            <ng-container *ngIf="item.uploadedFileName!=undefined && item.uploadedFileName !=''; else documentNotUploaded">
                <div class="col col-xs-12 col-lg-12 file-uploaded-summary">
                    {{ item.uploadedFileName }}
                </div>
            </ng-container>
        </div>
    </div>
</div>

<ng-template #documentNotUploaded>
    <div class="col col-xs-12 col-lg-12 file-not-uploaded-summary">
        {{ 'pageSummaryClaim.supportDocuments.notUpload' | translate }}
    </div>
</ng-template>
