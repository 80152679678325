import {OnInit, ViewChild, TemplateRef, Component} from '@angular/core';
import { DocumentHelperService } from '../../services/document-helper.service';
import {FileUploader, ParsedResponseHeaders, FileItem, FileLikeObject} from 'ng2-file-upload';
import {FormGroup, FormControl} from '@angular/forms';
import {HttpHeaders, HttpClient} from '@angular/common/http';
import { catchError, throwError } from 'rxjs';
import { DocumentForm } from '../../model/document-form.model';
import { TransactionInfoService } from '../../services/transaction-info.service';
import { ClaimService } from '../../services/claim.service';
import { DocumentTypes } from '../../utilities/enums/document-types.enum';
import { DocumentDetails } from '../../model/document-details.model';
import { DocumentFormFactoryService } from '../../services/document-form-factory.service';
import { DocumentFormBuilderComponent } from '../document-form-builder/document-form-builder.component';
import { DocumentField } from '../../model/document-field';
import JsonMapUtils from '../../utilities/utils/json-map.util';
import { Notifications } from '../../utilities/components/notification-messages/notifications.model';
import { SpinnerService } from '../../core/spinner/spinner.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Compressor from 'compressorjs';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilitiesService } from 'src/app/utilities/utilities.service';

declare var $: any;

@Component({
    selector: 'app-claim-documents',
    templateUrl: './claim-documents.component.html',
    styleUrls: ['./claim-documents.component.css']
})
export class ClaimDocumentsComponent implements OnInit {

    uploader: FileUploader;
    documentForm: DocumentForm;
    documentsForm: Map<string, FormGroup> = new Map<string, FormGroup>();
    documentType: DocumentField;
    documentTypes = new DocumentTypes();
    currentFileName: string;
    currentRemoveButton: string;
    showAddDoc = false;
    showAddDocs = new Map<string, string>();
    otherUploadedDocs: DocumentDetails[];
    additionalDocs = new Map<string, DocumentDetails[]>();
    formBuilder: DocumentFormBuilderComponent;
    currentClaimType: string;
    totalFile = 0;
    isUploading: boolean = false;
    notifications: Notifications = new Notifications;
    @ViewChild('replace_duplicate', {static: true}) replaceDocModal: TemplateRef<any>;
    replaceDocModalRef: any;
    duplicateFile: FileItem;
    isFileError: boolean = false;
    fileAction: string = '';
    fileTarget: any;
    @ViewChild('alert_unuploaded_documents', {static: true}) docAlertModal: TemplateRef<any>;
    docAlertModalRef: any;
    listOfUnuploadedStpDocs =  '';

    constructor(public claimService: ClaimService, private documentHelperService: DocumentHelperService,
                private transactionInfoService: TransactionInfoService,
                public http: HttpClient,
                private documentFormFactoryService: DocumentFormFactoryService,
                private spinnerService: SpinnerService,
                public modalService: NgbModal) {
        this.setDocumentForm(new DocumentForm());
    }

    setupAdditionalDocsTriggers(docList: DocumentField[]){
        for(let i = 0; i < docList.length; i++){
            this.showAddDocs.set(docList[i].id, 'false');
            this.additionalDocs.set(docList[i].id, []);
        }
    }

    setDocTypeIndexed(type: DocumentField, index: number) {
        //console.log("isUploading: " + this.isUploading + " type:" + type.id + " index: "+ index);
        if (!this.isUploading) {
            this.documentType = this.documentHelperService.getIndexedDocumentField(type, index);
        }
    }

     fileDropIndexed(file: any, docType: DocumentField, index: number) {
        this.setDocType(this.documentHelperService.getIndexedDocumentField(docType, index));
        //console.log('file drop indexed', file);
        if (file && file[0]) {
            console.log('file.target.files[0].name', file[0].name);
            this.setCurrentFileName(file[0].name.replace(/[?*<>|:"]+/g, ''));
            this.setCurrentRemoveButton('[name="' + docType.getId() + index + 'rmv' + '"]');
        }
    }


    fileUpload(file: any, type: string) {
        //console.log('file upload', file);
        if (file.target.files && file.target.files[0]) {
            this.fileAction = 'fileUpload';
            this.fileTarget = file.target;
            this.setCurrentFileName(file.target.files[0].name.replace(/[?*<>|:"]+/g, ''));
            this.setCurrentRemoveButton('[name="' + type + 'rmv' + '"]');
            this.applyRemoveDocBtnInitCss();
            $(this.getCurrentRemoveButton()).css('display', 'block');
            if (this.isFileError) {
                $(file.target).siblings('.form-control').html('<i class="fas fa-exclamation"></i>' + ' ' + file.target.files[0].name.replace(/[?*<>|:"]+/g, ''));
                $(file.target).siblings('.form-control').css({'background-color': '#f2dede', 'color': '#ff0000', 'border': '2px solid #ff0000', 'word-break': 'break-all'});
                $(file.target).siblings('.form-control').addClass('hasFile');
                $(file.target).siblings('.form-control').addClass('fileTypeError');
                $(this.getCurrentRemoveButton()).addClass('removeDocFailBtn');
            } else {
                $(file.target).siblings('.form-control').html('<i class="fas fa-check file-upload"></i> ' + file.target.files[0].name.replace(/[?*<>|:"]+/g, ''));
                $(file.target).siblings('.form-control').css({'background-color': '#0064c1', 'color': '#ffffff', 'border': 0, 'word-break': 'break-all'});
                $(file.target).siblings('.form-control').addClass('hasFile');
                $(this.getCurrentRemoveButton()).addClass('removeDocBtn');
            }
        }
        file.srcElement.value = '';
    }

    dropFile(file: any, docId?: string) {
        //console.log('drop file', file);
        if (file) {
            this.fileAction = 'dropFile';
            this.fileTarget = file.target;
            this.applyRemoveDocBtnInitCss();
            $(this.getCurrentRemoveButton()).css('display', 'block');
            if (this.isFileError) {
                $(file.target).html('<i class="fas fa-exclamation"></i>' + ' ' + this.getCurrentFileName());
                $(file.target).css({'background-color': '#f2dede', 'color': '#ff0000', 'border': '2px solid #ff0000', 'word-break': 'break-all'});
                $(this.getCurrentRemoveButton()).addClass('removeDocFailBtn');
            } else {
                if (docId) {
                    $('[name="' + docId + '"]').html('<i class="fas fa-check drop-file"></i> ' + this.getCurrentFileName());
                    $('[name="' + docId + '"]').css({'background-color': '#0064c1', 'color': '#ffffff', 'border': 0, 'word-break': 'break-all'});
                    $('[name="' + docId + '"]').addClass('hasFile');
                } else {
                    $(file.target).html('<i class="fas fa-check drop-file"></i> ' + this.getCurrentFileName());
                    $(file.target).css({'background-color': '#0064c1', 'color': '#ffffff', 'border': 0, 'word-break': 'break-all'});
                }
                $(this.getCurrentRemoveButton()).removeClass('removeDocFailBtn');
                $(this.getCurrentRemoveButton()).addClass('removeDocBtn');
            }
        }
        this.setCurrentFileName('');
        this.setCurrentRemoveButton('');
    }

    fileDrop(file: any, type: DocumentField) {
        this.setDocType(type);
        //console.log('file drop', file);
        if (file && file[0]) {
            console.log('file.target.files[0].name', file[0].name);
            this.setCurrentFileName(file[0].name.replace(/[?*<>|:"]+/g, ''));
            this.setCurrentRemoveButton('[name="' + type.getId() + 'rmv' + '"]');
        }
    }

    fileDropOthers(file: any, index: number) {
        this.setDocType(this.documentHelperService.getOtherDocumentField(index));
        //console.log('file drop others', file);
        if (file && file[0]) {
            console.log('file.target.files[0].name', file[0].name);
            this.setCurrentFileName(file[0].name.replace(/[?*<>|:"]+/g, ''));
            this.setCurrentRemoveButton('[name="' + 'OTHERS' + index + 'rmv' + '"]');
        }
    }

    uploadDrop(file: any) {
        //console.log('upload drop', file);
        if (file && file[0]) {
            this.fileAction = 'uploadDrop';
            this.fileTarget = file;
            console.log('file.target.files[0].name', file[0].name);
            if (this.isFileError) {
                $(file).html('<i class="fas fa-exclamation"></i>' + ' ' + file[0].name.replace(/[?*<>|:"]+/g, ''));
                $(file).siblings('.form-control').css({'background-color': '#f2dede', 'color': '#ff0000', 'border': '2px solid #ff0000', 'word-break': 'break-all'});
            } else {
                $(file).html('<i class="fas fa-check upload-drop"></i> ' + file[0].name.replace(/[?*<>|:"]+/g, ''));
                $(file).siblings('.form-control').css({'background-color': '#0064c1', 'color': '#ffffff', 'border': 0, 'word-break': 'break-all'});
            }
        }
    }

    setDocType(type: DocumentField) {
        //console.log("isUploading: " + this.isUploading + " type:" + type.id);
        if (!this.isUploading) {
            this.documentType = type;
        }
    }

    setDocTypeOthers(index: number) {
        //console.log("isUploading: " + this.isUploading);
        if (!this.isUploading) {
            this.documentType = this.documentHelperService.getOtherDocumentField(index);
        }
    }

    getDocType(): DocumentField {
        return this.documentType;
    }

    setCurrentClaimType(claimType: string) {
        this.currentClaimType = claimType;
    }

    getCurrentClaimType(): string {
        return this.currentClaimType;
    }

    setCurrentFileName(currentFileName: string) {
        this.currentFileName = currentFileName;
    }

    getCurrentFileName() {
        return this.currentFileName;
    }

    setCurrentRemoveButton(currentRemoveButton: string) {
        this.currentRemoveButton = currentRemoveButton;
    }

    getCurrentRemoveButton() {
        return this.currentRemoveButton;
    }

    setDuplicateFile(duplicateFile: FileItem){
        this.duplicateFile = duplicateFile;
    }

    getDuplicateFile(): FileItem{
        return this.duplicateFile;
    }

    addAdditionalFile() {
        this.otherUploadedDocs.push(new DocumentDetails());
    }

    addAdditionalFiles(docId: string) {
        this.additionalDocs.get(docId).push(new DocumentDetails());
    }

    uploadFile(file: any, type: string) {
        this.uploader.uploadItem(file.target.files[0]);
    }

    ngOnInit(): void {
        const self = this;
        const integrationToken = this.transactionInfoService.getTransactionInfo().getIntegrationToken();
        //console.log('integ token: ' + integrationToken);
        let url =  '/ms/claims-service/rest/documents/upload';
        if (this.transactionInfoService.getTransactionInfo().isLiabilityProduct()) {
            url =  '/ms/claims-service/rest/documents/uploadWithScan';
        }
        this.uploader = new FileUploader(
            {
                url: url,
                headers: [
                    {name: "Authorization", value: "Bearer " + integrationToken}
                ],
                maxFileSize: this.documentHelperService.getMaxDocFileSize(),
                autoUpload: false
            }
        );
        this.documentHelperService.addDocumentFormBuilder(this.getCurrentClaimType());
        this.formBuilder = this.documentHelperService.getDocumentFormBuilder(this.getCurrentClaimType());
        this.formBuilder.setRequiredDocuments();
        this.setupAdditionalDocsTriggers(this.formBuilder.getRequiredDocuments());
        this.totalFile = this.formBuilder.getRequiredDocuments().length;
        this.otherUploadedDocs = this.getOtherUploadedDocuments();
        this.additionalDocs = this.getAdditionalUploadedDocuments(this.additionalDocs);

        this.uploader.onBuildItemForm = function (fileItem, form) {
            form.append('documentType', self.documentType.getSmartqField());
            form.append('identifier', self.claimService.getClaim().getDocumentForm().identifier);
            return {fileItem, form}
        };

        this.uploader.onAfterAddingFile = (fileItem) => {
            this.isUploading = true;
            //console.log('adding file', fileItem);

        if(this.documentHelperService.validateFile(fileItem.file, this.notifications, this.documentType)){
            this.isFileError = true;
            self.abortUpload();
        } else {
            this.isFileError = false;
            if(self.hasDuplicateDocument(self.getDocType().getId(), self.getCurrentClaimType(), fileItem.file.name)){
                self.setDuplicateFile(fileItem);
                this.replaceDocModalRef = this.modalService.open(this.replaceDocModal, {size: 'sm',
                    backdrop: 'static',
                    keyboard: false
                });
            } else {
                if(this.documentHelperService.isImage(fileItem._file.name)){
                    self.startUpload(this.getCompressedImageFile(fileItem));
                } else {
                    self.startUpload(fileItem);
                }
            }
        }
        /* if(this.target) this.target.value = ''; */
        };

        this.uploader.onBeforeUploadItem = function (fileItem) {
            this.isUploading = true;
            //console.log("checking if existing file exists");
            self.replaceExistingDocument(self.getDocType().getId(), self.getCurrentClaimType());
        };

        this.uploader.onCompleteItem = (item: FileItem, response: string, status: number, headers: ParsedResponseHeaders) => {
             this.isUploading = false;
            try {
                if (response && JSON.parse(response).docID) {
                    console.log(this.documentHelperService.mapDocumentDetails(JSON.parse(response)));
                    let documentDetails: DocumentDetails = this.documentHelperService.mapDocumentDetails(JSON.parse(response));
                    documentDetails.docClaimType = this.getCurrentClaimType();
                    documentDetails.docSubTypeId = this.getDocType().getId();
                    documentDetails.docRiskId = this.getDocType().getRiskId();
                    documentDetails.contentSubType = this.getDocType().getContentSubType();
                    this.claimService.getClaim().getDocumentForm().documents.push(documentDetails);
                } else {
                    this.showFileUploadErrorMessage(item, this.notifications, this.getDocType().getId());
                }
                } catch {
                    this.showFileUploadErrorMessage(item, this.notifications, this.getDocType().getId());
                    
                }
            this.spinnerService.displayLoader(false);
        };

        this.uploader.onErrorItem = ((item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any => {
            let notificationMessages = null;
            try {
                notificationMessages = JsonMapUtils.deserialize(Notifications, JSON.parse(response)).errorMessages;
            } catch {
                notificationMessages = [];
                this.documentHelperService.getFileUploadingErrorMessage(item.file.name, this.notifications, this.getDocType().getId());
                this.isFileError = true;
                self.abortUpload();
                window.scroll(0, 0);
                this.applyErrorStyle(item);
            }
            
            for (const notificationMessage of notificationMessages) {
                if (!this.notifications.isMessageExisting(notificationMessage)) {
                    this.notifications.addMessage(notificationMessage);
                }
            }
            this.notifications.showPreamble = false;
            this.spinnerService.displayLoader(false);
        });

        this.uploader.onWhenAddingFileFailed = (item: FileLikeObject, filter: any, options: any) => {
            //console.log(item);
            this.documentHelperService.validateFile(item, this.notifications, this.documentType);
            this.isFileError = true;
            this.abortUpload();
            this.spinnerService.displayLoader(false);
            return {item, filter, options};
        }


    }

    showFileUploadErrorMessage(item, notifications, id) {
        this.documentHelperService.getFileUploadingErrorMessage(item.file.name, notifications, id);
        this.isFileError = true;
        this.abortUpload();
        window.scroll(0, 0);
        this.applyErrorStyle(item);
    }

    ngAfterViewInit() {
        this.populateUploadedDocumentFields();
    }

    getDocumentForm() {
        return this.documentForm;
    }

    setDocumentForm(data) {
        this.documentForm = data;
    }

    getDocumentFormGroup(key: string) {
        if (UtilitiesService.isNullOrUndefined(this.documentsForm.get(key))) {
            this.documentsForm.set(key,
                new FormGroup({
                    documentType: new FormControl()
                })
            );
        }
        return this.documentsForm.get(key);
    }

    populateUploadedDocumentFields() {
        this.claimService.getClaim().getDocumentForm().documents.forEach(function (uploadedDocument) {
            if (uploadedDocument.docClaimType == this.getCurrentClaimType()) {
                console.log(uploadedDocument)
                const lastChar = uploadedDocument.docSubTypeId.substr(uploadedDocument.docSubTypeId.length - 1);
                if (!isNaN(+lastChar)) {     
                    if ($('[id="' + uploadedDocument.docSubTypeId + '"]') != undefined) {
                        $('[id="' + uploadedDocument.docSubTypeId + '"]').nextAll(':lt(2)').html('<i class="fas fa-check populate-uploaded"></i> ' + uploadedDocument.docFileName.replace(/[?*<>|:"]+/g, ''));
                        $('[id="' + uploadedDocument.docSubTypeId + '"]').nextAll(':lt(2)').css({'background-color': '#0064c1', 'color': '#ffffff', 'word-break': 'break-all', 'border': 0});
                        $('[name="' + uploadedDocument.docSubTypeId + '"]').addClass("hasFile");
                        $('[name="' + uploadedDocument.docSubTypeId + 'rmv' + '"]').css({'display': 'block'});
                        $('[name="' + uploadedDocument.docSubTypeId + 'rmv' + '"]').addClass('removeDocBtn');
                    }
                } else { 
                    if ($('[name="' + uploadedDocument.docSubTypeId + '"]') != undefined) {
                        $('[name="' + uploadedDocument.docSubTypeId + '"]').html('<i class="fas fa-check populate-uploaded"></i> ' + uploadedDocument.docFileName.replace(/[?*<>|:"]+/g, ''));
                        $('[name="' + uploadedDocument.docSubTypeId + '"]').css({'background-color': '#0064c1', 'color': '#ffffff', 'word-break': 'break-all', 'border': 0});
                        $('[name="' + uploadedDocument.docSubTypeId + '"]').addClass("hasFile");
                        $('[name="' + uploadedDocument.docSubTypeId + 'rmv' + '"]').css({'display': 'block'});
                        $('[name="' + uploadedDocument.docSubTypeId + 'rmv' + '"]').addClass('removeDocBtn');
                    }
                }
            }
        }, this);
    }

    getOtherUploadedDocuments(): DocumentDetails[] {
        let otherUploadedDocuments: DocumentDetails[] = [];
        let index = 0;
        this.claimService.getClaim().getDocumentForm().documents.forEach(function (uploadedDocument) {
            if (uploadedDocument.docClaimType == this.getCurrentClaimType()) {
                if (uploadedDocument.docSubTypeId.startsWith('OTHERS')) {
                    this.showAddDoc = true;
                    uploadedDocument.docSubTypeId = 'OTHERS' + index;
                    otherUploadedDocuments.push(uploadedDocument);
                    index++;
                }
            }
        }, this);
        /*  if(otherUploadedDocuments.length == 0){
         otherUploadedDocuments.push('');
         } */
        return otherUploadedDocuments;
    }

    getAdditionalUploadedDocuments(additionalDocs: Map<string, DocumentDetails[]>): Map<string, DocumentDetails[]> {
        const self = this;
        this.claimService.getClaim().getDocumentForm().documents.forEach(function (uploadedDocument) {
            if (uploadedDocument.docClaimType == this.getCurrentClaimType()) {
                if (!uploadedDocument.docSubTypeId.startsWith('OTHERS') && 
                !isNaN(+uploadedDocument.docSubTypeId.substr(uploadedDocument.docSubTypeId.length - 1))) {
                    this.formBuilder.getRequiredDocuments().forEach(function (reqDoc) {
                        if(uploadedDocument.docSubTypeId.startsWith(reqDoc.id)){
                            additionalDocs.get(reqDoc.id).push(uploadedDocument);
                            self.showAddDocs.set(reqDoc.id, 'true');
                            return;
                        }
                    });
                }
            }
        }, this);
        additionalDocs.forEach((uploadedDocs: DocumentDetails[], key: string) => {
            if (uploadedDocs.length > 0) {
                let baseDocExists = self.checkIfBaseDocExists(key);
                if (baseDocExists) {
                    uploadedDocs.forEach((uploadedDoc: DocumentDetails, index: number) => {
                        uploadedDoc.docSubTypeId = key + index;
                    });
                } else {
                    if (self.claimService.getClaim().getDocumentForm().documents != undefined) {
                        for (let i = 0; i < self.claimService.getClaim().getDocumentForm().documents.length; i++) {
                            let doc = self.claimService.getClaim().getDocumentForm().documents[i];
                            if (doc.docClaimType == self.getCurrentClaimType() &&
                                doc.docSubTypeId == uploadedDocs[0].docSubTypeId &&
                                doc.docFileName == uploadedDocs[0].docFileName) {
                                doc.docSubTypeId = key;
                                break;
                            }
                        };

                        const i = uploadedDocs.indexOf(uploadedDocs[0], 0);

                        if (i > -1) {
                            uploadedDocs.splice(i, 1);
                        }

                        uploadedDocs.forEach((uploadedDoc: DocumentDetails, index: number) => {
                            uploadedDoc.docSubTypeId = key + index;
                        });
                    }
                }
            }
        });

        return additionalDocs;
    }

    checkIfBaseDocExists(key): boolean {
        let baseDocExists = false;
        this.claimService.getClaim().getDocumentForm().documents.forEach(function (uploadedDocument) {
            if(uploadedDocument.docClaimType == this.getCurrentClaimType() && uploadedDocument.docSubTypeId == key){
                baseDocExists = true;
                return;
            }
        }, this);
        return baseDocExists;
    }

    getUploadedFileName(documentType: string, claimType: string): string {
        let uploadedFileName: string = '';
        this.claimService.getClaim().getDocumentForm().documents.forEach(function (uploadedFile) {
            if (this.locateDocument(documentType, claimType, uploadedFile)) {
                uploadedFileName = uploadedFile.docFileName;
                return;
            }
        }, this);
        return uploadedFileName;
    }

    replaceExistingDocument(documentType: string, claimType: string) {
        this.claimService.getClaim().getDocumentForm().documents.forEach(function (uploadedFile, index) {

            if ((documentType != 'OTHERS') && this.locateDocument(documentType, claimType, uploadedFile)
                || ((documentType == 'OTHERS') && this.locateAdditionalDocument(claimType, this.getCurrentFileName(), uploadedFile))) {
                if (this.removeDocument(uploadedFile.docID)) {
                    console.log("Replacing documentType " + documentType);
                    this.claimService.getClaim().getDocumentForm().documents.splice(index, 1);
                    console.log("Document updated, updated list: ", this.claimService.getClaim().getDocumentForm().documents);
                    return;
                }
            }

        }, this);
    }

    hasDuplicateDocument(documentType: string, claimType: string, filename: string): boolean {
        let hasDuplicate: boolean = false;
        this.claimService.getClaim().getDocumentForm().documents.forEach(function (uploadedFile, index) {

            if (this.locateDuplicateDocument(documentType, claimType, uploadedFile,filename)) {
                    hasDuplicate= true;
                    return;
                }
        }, this);
        return hasDuplicate;
    }

    locateDuplicateDocument(documentType: string, claimType: string, uploadedFile: DocumentDetails, filename: string): boolean {
        if ((!UtilitiesService.isNullOrUndefined(uploadedFile.docSubTypeId) && uploadedFile.docSubTypeId == documentType) &&
            (!UtilitiesService.isNullOrUndefined(uploadedFile.docClaimType) && uploadedFile.docClaimType == claimType) &&
            (!UtilitiesService.isNullOrUndefined(uploadedFile.docFileName) && uploadedFile.docFileName.toLowerCase() == filename.toLowerCase())) {
            return true;
        }
        return false;
    }

    locateDocument(documentType: string, claimType: string, uploadedFile: DocumentDetails): boolean {
        if ((!UtilitiesService.isNullOrUndefined(uploadedFile.docSubTypeId) && uploadedFile.docSubTypeId == documentType) &&
            (!UtilitiesService.isNullOrUndefined(uploadedFile.docClaimType) && uploadedFile.docClaimType == claimType)) {
            return true;
        }
        return false;
    }

    locateAdditionalDocument(claimType: string, currentFileName: string, uploadedFile: DocumentDetails): boolean {
        if ((!UtilitiesService.isNullOrUndefined(uploadedFile.docClaimType) && uploadedFile.docClaimType == claimType) &&
            (!UtilitiesService.isNullOrUndefined(uploadedFile.docFileName) && uploadedFile.docFileName == currentFileName)) {
            return true;
        }
        return false;
    }

    removeDocument(documentId: string): boolean {
        const self = this;
        let removalSuccessful: boolean = true;
        let indexRemove = 0;
        let documentForm: DocumentDetails = new DocumentDetails();

        this.claimService.claim.getDocumentForm().documents.forEach(function(uploadedDoc, index){
            if(uploadedDoc.docClaimType == self.getCurrentClaimType()){
                if(uploadedDoc.docSubTypeId == documentId){
                    indexRemove = index;
                    documentForm.docID = uploadedDoc.docID;
                    documentForm.hasAutoSaveId = uploadedDoc.hasAutoSaveId;
                }
            }
        });
        if(documentForm.docID !== ''){
            this.claimService.claim.getDocumentForm().documents.splice(indexRemove, 1);
            let integrationToken = this.transactionInfoService.getTransactionInfo().getIntegrationToken();
            const httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + integrationToken
                })
            };

            let url: string = '/ms/claims-service/rest/documents/remove';

            this.http.post(url, JSON.stringify(documentForm), httpOptions).subscribe(res => {
                //console.log(res);
                if (res == true || res == "true") {
                    removalSuccessful = true;
                }
                return res;
            }),
                //...errors if any
                catchError(this.handleError);
        }
        return removalSuccessful;
    }

    removeDoc(documentId: string, isMobile: boolean){
        if(this.removeDocument(documentId)){
            this.notifications.removeFileErrorMessage(documentId);
            let isMox = this.claimService.getClaim().isMox();
            let defaultText = this.documentHelperService.getDefaultLabel(isMobile, isMox);

            
            if (!isNaN(+documentId)) {     
                if ($('[id="' + documentId + '"]') != undefined) {
                    $('[id="' + documentId + '"]').siblings().html(defaultText);
                    $('[id="' + documentId + '"]').siblings().css({'background-color': '#f1f1f1', 'color': '#495057', 'border': 0});
                    $('[id="' + documentId + '"]').siblings().removeClass('hasFile');
                    $('[id="' + documentId + '"]').siblings().removeClass('fileTypeError');
                    $('[id="' + documentId + 'rmv' + '"]').siblings().removeClass('removeDocBtn');
                    $('[id="' + documentId + 'rmv' + '"]').siblings().removeClass('removeDocFailBtn');
                    $('[id="' + documentId + 'rmv' + '"]').siblings().css({'display': 'none'});
                }
            } else { 
                if ($('[name="' + documentId + '"]') != undefined) {
                    $('[name="' + documentId + '"]').html(defaultText);
                    $('[name="' + documentId + '"]').css({'background-color': '#f1f1f1', 'color': '#495057', 'border': 0});
                    $('[name="' + documentId + '"]').removeClass('hasFile');
                    $('[name="' + documentId + '"]').removeClass('fileTypeError');
                    $('[name="' + documentId + 'rmv' + '"]').removeClass('removeDocBtn');
                    $('[name="' + documentId + 'rmv' + '"]').removeClass('removeDocFailBtn');
                    $('[name="' + documentId + 'rmv' + '"]').css({'display': 'none'});
                }
            }
        }
    }

    private handleError(error: Response | any) {
        // In a real world app, you might use a remote logging infrastructure
        this.spinnerService.displayLoader(false);
        let errMsg: string;
        if (error instanceof Response) {
            errMsg = `${error.status} - ${error.statusText || ''}`;
        } else {
            errMsg = error.message ? error.message : error.toString();
        }
        errMsg = 'documentservice handleError' + errMsg;
        return throwError(() => new Error(errMsg));
    }

    startUpload(fileItem:FileItem){
        this.spinnerService.displayLoader(true);
        this.uploader.uploadItem(fileItem);
    }

    proceedReplace(){
        if(!UtilitiesService.isNullOrUndefined(this.getDuplicateFile())){
            this.startUpload(this.getDuplicateFile());
        }
        this.replaceDocModalRef.close();
    }

    abortReplace(){
        this.abortUpload();
        this.replaceDocModalRef.close();
    }

    abortUpload(){
        this.setDuplicateFile(null);
        this.isUploading = false;
    }

    isUploaded(documentId: string): boolean{
        let isUploaded = false;
        let self = this;
        this.claimService.getClaim().getDocumentForm().documents.forEach(function (uploadedDocument) {
            if(documentId == uploadedDocument.docSubTypeId && uploadedDocument.docClaimType == self.getCurrentClaimType()){
                isUploaded = true;
                return;
            }
        });
        return isUploaded;
    }


    uploadHereDoc(value: boolean) {
        this.showAddDoc = value;
        if (this.showAddDoc) {
            this.pushGAUploadMoreDocuments();
        }
    }

    //START - Google Analytics
    pushGAUploadMoreDocuments() {
        (<any>window).dataLayer.push({'event': 'uploadmorefile'});
    }
    //END - Google Analytics

    applyRemoveDocBtnInitCss() {
        $(this.getCurrentRemoveButton()).css({'display': 'none'});
        $(this.getCurrentRemoveButton()).removeClass('removeDocFailBtn');
        $(this.getCurrentRemoveButton()).removeClass('removeDocBtn');
    }

    applyErrorStyle(file: FileItem){
        if(this.fileAction == 'fileUpload') {
            $(this.fileTarget).siblings('.form-control').html('<i class="fas fa-exclamation"></i>' + ' ' + file.file.name.replace(/[?*<>|:"]+/g, ''));
            $(this.fileTarget).siblings('.form-control').css({'background-color': '#f2dede', 'color': '#ff0000', 'border': '2px solid #ff0000', 'word-break': 'break-all'});
            $(this.getCurrentRemoveButton()).addClass('removeDocFailBtn');
        } else if (this.fileAction == 'dropFile') {
            this.setCurrentRemoveButton('[name="' + this.getDocType().getId() + 'rmv' + '"]');
            $(this.fileTarget).html('<i class="fas fa-exclamation"></i>' + ' ' + file.file.name.replace(/[?*<>|:"]+/g, ''));
            $(this.fileTarget).css({'background-color': '#f2dede', 'color': '#ff0000', 'border': '2px solid #ff0000', 'word-break': 'break-all'});
            $(this.getCurrentRemoveButton()).addClass('removeDocFailBtn');
        } else if (this.fileAction == 'uploadDrop') {
            this.setCurrentRemoveButton('[name="' + this.getDocType().getId() + 'rmv' + '"]');
            $(this.fileTarget).html('<i class="fas fa-exclamation"></i>' + ' ' + file.file.name.replace(/[?*<>|:"]+/g, ''));
            $(this.fileTarget).siblings('.form-control').css({'background-color': '#f2dede', 'color': '#ff0000', 'border': '2px solid #ff0000', 'word-break': 'break-all'});
            $(this.getCurrentRemoveButton()).addClass('removeDocFailBtn');
        }
        this.fileAction = '';
        this.fileTarget = null;
    }

    getCompressedImageFile(fileItem: FileItem) {
        new Compressor(fileItem._file, {
            quality: 0.6,
            success(result) {
                //console.log("Image compression success!", result);
                var b: any = result;
                let oldFileSize = fileItem._file.size;
                if(b.size < oldFileSize){
                    b.lastModifiedDate = new Date();
                    b.name = fileItem._file.name;
                    fileItem._file = <File>b;
                }
            },
            error(error) {
                console.log("Error in compression, uploading original file instead", error);
            }
        });
        return fileItem;
    }
 
    buildSTPAlertMessage(claim, translate, listOfStpDocs): any{
        this.listOfUnuploadedStpDocs = '';
        let unuploadedDocs = this.verifyUploadedDocuments(this.formBuilder.getRequiredDocuments(), claim, listOfStpDocs);

        if(unuploadedDocs.length > 0){
            //remove redundant doc file
            let copyUnuploadedDocs: string[][]  = [];
            let listOfStpDocsCtr = new Map<string, number>();
            listOfStpDocs.forEach((stpDoc)=>{
                listOfStpDocsCtr.set(stpDoc, 0);
            });
            unuploadedDocs.forEach(function (unuploaded, index){
                listOfStpDocsCtr.forEach((value, key)=>{
                    if(unuploaded[0].startsWith(key) && value == 0){
                        listOfStpDocsCtr.set(key, 1);
                        copyUnuploadedDocs.push([unuploaded[0], unuploaded[1]])
                    }
                });
            });
            // set unuploaded documents to display in alert message
            if(copyUnuploadedDocs.length >= 1){
                let totalLength = copyUnuploadedDocs.length;
                let maxSize = copyUnuploadedDocs.length -1;
                let self = this;
                let currentLanguage = translate.currentLang;
                copyUnuploadedDocs.forEach(function (description, index) {
                    translate.stream(description[1]).subscribe((res:string) => {
                        //console.log("res:"+res)
                        if(self.listOfUnuploadedStpDocs == null || self.listOfUnuploadedStpDocs == "" || self.listOfUnuploadedStpDocs === undefined){
                            if(index > 0 || totalLength > 1){
                                self.listOfUnuploadedStpDocs = res + ", ";
                            }else{
                                if(currentLanguage == 'zh' || currentLanguage == 'cn'){
                                    self.listOfUnuploadedStpDocs = res + "。";
                                }else{
                                    self.listOfUnuploadedStpDocs = res + ".";
                                }
                            }
                        }else if(index == maxSize){
                            if(currentLanguage == 'zh' || currentLanguage == 'cn'){
                                self.listOfUnuploadedStpDocs += (res + "。");
                            }else{
                                self.listOfUnuploadedStpDocs += (res + ".");
                            }
                        }else{
                            self.listOfUnuploadedStpDocs += (res + ", ");
                        }
                    });

                });
            }
        }
        return this.listOfUnuploadedStpDocs;
    }

    verifyUploadedDocuments(requiredDocuments, claim, listOfStpDocs): string[]{
    
        let unuploadedDocs = [];
        const documentsDetails = requiredDocuments;
        let uploadedDocuments = claim.getDocumentForm().documents;
        let previousClaimDoc: string = 'none';
        // we check all required document if uploaded
        for (let documentsDetailsValue of documentsDetails) {
            //check if there are already documents uploaded
            let docUploaded: boolean = false;
            if(uploadedDocuments.length < 1){
                if(listOfStpDocs.indexOf(documentsDetailsValue.id)>-1){
                    unuploadedDocs.push([documentsDetailsValue.id, documentsDetailsValue.description]);
                }
                
            }else{
                // for primary claimant
                for (let uploadedDoc of uploadedDocuments) {
                    //if required documents uploaded we skip then proceed to check the other details
                    if(uploadedDoc.docSubTypeId.startsWith(documentsDetailsValue.id) && uploadedDoc.docClaimType == this.getCurrentClaimType() && (listOfStpDocs.indexOf(documentsDetailsValue.id)>-1)){
                        docUploaded = true;
                        previousClaimDoc = documentsDetailsValue.id;
                        break;  
                    }
                    
                }
                          
                if(docUploaded == false){
                    if(previousClaimDoc != documentsDetailsValue.id && (listOfStpDocs.indexOf(documentsDetailsValue.id)>-1)){
                        unuploadedDocs.push([documentsDetailsValue.id, documentsDetailsValue.description]);
                        previousClaimDoc = documentsDetailsValue.id;
                    }
                    
                }
            }
        }
        return unuploadedDocs;

    }

    goToNextDynamic(router: Router, activatedRoute: ActivatedRoute) {
        this.transactionInfoService.getDynamicRoutingService().goToNextRoute(this.transactionInfoService.getTransactionInfo().getDynamicRoutes(), router, activatedRoute)
    }

    goBackDynamic(router: Router, activatedRoute: ActivatedRoute) {
        this.transactionInfoService.getDynamicRoutingService().goToPreviousRoute(this.transactionInfoService.getTransactionInfo().getDynamicRoutes(), router, activatedRoute);
    }
}


