import {Component, Injector, OnInit} from '@angular/core';
import {ClaimsBaseComponent} from "../claim-base.component";
import {ClaimService} from "../../services/claim.service";
import {ActivatedRoute, Router} from '@angular/router';
import {SideMenuService} from '../../services/side-menu.service';
import { UtilitiesService } from 'src/app/utilities/utilities.service';
import {PAYMENT_METHODS} from '../../model/payment-methods.constant';

@Component({
  selector: 'app-payment-contact',
  templateUrl: './payment-contact.component.html',
  styleUrls: ['./payment-contact.component.css']
})
export class PaymentContactComponent extends ClaimsBaseComponent implements OnInit {

  constructor(private claimService: ClaimService,
              private activatedRoute: ActivatedRoute,
              public sideMenuService: SideMenuService,
              private router: Router,
              private injector : Injector) {
              super(injector);
              this.routing();

  }

  routing() {
      // if requested/previous page is contact
      if (this.router.url.toLowerCase().endsWith('providecontactinfo')) {
          this.router.navigate([this.router.url], {
              relativeTo: this.activatedRoute
          });
      } else {
          let paymentMethod = this.claim.getPaymentMethod();
          if (!UtilitiesService.isNullOrUndefined(paymentMethod) && paymentMethod != "") {
              if (paymentMethod === PAYMENT_METHODS.BANK_TRANSFER || paymentMethod === PAYMENT_METHODS.FASTER_PAYMENT_SYSTEM){
                  this.router.navigate(["/claimform/paymentAndContact/bankAccount"], {
                      relativeTo: this.activatedRoute
                  });
              } else if (paymentMethod === PAYMENT_METHODS.OVERSEAS_BANK) {
                this.router.navigate(["/claimform/paymentAndContact/payOverseasBank"], {
                    relativeTo: this.activatedRoute
                });
              } else {
                  this.router.navigate(["/claimform/paymentAndContact/sendCheque"], {
                      relativeTo: this.activatedRoute
                  });
              }
          }
      }

  }

}
