<div class="section form-group">
    <div class="section-header sub-section-header">
        <label class="section-title">{{'paClaim.accidentalMedicalExpenses.title' | translate}}</label>
        <span class="section-header-desc">{{'paClaim.accidentalMedicalExpenses.title1' | translate}}</span>
    </div>
    <div class="section-content">
        <div class="sub-section">
            <label class="section-header-desc">{{'paClaim.accidentalMedicalExpenses.title1' | translate}}</label>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{'paClaim.accidentalMedicalExpenses.wasAdmitted' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{returnYesOrNo(accidentalMedicalExpenses.getWasAdmitted())}}
                </div>
            </div>
            <ng-container *ngIf="accidentalMedicalExpenses.getWasAdmitted()">
                <ng-container *ngFor="let item of this.accidentalMedicalExpenses.getHospitalExpenses(); let i = index;">
                    <div class="section-header sub-section-header">
                        <span class="section-header-desc">{{'paClaim.common.hospitalExpenseTitle' | translate}} {{i + 1}}</span>
                    </div>
                    <label class="header-label section-header-desc">{{'paClaim.common.hospitalExpenseTitle' | translate}} {{i + 1}}</label>

                    <div class="row">
                        <div class="col col-xs-12 col-lg-7">
                            {{'paClaim.common.hospitalName' | translate}}
                        </div>
                        <div class="col col-xs-12 col-lg-5 content">
                            {{item.getHospitalName()}}
                        </div>
                    </div>
    
                    <div class="row">
                        <div class="col col-xs-12 col-lg-7">
                            {{'paClaim.common.dateAdmission' | translate}}
                        </div>
                        <div class="col col-xs-12 col-lg-5 content">
                            {{getDateTime(item.getDateAdmission())}}
                        </div>
                    </div>
    
                    <div class="row">
                        <div class="col col-xs-12 col-lg-7">
                            {{'paClaim.common.dateDischarge' | translate}}
                        </div>
                        <div class="col col-xs-12 col-lg-5 content">
                            {{getDateTime(item.getDateDischarge())}}
                        </div>
                    </div>
    
                    <div class="row">
                        <div class="col col-xs-12 col-lg-7">
                            {{'paClaim.common.hospitalExpense' | translate}}
                        </div>
                        <div class="col col-xs-12 col-lg-5 content">
                            {{item.getHospitalExpenseCurrency()}} {{item.getHospitalExpense() | number : '1.2-2'}}
                        </div>
                    </div>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="accidentalMedicalExpenses.getWasAdmitted() == false">
                <ng-container *ngFor="let item of this.accidentalMedicalExpenses.getClinicalExpenses(); let i = index;">
                    <div class="section-header sub-section-header">
                        <span class="section-header-desc">{{'paClaim.common.clinicalExpenseTitle' | translate}} {{i + 1}}</span>
                    </div>
                    <label class="header-label section-header-desc">{{'paClaim.common.clinicalExpenseTitle' | translate}} {{i + 1}}</label>

                    <div class="row">
                        <div class="col col-xs-12 col-lg-7">
                            {{'paClaim.accidentalMedicalExpenses.dateVisit' | translate}}
                        </div>
                        <div class="col col-xs-12 col-lg-5 content">
                            {{getDate(item.getDateVisit())}}
                        </div>
                    </div>
    
                    <div class="row">
                        <div class="col col-xs-12 col-lg-7">
                            {{'paClaim.accidentalMedicalExpenses.medicalAttention.label' | translate}}
                        </div>
                        <div class="col col-xs-12 col-lg-5 content" *ngIf="item.getMedicalAttention() !== 'Others'">
                            {{item.getMedicalAttentionDesc()}}
                        </div>
                        <div class="col col-xs-12 col-lg-5 content" *ngIf="item.getMedicalAttention() === 'Others'">
                            {{item.getMedicalAttentionOthers()}}
                        </div>
                    </div>
    
                    <div class="row">
                        <div class="col col-xs-12 col-lg-7">
                            {{'paClaim.accidentalMedicalExpenses.clinicalExpense' | translate}}
                        </div>
                        <div class="col col-xs-12 col-lg-5 content">
                            {{item.getClinicalExpenseCurrency()}} {{item.getClinicalExpense() | number : '1.2-2'}}
                        </div>
                    </div>
                </ng-container>

                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{'paClaim.common.fullyRecovered' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{returnYesOrNo(accidentalMedicalExpenses.getFullyRecovered())}}
                    </div>
                </div>

                <div class="row" *ngIf="!accidentalMedicalExpenses.getFullyRecovered()">
                    <div class="col col-xs-12 col-lg-7">
                        {{'paClaim.common.treatment' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{accidentalMedicalExpenses.getTreatment()}}
                    </div>
                </div>
            </ng-container>
        </div>

        <ng-container *ngIf="accidentalMedicalExpenses.getWasAdmitted()">
            <div *ngIf="!claim.isHKGorHGI()" class="sub-section">
                <label>{{'paClaim.icu.title' | translate}}</label>
                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{'paClaim.icu.stayedIn' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{returnYesOrNo(accidentalMedicalExpenses.getStayedIn())}}
                    </div>
                </div>

                <ng-container *ngIf="accidentalMedicalExpenses.getStayedIn()">
                    <div class="row">
                        <div class="col col-xs-12 col-lg-7">
                            {{'paClaim.icu.dateFrom' | translate}}
                        </div>
                        <div class="col col-xs-12 col-lg-5 content">
                            {{getDate(accidentalMedicalExpenses.getDateFrom())}}
                        </div>
                    </div>

                    <div class="row">
                        <div class="col col-xs-12 col-lg-7">
                            {{'paClaim.icu.dateTo' | translate}}
                        </div>
                        <div class="col col-xs-12 col-lg-5 content">
                            {{getDate(accidentalMedicalExpenses.getDateTo())}}
                        </div>
                    </div>
                </ng-container>

            </div>

            <div class="sub-section">
                <label>{{'paClaim.outpatient.title' | translate}}</label>
                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{'paClaim.outpatient.hasOutpatientConsultations' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{returnYesOrNo(accidentalMedicalExpenses.getOutpatientConsultation().getHasOutpatientConsultations())}}
                    </div>
                </div>
                <ng-container *ngIf="accidentalMedicalExpenses.getOutpatientConsultation().getHasOutpatientConsultations()">
                    <ng-container *ngFor="let item of accidentalMedicalExpenses.getOutpatientConsultation().getClinicalExpenses(); let i = index;">
                        <label class="header-label">{{'paClaim.common.clinicalExpenseTitle' | translate}} {{i + 1}}</label>

                        <div class="row">
                            <div class="col col-xs-12 col-lg-7">
                                {{'paClaim.accidentalMedicalExpenses.dateVisit' | translate}}
                            </div>
                            <div class="col col-xs-12 col-lg-5 content">
                                {{getDate(item.getDateVisit())}}
                            </div>
                        </div>

                        <div class="row">
                            <div class="col col-xs-12 col-lg-7">
                                {{'paClaim.accidentalMedicalExpenses.medicalAttention.label' | translate}}
                            </div>
                            <div class="col col-xs-12 col-lg-5 content" *ngIf="item.getMedicalAttention() !== 'Others'">
                                {{item.getMedicalAttentionDesc()}}
                            </div>
                            <div class="col col-xs-12 col-lg-5 content" *ngIf="item.getMedicalAttention() === 'Others'">
                                {{item.getMedicalAttentionOthers()}}
                            </div>
                        </div>

                        <div class="row">
                            <div class="col col-xs-12 col-lg-7">
                                {{'paClaim.accidentalMedicalExpenses.clinicalExpense' | translate}}
                            </div>
                            <div class="col col-xs-12 col-lg-5 content">
                                {{item.getClinicalExpenseCurrency()}} {{item.getClinicalExpense() | number : '1.2-2'}}
                            </div>
                        </div>
                    </ng-container>

                    <div class="row">
                        <div class="col col-xs-12 col-lg-7">
                            {{'paClaim.common.fullyRecovered' | translate}}
                        </div>
                        <div class="col col-xs-12 col-lg-5 content">
                            {{returnYesOrNo(accidentalMedicalExpenses.getOutpatientConsultation().getFullyRecovered())}}
                        </div>
                    </div>

                    <div class="row" *ngIf="!accidentalMedicalExpenses.getOutpatientConsultation().getFullyRecovered()">
                        <div class="col col-xs-12 col-lg-7">
                            {{'paClaim.common.treatment' | translate}}
                        </div>
                        <div class="col col-xs-12 col-lg-5 content">
                            {{accidentalMedicalExpenses.getOutpatientConsultation().getTreatment()}}
                        </div>
                    </div>
                </ng-container>
            </div>
        </ng-container>
        <ng-container *ngIf="country === 'SGP'">
            <div class="sub-section">
                <label>{{'paClaim.medicalCertificate.title' | translate}}</label>
                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{'paClaim.medicalCertificate.wasMedCertIssued' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{returnYesOrNo(accidentalMedicalExpenses.getWasMedCertIssued())}}
                    </div>
                </div>
                
                <ng-container *ngIf="accidentalMedicalExpenses.getWasMedCertIssued()">
                    <ng-container *ngFor="let item of this.accidentalMedicalExpenses.getMedicalCertificates(); let i = index;">
                        <div class="row">
                            <div class="col col-xs-12 col-lg-7">
                                {{'paClaim.medicalCertificate.title' | translate}} {{i + 1}}
                            </div>
                            <div class="col col-xs-12 col-lg-5 content">
                            </div>
                        </div>

                        <div class="row">
                            <div class="col col-xs-12 col-lg-7">
                                {{'paClaim.medicalCertificate.periodOfMedLeave' | translate}}
                            </div>
                            <div class="col col-xs-12 col-lg-5 content">
                            </div>
                        </div>
        
                        <div class="row">
                            <div class="col col-xs-12 col-lg-7">
                                {{'paClaim.medicalCertificate.periodFrom' | translate}}
                            </div>
                            <div class="col col-xs-12 col-lg-5 content">
                                {{getDate(item.getPeriodFrom())}}
                            </div>
                        </div>
        
                        <div class="row">
                            <div class="col col-xs-12 col-lg-7">
                                {{'paClaim.medicalCertificate.periodTo' | translate}}
                            </div>
                            <div class="col col-xs-12 col-lg-5 content">
                                {{getDate(item.getPeriodTo())}}
                            </div>
                        </div>
        
                        <div class="row">
                            <div class="col col-xs-12 col-lg-7">
                                {{'paClaim.medicalCertificate.medCertPurpose.label' | translate}}
                            </div>
                            <div class="col col-xs-12 col-lg-5 content">
                                {{item.getMedCertPurpose()}}
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
            </div>
        </ng-container>
    </div>     
</div>

<ng-container *ngIf="country === 'SGP'">
    <another-insurance-summary></another-insurance-summary>
</ng-container>

<div class="form-group section attached-file-section">
    <div class="section-header sub-section-header">
        <label class="custom-summary-title section-title">{{'motorClaim.claimSection.windscreen.documents.description' | translate}}</label>
    </div>
    <label class="sub-section section-header-desc">{{'motorClaim.claimSection.windscreen.documents.description' | translate}}</label>
    <div class="row documents-row" *ngFor="let item of this.listOfDocuments;">
        <div class="col col-xs-12 col-lg-12 header-file-desc">
            {{item.description | translate}}
        </div>
        <ng-container *ngIf="item.uploadedFileName!=undefined && item.uploadedFileName !=''; else documentNotUploaded">
            <div class="col col-xs-12 col-lg-12 file-uploaded-summary">
                {{ item.uploadedFileName }}
            </div>
        </ng-container>
    </div>
    <div class="sub-section other-documents-container" *ngIf="this.listOfOtherDocuments.length > 0">
        <div class="section-header sub-section-header">
            <label class="custom-summary-title section-title">{{'pageSummaryClaim.claimItem.sectionOther.subSectionSuppDocs.others' | translate }}</label>
        </div>
        <label class="sub-section section-header-desc">{{ 'pageSummaryClaim.claimItem.sectionOther.subSectionSuppDocs.others' | translate }}</label>
        <div class="row documents-row" *ngFor="let item of this.listOfOtherDocuments;">
            <div class="col col-xs-12 col-lg-12 header-file-desc">
                {{item.description | translate}}
            </div>
            <ng-container *ngIf="item.uploadedFileName!=undefined && item.uploadedFileName !=''; else documentNotUploaded">
                <div class="col col-xs-12 col-lg-12 file-uploaded-summary">
                    {{ item.uploadedFileName }}
                </div>
            </ng-container>
        </div>
    </div>
</div>

<ng-template #documentNotUploaded>
    <div class="col col-xs-12 col-lg-12 file-not-uploaded-summary">
        {{ 'pageSummaryClaim.supportDocuments.notUpload' | translate }}
    </div>
</ng-template>
