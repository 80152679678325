import {Injectable, Injector} from "@angular/core";
import {Observable, throwError, catchError, finalize, map} from "rxjs";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {PolicyAuthJson} from "../model/policy.auth-model";
import {DateService} from "../ui/ui-datepicker/date.service";
import { SpinnerService } from '../core/spinner/spinner.service';
import { ErrorsService } from "../utilities/errors/errors.service";
import { PolicyVerifyForm } from "../model/policy-verify-form.model";
import {tap} from 'rxjs/operators';


@Injectable()
export class IntegrationService {

    constructor(private http: HttpClient,
                private dateService: DateService,
                private spinnerService :  SpinnerService,
                private injector: Injector) {
    }
    public pendingRequests: number = 0;

    // Check whether the policy exists.
    public checkPolicyNumber(policyVerifyForm: PolicyVerifyForm): Observable<PolicyAuthJson> {

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type':  'application/json'
            })
        };

        const url: string = '/ms/claims-service/rest/check/policy';
        let formString : string = JSON.stringify(policyVerifyForm);

        this.spinnerService.displayLoader(true);

        return this.http.post<PolicyAuthJson>(url, formString, httpOptions)
            .pipe(
                tap(() => {
                    this.spinnerService.displayLoader(false);
                }),
                map((res: PolicyAuthJson) => {
                    this.pendingRequests++;
                    return this.mapPolicyAuth(res);
                }),
                catchError<any, Observable<PolicyAuthJson>>(error => {
                    this.spinnerService.displayLoader(false);
                    return this.handleError(error);
                })
            );

    }

    // Check whether the policy exists.
    public checkPolicyNumberGeneric(policyVerifyForm: PolicyVerifyForm): Observable<PolicyAuthJson> {

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type':  'application/json'
            })
        };

        const url: string = '/ms/claims-service/rest/check/policy/generic';
        let formString : string = JSON.stringify(policyVerifyForm);

        this.spinnerService.displayLoader(true);

        return this.http.post<PolicyAuthJson>(url, formString, httpOptions)
        // ...and calling .json() on the response to return data
            .pipe(map((res: PolicyAuthJson) => {

                this.pendingRequests++;

                console.log('http return result');
                console.log(res);
                let policyAuthJson = this.mapPolicyAuth(res);
                this.spinnerService.displayLoader(false);
                return policyAuthJson;
            }))
            .pipe(catchError<any,Observable<PolicyAuthJson>>( this.handleError))
            .pipe(finalize( () => {this.spinnerService.displayLoader(false)}));
    }

    // Check the security info against the policy to verify if we can continue
    public verifyPolicyNumber(policyVerifyForm: PolicyVerifyForm): Observable<PolicyAuthJson> {

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type':  'application/json'
            })
        };

        this.spinnerService.displayLoader(true);

        const url: string = '/ms/claims-service/rest/verify/policy';
        let formString : string = JSON.stringify(policyVerifyForm);

        return this.http.post<PolicyAuthJson>(url, formString, httpOptions)
            .pipe(map((res : PolicyAuthJson) => {

                console.log('http return result');
                console.log(res);
                let policyAuthJson = this.mapPolicyAuth(res);
                this.spinnerService.displayLoader(false);
                return policyAuthJson;
            }))
            //...errors if any
            .pipe(catchError(this.handleError))
            .pipe(finalize( () => {this.spinnerService.displayLoader(false) }));
    }

    // bypass security info against for cover note and pre numbered policy - motor
    public verifyCoverNote(policyVerifyForm: PolicyVerifyForm): Observable<PolicyAuthJson> {

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type':  'application/json'
            })
        };

        const url: string = '/ms/claims-service/rest/verify/coverNote';
        let formString : string = JSON.stringify(policyVerifyForm);

        this.spinnerService.displayLoader(true);

        return this.http.post<PolicyAuthJson>(url, formString, httpOptions)
        // ...and calling .json() on the response to return data
            .pipe(map((res: PolicyAuthJson) => {

                console.log('http return result');
                console.log(res);
                let policyAuthJson = this.mapPolicyAuth(res);
                this.spinnerService.displayLoader(false);
                return policyAuthJson;
            }))
            //...errors if any
            .pipe(catchError(this.handleError))
            .pipe(finalize( () => {this.spinnerService.displayLoader(false) }));
    }


    private mapPolicyAuth(res : PolicyAuthJson) : PolicyAuthJson {
        let policyAuthJson: PolicyAuthJson = Object.assign(new PolicyAuthJson(), res);
        policyAuthJson  = PolicyAuthJson.jsonConvert(policyAuthJson);
        return policyAuthJson;
    }

    public getIntegrationData(integrationToken: string, policyType: string): Observable<PolicyAuthJson> {

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + integrationToken
            })
        };

        // ...using get request
        console.log('Calling integrationInfo url');

        this.spinnerService.displayLoader(true);
        let url: string = "/ms/claims-service/rest/verify/ebusiness/policy";
        url += "?policyType=" + policyType;

        let integObservable: Observable<PolicyAuthJson> =
            this.http.get<PolicyAuthJson>(url, httpOptions)
                .pipe(map((res : PolicyAuthJson) => {
                    console.log('http return result');
                    console.log(res);
                    let policyAuthJson = this.mapPolicyAuth(res);
                    this.spinnerService.displayLoader(false);
                    return policyAuthJson;
                }))
                //...errors if any
                .pipe(catchError<any, Observable<PolicyAuthJson>>(this.handleError))
                .pipe(finalize( () => {this.spinnerService.displayLoader(false) }));

        return integObservable;
    }

    private handleError(error: Response | any) {
        // In a real world app, you might use a remote logging infrastructure
        const errorsService = this.injector.get(ErrorsService);
        let errMsg: string;
        if (error instanceof Response) {
            errMsg = `${error.status} - ${error.statusText || ''}`;
        } else {
            errMsg = error.message ? error.message : error.toString();
        }
        errMsg = 'IntegrationService handleError' + errMsg;
        errorsService.log(errMsg);
        return throwError(() => new Error(errMsg));
    }

    getSystemStatus(country: string): Observable<string> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        return this.http.get<string>('/ms/claims-service/rest/system/getStatus/'+ country, httpOptions);
    }

    public verifyRedirectToken(token: string, iv: string): Observable<any> {
        let url: string = '/ms/claims-service/rest/verify/redirectUrlToken?token=' + token + '&iv=' + iv;
        return this.http.get(url);
    }

}
