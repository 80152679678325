<ng-container *ngIf="!uiModel.multiField ; else multiField">
    <div class="form-group row" [formGroup]="formGroup"
         [ngClass]="{'ui-error' : (!uiModel.textOnly && ((!formControl.valid && formControl.touched) )) || (uiModel.textOnly && !formControl.valid)}"
         [ngStyle]="{'display': uiModel.hidden ? 'none' : 'block'}">
        <qnect-ui-label-part [fieldId]="fieldId" [uiModel]="uiModel"></qnect-ui-label-part>
        <div class="col-md-{{uiModel.size}}">
            <div class="input-group">
                <div class="input-group-prepend">
                    <ng-container *ngIf="uiModel.options?.length > 0">
                        <button type="button" class="btn-drpdown dropdown-toggle" data-toggle="dropdown">{{dropdownSelectedText}} </button>
                        <input type="hidden" formControlName="{{uiModel.dropdownFieldName}}" id="{{uiModel.dropdownFieldId}}"
                               value="{{dropdownSelectedText}}">
                        <div class="dropdown-menu scrollable-menu" role="menu">
                            <ng-container *ngFor="let option of uiModel.options">
                                <span class="dropdown-item" (click)="dropdownFieldChangeHandler(option)">{{option.label | translate}}</span>
                            </ng-container>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="!uiModel.options ||uiModel.options?.length == 0 || uiModel.currencyFieldStaticValue">
                        <span class="input-group-text">{{uiModel.currencyFieldStaticValue}}</span>
                    </ng-container>
                </div>
                <input id="{{fieldId}}" formControlName="{{uiModel.name}}" type="text" class="form-control"
                       placeholder="{{uiModel.placeholder | translate}}"
                       maxlength="{{uiModel.maxlength}}" autocomplete="off">
            </div>
            <div class="col-md-{{getDivRemainder()}}">
            </div>
            <p class="form-control-static" id="{{fieldId}}" *ngIf="uiModel.textOnly">{{formControl.value}}</p>
            <ui-error *ngIf="(formControl.touched || uiModel.textOnly) " [inputErrors]="formControl.errors" [dictionary]="uiModel.dictionary"></ui-error>
        </div>
    </div>
</ng-container>

<ng-template #multiField>
    <div class="form-group" [formGroup]="formGroup"
         [ngClass]="{'ui-error' : (!uiModel.textOnly && ((!formControl.valid && formControl.touched) )) || (uiModel.textOnly && !formControl.valid)}"
         [ngStyle]="{'display': uiModel.hidden ? 'none' : 'block'}">

        <!--<div class="col-md-{{uiModel.size}}">-->
            <div class="input-group">
                <div class="input-group-prepend">
                    <ng-container *ngIf="uiModel.options?.length > 0">
                        <button type="button" class="btn-drpdown dropdown-toggle" data-toggle="dropdown">{{dropdownSelectedText}} </button>
                        <input type="hidden" formControlName="{{uiModel.dropdownFieldName}}" id="{{uiModel.dropdownFieldId}}"
                               value="{{dropdownSelectedText}}">
                        <div class="dropdown-menu scrollable-menu" role="menu">
                            <ng-container *ngFor="let option of uiModel.options">
                                <span class="dropdown-item" (click)="dropdownFieldChangeHandler(option)">{{option.label | translate}}</span>
                            </ng-container>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="!uiModel.options ||uiModel.options?.length == 0 || uiModel.currencyFieldStaticValue">
                        <span class="input-group-text">{{uiModel.currencyFieldStaticValue}}</span>
                    </ng-container>
                </div>
                <input id="{{fieldId}}" formControlName="{{uiModel.name}}" type="text" class="form-control"
                       placeholder="{{uiModel.placeholder | translate}}"
                       maxlength="{{uiModel.maxlength}}" autocomplete="off">
            </div>
            <div class="col-md-{{getDivRemainder()}}">
            </div>
            <p class="form-control-static" id="{{fieldId}}" *ngIf="uiModel.textOnly">{{formControl.value}}</p>
            <ui-error *ngIf="(formControl.touched || uiModel.textOnly) " [inputErrors]="formControl.errors" [dictionary]="uiModel.dictionary"></ui-error>
        <!--</div>-->

    </div>
</ng-template>
