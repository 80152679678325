import { UiOptionsModel } from './ui-options.model';

export class UiModel {

  public type: string;
  public name: string;
  public required: string;
  public moneyFieldAmountRequired: string;
  public moneyFieldCurrencyRequired: string;
  public disabled: boolean;
  public hidden: boolean;
  public labelText: string;
  public labelText2: string;
  public labelSize: string;
  public labelClass: string;
  public size: string;
  public placeholder: string;
  public textOnly: boolean;
  public validationRegex: string;
  public defaultValue: string;
  public popover: UiPopoverModel;
  public options: UiOptionsModel[];
  public staticOptionName: string;
  public disabledOptions: String[];
  public dictionary: any;
  public remoteSource: string;
  public rangeValidator: any;
  public dateFormat: string;
  public momentFormat:string;
  public initialDate: string = "";
  public minDate: string;
  public maxDate: string;
  public maxlength: number;
  public atLeastOneRequired: string[];
  public hideLabel: boolean;
  public validationRegexList: any;
  public currencyFieldId: string;
  public currencyFieldName: string;
  public currencyPlaceholder: string = '';
  public currencyOptions: UiOptionsModel[];
  public countryCodeOptions: UiOptionsModel[];
  public style: string;
  public hideMandatoryMark: boolean;
  public twelveHourClock: boolean;
  public isOtherTextField: boolean;
  public currencyFieldStaticValue: string;
  public p400moreThan1: string;
  public p400Format: string;
  public p400noSpace: string;
  public p400consecutive: string;
  public atleastOneRegexValid: any;
  public countryCodeFieldName: string;
  public countryCodeFieldId: string;
  public dropdownFieldName: string;
  public dropdownFieldId: string;
  public multiField: boolean;
  public optionDisplayLabel: boolean;
  public disablePaste: boolean;
}

export class UiPopoverModel {

  public icon: string;
  public content: string;

}
