import {AfterViewInit, Component, Injector, OnInit, TemplateRef, ViewChild} from "@angular/core";
import {FormBuilder, FormGroup} from '@angular/forms';
import {TransactionInfoService} from "../../services/transaction-info.service";
import {ClaimsBaseComponent} from "../../claim-pages/claim-base.component";
import {ActivatedRoute, Router} from "@angular/router";
import {TransactionInfo} from "../../model/transaction-info.model";
import {ClaimSearchService} from "../../services/claim-search.service";
import {takeUntil} from "rxjs/operators";
import {ClaimAuthJson} from "../../model/search-claim/claim-auth.model";
import {IntegrationClaimDetailsJson} from "../../model/search-claim/integration-claim-details.model";
import {Notifications} from "../../utilities/components/notification-messages/notifications.model";
import {LangChangeEvent} from '@ngx-translate/core';
import {GoogleAnalyticsService} from "../../services/google-analytics.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { PolicyVerifyForm } from "src/app/model/policy-verify-form.model";
import { DateService } from "src/app/ui/ui-datepicker/date.service";
import { UtilitiesService } from "src/app/utilities/utilities.service";
import { OtpBoxComponent } from "src/app/otp-box/otp-box.component";

declare var $: any;

@Component({
    selector: 'search-claim-homepage',
    templateUrl: './claim-search-homepage.component.html',
    styleUrls: ['./claim-search-homepage.component.css']
})
export class ClaimSearchHomepageComponent extends ClaimsBaseComponent implements OnInit, AfterViewInit {

    @ViewChild('modal_otp', {static: true}) otpBox: OtpBoxComponent;
    @ViewChild('modal_info', {static: true}) private infoModal: TemplateRef<any>;
    @ViewChild('modal_user_locked', {static: true}) private lockedModal: TemplateRef<any>;

    otpToken: string = "";
    showOtpError: boolean = false;
    otp: string = "";
    maskedEmailAddress: string = "";
    infoMessage: string = "";
    subInfoMessage: string = "";
    claimAuthJson: ClaimAuthJson;

    claimsSearchFormGroup: FormGroup;
    transactionInfo: TransactionInfo;
    authMethod: string = "";
    notifications: Notifications = new Notifications();
    country: string = "";

    private transactionInfoService : TransactionInfoService;
    private claimSearchService: ClaimSearchService;
    private googleAnalyticsService: GoogleAnalyticsService;
    private dateService: DateService;

    constructor(private fb: FormBuilder,
                private router: Router,
                private activatedRoute: ActivatedRoute,
                private injector : Injector,
                private modalService: NgbModal) {

        super(injector);
        this.transactionInfoService = this.injector.get(TransactionInfoService);
        this.claimSearchService = this.injector.get(ClaimSearchService);
        this.googleAnalyticsService = this.injector.get(GoogleAnalyticsService);
        this.dateService = this.injector.get(DateService);
        this.transactionInfo = this.transactionInfoService.getTransactionInfo();
        this.notifications.showPreamble = false;
        this.country = this.transactionInfo.getCountry().toUpperCase();
        document.title = this.setUpDocumentTitle();
    }

    ngOnInit() {

        this.pushGAClaimSearchView();

        //set default auth method when transaction info dob and name are empty
        this.authMethod = "fullname";
        let policyNumberInfo: string = !UtilitiesService.isNullOrUndefined(this.transactionInfo.getPolicyNumber())? this.transactionInfo.getPolicyNumber() : "";
        let claimNo: string = !UtilitiesService.isNullOrUndefined(this.transactionInfo.getClaimNumber())? this.transactionInfo.getClaimNumber() : "";
        // set default product type from product parameter
        let productTypeValue: string = this.transactionInfo.getPolicyType()?.toLowerCase();

        this.claimsSearchFormGroup = this.fb.group({
            productType: [productTypeValue],
            policyNumber: [policyNumberInfo],
            claimNumber: [claimNo],
            authName: [],
            authDateOfBirth: [],
            authIdNumber: []
        });

        this.resetFields(this.authMethod);
        this.resetFields("claimNumber");

        if (this.transactionInfo.isEmployeeCompensationProduct()) {
           this.enableECFields();
        }
    }

    ngAfterViewInit() {

        this.claimsSearchFormGroup.valueChanges.subscribe(data => {
            this.transactionInfo.setClaimNumber(this.claimsSearchFormGroup.get('claimNumber').value);
            this.transactionInfo.setName(this.claimsSearchFormGroup.get('authName').value);
            this.transactionInfo.setDateOfBirth(super.getValueInComponent('authDateOfBirth'));
            this.transactionInfo.setAuthIdNumber(this.claimsSearchFormGroup.get('authIdNumber').value);
        });

        this.claimsSearchFormGroup.get('productType').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
            this.transactionInfo.setProductByString(UtilitiesService.getDefaultProductCode(data));
            // reset-disable authentication fields, add active class to full name
            this.transactionInfo.setName(null);
            this.transactionInfo.setDateOfBirth(null);
            this.transactionInfo.setAuthIdNumber(null);
            this.transactionInfo.setClaimNumber(null);

            if (this.transactionInfo.isEmployeeCompensationProduct()) {
                this.enableECFields();
            } else {
                $('#nameDiv').addClass('active show');
                $('#birthDiv').removeClass('active show');
                $('#idNumberDiv').removeClass('active show');
                this.resetFields("fullname");
                this.resetFields("claimNumber");
            }
        });

        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            document.title = this.setUpDocumentTitle();
        });
    }

    setUpDocumentTitle() {
        return this.translate.instant("claimStatus.description");
    }

    resetFields(fieldName: string) {
        if (fieldName == 'fullname')  {
            this.enableFullNameField();
            this.authMethod = "fullname";
            this.transactionInfo.setName(this.claimsSearchFormGroup.get('authName').value);
        } else if (fieldName  == 'dateofbirth') {
            this.enableDOBField();
            this.authMethod = "dob";
            this.transactionInfo.setDateOfBirth(super.getValueInComponent('authDateOfBirth'));
        } else if (fieldName == 'idNumber') {
            this.enableIdNumber();
            this.authMethod = "idnumber";
            this.transactionInfo.setAuthIdNumber(this.claimsSearchFormGroup.get('authIdNumber').value);
        } else if (fieldName == 'claimNumber') {
            this.transactionInfo.setClaimNumber(this.claimsSearchFormGroup.get('claimNumber').value);
        }
    }

    enableFullNameField() {
        this.claimsSearchFormGroup.get('authName').enable({onlySelf: false, emitEvent: false});
        this.claimsSearchFormGroup.get('authDateOfBirth').reset();
        this.claimsSearchFormGroup.get('authDateOfBirth').disable({onlySelf: false, emitEvent: false});
        if (this.transactionInfo.isMotorProduct() || this.transactionInfo.isHomeProduct() || this.transactionInfo.isGenericClaim() ||
            this.transactionInfo.isAccidentProduct()) {
            this.claimsSearchFormGroup.get('authIdNumber').enable({onlySelf: false, emitEvent: false});
            $('#idNumberDiv').addClass('active show');
        } else {
            this.claimsSearchFormGroup.get('authIdNumber').disable({onlySelf: false, emitEvent: false});
        }
    }

    enableDOBField() {
        this.claimsSearchFormGroup.get('authDateOfBirth').enable({onlySelf: false, emitEvent: false});
        this.claimsSearchFormGroup.get('authName').reset();
        this.claimsSearchFormGroup.get('authName').disable({onlySelf: false, emitEvent: false});
        this.claimsSearchFormGroup.get('authIdNumber').disable({onlySelf: false, emitEvent: false});
    }

    enableIdNumber() {
        this.claimsSearchFormGroup.get('authIdNumber').enable({onlySelf: false, emitEvent: false});
        this.claimsSearchFormGroup.get('authName').reset();
        this.claimsSearchFormGroup.get('authName').disable({onlySelf: false, emitEvent: false});
        this.claimsSearchFormGroup.get('authDateOfBirth').disable({onlySelf: false, emitEvent: false});
    }

    enableECFields() {
        this.claimsSearchFormGroup.get('authName').enable({onlySelf: false, emitEvent: false});
        this.claimsSearchFormGroup.get('authIdNumber').enable({onlySelf: false, emitEvent: false});
    }

    goToNext() {
        this.claimAuthJson = new ClaimAuthJson();
        this.pushGAOnClick();
        if (this.validateForm()) {
            this.notifications.clearMessages();
            let claimNumber: string = this.claimsSearchFormGroup.get('claimNumber').value;
            claimNumber = !UtilitiesService.isNullOrUndefined(claimNumber) ? claimNumber.toUpperCase() : "";

             if (claimNumber && claimNumber == 'TST1234') {    // this is for testing purposes only
               this.testData();
             } else {
                let loggingId = this.transactionInfo.getLoggingId();
                let policyType = this.transactionInfo.getPolicyType();

                let authName;
                let authDob;
                let authIdNumber;  
                let product = this.transactionInfo.getProduct();
                if (product && ['Motors', 'Generic', 'Home', 'Accident', 'EC'].indexOf(product.getProductTypeDesc()) !== -1) {
                    authName = !UtilitiesService.isNullOrUndefined(this.transactionInfo.getName()) ? this.transactionInfo.getName() : "";
                    authIdNumber = !UtilitiesService.isNullOrUndefined(this.transactionInfo.getAuthIdNumber()) ? this.transactionInfo.getAuthIdNumber() : "";
                    authDob = null;
                } else {
                    authName = !UtilitiesService.isNullOrUndefined(this.transactionInfo.getName()) && this.authMethod == "fullname" ? this.transactionInfo.getName() : "";
                    authDob = !UtilitiesService.isNullOrUndefined(this.transactionInfo.getDateOfBirth()) && this.authMethod == "dob" ? this.transactionInfo.getDateOfBirth() : null;
                    authIdNumber = !UtilitiesService.isNullOrUndefined(this.transactionInfo.getAuthIdNumber()) && this.authMethod == "idnumber" ? this.transactionInfo.getAuthIdNumber() : "";
                }
                
                let policyVerifyForm: PolicyVerifyForm = new PolicyVerifyForm();
                policyVerifyForm.setCountry(this.country);
                policyVerifyForm.setClaimNumber(claimNumber);
                policyVerifyForm.setPolicyType(policyType);
                policyVerifyForm.setName(authName);
                policyVerifyForm.setDateOfBirth(this.dateService.dateToString(authDob, "dd/MM/yyyy"));
                policyVerifyForm.setIdNumber(authIdNumber);
                policyVerifyForm.setLoggingId(loggingId);
                policyVerifyForm.setOrigin('EFNOL');

                this.claimSearchService.getClaimsStatus(policyVerifyForm)
                    .pipe(takeUntil(this.ngUnsubscribe))
                    .subscribe({
                        next: (claimAuth: ClaimAuthJson) => {
                            if (!this.isNotNullOrUndefinedStr(claimAuth.getErrorStr())) {
                                this.claimAuthJson = claimAuth;
                                this.sendInitOtp(claimAuth);
                            } else if (claimAuth.getErrorCode() == 'P02'){
                                const policyNumberLockedMsg = "error_messages.notificationsErrMsg.policyNumberLocked";
                                this.notifications.addErrorMessage(policyNumberLockedMsg);
                                throw new Error("PLCYERR - Error policy number locked");
                            } else if (claimAuth.getErrorCode() == "E01") {
                                this.displayGenericError();
                            } else if (claimAuth.getErrorCode() == "P03") {
                                this.claimsSearchFormGroup.get('productType').setValue("ec");
                            } else {
                                const errorMessage = "error_messages.notificationsErrMsg.authenticationMisMatch";
                                this.notifications.addErrorMessage(errorMessage);
                                throw new Error("PLCYERR - Error verifying policy");
                            }
                        },
                        error: () => {
                            this.displayGenericError();
                        }
                    });
            }
        }
    }

    sendInitOtp(claimAuth: ClaimAuthJson) {
        this.showOtpError = false;
        this.infoMessage = '';
        this.subInfoMessage = '';

        if (claimAuth?.getOtpStatus() == 'SUCCESS' && claimAuth.getClaimToken()) {
            if (claimAuth.getOtpToken() && claimAuth.getOtpEmail()) {
                this.otpToken = claimAuth.getOtpToken();
                this.maskedEmailAddress = UtilitiesService.maskEmail(claimAuth.getOtpEmail());
                this.otpBox.open();
            } else {
                // if there's no email address, no need for 2fa
                this.claimSearchService.setUpClaimResult(claimAuth);
                this.router.navigate(["/claim-status-summary"], {
                    relativeTo: this.activatedRoute
                });
            }
        } else {
            if (claimAuth?.getOtpStatus() == 'LOCKED') {
                this.infoMessage = "motorClaim.homePage.emailAddressLocked";
                this.subInfoMessage ="motorClaim.homePage.emailAddressLockedOtp";
                this.modalService.open(this.infoModal, {size: 'md', backdrop: 'static', keyboard: false});
            } else {
                this.displayGenericError();
            }
        }
    }

    resendOtp() {
        this.showOtpError = false;
        this.infoMessage = '';
        this.subInfoMessage = '';

        this.claimSearchService.sendOtp(this.claimAuthJson.getClaimToken(), null, null)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: (result: any) => {
                    if (result?.status == 'SUCCESS') {
                        if (result.token && result.email) {
                            this.otpToken = result.token;
                            this.maskedEmailAddress = UtilitiesService.maskEmail(result.email);
                            this.otpBox.open();
                        } else {
                            // if there's no email address, no need for 2fa
                            this.claimSearchService.setUpClaimResult(this.claimAuthJson);
                            this.router.navigate(["/claim-status-summary"], {
                                relativeTo: this.activatedRoute
                            });
                        }
                    } else {
                        if (result?.status == 'LOCKED') {
                            this.infoMessage = "motorClaim.homePage.emailAddressLocked";
                            this.subInfoMessage ="motorClaim.homePage.emailAddressLockedOtp";
                            this.modalService.open(this.infoModal, {size: 'md', backdrop: 'static', keyboard: false});
                        } else {
                            this.showOtpError = true;
                        }
                    }
                },
                error: () => {
                    this.displayGenericError();
                }
            });
    }

    verifyOtpAndGetClaimStatus(otp: string) {
        this.infoMessage = '';
        this.subInfoMessage = '';

        this.claimSearchService.verifyOtpAndGetClaimStatus(this.otpToken, otp)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: (result: any) => {
                    if (result?.status == 'SUCCESS') {
                        let claimAuthJsonObj = ClaimAuthJson.jsonConvert(result?.claimAuthJson);
                        claimAuthJsonObj.setProductType(this.claimAuthJson.getProductType());
                        claimAuthJsonObj.setPolicyType(this.claimAuthJson.getPolicyType());
                        claimAuthJsonObj.setIsManulife(this.claimAuthJson.getIsManulife());
                        claimAuthJsonObj.setCatalogueCode(this.claimAuthJson.getCatalogueCode());
                        claimAuthJsonObj.setClaimToken(this.claimAuthJson.getClaimToken());

                        this.claimSearchService.setUpClaimResult(claimAuthJsonObj);
                        this.otpBox.dismiss();
                        this.router.navigate(["/claim-status-summary"], {
                            relativeTo: this.activatedRoute
                        });
                    } else {
                        if (result?.status == 'LOCKED') {
                            this.modalService.dismissAll();
                            this.infoMessage = "motorClaim.homePage.emailAddressLocked";
                            this.subInfoMessage ="motorClaim.homePage.emailAddressLockedOtp";
                            this.modalService.open(this.infoModal, {size: 'md', backdrop: 'static', keyboard: false});
                        } else {
                            this.showOtpError = true;
                        }
                    }
                },
                error: () => {
                    this.displayGenericError();
                }
            });
    }

    displayGenericError()  {
        const errorMessage1 = "claimStatus.error_messages.genericError";
        this.notifications.addErrorMessage(errorMessage1);
        const errorMessage2 = "error_messages.notificationsErrMsg.genericError" +
            this.transactionInfo.getRealCountry()?.toUpperCase();
        this.notifications.addErrorMessage(errorMessage2);
        throw new Error("PLCYERR - Error Looking up claim number - getClaimDetails");
    }

    validateForm(): boolean {
        return super.validateForm(this.claimsSearchFormGroup);
    }

    testData() {

        // Test Data
        const integrationData = new ClaimAuthJson();
        integrationData.setPolicyNumber("T0200754");
        integrationData.setCountry("HKG");
        integrationData.setPolicyType("travel");

        const claimDetailsIntegrationData: IntegrationClaimDetailsJson[] = [];

       const claimDetailsTest1 = new IntegrationClaimDetailsJson();
        claimDetailsTest1.setPolicyNumber("T0200754");
        claimDetailsTest1.setClaimNumber("TC022877");
        claimDetailsTest1.setProductType("PTR");
        claimDetailsTest1.setQnectStatus("Additional information requested");
        claimDetailsTest1.setDateOfLoss(new Date());
        claimDetailsTest1.setClientName('CARDO DALISAY');
        claimDetailsIntegrationData.push(claimDetailsTest1);


        const claimDetailsTest2 = new IntegrationClaimDetailsJson();
        claimDetailsTest2.setPolicyNumber("T0200754");
        claimDetailsTest2.setClaimNotificationNumber("CN-HK-292020");
        claimDetailsTest2.setProductType("PTR");
        claimDetailsTest2.setQnectStatus('Consultant investigation in progress');
        claimDetailsTest2.setDateOfLoss(new Date());
        claimDetailsTest2.setLastUpdate(new Date());
        claimDetailsTest2.setClientName('WINNIE THE POOH');
        claimDetailsIntegrationData.push(claimDetailsTest2);

        integrationData.setClaimDetailsIntegrationInfolist(claimDetailsIntegrationData);

        this.claimSearchService.setUpClaimResult(integrationData);

        this.router.navigate(["/claim-status-summary"], {
            relativeTo: this.activatedRoute
        });

    }

    pushGAClaimSearchView() {
        let utmSource = this.isNotNullOrUndefinedStr(this.googleAnalyticsService.getUtmSource()) ?
            this.googleAnalyticsService.getUtmSource() : "N/A" ;
        let utmMedium = this.isNotNullOrUndefinedStr(this.googleAnalyticsService.getUtmMedium()) ?
            this.googleAnalyticsService.getUtmMedium() : "N/A" ;
        let utmCampaign =  this.isNotNullOrUndefinedStr(this.googleAnalyticsService.getUtmCampaign()) ?
            this.googleAnalyticsService.getUtmCampaign() : "N/A" ;

        (<any>window).dataLayer.push({
            'pageStep': 'Home',
            'vPath': '/home',
            'utm_source': utmSource,
            'utm_medium': utmMedium,
            'utm_campaign': utmCampaign,
            'event': 'vpageview',
            'ecommerce': {
                'checkout': {
                    'actionField': {'step': 1},	//Step1-Home
                }
            }
        });
    }

    pushGAOnClick() {
        (<any>window).dataLayer.push({'event': 'start'});
    }

    trim(formGroup, evt: any) {
        formGroup.setValue(evt.target.value.trim());
        this.onKeyDown(evt);
    }

    onKeyDown(evt: any) {
        if (evt.keyCode === 32) {
            evt.preventDefault();
        }
    }
}
