import {Component, Injector} from '@angular/core';
import {ClaimsBaseComponent} from "../../../claim-base.component";
import {DocumentHelperService} from '../../../../services/document-helper.service';
import {DocumentField} from '../../../../model/document-field';
import {ClaimTypes} from '../../../../model/claim-type.model';
import {ClaimEmployeeCompensation} from '../../../../model/claim-employee-compensation.model';
import {DatePipe} from '@angular/common';

@Component({
    selector: 'app-summary-form2',
    templateUrl: './summary-form2.component.html',
    styleUrls: ['../employee-compensation-summary.component.css',]
})

export class SummaryForm2Component extends ClaimsBaseComponent {

    employeeCompensation: ClaimEmployeeCompensation;
    listOfDocuments: DocumentField[] = [];
    listOfOtherDocuments: DocumentField[] = [];

    constructor(private injector : Injector) {
        super(injector);
        this.employeeCompensation = this.claim.getClaimEmployeeCompensation();
        this.listOfDocuments = this.injector.get(DocumentHelperService).getListOfUploadedDocuments(this.claim.getDocumentForm().documents,
            this.injector.get(DocumentHelperService).getDocumentFormBuilder(ClaimTypes[ClaimTypes.CLAIM_TYPE_EC_FORM2]).getRequiredDocuments(),
            ClaimTypes[ClaimTypes.CLAIM_TYPE_EC_FORM2]);
        this.listOfOtherDocuments = this.injector.get(DocumentHelperService).getListOfOtherUploadedDocuments(this.claim.getDocumentForm().documents,
            this.injector.get(DocumentHelperService).getDocumentFormBuilder(ClaimTypes[ClaimTypes.CLAIM_TYPE_EC_FORM2]).getRequiredDocuments(),
            ClaimTypes[ClaimTypes.CLAIM_TYPE_EC_FORM2]);
    }

    toBePaid(value: any) {
        if (value) {
            return value == 'To be paid' ?  this.translate.instant("claimSection.generalLabels.radioButtonLabels.yesSend") :
             this.translate.instant("claimSection.generalLabels.radioButtonLabels.noSend");
        }
        if(value == 'To be paid'){
            return this.translate.instant("claimSection.generalLabels.radioButtonLabels.yesSend");
        }
        return '';
    }

    returnYesOrNo(value: boolean) {
        if (value) {
            return this.translate.instant("claimSection.generalLabels.generalButtonLabels.yes");
        }
        return this.translate.instant("claimSection.generalLabels.generalButtonLabels.no");
    }

    getCommaSeparatedValues(options: any[]) {
        return options?.map(item => item.label).join(", ");
    }

    isMachineryInvolvedContainOthers(machineryInvolved) {
        return machineryInvolved.find(item => item.value === '14');
    }

    getDateDuration(startDate: Date, endDate: Date) {
        return (startDate ? this.getFormattedDate(startDate) : "") + (startDate && endDate ?  " - " : "") +
            (endDate ? this.getFormattedDate(endDate) : "");
    }

    getDate(dt: Date) {
        return dt ? new DatePipe('en-US').transform(dt, "dd/MM/yyyy") : "";
    }
}
