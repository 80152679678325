import {Component, Injector, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup} from '@angular/forms';
import { ClaimsBaseComponent } from 'src/app/claim-pages/claim-base.component';
import {takeUntil} from 'rxjs/operators';
import {ClaimEmployeeCompensation} from '../../../model/claim-employee-compensation.model';
import {PeriodOfSickLeave} from '../../../model/employee-compensation/period-of-sick-leave.model';
import {UtilitiesService} from '../../../utilities/utilities.service';
import {TransactionInfoService} from '../../../services/transaction-info.service';
import {ClaimTypes} from '../../../model/claim-type.model';

@Component({
    selector: 'app-period-of-sick-leave',
    templateUrl: './period-of-sick-leave.component.html',
    styleUrls: ['./period-of-sick-leave.css']
})
export class PeriodOfSickLeaveComponent extends ClaimsBaseComponent implements OnInit {

    sickLeaveForm: FormGroup;
    claimEmployeeCompensation: ClaimEmployeeCompensation;
    periodOfSickLeaveArray: PeriodOfSickLeave[] = [];
    maxSickLeave: string;
    currentClaimType: string;
    isForm2Or2a: boolean = false;

    constructor(private fb: FormBuilder,
                private injector : Injector) {
        super(injector);
        this.claimEmployeeCompensation = this.claim.getClaimEmployeeCompensation();
        this.currentClaimType = this.injector.get(TransactionInfoService).getTransactionInfo().getCurrentClaimType();
        this.maxSickLeave = this.getMaxSickLeaves(this.currentClaimType);
        this.isForm2Or2a = [ClaimTypes[ClaimTypes.CLAIM_TYPE_EC_FORM2], ClaimTypes[ClaimTypes.CLAIM_TYPE_EC_FORM2A]].indexOf(this.currentClaimType) !== -1;
        this.periodOfSickLeaveArray = this.claimEmployeeCompensation.getPeriodOfSickLeaves();
    }

    ngOnInit() {
        this.sickLeaveForm = this.fb.group({
            items: this.fb.array(this.buildStoredItems(this.periodOfSickLeaveArray)),
            sickLeaveDays: [this.isNotNullOrUndefined(this.claimEmployeeCompensation.getTotalSickLeaveDays()) ?
                this.claimEmployeeCompensation.getTotalSickLeaveDays() : 0],
            isSickLeaveContinuing: [super.getBooleanString(this.claimEmployeeCompensation.getIsSickLeaveContinuing())],
            isDutySuitableForEmployee: [super.getBooleanString(this.claimEmployeeCompensation.getIsDutySuitableForEmployee())],
            contactEmployeeForValueAddedService: [super.getBooleanString(this.claimEmployeeCompensation.getContactEmployeeForValueAddedService())]
        });

        this.initializeArrays();
    }

    ngAfterViewInit() {
        let self = this;

        let sickLeaveFormControl = this.sickLeaveForm.get('sickLeaveDays') as FormControl;
        let isSickLeaveContinuingFC = this.sickLeaveForm.get('isSickLeaveContinuing') as FormControl;
        let isDutySuitableForEmployeeFC = this.sickLeaveForm.get('isDutySuitableForEmployee') as FormControl;
        let contactEmployeeForValueAddedServiceFC = this.sickLeaveForm.get('contactEmployeeForValueAddedService') as FormControl;

        this.sickLeaveForm.get('items').valueChanges.pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((data) => {
                let total = 0;

               data.forEach((periodOfSickLeave, index)=>{
                    let itemDetail : PeriodOfSickLeave = self.periodOfSickLeaveArray[index];
                    itemDetail.setQuantity(super.getIndexedValueInComponent('quantity', index));
                    itemDetail.setQuantityDesc(super.getIndexedTextInComponent('quantity', index));
                    itemDetail.setStartDate(super.getIndexedValueInComponent("sickLeaveFromDate", index));
                    if (itemDetail.getQuantity() == 'half day' && this.isNotNullOrUndefined(itemDetail.getStartDate())) {
                        itemDetail.setEndDate(super.getIndexedValueInComponent("sickLeaveFromDate", index));
                    } else {
                        itemDetail.setEndDate(super.getIndexedValueInComponent("sickLeaveToDate", index));
                    }

                    let numberOfDays = this.calculateSickLeaveDays(itemDetail.getStartDate(), itemDetail.getEndDate(),
                        itemDetail.getQuantity() == 'half day');
                    itemDetail.setNumberOfDays(numberOfDays);
                    total += numberOfDays;
                });

                sickLeaveFormControl.setValue(total);    
        });

        sickLeaveFormControl.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
            this.claimEmployeeCompensation.setTotalSickLeaveDays(this.sickLeaveForm.get('sickLeaveDays').value)
        });

        isSickLeaveContinuingFC.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
            this.claimEmployeeCompensation.setIsSickLeaveContinuing(data === 'true');
            this.validateQBEConnectFields(data);
        });

        isDutySuitableForEmployeeFC.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
            this.claimEmployeeCompensation.setIsDutySuitableForEmployee(this.isNotNullOrUndefined(data) ? data === 'true' : data);
        });

        contactEmployeeForValueAddedServiceFC.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
            this.claimEmployeeCompensation.setContactEmployeeForValueAddedService(this.isNotNullOrUndefined(data) ? data === 'true' : data);
        });

        setTimeout(() => {
            self.quantityFromDateValueChanged();
            self.validateQuantityField();
        }, 10);
    }

    initializeArrays() {
        if (UtilitiesService.isNullOrUndefined(this.periodOfSickLeaveArray)) {
            this.periodOfSickLeaveArray = [];
            this.claimEmployeeCompensation.setPeriodOfSickLeaves(this.periodOfSickLeaveArray);
        }

        if (this.periodOfSickLeaveArray.length === 0) {
            this.periodOfSickLeaveArray.push(new PeriodOfSickLeave());
        }
    }

    validateQBEConnectFields(data) {
        this.sickLeaveForm.get('isDutySuitableForEmployee').reset();
        this.sickLeaveForm.get('contactEmployeeForValueAddedService').reset();
        if (data && data == "true") {
            this.sickLeaveForm.get('isDutySuitableForEmployee').enable({onlySelf: false, emitEvent: false});
            this.sickLeaveForm.get('contactEmployeeForValueAddedService').enable({onlySelf: false, emitEvent: false});
            this.sickLeaveForm.get('isDutySuitableForEmployee').setValue('true');
            this.sickLeaveForm.get('contactEmployeeForValueAddedService').setValue('true');
        } else {
            this.sickLeaveForm.get('isDutySuitableForEmployee').disable({onlySelf: false, emitEvent: false});
            this.sickLeaveForm.get('contactEmployeeForValueAddedService').disable({onlySelf: false, emitEvent: false});
        }
    }


    buildStoredItems(periodOfSickLeaves: PeriodOfSickLeave[]): FormGroup[] {
        let formGroupArray : FormGroup[] = [];

        if(periodOfSickLeaves != null && periodOfSickLeaves.length > 0) {
            periodOfSickLeaves.forEach((sickLeave)=>{
                let rowFormGroup = this.fb.group({
                    quantity: [sickLeave.getQuantity()],
                    sickLeaveFromDate: [sickLeave.getStartDate()],
                    sickLeaveToDate: [sickLeave.getEndDate()]
                });
                formGroupArray.push(rowFormGroup);
            });
        }
        else {
            formGroupArray.push(this.createDefaultItems());
        }

        return formGroupArray;
    }

    createDefaultItems(): FormGroup {
        return this.fb.group({
            quantity: 'full day',
            sickLeaveFromDate: null,
            sickLeaveToDate: null
        });
    }

    addItem(): void {
        let self = this;
        setTimeout(function () {
            self.periodOfSickLeaveArray.push(new PeriodOfSickLeave());
            let items = self.sickLeaveForm.get('items') as FormArray;
            let newItem : FormGroup = self.createDefaultItems();
            items.push(newItem);
            self.quantityFromDateValueChanged();
        }, 10);

    }

    removeItem(i) {
        let items = this.sickLeaveForm.get('items') as FormArray;
        items.removeAt(i);
        this.periodOfSickLeaveArray.splice(i, 1);
    }

    validateQuantityChange(formGroup) {
        let quantity = formGroup.get('quantity').value;
        let sickLeaveFromDate = formGroup.get('sickLeaveFromDate').value;
        if (quantity == 'half day') {
            if (this.isNotNullOrUndefined(sickLeaveFromDate)) {
                formGroup.get('sickLeaveToDate').setValue(sickLeaveFromDate);
            }
        } else {
            formGroup.get('sickLeaveToDate').enable({onlySelf: false, emitEvent: false});
        }
    }

    quantityFromDateValueChanged() {
        const leaveFormArray = this.sickLeaveForm.get('items') as FormArray;
        const newIndex = leaveFormArray.length - 1;
        leaveFormArray.at(newIndex).get('quantity').valueChanges.pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(() => {
                this.validateQuantityChange(leaveFormArray.at(newIndex));
            });
        leaveFormArray.at(newIndex).get('sickLeaveFromDate').valueChanges.pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(() => {
                this.validateQuantityChange(leaveFormArray.at(newIndex));
            });
    }

    validateQuantityField(){
        let leaveFormArray = this.sickLeaveForm.get('items') as FormArray;
        for (let i = 0; i < leaveFormArray.length; i++) {
            let itemGroup = leaveFormArray.at(i);
            this.validateQuantityChange(itemGroup);
        }
    }

    validateForm(): boolean {
        return super.validateForm(this.sickLeaveForm);
    }

    calculateSickLeaveDays(startDate: Date, endDate: Date, isHalfDay: boolean): number {
        if (isHalfDay && startDate) {
            return 0.5;
        } else {
            if (startDate && endDate) {
                let diffDays = UtilitiesService.getDiffDays(startDate, endDate);
                return diffDays > 0 ? diffDays : 0;
            }
        }
        return 0;
    }

    getDatesForValidation(i, fieldType): [Date, Date[][], Date] {
        return this.getSickLeaveDates(i, fieldType, this.periodOfSickLeaveArray);
    }

    getSickLeaveDates(i, fieldType, periodOfSickLeaves): [Date, Date[][], Date] {
        if(fieldType == 'from'){
            return [null, this.getDates(i, periodOfSickLeaves), null];
        }
        return [this.getDateFrom(i, periodOfSickLeaves), this.getDates(i, periodOfSickLeaves), null];
    }

    getDateFrom(i, periodOfSickLeaves): Date {
        if(periodOfSickLeaves != null && periodOfSickLeaves.length > 0) {
            return periodOfSickLeaves[i].getStartDate();
        }
        return null;
    }

    getDates(i, periodOfSickLeaves): Date[][] {
        let completedDates : Date[][] = [];
        if(periodOfSickLeaves != null && periodOfSickLeaves.length > 1) {
            for(let x = 0; x < periodOfSickLeaves.length; x++){
                if(x != i) {
                    let startDate = periodOfSickLeaves[x].getStartDate();
                    let endDate = periodOfSickLeaves[x].getEndDate();
                    if(startDate != null && endDate != null && startDate <= endDate) {
                        let dates: Date[] = [];
                        dates.push(startDate);
                        dates.push(endDate);
                        completedDates.push(dates);
                    }
                }
            }
        }
        return completedDates;
    }

    getMaxSickLeaves(claimType: string) : string {
        if (claimType == ClaimTypes[ClaimTypes.CLAIM_TYPE_EC_FORM2B]) {
            return '3';
        } else if (claimType == ClaimTypes[ClaimTypes.CLAIM_TYPE_EC_FORM2A] || claimType == ClaimTypes[ClaimTypes.CLAIM_TYPE_EC_FORM2]) {
            return '7';
        }
        return '';
    }

}
