<div class="form-group section">
    <label class="section-title">{{ 'ecClaim.selectClaim.form2' | translate }}</label>
    <div class="section-content">
        <!-- details of employee -->
        <div class="sub-section">
            <label>{{ 'ecClaim.employeeDetails.title' | translate }}</label>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{ 'ecClaim.employeeDetails.name' | translate }}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{employeeCompensation.getEmployeeDetails().getName()}}
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12 col-lg-7" *ngIf="employeeCompensation.getEmployeeDetails().getIdType() === 'HKID'">
                    {{ 'ecClaim.employeeDetails.hkid' | translate }}
                </div>
                <div class="col col-xs-12 col-lg-7" *ngIf="employeeCompensation.getEmployeeDetails().getIdType() !== 'HKID'">
                    {{ 'ecClaim.employeeDetails.passport' | translate }}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{employeeCompensation.getEmployeeDetails().getIdNumber()}}
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{ 'ecClaim.employeeDetails.birthDate' | translate }}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{this.getFormattedDate(employeeCompensation.getEmployeeDetails().getBirthDate())}}
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{ 'ecClaim.employeeDetails.gender.label' | translate }}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{employeeCompensation.getEmployeeDetails().getGenderDesc()}}
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{ 'pagePaymentAndContact.contactInfo.telephone' | translate }}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{employeeCompensation.getEmployeeDetails().getTelephoneNumberCode()}}
                    {{employeeCompensation.getEmployeeDetails().getTelephoneNumber()}}
                </div>
            </div>
            <div class="row" *ngIf="employeeCompensation.getEmployeeDetails().getEmailAddress()">
                <div class="col col-xs-12 col-lg-7">
                    {{ 'ecClaim.employerDetails.email' | translate }}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{employeeCompensation.getEmployeeDetails().getEmailAddress()}}
                </div>
            </div>
            <div class="row" *ngIf="employeeCompensation.getEmployeeDetails().getFaxNumber()">
                <div class="col col-xs-12 col-lg-7">
                    {{ 'pagePaymentAndContact.contactInfo.fax' | translate }}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{employeeCompensation.getEmployeeDetails().getFaxNumber()}}
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{ 'pagePaymentAndContact.contactInfo.address' | translate }}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{employeeCompensation.getEmployeeDetails().getAddress()}}
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{ 'ecClaim.employeeDetails.occupation' | translate }}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{employeeCompensation.getEmployeeDetails().getOccupation()}}
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{ 'ecClaim.employeeDetails.apprentice.label' | translate }}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{returnYesOrNo(employeeCompensation.getEmployeeDetails().getIsApprentice())}}
                </div>
            </div>
            <div class="row" *ngIf="employeeCompensation.getEmployeeDetails().getDurationDateFrom() || employeeCompensation.getEmployeeDetails().getDurationDateTo()">
                <div class="col col-xs-12 col-lg-7">
                    {{ 'ecClaim.employeeDetails.durationDate' | translate }}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{getDateDuration(employeeCompensation.getEmployeeDetails().getDurationDateFrom(), employeeCompensation.getEmployeeDetails().getDurationDateTo())}}
                </div>
            </div>
        </div>
        <!-- place of accident -->
        <div class="sub-section">
            <label>{{ 'ecClaim.accidentPlace.title' | translate }}</label>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{'ecClaim.accidentPlace.placeOfOccurrence.label' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{employeeCompensation.getAccidentPlaceDetails().getAccidentPlaceDesc()}}
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{'ecClaim.accidentPlace.placeType.label' | translate}}
                </div>
                <ng-container *ngIf="employeeCompensation.getAccidentPlaceDetails().getPlaceType() === '15'">
                    <div class="col col-xs-12 col-lg-5 content">
                        {{employeeCompensation.getAccidentPlaceDetails().getPlaceTypeOther()}}
                    </div>
                </ng-container>
                <ng-container *ngIf="employeeCompensation.getAccidentPlaceDetails().getPlaceType() !== '15'">
                    <div class="col col-xs-12 col-lg-5 content">
                        {{employeeCompensation.getAccidentPlaceDetails().getPlaceTypeDesc()}}
                    </div>
                </ng-container>
            </div>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{'ecClaim.accidentPlace.activityCarriedOut' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{employeeCompensation.getAccidentPlaceDetails().getActivity()}}
                </div>
            </div>
            <ng-container *ngIf="employeeCompensation.getAccidentPlaceDetails().getAccidentPlace() == 'Construction site'">
                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{'ecClaim.accidentPlace.typeofWorkPerformed.label' | translate}}
                    </div>
                    <ng-container *ngIf="employeeCompensation.getAccidentPlaceDetails().getWorkType() === '20'">
                        <div class="col col-xs-12 col-lg-5 content">
                            {{employeeCompensation.getAccidentPlaceDetails().getWorkTypeOther()}}
                        </div>
                    </ng-container>
                    <ng-container *ngIf="employeeCompensation.getAccidentPlaceDetails().getWorkType() !== '20'">
                        <div class="col col-xs-12 col-lg-5 content">
                            {{employeeCompensation.getAccidentPlaceDetails().getWorkTypeDesc()}}
                        </div>
                    </ng-container>
                </div>
                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{'ecClaim.accidentPlace.whereabouts' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{employeeCompensation.getAccidentPlaceDetails().getWhereabouts()}}
                    </div>
                </div>
                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{'ecClaim.accidentPlace.machineryInvolved.label' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{getCommaSeparatedValues(employeeCompensation.getAccidentPlaceDetails().getMachineryInvolved())}}
                    </div>
                </div>
                <ng-container *ngIf="this.isMachineryInvolvedContainOthers(employeeCompensation.getAccidentPlaceDetails().getMachineryInvolved())">
                    <div class="row">
                        <div class="col col-xs-12 col-lg-7">
                            {{'ecClaim.accidentPlace.machineryInvolved.others' | translate}}
                        </div>
                        <div class="col col-xs-12 col-lg-5 content">
                            {{employeeCompensation.getAccidentPlaceDetails().getMachineryInvolvedOther()}}
                        </div>
                    </div>
                </ng-container>
                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{'ecClaim.accidentPlace.transportingConstructionMachineryInvolved.label' | translate}}
                    </div>
                    <ng-container *ngIf="employeeCompensation.getAccidentPlaceDetails().getTransportingConstructionMachineryInvolved() === '07'">
                        <div class="col col-xs-12 col-lg-5 content">
                            {{employeeCompensation.getAccidentPlaceDetails().getTransportingConstructionMachineryInvolvedOther()}}
                        </div>
                    </ng-container>
                    <ng-container *ngIf="employeeCompensation.getAccidentPlaceDetails().getTransportingConstructionMachineryInvolved() !== '07'">
                        <div class="col col-xs-12 col-lg-5 content">
                            {{employeeCompensation.getAccidentPlaceDetails().getTransportingConstructionMachineryInvolvedDesc()}}
                        </div>
                    </ng-container>
                </div>
            </ng-container>
        </div>

        <!-- supplementary information on accidents on construction sites -->
        <ng-container *ngIf="employeeCompensation.getAccidentPlaceDetails().getAccidentPlace() == 'Construction site'">
            <div class="sub-section">
                <label>{{ 'ecClaim.accidentPlace.supplementaryInfo.title' | translate }}</label><br/>
                <label>{{ 'ecClaim.accidentPlace.supplementaryInfo.particularsWorksite.label' | translate }}</label>
                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{'ecClaim.accidentPlace.supplementaryInfo.particularsWorksite.commencement' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{employeeCompensation.getAccidentPlaceDetails().getCommencementDate()}}
                    </div>
                </div>
                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{'ecClaim.accidentPlace.supplementaryInfo.particularsWorksite.expectedCompletionDate' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{employeeCompensation.getAccidentPlaceDetails().getCompletionDate()}}
                    </div>
                </div>
                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{'ecClaim.accidentPlace.supplementaryInfo.particularsWorksite.contractorName' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{employeeCompensation.getAccidentPlaceDetails().getContractorName()}}
                    </div>
                </div>
                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{'ecClaim.accidentPlace.supplementaryInfo.particularsWorksite.siteAddress' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{employeeCompensation.getAccidentPlaceDetails().getSiteAddress()}}
                    </div>
                </div>
                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{'ecClaim.accidentPlace.supplementaryInfo.particularsWorksite.contractNo' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{employeeCompensation.getAccidentPlaceDetails().getContractNo()}}
                    </div>
                </div>
                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{'ecClaim.accidentPlace.supplementaryInfo.particularsWorksite.accidentDate' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{getFormattedDate(employeeCompensation.getAccidentPlaceDetails().getAccidentDate())}}
                    </div>
                </div>
                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{'ecClaim.accidentPlace.supplementaryInfo.particularsWorksite.contactNo' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{employeeCompensation.getAccidentPlaceDetails().getContactNoCode()}}
                        {{employeeCompensation.getAccidentPlaceDetails().getContactNo()}}
                    </div>
                </div>
                <br/>
                <label>{{ 'ecClaim.accidentPlace.supplementaryInfo.particularsProject.label' | translate }}</label>
                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{'ecClaim.accidentPlace.supplementaryInfo.particularsProject.nature.label' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{employeeCompensation.getAccidentPlaceDetails().getProjectNatureDesc()}}
                    </div>
                </div>
                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{'ecClaim.accidentPlace.supplementaryInfo.particularsProject.privateProject.label' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{returnYesOrNo(employeeCompensation.getAccidentPlaceDetails().getIsPrivateProject())}}
                    </div>
                </div>
                <ng-container *ngIf="employeeCompensation.getAccidentPlaceDetails().getIsPrivateProject()">
                    <div class="row">
                        <div class="col col-xs-12 col-lg-7">
                            {{'ecClaim.accidentPlace.supplementaryInfo.particularsProject.privateProject.authorizedPersonName' | translate}}
                        </div>
                        <div class="col col-xs-12 col-lg-5 content">
                            {{employeeCompensation.getAccidentPlaceDetails().getAuthorizedPersonName()}}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col col-xs-12 col-lg-7">
                            {{'ecClaim.accidentPlace.supplementaryInfo.particularsProject.privateProject.position' | translate}}
                        </div>
                        <div class="col col-xs-12 col-lg-5 content">
                            {{employeeCompensation.getAccidentPlaceDetails().getAuthorizedPersonPosition()}}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col col-xs-12 col-lg-7">
                            {{'ecClaim.accidentPlace.supplementaryInfo.particularsProject.privateProject.phoneNo' | translate}}
                        </div>
                        <div class="col col-xs-12 col-lg-5 content">
                            {{employeeCompensation.getAccidentPlaceDetails().getAuthorizedPersonTelNoCode()}}
                            {{employeeCompensation.getAccidentPlaceDetails().getAuthorizedPersonTelNo()}}
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="!employeeCompensation.getAccidentPlaceDetails().getIsPrivateProject()">
                    <div class="row">
                        <div class="col col-xs-12 col-lg-7">
                            {{'ecClaim.accidentPlace.supplementaryInfo.particularsProject.publicWorks.label' | translate}}
                        </div>
                        <ng-container *ngIf="employeeCompensation.getAccidentPlaceDetails().getPublicWorks() === '99'">
                            <div class="col col-xs-12 col-lg-5 content">
                                {{employeeCompensation.getAccidentPlaceDetails().getPublicWorksOther()}}
                            </div>
                        </ng-container>
                        <ng-container *ngIf="employeeCompensation.getAccidentPlaceDetails().getPublicWorks() !== '99'">
                            <div class="col col-xs-12 col-lg-5 content">
                                {{employeeCompensation.getAccidentPlaceDetails().getPublicWorksDesc()}}
                            </div>
                        </ng-container>
                    </div>
                </ng-container>
                <br/>
                <label>{{ 'ecClaim.accidentPlace.supplementaryInfo.particularsPlace.title' | translate }}</label>
                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{'ecClaim.accidentPlace.supplementaryInfo.particularsPlace.label' | translate}}
                    </div>
                    <ng-container *ngIf="employeeCompensation.getAccidentPlaceDetails().getPlaceOfFall() === '08'">
                        <div class="col col-xs-12 col-lg-5 content">
                            {{employeeCompensation.getAccidentPlaceDetails().getPlaceOfFallOther()}}
                        </div>
                    </ng-container>
                    <ng-container *ngIf="employeeCompensation.getAccidentPlaceDetails().getPlaceOfFall() !== '08'">
                        <div class="col col-xs-12 col-lg-5 content">
                            {{employeeCompensation.getAccidentPlaceDetails().getPlaceOfFallDesc()}}
                        </div>
                    </ng-container>
                </div>
                <br/>
                <label>{{ 'ecClaim.accidentPlace.supplementaryInfo.ethnicity.title' | translate }}</label>
                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{'ecClaim.accidentPlace.supplementaryInfo.ethnicity.label' | translate}}
                    </div>
                    <ng-container *ngIf="employeeCompensation.getAccidentPlaceDetails().getEthnicity() === '11'">
                        <div class="col col-xs-12 col-lg-5 content">
                            {{employeeCompensation.getAccidentPlaceDetails().getEthnicityOther()}}
                        </div>
                    </ng-container>
                    <ng-container *ngIf="employeeCompensation.getAccidentPlaceDetails().getEthnicity() !== '11'">
                        <div class="col col-xs-12 col-lg-5 content">
                            {{employeeCompensation.getAccidentPlaceDetails().getEthnicityDesc()}}
                        </div>
                    </ng-container>
                </div>
                <br/>
                <label>{{ 'ecClaim.accidentPlace.supplementaryInfo.languageAbility.label' | translate }}</label><br/>
                <label>{{ 'ecClaim.accidentPlace.supplementaryInfo.languageAbility.spoken' | translate }}</label>
                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{'ecClaim.accidentPlace.supplementaryInfo.languageAbility.cantonese' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{employeeCompensation.getAccidentPlaceDetails().getSpokenCantonese()}}
                    </div>
                </div>
                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{'ecClaim.accidentPlace.supplementaryInfo.languageAbility.putonghua' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{employeeCompensation.getAccidentPlaceDetails().getSpokenPutonghua()}}
                    </div>
                </div>
                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{'ecClaim.accidentPlace.supplementaryInfo.languageAbility.english' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{employeeCompensation.getAccidentPlaceDetails().getSpokenEnglish()}}
                    </div>
                </div>
                <ng-container *ngIf="employeeCompensation.getAccidentPlaceDetails().getSpokenOtherText()">
                    <div class="row">
                        <div class="col col-xs-12 col-lg-7">
                            {{'ecClaim.accidentPlace.supplementaryInfo.languageAbility.others' | translate}}
                        </div>
                        <div class="col col-xs-12 col-lg-5 content">
                            {{employeeCompensation.getAccidentPlaceDetails().getSpokenOtherText()}} - {{employeeCompensation.getAccidentPlaceDetails().getSpokenOtherOpt()}}
                        </div>
                    </div>
                </ng-container>
                <label>{{ 'ecClaim.accidentPlace.supplementaryInfo.languageAbility.reading' | translate }}</label>
                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{'ecClaim.accidentPlace.supplementaryInfo.languageAbility.chinese' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{employeeCompensation.getAccidentPlaceDetails().getReadingChinese()}}
                    </div>
                </div>
                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{'ecClaim.accidentPlace.supplementaryInfo.languageAbility.english' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{employeeCompensation.getAccidentPlaceDetails().getReadingEnglish()}}
                    </div>
                </div>
                <ng-container *ngIf="employeeCompensation.getAccidentPlaceDetails().getReadingOtherText()">
                    <div class="row">
                        <div class="col col-xs-12 col-lg-7">
                            {{'ecClaim.accidentPlace.supplementaryInfo.languageAbility.others' | translate}}
                        </div>
                        <div class="col col-xs-12 col-lg-5 content">
                            {{employeeCompensation.getAccidentPlaceDetails().getReadingOtherText()}} - {{employeeCompensation.getAccidentPlaceDetails().getReadingOtherOpt()}}
                        </div>
                    </div>
                </ng-container>
                <label>{{ 'ecClaim.accidentPlace.supplementaryInfo.languageAbility.written' | translate }}</label>
                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{'ecClaim.accidentPlace.supplementaryInfo.languageAbility.chinese' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{employeeCompensation.getAccidentPlaceDetails().getWrittenChinese()}}
                    </div>
                </div>
                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{'ecClaim.accidentPlace.supplementaryInfo.languageAbility.english' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{employeeCompensation.getAccidentPlaceDetails().getWrittenEnglish()}}
                    </div>
                </div>
                <ng-container *ngIf="employeeCompensation.getAccidentPlaceDetails().getWrittenOtherText()">
                    <div class="row">
                        <div class="col col-xs-12 col-lg-7">
                            {{'ecClaim.accidentPlace.supplementaryInfo.languageAbility.others' | translate}}
                        </div>
                        <div class="col col-xs-12 col-lg-5 content">
                            {{employeeCompensation.getAccidentPlaceDetails().getWrittenOtherText()}} - {{employeeCompensation.getAccidentPlaceDetails().getWrittenOtherOpt()}}
                        </div>
                    </div>
                </ng-container>
            </div>
        </ng-container>
        <!-- details of accident -->
        <div class="sub-section">
            <label>{{ 'ecClaim.accidentDetails.title' | translate }}</label>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{'ecClaim.accidentDetails.accidentResult' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{employeeCompensation.getAccidentDetails().getResult()}}
                </div>
            </div>
            <ng-container *ngIf="employeeCompensation.getAccidentDetails().getResult()?.toLowerCase() == 'death'">
                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{ 'ecClaim.occupationalDisease.police.label' | translate }}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{returnYesOrNo(employeeCompensation.getFatalCase().getWasPoliceNotified())}}
                    </div>
                </div>
                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{ 'ecClaim.occupationalDisease.policeStation' | translate }}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{employeeCompensation.getFatalCase().getPoliceStation()}}
                    </div>
                </div>
                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{ 'ecClaim.occupationalDisease.kin' | translate }}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{employeeCompensation.getFatalCase().getKin()}}
                    </div>
                </div>
                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{ 'ecClaim.occupationalDisease.relationship' | translate }}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{employeeCompensation.getFatalCase().getRelationship()}}
                    </div>
                </div>
                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{ 'ecClaim.occupationalDisease.telephoneNumber' | translate }}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{employeeCompensation.getFatalCase().getTelephoneNumberCode()}}
                        {{employeeCompensation.getFatalCase().getTelephoneNumber()}}
                    </div>
                </div>
                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{ 'ecClaim.occupationalDisease.address' | translate }}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{employeeCompensation.getFatalCase().getAddress()}}
                    </div>
                </div>
            </ng-container>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{'ecClaim.accidentDetails.occurredAtWork' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{employeeCompensation.getAccidentDetails().getOccurredAtWorkDesc()}}
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{'ecClaim.accidentDetails.accidentDescription' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{employeeCompensation.getAccidentDetails().getAccidentDescription()}}
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{'ecClaim.accidentDetails.accidentDate' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{getFormattedDate(employeeCompensation.getAccidentDetails().getAccidentDate())}}
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{'ecClaim.accidentDetails.accidentTime' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{employeeCompensation.getAccidentDetails().getTimeOfAccident()}}
                    {{employeeCompensation.getAccidentDetails().getMeridiem()}}
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{'ecClaim.accidentDetails.accidentAddress' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{employeeCompensation.getAccidentDetails().getAccidentAddress()}}
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{'ecClaim.occupationalDisease.hospitalName' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{employeeCompensation.getAccidentDetails().getHospital()}}
                </div>
            </div>
        </div>
        <!-- details of injury -->
        <div class="sub-section">
            <label>{{ 'ecClaim.injuryDetails.title' | translate }}</label>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{'ecClaim.accidentDetails.accidentType.label' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{employeeCompensation.getAccidentDetails().getAccidentTypeDesc()}}
                    <span *ngIf="employeeCompensation.getAccidentDetails().getAccidentType() == '17_15'">
                        , {{employeeCompensation.getAccidentDetails().getAccidentTypeOthers()}}
                    </span>
                </div>
            </div>
            <div class="row" *ngIf="employeeCompensation.getAccidentDetails().getAccidentType() == '4'">
                <div class="col col-xs-12 col-lg-7">
                    {{'ecClaim.accidentDetails.accidentType.distancePersonFall' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{employeeCompensation.getAccidentDetails().getFallFromHeightOf()}}
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{ 'ecClaim.injuryDetails.diagnosis' | translate }}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{employeeCompensation.getAccidentDetails().getInjuryDiagnosis()}}
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{ 'ecClaim.injuryDetails.description' | translate }}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{employeeCompensation.getAccidentDetails().getInjuryDescription()}}
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{ 'ecClaim.injuryDetails.involveAgents' | translate }}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{returnYesOrNo(employeeCompensation.getAccidentDetails().getIsAgentInvolved())}}
                </div>
            </div>
            <ng-container *ngIf="employeeCompensation.getAccidentDetails().getIsAgentInvolved()">
                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{'ecClaim.injuryDetails.agentsInvolved.label' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{getCommaSeparatedValues(employeeCompensation.getAccidentDetails().getAgentsInvolved())}}
                    </div>
                </div>
                <div class="row" *ngIf="employeeCompensation.getAccidentDetails().getOtherMachineryType()">
                    <div class="col col-xs-12 col-lg-7">
                        {{'ecClaim.injuryDetails.otherMachineryType' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{employeeCompensation.getAccidentDetails().getOtherMachineryType()}}
                    </div>
                </div>
                <div class="row" *ngIf="employeeCompensation.getAccidentDetails().getPartCausingInjury()">
                    <div class="col col-xs-12 col-lg-7">
                        {{'ecClaim.injuryDetails.partCausingInjury.label' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{getCommaSeparatedValues(employeeCompensation.getAccidentDetails().getPartCausingInjury())}}
                    </div>
                </div>
                <div class="row" *ngIf="employeeCompensation.getAccidentDetails().getAgentsInvolvedOthers()">
                    <div class="col col-xs-12 col-lg-7">
                        {{'ecClaim.injuryDetails.othersPleaseSpecify' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{employeeCompensation.getAccidentDetails().getAgentsInvolvedOthers()}}
                    </div>
                </div>
                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{'ecClaim.injuryDetails.describeAgents' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{employeeCompensation.getAccidentDetails().getAgentsDescription()}}
                    </div>
                </div>
            </ng-container>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{ 'ecClaim.injuryDetails.treatmentReceived' | translate }}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{returnYesOrNo(employeeCompensation.getAccidentDetails().getIsTreatmentReceived())}}
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{ 'ecClaim.injuryDetails.treatmentType.label' | translate }}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{getCommaSeparatedValues(employeeCompensation.getAccidentDetails().getTreatmentType())}}
                </div>
            </div>
            <ng-container *ngIf="employeeCompensation.getAccidentDetails().getInjuredBodyParts()">
                <div style="padding-top: 5px;">
                    {{'ecClaim.injuryDetails.injuredBodyParts' | translate}}
                </div>
                <div *ngFor="let injuredBodyParts of employeeCompensation.getAccidentDetails().getInjuredBodyParts()">
                    <div style="padding-top: 10px;">
                        <div class="row" *ngIf="injuredBodyParts.getAreaDesc()">
                            <div class="col col-xs-12 col-lg-7">
                                {{ 'ecClaim.injuryDetails.mainBodyPart' | translate }}
                            </div>
                            <div class="col col-xs-12 col-lg-5 content">
                                {{injuredBodyParts.getAreaDesc()}}
                            </div>
                        </div>
                        <ng-container *ngFor="let bodyParts of injuredBodyParts.getBodyParts()">
                            <div class="row" *ngIf="bodyParts.getBodyPartDesc()">
                                <div class="col col-xs-12 col-lg-7">
                                    {{ 'ecClaim.injuryDetails.selectedBodyPart' | translate }}
                                </div>
                                <div class="col col-xs-12 col-lg-5 content">
                                    {{bodyParts.getBodyPartDesc()}}
                                </div>
                            </div>
                            <div class="row" *ngIf="bodyParts.getNatureOfInjuryDesc()">
                                <div class="col col-xs-12 col-lg-7">
                                    {{ 'ecClaim.injuryDetails.injuryLabel' | translate }}
                                </div>
                                <div class="col col-xs-12 col-lg-5 content">
                                    {{bodyParts.getNatureOfInjuryDesc().join(", ")}}
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </ng-container>
        </div>
        <!-- earnings of the injured employee -->
        <div class="sub-section">
            <label>{{ 'ecClaim.employeeEarnings.title' | translate }}</label>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{ 'ecClaim.employeeEarnings.workingDays.label' | translate }}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{employeeCompensation.getEmployeeEarnings().getWorkingDaysDesc()}}
                </div>
            </div>
            <div class="row" *ngIf="employeeCompensation.getEmployeeEarnings().getWorkingDays()?.toLowerCase() == 'others'">
                <div class="col col-xs-12 col-lg-7">
                    {{ 'ecClaim.employeeEarnings.specify' | translate }}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{employeeCompensation.getEmployeeEarnings().getSpecify()}}
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{ 'ecClaim.employeeEarnings.paidOrUnpaid.label' | translate }}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{employeeCompensation.getEmployeeEarnings().getPaidOrUnpaidDesc()}}
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{ 'ecClaim.employeeEarnings.fixedOrFlexible.label' | translate }}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{employeeCompensation.getEmployeeEarnings().getFixedOrFlexibleDesc()}}
                </div>
            </div>
            <div class="row" *ngIf="employeeCompensation.getEmployeeEarnings().getDayOfWeek()">
                <div class="col col-xs-12 col-lg-7">
                    {{ 'ecClaim.employeeEarnings.dayOfWeek.label' | translate }}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{getCommaSeparatedValues(employeeCompensation.getEmployeeEarnings().getDayOfWeek())}}
                </div>
            </div>
            <div class = "content" style="padding-top: 10px;">
                {{'ecClaim.employeeEarnings.monthlyEarningsForm2' | translate}}
            </div>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{ 'ecClaim.employeeEarnings.wages' | translate }}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{employeeCompensation.getCurrency()}}
                    {{employeeCompensation.getEmployeeEarnings().getWages() | number : '1.2-2'}}
                </div>
            </div>
            <div class="row" *ngIf="employeeCompensation.getEmployeeEarnings().getFoodAllowance()">
                <div class="col col-xs-12 col-lg-7">
                    {{ 'ecClaim.employeeEarnings.foodAllowance' | translate }}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{employeeCompensation.getCurrency()}}
                    {{employeeCompensation.getEmployeeEarnings().getFoodAllowance() | number : '1.2-2'}}
                </div>
            </div>
            <div class="row" *ngIf="employeeCompensation.getEmployeeEarnings().getOtherItem()">
                <div class="col col-xs-12 col-lg-7">
                    {{ 'ecClaim.employeeEarnings.otherItem' | translate }}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{employeeCompensation.getEmployeeEarnings().getOtherItem()}}
                </div>
            </div>
            <div class="row" *ngIf="employeeCompensation.getEmployeeEarnings().getOtherAmount()">
                <div class="col col-xs-12 col-lg-7">
                    {{ 'ecClaim.employeeEarnings.otherAmount' | translate }}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{employeeCompensation.getCurrency()}}
                    {{employeeCompensation.getEmployeeEarnings().getOtherAmount() | number : '1.2-2'}}
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{ 'ecClaim.employeeEarnings.totalAmount' | translate }}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{employeeCompensation.getCurrency()}}
                    {{employeeCompensation.getEmployeeEarnings().getTotalAmount() | number : '1.2-2'}}
                </div>
            </div>
            <div class = "content" style="padding-top: 10px;">
                {{'ecClaim.employeeEarnings.averageMonthlyEarnings' | translate}}
            </div>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{ 'ecClaim.employeeEarnings.averageEarnings' | translate }}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{employeeCompensation.getCurrency()}}
                    {{employeeCompensation.getEmployeeEarnings().getAverageEarnings() | number : '1.2-2'}}
                </div>
            </div>
        </div>
        <!-- direct settlement -->
        <ng-container *ngIf="this.isNotEmptyObject(employeeCompensation.getCompensationDetails()) || employeeCompensation.getPeriodOfSickLeaves()?.length > 0 ||
                        this.isNotNullOrUndefined(employeeCompensation.getIsSickLeaveContinuing()) || employeeCompensation.getOutpatientTreatments()?.length > 0">
            <div class="sub-section">
                <label>{{ 'ecClaim.directSettlement.title' | translate }}</label>
                <ng-container *ngIf="employeeCompensation.getPeriodOfSickLeaves()?.length > 0">
                    <ng-container *ngFor="let period of employeeCompensation.getPeriodOfSickLeaves(); let i = index;">
                         <div *ngIf="period.getStartDate() && period.getEndDate()">
                             <div class="row">
                                 <div class="col col-xs-12 col-lg-7" *ngIf="i == 0">
                                     {{ 'ecClaim.accidentDetails.periodOfSickLeave' | translate }}
                                 </div>
                                 <div class="col col-xs-12 col-lg-7" *ngIf="i != 0">
                                 </div>
                                 <div class="col col-xs-12 col-lg-5 content" *ngIf="period.getQuantityDesc()">
                                     {{period.getQuantityDesc()}}
                                 </div>
                                 <div class="col col-xs-12 col-lg-7" *ngIf="i == 0">
                                 </div>
                                 <div class="col col-xs-12 col-lg-7" *ngIf="i != 0"></div>
                                 <div class="col col-xs-12 col-lg-5 content">
                                     {{getDateDuration(period.getStartDate(), period.getEndDate())}}
                                 </div>
                             </div>
                         </div>
                    </ng-container>
                    <div class="row" *ngIf="employeeCompensation.getTotalSickLeaveDays() && employeeCompensation.getTotalSickLeaveDays() > 0">
                        <div class="col col-xs-12 col-lg-7">
                            {{'ecClaim.accidentDetails.sickLeaveDays' | translate}}
                        </div>
                        <div class="col col-xs-12 col-lg-5 content">
                            {{employeeCompensation.getTotalSickLeaveDays()}}
                        </div>
                    </div>
                </ng-container>
                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{'ecClaim.compensationDetails.isSickLeaveContinuing' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{returnYesOrNo(employeeCompensation.getIsSickLeaveContinuing())}}
                    </div>
                    <ng-container *ngIf="employeeCompensation.getIsSickLeaveContinuing()">
                        <div class="col col-xs-12 col-lg-7">
                            {{'ecClaim.compensationDetails.isDutySuitableForEmployee' | translate}}
                        </div>
                        <div class="col col-xs-12 col-lg-5 content">
                            {{returnYesOrNo(employeeCompensation.getIsDutySuitableForEmployee())}}
                        </div>
                        <div class="col col-xs-12 col-lg-7">
                            {{'ecClaim.compensationDetails.contactEmployeeForValueAddedService' | translate}}
                        </div>
                        <div class="col col-xs-12 col-lg-5 content">
                            {{returnYesOrNo(employeeCompensation.getContactEmployeeForValueAddedService())}}
                        </div>
                    </ng-container>
                </div>
                <ng-container *ngIf="employeeCompensation.getOutpatientTreatments()?.length > 0">
                    <ng-container *ngFor="let item of employeeCompensation.getOutpatientTreatments(); let i = index;">
                        <div class="row">
                            <div class="col col-xs-12 col-lg-7">
                                {{'ecClaim.compensationDetails.outpatientTreatment.clinicTreatment' | translate}} {{i + 1}}
                            </div>
                            <div class="col col-xs-12 col-lg-5 content">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col col-xs-12 col-lg-7">
                                {{'ecClaim.compensationDetails.outpatientTreatment.dateOfTreatment' | translate}}
                            </div>
                            <div class="col col-xs-12 col-lg-5 content">
                                {{getDate(item.getTreatmentDate())}}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col col-xs-12 col-lg-7">
                                {{'ecClaim.compensationDetails.outpatientTreatment.medicalExpense' | translate}}
                            </div>
                            <div class="col col-xs-12 col-lg-5 content">
                                {{employeeCompensation.getCurrency()}}
                                {{item.getMedicalExpense() | number : '1.2-2'}}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col col-xs-12 col-lg-7">
                                {{'ecClaim.compensationDetails.outpatientTreatment.isUnderChineseMedPractitioner' | translate}}
                            </div>
                            <div class="col col-xs-12 col-lg-5 content">
                                {{returnYesOrNo(item.getIsUnderChineseMedPractitioner())}}
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
                <div class="row" *ngIf="employeeCompensation.getCompensationDetails().getCompensationAmount()">
                    <div class="col col-xs-12 col-lg-7">
                        {{ 'ecClaim.compensationDetails.compensationAmount' | translate }}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{employeeCompensation.getCurrency()}}
                        {{employeeCompensation.getCompensationDetails().getCompensationAmount() | number : '1.2-2'}}
                    </div>
                </div>
                <div class="row" *ngIf="employeeCompensation.getCompensationDetails().getPaymentStatusDesc()">
                    <div class="col col-xs-12 col-lg-7">
                        {{ 'ecClaim.compensationDetails.paymentStatus.title' | translate }}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{employeeCompensation.getCompensationDetails().getPaymentStatusDesc()}}
                    </div>
                </div>
                <div class="row" *ngIf="employeeCompensation.getCompensationDetails().getPaymentDate()">
                    <div class="col col-xs-12 col-lg-7">
                        {{ 'ecClaim.compensationDetails.paymentStatus.paidOn' | translate }}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{this.getFormattedDate(employeeCompensation.getCompensationDetails().getPaymentDate())}}
                    </div>
                </div>
            </div>
        </ng-container>
        <!-- insurance details -->
        <div class="sub-section">
            <label>{{ 'ecClaim.insuranceDetails.title' | translate }}</label>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{ 'ecClaim.insuranceDetails.nameForm2' | translate }}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{employeeCompensation.getInsuranceDetails().getCompanyName()}}
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{ 'ecClaim.insuranceDetails.addressForm2' | translate }}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{employeeCompensation.getInsuranceDetails().getCompanyAddress()}}
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{ 'ecClaim.insuranceDetails.policyNumber' | translate }}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{employeeCompensation.getInsuranceDetails().getPolicyNumber()}}
                </div>
            </div>
        </div>
        <!-- supporting documents -->
        <div class="sub-section">
            <label>{{ 'pageSummaryClaim.supportDocuments.title' | translate }}</label>
            <div class="row" *ngFor="let item of this.listOfDocuments;">
                <div class="col col-xs-12 col-lg-12">
                    {{item.description | translate}}
                </div>
                <ng-container *ngIf="item.uploadedFileName!=undefined && item.uploadedFileName !=''; else documentNotUploaded">
                    <div class="col col-xs-12 col-lg-12 file-uploaded-summary">
                        {{ item.uploadedFileName }}
                    </div>
                </ng-container>
            </div>
        </div>
        <div class="sub-section" *ngIf="this.listOfOtherDocuments.length > 0">
            <label>{{ 'pageSummaryClaim.claimItem.sectionOther.subSectionSuppDocs.others' | translate }}</label>
            <div class="row" *ngFor="let item of this.listOfOtherDocuments;">
                <div class="col col-xs-12 col-lg-12">
                    {{item.description | translate}}
                </div>
                <ng-container *ngIf="item.uploadedFileName!=undefined && item.uploadedFileName !=''; else documentNotUploaded">
                    <div class="col col-xs-12 col-lg-12 file-uploaded-summary">
                        {{ item.uploadedFileName }}
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>

<ng-template #documentNotUploaded>
    <div class="col col-xs-12 col-lg-12 file-not-uploaded-summary">
        {{ 'pageSummaryClaim.supportDocuments.notUpload' | translate }}
    </div>
</ng-template>