<div class="medical-certificate-container">
  <div class="prev-bar">
    <a (click)="back()" style="color: #0064c1" class="btn new-btn btn-secondary"><em class="fas fa-arrow-left"></em> {{ 'claimSection.generalLabels.generalButtonLabels.back' | translate}} </a>
  </div>
  <div class="col-12">
      <h5>{{ 'paClaim.medicalCertificate.title' | translate }}</h5>
    <br>
  </div>
  <div class="row col-12">
    <div class="col-lg-12 col-md-12">
      <form>
        <div class="form-group">
          <qnect-ui #PA_WAS_MEDCERT_ISSUED [fieldId]="'PA_WAS_MEDCERT_ISSUED'" [formGroup]="ameForm" name="wasMedCertIssued"></qnect-ui>
        </div>
      </form>
    </div>
  </div>
  <ng-container *ngIf="this.claimPA.getAccidentalMedicalExpenses().getWasMedCertIssued()">
    <div class="row col-12">
      <div class="col-xl-9 col-lg-12">
        <form [formGroup]="medCertForm">
          <div formArrayName="items" *ngFor="let item of medCertForm.get('items')['controls']; let i = index;">
            <div [formGroupName]="i">
              <div class="form-group click-more-content">
                <label>{{'paClaim.medicalCertificate.title' | translate}} &nbsp; {{i+1}}</label><button [hidden]="i==0" class="removeBtn" (click)="removeMedCertItem(i)">{{ 'claimSection.generalLabels.generalButtonLabels.remove' | translate}}</button>
                <br/><br/>
                <label>{{'paClaim.medicalCertificate.periodOfMedLeave' | translate}}</label>
            
                <qnect-ui [fieldId]="'PA_MEDCERT_PERIOD_FROM'"
                [frmArrayGroup]="medCertForm"
                [frmArrayName]="'items'"
                frmArrayIndex="{{i}}"
                [claimsDateValidator]="claimPA.getAccidentDetails().getAccidentDate()"
                name="periodFrom"></qnect-ui>

                <qnect-ui [fieldId]="'PA_MEDCERT_PERIOD_TO'"
                [frmArrayGroup]="medCertForm"
                [frmArrayName]="'items'"
                frmArrayIndex="{{i}}"
                [claimsDateValidator]="this.claimPA.getAccidentalMedicalExpenses()?.getMedicalCertificates()?.[i]?.getPeriodFrom()"
                name="periodTo"></qnect-ui>
                <br/>

                <qnect-ui [fieldId]="'PA_MEDCERT_PURPOSE'"
                [frmArrayGroup]="medCertForm"
                [frmArrayName]="'items'"
                frmArrayIndex="{{i}}"
                name="medCertPurpose"></qnect-ui>
                  
              </div>
              <div class="col-xl-2 col-lg-12">
                  &nbsp;
              </div>
            </div>
          </div>
        </form>
        <div *ngIf="medCertForm.get('items')?.length &lt; 5" class="addFile" (click)="addMedCertItem()">
          <em class="fas fa-plus"></em>
          <span>{{ 'paClaim.medicalCertificate.addAnotherPeriod' | translate}}</span>
        </div>
      </div>
    <div class="col-lg-3 col-xl-3 col-md-12">
      &nbsp;
    </div>
  </div>
  </ng-container>
  
  <div class="submit-bar">
    <abandon-button></abandon-button>
    <a href="javascript:;" (click)="showModal()" class="Tertiary">{{ 'claimSection.generalLabels.generalButtonLabels.save' | translate}}</a>
    <button (click)="goToNext()" class="btn btn-primary float-right new-btn">&nbsp;{{ 'claimSection.generalLabels.generalButtonLabels.next' | translate}}&nbsp;<em class="fas fa-arrow-right"></em></button>
  </div>
  <app-save-box [showTotalBox]="showTotalBox" (childEvent)="getData($event)"></app-save-box>
</div>

