export class OutpatientTreatment {

    private treatmentDate: Date;
    private medicalExpense: number;
    private isUnderChineseMedPractitioner: boolean;

    getTreatmentDate(): Date {
        return this.treatmentDate;
    }

    setTreatmentDate(treatmentDate: Date): void {
        this.treatmentDate = treatmentDate;
    }

    getMedicalExpense(): number {
        return this.medicalExpense;
    }

    setMedicalExpense(medicalExpense: number): void {
        this.medicalExpense = medicalExpense;
    }

    getIsUnderChineseMedPractitioner(): boolean {
        return this.isUnderChineseMedPractitioner;
    }

    setIsUnderChineseMedPractitioner(isUnderChineseMedPractitioner: boolean): void {
        this.isUnderChineseMedPractitioner = isUnderChineseMedPractitioner;
    }


    static jsonConvert(outpatientTreatment: OutpatientTreatment): OutpatientTreatment {
        if (outpatientTreatment !== null) {
            if (outpatientTreatment.getTreatmentDate() !== null) {
                outpatientTreatment.setTreatmentDate(new Date(outpatientTreatment.getTreatmentDate()))
            }
        }
        return outpatientTreatment;
    }

}