import { PaAccidentDetails } from "./personal-accident/pa-accident-details.model";
import { InjuryDetails } from "./personal-accident/injury-details.model";
import { AccidentalMedicalExpenses } from "./personal-accident/accidental-medical-expenses.model";
import { HospitalCash } from "./personal-accident/hospital-cash.model";
import { PoliceReport } from "./motor/police-report.model";
import { Others } from "./common/others.model";

export class ClaimPersonalAccident {
    private accidentDetails: PaAccidentDetails = new PaAccidentDetails();
    private hospitalCash: HospitalCash = new HospitalCash();
    private injuryDetails: InjuryDetails = new InjuryDetails();
    private accidentalMedicalExpenses: AccidentalMedicalExpenses = new AccidentalMedicalExpenses();
    private others: Others = new Others();
    private mapReachable: boolean = true;
    private locationPermissionGranted: boolean = true;
    private policeReport: PoliceReport = new PoliceReport();
    private hasEligibleClaimTypes: boolean = true;
    private policyStartDate: Date;
    private policyEndDate: Date;
   
    public getAccidentDetails(): PaAccidentDetails {
        return this.accidentDetails;
    }

    public setAccidentDetails(obj: PaAccidentDetails) {
        this.accidentDetails = obj;    
    }

    public getInjuryDetails(): InjuryDetails {
        return this.injuryDetails;
    }

    public setInjuryDetails(obj: InjuryDetails) {
        this.injuryDetails = obj;    
    }

    public getHospitalCash(): HospitalCash {
        return this.hospitalCash;
    }

    public setHospitalCash(obj: HospitalCash) {
        this.hospitalCash = obj;    
    }

    public getMapReachable(): boolean {
        return this.mapReachable;
    }
    
    public setMapReachable(value: boolean) {
        this.mapReachable = value;
    }

    public getLocationPermissionGranted(): boolean {
        return this.locationPermissionGranted;
    }

    public setLocationPermissionGranted(value: boolean) {
        this.locationPermissionGranted = value;
    }

    public getHasEligibleClaimTypes(): boolean {
        return this.hasEligibleClaimTypes;
    }

    public setHasEligibleClaimTypes(value: boolean) {
        this.hasEligibleClaimTypes = value;
    }

    public getAccidentalMedicalExpenses(): AccidentalMedicalExpenses {
        return this.accidentalMedicalExpenses;
    }

    public setAccidentalMedicalExpenses(obj: AccidentalMedicalExpenses) {
        this.accidentalMedicalExpenses = obj;    
    }

    setOthers(others: Others): void {
        this.others = others;
    }

    getOthers():Others {
        return this.others;
    }

    getPoliceReport(): PoliceReport {
        return this.policeReport;
    } 

    setPoliceReport(value: PoliceReport){
        this.policeReport = value;
    }

    getPolicyStartDate(): Date {
        return this.policyStartDate;
    }

    setPolicyStartDate(value: Date) {
        this.policyStartDate = value;
    }

    getPolicyEndDate(): Date {
        return this.policyEndDate;
    }

    setPolicyEndDate(value: Date) {
        this.policyEndDate = value;
    }

    static jsonConvertClaimPersonalAccident(claimPA: ClaimPersonalAccident): ClaimPersonalAccident {

        claimPA = Object.assign(new ClaimPersonalAccident(), claimPA);
    
        if (claimPA != null) {

            if(claimPA.getAccidentDetails()) {
                claimPA.setAccidentDetails(Object.assign(new PaAccidentDetails(), claimPA.getAccidentDetails()))
                PaAccidentDetails.jsonConvert(claimPA.getAccidentDetails());
            }

            if(claimPA.getInjuryDetails()) {
                claimPA.setInjuryDetails(Object.assign(new InjuryDetails(), claimPA.getInjuryDetails()))
                InjuryDetails.jsonConvert(claimPA.getInjuryDetails());
            }

            if(claimPA.getAccidentalMedicalExpenses()) {
                claimPA.setAccidentalMedicalExpenses(Object.assign(new AccidentalMedicalExpenses(), claimPA.getAccidentalMedicalExpenses()))
                AccidentalMedicalExpenses.jsonConvert(claimPA.getAccidentalMedicalExpenses());
            }

            if(claimPA.getHospitalCash()) {
                claimPA.setHospitalCash(Object.assign(new HospitalCash(), claimPA.getHospitalCash()))
                HospitalCash.jsonConvert(claimPA.getHospitalCash());
            }

            if(claimPA.getOthers()) {
                claimPA.setOthers(Object.assign(new Others(), claimPA.getOthers()))
                Others.jsonConvert(claimPA.getOthers());
            }

            if(claimPA.getPoliceReport()) {
                claimPA.setPoliceReport(Object.assign(new PoliceReport(), claimPA.getPoliceReport()))
                PoliceReport.jsonConvert(claimPA.getPoliceReport());
            }

            if (claimPA.getPolicyStartDate() != null) {
                claimPA.setPolicyStartDate(new Date(claimPA.getPolicyStartDate()));
            }

            if (claimPA.getPolicyEndDate() != null) {
                claimPA.setPolicyEndDate(new Date(claimPA.getPolicyEndDate()));
            }
        }
        return claimPA;
    }

}
