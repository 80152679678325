<div class="form-group section">
    <label class="section-title">{{ 'ecClaim.selectClaim.form2b' | translate }}</label>
    <div class="section-content">
        <!-- details of injured employee -->
        <div class="sub-section">
            <label>{{ 'ecClaim.employeeDetails.title' | translate }}</label>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{ 'ecClaim.employeeDetails.name' | translate }}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{employeeCompensation.getEmployeeDetails().getName()}}
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12 col-lg-7" *ngIf="employeeCompensation.getEmployeeDetails().getIdType() === 'HKID'">
                    {{ 'ecClaim.employeeDetails.hkid' | translate }}
                </div>
                <div class="col col-xs-12 col-lg-7" *ngIf="employeeCompensation.getEmployeeDetails().getIdType() !== 'HKID'">
                    {{ 'ecClaim.employeeDetails.passport' | translate }}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{employeeCompensation.getEmployeeDetails().getIdNumber()}}
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{ 'pagePaymentAndContact.contactInfo.telephone' | translate }}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{employeeCompensation.getEmployeeDetails().getTelephoneNumberCode()}}
                    {{employeeCompensation.getEmployeeDetails().getTelephoneNumber()}}
                </div>
            </div>
            <div class="row" *ngIf="employeeCompensation.getEmployeeDetails().getEmailAddress()">
                <div class="col col-xs-12 col-lg-7">
                    {{ 'ecClaim.employerDetails.email' | translate }}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{employeeCompensation.getEmployeeDetails().getEmailAddress()}}
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{ 'pagePaymentAndContact.contactInfo.address' | translate }}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{employeeCompensation.getEmployeeDetails().getAddress()}}
                </div>
            </div>
        </div>
        <!-- details of accident -->
        <div class="sub-section">
            <label>{{ 'ecClaim.accidentDetails.title' | translate }}</label>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{'ecClaim.accidentDetails.accidentDate' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{getDate(employeeCompensation.getAccidentDetails().getAccidentDate())}}
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{'ecClaim.accidentDetails.accidentAddress' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{employeeCompensation.getAccidentDetails().getAccidentAddress()}}
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{'ecClaim.accidentDetails.accidentDescription' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{employeeCompensation.getAccidentDetails().getAccidentDescription()}}
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{'ecClaim.accidentDetails.accidentType.label' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{employeeCompensation.getAccidentDetails().getAccidentTypeDesc()}}
                    <span *ngIf="employeeCompensation.getAccidentDetails().getAccidentType() == '17_15'">
                        , {{employeeCompensation.getAccidentDetails().getAccidentTypeOthers()}}
                    </span>
                </div>
            </div>
            <div class="row" *ngIf="employeeCompensation.getAccidentDetails().getAccidentType() == '4'">
                <div class="col col-xs-12 col-lg-7">
                    {{'ecClaim.accidentDetails.accidentType.distancePersonFall' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{employeeCompensation.getAccidentDetails().getFallFromHeightOf()}}
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{ 'ecClaim.injuryDetails.treatmentType.label' | translate }}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{getCommaSeparatedValues(employeeCompensation.getAccidentDetails().getTreatmentType())}}
                </div>
            </div>
            <ng-container *ngIf="employeeCompensation.getAccidentDetails().getInjuredBodyParts()">
                <div style="padding-top: 5px;">
                    {{'ecClaim.injuryDetails.injuredBodyParts' | translate}}
                </div>
                <div *ngFor="let injuredBodyParts of employeeCompensation.getAccidentDetails().getInjuredBodyParts()">
                    <div style="padding-top: 10px;">
                        <div class="row" *ngIf="injuredBodyParts.getAreaDesc()">
                            <div class="col col-xs-12 col-lg-7">
                                {{ 'ecClaim.injuryDetails.mainBodyPart' | translate }}
                            </div>
                            <div class="col col-xs-12 col-lg-5 content">
                                {{injuredBodyParts.getAreaDesc()}}
                            </div>
                        </div>
                        <ng-container *ngFor="let bodyParts of injuredBodyParts.getBodyParts()">
                            <div class="row" *ngIf="bodyParts.getBodyPartDesc()">
                                <div class="col col-xs-12 col-lg-7">
                                    {{ 'ecClaim.injuryDetails.selectedBodyPart' | translate }}
                                </div>
                                <div class="col col-xs-12 col-lg-5 content">
                                    {{bodyParts.getBodyPartDesc()}}
                                </div>
                            </div>
                            <div class="row" *ngIf="bodyParts.getNatureOfInjuryDesc()">
                                <div class="col col-xs-12 col-lg-7">
                                    {{ 'ecClaim.injuryDetails.injuryLabel' | translate }}
                                </div>
                                <div class="col col-xs-12 col-lg-5 content">
                                    {{bodyParts.getNatureOfInjuryDesc().join(", ")}}
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </ng-container>
        </div>
        <!-- employee compensation details -->
        <ng-container *ngIf="this.isNotEmptyObject(employeeCompensation.getCompensationDetails()) ||
                employeeCompensation.getPeriodOfSickLeaves()?.length > 0 || employeeCompensation.getOutpatientTreatments()?.length > 0">
            <div class="sub-section">
                <label>{{ 'ecClaim.compensationDetails.title' | translate }}</label>
                <ng-container *ngIf="employeeCompensation.getPeriodOfSickLeaves()?.length > 0">
                    <ng-container *ngFor="let period of employeeCompensation.getPeriodOfSickLeaves(); let i = index;">
                        <div *ngIf="period.getStartDate() && period.getEndDate()">
                            <div class="row">
                                <div class="col col-xs-12 col-lg-7" *ngIf="i == 0">
                                    {{ 'ecClaim.accidentDetails.periodOfSickLeave' | translate }}
                                </div>
                                <div class="col col-xs-12 col-lg-7" *ngIf="i != 0">
                                </div>
                                <div class="col col-xs-12 col-lg-5 content" *ngIf="period.getQuantityDesc()">
                                    {{period.getQuantityDesc()}}
                                </div>
                                <div class="col col-xs-12 col-lg-7" *ngIf="i == 0">
                                </div>
                                <div class="col col-xs-12 col-lg-7" *ngIf="i != 0"></div>
                                <div class="col col-xs-12 col-lg-5 content">
                                    {{getDateDuration(period.getStartDate(), period.getEndDate())}}
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <div class="row" *ngIf="employeeCompensation.getTotalSickLeaveDays() && employeeCompensation.getTotalSickLeaveDays() > 0">
                        <div class="col col-xs-12 col-lg-7">
                            {{'ecClaim.accidentDetails.sickLeaveDays' | translate}}
                        </div>
                        <div class="col col-xs-12 col-lg-5 content">
                            {{employeeCompensation.getTotalSickLeaveDays()}}
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="employeeCompensation.getOutpatientTreatments()?.length > 0">
                    <ng-container *ngFor="let item of employeeCompensation.getOutpatientTreatments(); let i = index;">
                        <div class="row">
                            <div class="col col-xs-12 col-lg-7">
                                {{'ecClaim.compensationDetails.outpatientTreatment.clinicTreatment' | translate}} {{i + 1}}
                            </div>
                            <div class="col col-xs-12 col-lg-5 content">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col col-xs-12 col-lg-7">
                                {{'ecClaim.compensationDetails.outpatientTreatment.dateOfTreatment' | translate}}
                            </div>
                            <div class="col col-xs-12 col-lg-5 content">
                                {{getDate(item.getTreatmentDate())}}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col col-xs-12 col-lg-7">
                                {{'ecClaim.compensationDetails.outpatientTreatment.medicalExpense' | translate}}
                            </div>
                            <div class="col col-xs-12 col-lg-5 content">
                                {{employeeCompensation.getCurrency()}}
                                {{item.getMedicalExpense() | number : '1.2-2'}}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col col-xs-12 col-lg-7">
                                {{'ecClaim.compensationDetails.outpatientTreatment.isUnderChineseMedPractitioner' | translate}}
                            </div>
                            <div class="col col-xs-12 col-lg-5 content">
                                {{returnYesOrNo(item.getIsUnderChineseMedPractitioner())}}
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
                <div class="row" *ngIf="employeeCompensation.getCompensationDetails().getMonthlyEarnings()">
                    <div class="col col-xs-12 col-lg-7">
                        {{ 'ecClaim.compensationDetails.monthlyEarnings' | translate }}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{employeeCompensation.getCurrency()}}
                        {{employeeCompensation.getCompensationDetails().getMonthlyEarnings() | number : '1.2-2'}}
                    </div>
                </div>
                <div class="row" *ngIf="employeeCompensation.getCompensationDetails().getCompensationAmount()">
                    <div class="col col-xs-12 col-lg-7">
                        {{ 'ecClaim.compensationDetails.compensationAmount' | translate }}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{employeeCompensation.getCurrency()}}
                        {{employeeCompensation.getCompensationDetails().getCompensationAmount() | number : '1.2-2'}}
                    </div>
                </div>
                <div class="row" *ngIf="employeeCompensation.getCompensationDetails().getPaymentStatusDesc()">
                    <div class="col col-xs-12 col-lg-7">
                        {{ 'ecClaim.compensationDetails.paymentStatus.title' | translate }}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{employeeCompensation.getCompensationDetails().getPaymentStatusDesc()}}
                    </div>
                </div>
                <div class="row" *ngIf="employeeCompensation.getCompensationDetails().getPaymentDate()">
                    <div class="col col-xs-12 col-lg-7">
                        {{ 'ecClaim.compensationDetails.paymentStatus.paidOn' | translate }}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{getDate(employeeCompensation.getCompensationDetails().getPaymentDate())}}
                    </div>
                </div>
            </div>
        </ng-container>
        <!-- supporting documents -->
        <div class="sub-section">
            <label>{{ 'pageSummaryClaim.supportDocuments.title' | translate }}</label>
            <div class="row" *ngFor="let item of this.listOfDocuments;">
                <div class="col col-xs-12 col-lg-12">
                    {{item.description | translate}}
                </div>
                <ng-container *ngIf="item.uploadedFileName!=undefined && item.uploadedFileName !=''; else documentNotUploaded">
                    <div class="col col-xs-12 col-lg-12 file-uploaded-summary">
                        {{ item.uploadedFileName }}
                    </div>
                </ng-container>
            </div>
        </div>
        <div class="sub-section" *ngIf="this.listOfOtherDocuments.length > 0">
            <label>{{ 'pageSummaryClaim.claimItem.sectionOther.subSectionSuppDocs.others' | translate }}</label>
            <div class="row" *ngFor="let item of this.listOfOtherDocuments;">
                <div class="col col-xs-12 col-lg-12">
                    {{item.description | translate}}
                </div>
                <ng-container *ngIf="item.uploadedFileName!=undefined && item.uploadedFileName !=''; else documentNotUploaded">
                    <div class="col col-xs-12 col-lg-12 file-uploaded-summary">
                        {{ item.uploadedFileName }}
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>

<ng-template #documentNotUploaded>
    <div class="col col-xs-12 col-lg-12 file-not-uploaded-summary">
        {{ 'pageSummaryClaim.supportDocuments.notUpload' | translate }}
    </div>
</ng-template>