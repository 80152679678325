<div class="col-12 col-md-12  text-left less-content complete-bg " [ngClass]="{'mox-style': claim.isMox(), 'lang-zh': (translate.currentLang === 'zh')}">
    <div class="container">
        <div class="padding-bottom-30 complete-heading">
            <div class="tickcircle"><img class="svg" src="assets/images/tick-with-circle.svg"></div>
            <div class="tickcircle-text">
                <h1>{{ 'completePage.body.title' | translate }}</h1>
                <ng-container *ngIf="claim.isMox()">
                    <p>{{ 'completePage.body.description1' | translate }}</p>
                    <p>{{ 'completePage.body.description3' | translate }}</p>
                    <p class="recipient-email">{{claim.getContactEmail()}}</p>
                </ng-container>
            </div>
        </div>
        <ng-container *ngIf="transactionInfo.isEmployeeCompensationProduct()">
            <div *ngFor="let claimResponseDisplay of claimResponseDisplayList; let i = index">
                <div class="blue-banner col-word-break">
                    <h4><strong>{{'completePage.body.description1' | translate}}</strong></h4>
                    <h6>{{'completePage.body.description3' | translate}} {{claimResponseDisplay.getContactEmail()}}.</h6>
                    <h6>{{'completePage.body.claimNumber' | translate }}: <strong>{{claimResponseDisplay.getClaimNumberDisplayLine()}}</strong></h6>
                    <h6>{{'completePage.body.policyNumber' | translate}}: <strong>{{claimResponseDisplay.getPolicyNumber()}}</strong></h6>
                    <h6>{{'completePage.body.type' | translate}}: <strong>{{'claimStatus.productDesc.WCA' | translate}}</strong></h6>
                </div>
                <div class="claim-information">
                    <p><strong>{{'completePage.body.downloadCopyOf' | translate}} {{ claimResponseDisplay.getClaimTypesList()[0] | translate }}.</strong></p>
                    <p>{{'completePage.body.pleaseDownload' | translate}} {{ claimResponseDisplay.getClaimTypesList()[0] | translate }}
                        {{'completePage.body.printAndSign' | translate}} <a href="javascript:;" (click)="instructionModal.open();">{{ 'completePage.body.signInstructions.clickHere' | translate}}</a>{{ 'completePage.body.signInstructions.toView' | translate}}</p>
                    <button class="new-btn btn btn-primary" (click)="downloadPDF('ECFORM', claimResponseDisplay.getClaimTypesList()[0])">{{ 'completePage.body.download' | translate }} <i class="fas fa-download"></i></button>
                </div>
                <br/><br/>
                <div class="claim-information">
                    <ng-container *ngIf="missingDocs">
                        <p><strong>{{ 'completePage.body.description2b' | translate }}</strong></p>
                        <p>{{'completePage.body.pleaseNote1' | translate }}</p>
                        <p>{{'completePage.body.pleaseNote2' | translate }}</p>
                        <ul>
                            <ng-container class="claimDetails" *ngFor="let documentResponseDisplay of claimResponseDisplay.getDocumentResponseDisplayList()">
                                <li *ngIf="documentResponseDisplay.getDescriptionHtml() == null" style="list-style: inherit;">
                                    {{documentResponseDisplay.getDescription() | translate}}
                                </li>
                            </ng-container>
                        </ul>
                        <br/>
                    </ng-container>
                    <ng-container *ngIf="!missingDocs">
                        <p><strong>{{ 'completePage.body.description2' | translate }}</strong></p>
                    </ng-container>
                    <span *ngIf="claim.getCountry()?.toLowerCase() === 'hkg'"><p>{{ 'completePage.body.description4_hksi' | translate }}</p></span>
                    <span *ngIf="claim.getCountry()?.toLowerCase() === 'hgi'"><p>{{ 'completePage.body.description4_qgi' | translate }}</p></span>
                </div>
             </div>
            </ng-container>
            <ng-container *ngIf="!transactionInfo.isEmployeeCompensationProduct()">
            <div>
                <div class="box1">
                    <div>
                        <div *ngFor="let claimResponseDisplay of claimResponseDisplayList; let i = index">
                            <div class="notUpload col-word-break">
                                <h4 *ngIf="!claim.isMox()" style="margin-left: -19px;">{{'completePage.body.claimNumber' | translate }}: {{claimResponseDisplay.getClaimNumberDisplayLine()}} <br/>
                                    <div *ngIf="transactionInfo.isMotorProduct()">
                                        {{claim.getClaimMotor().getVehicleNumber()}}
                                    </div>
                                    <div *ngIf="!transactionInfo.isMotorProduct()">
                                        {{claimResponseDisplay.getClaimantName()}}
                                    </div>
                                    <ng-container *ngFor="let claimTypeId of claimResponseDisplay.getClaimTypesList(); let x = index">
                                        <span *ngIf="x == 0">{{claimTypeId | translate}}</span>
                                        <span *ngIf="x != 0">,&nbsp;{{claimTypeId | translate}}</span>
                                    </ng-container>
                                </h4>
                                <div class="claim-details-block" *ngIf="claim.isMox()">
                                    <div class="claim-details-block-row">
                                        <span class="claim-details-title">{{'completePage.body.claimNumber' | translate }}</span>
                                        <span class="claim-details-content">{{claimResponseDisplay.getClaimNumberDisplayLine()}}</span>
                                    </div>
                                    <div class="claim-details-block-row">
                                        <span class="claim-details-title" *ngIf="transactionInfo.isMotorProduct()">Vehicle Number</span>
                                        <span class="claim-details-title" *ngIf="!transactionInfo.isMotorProduct()">Insured Person</span>
                                        <span class="claim-details-content" *ngIf="transactionInfo.isMotorProduct()">{{claim.getClaimMotor().getVehicleNumber()}}</span>
                                        <span class="claim-details-content" *ngIf="!transactionInfo.isMotorProduct()">{{claimResponseDisplay.getClaimantName()}}</span>
                                    </div>
                                    <div class="claim-details-block-row claim-types-row">
                                        <span class="claim-details-title">Claim Type</span>
                                        <span class="claim-details-content">
                                            <ng-container *ngFor="let claimTypeId of claimResponseDisplay.getClaimTypesList(); let x = index">
                                                <span *ngIf="x == 0">{{claimTypeId | translate}}</span>
                                                <span *ngIf="x != 0">,&nbsp;{{claimTypeId | translate}}</span>
                                            </ng-container>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="additional-docs-container" *ngIf="missingDocs">
                                <div class="additional-docs-main-content" *ngFor="let documentResponseDisplay of claimResponseDisplay.getDocumentResponseDisplayList(); let x = index">
                                    <div class="claimDetail">
                                        <div class="docs-note" *ngIf="x === 0">
                                            <br/>
                                            <p *ngIf="!claim.isMox()">{{'completePage.body.pleaseNote' | translate }}</p>
                                            <p *ngIf="claim.isMox()">{{'completePage.body.pleaseNote_alt' | translate }}</p>
                                            <ng-container *ngIf="showOrigDocsAddressMotor() == true">
                                                <br>
                                                <p>{{'completePage.body.pleaseNoteOriginalDocs' | translate }}</p>
                                                <p>{{'completePage.body.pleaseNoteOriginalDocs1' | translate }}</p>
                                                <p>{{'completePage.body.pleaseNoteOriginalDocs2' | translate }}</p>
                                                <p>{{'completePage.body.pleaseNoteOriginalDocs3' | translate }}</p>
                                            </ng-container>
                                        </div>
                                        <div class="claim-type-docs" *ngIf="x === 0 || claimResponseDisplay.getDocumentResponseDisplayList()[x].getClaimType() != claimResponseDisplay.getDocumentResponseDisplayList()[x-1].getClaimType()">
                                            <br *ngIf="!claim.isMox()"/>
                                            <p *ngIf="!claim.isMox()">{{documentResponseDisplay.getClaimType() | translate}}</p>
                                        </div>
                                        <ul class="claim-type-docs-detail">
                                            <li *ngIf="documentResponseDisplay.getDescriptionHtml() == null">
                                                {{documentResponseDisplay.getDescription() | translate}}
                                            </li>
                                            <li *ngIf="documentResponseDisplay.getDescriptionHtml() != null">
                                                <span [innerHtml]="documentResponseDisplay.getDescriptionHtml() | translate"></span>
                                                <span [innerHtml]="'motorClaim.claimSection.documents.original' | translate"></span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="claimDetail claimDetail-extra">
                                    <br/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="box1 claim-complete-details">
                        <br/>
                        <strong *ngIf="!missingDocs && !claim.isMox()">{{ 'completePage.body.description2' | translate }}</strong>
                        <strong *ngIf="missingDocs && !claim.isMox()">{{ 'completePage.body.description2b' | translate }}</strong>
                        <p *ngIf="!missingDocs && claim.isMox()">{{ 'completePage.body.description2' | translate }}</p>
                        <p *ngIf="missingDocs && claim.isMox()">{{ 'completePage.body.description2b' | translate }}</p>
                        <br/><br/>
                        <div class="clarify-note" *ngIf="claim.getCountry()?.toLowerCase() === 'hkg'"><p>{{ 'completePage.body.description4_hksi' | translate }}</p><br/></div>
                        <div class="clarify-note" *ngIf="claim.getCountry()?.toLowerCase() === 'hgi'"><p>{{ 'completePage.body.description4_qgi' | translate }}</p><br/></div>
                        <div class="clarify-note" *ngIf="claim.getCountry()?.toLowerCase() === 'sgp' || claim.getCountry()?.toLowerCase() === 'mys'"><p>{{ 'completePage.body.description4_sgp' | translate }}</p><br/></div>
                        <div class="clarify-note" *ngIf="!transactionInfo.isMotorProduct() && claim.getCountry()?.toLowerCase() !== 'mys'"><p>{{ 'completePage.body.description5' | translate }}</p><br/></div>
                        <div class="clarify-note" *ngIf="claim.getCountry()?.toLowerCase() === 'mys'"><p>{{ 'completePage.body.description7' | translate }}</p><br/></div>
                    </div>
                </div>
            </div>
        </ng-container>
        <div class="bottom-buttons-container">
            <div class="buttons" *ngIf="!claim.isMox()">
                <button (click)="downloadPDF('CD', 'ClaimDetails')" class="new-btn btn btn-secondary-invert">{{ 'completePage.body.generalButtonLabels.save' | translate }} <i class="fas fa-save"></i></button>
                <a id="closeBtn" href="{{entryUrl}}" class="new-btn btn btn-secondary-invert" style="margin-left: 20px;">{{ 'completePage.body.generalButtonLabels.close' | translate }} <i class="fas fa-times"></i></a>
            </div>
            <div class="buttons" *ngIf="claim.isMox()">
                <button (click)="downloadPDF('CD', 'ClaimDetails')" class="new-btn btn btn-secondary-invert">{{ 'completePage.body.generalButtonLabels.mox-save' | translate }} <i class="fas fa-save"></i></button>
                <a id="closeBtn" href="{{entryUrl}}" class="new-btn btn btn-secondary-invert">{{ 'completePage.body.generalButtonLabels.mox-close' | translate }} <i class="fas fa-times"></i></a>
            </div>
            <div class="box1 bottom-box">
                <div>
                    <div *ngIf="claim.getCountry()?.toLowerCase() === 'hkg'" [innerHTML]="'completePage.complaintsStatement.hksi.content' | translate"></div>
                    <div *ngIf="claim.getCountry()?.toLowerCase() === 'hgi'" [innerHTML]="'completePage.complaintsStatement.qgi_content' | translate"></div>
                    <div *ngIf="claim.getCountry()?.toLowerCase() === 'sgp'" [innerHTML]="'completePage.complaintsStatement.sgp_content' | translate"></div>
                    <div *ngIf="claim.getCountry()?.toLowerCase() === 'mys'" [innerHTML]="'completePage.complaintsStatement.mys_content' | translate"></div>
                </div>
            </div>
        </div>
    </div>
</div>

<sign-instruction-modal #sign_instruction_modal></sign-instruction-modal>

<ng-template #doc_error_modal let-c="close" let-d="dismiss">
    <div class="modal-body">
        <h5 class="modal-title">{{'error_messages.notificationsErrMsg.error' | translate }}</h5><br/>
        <p>{{ 'error_messages.notificationsErrMsg.saveDocErrorMsg' | translate }}</p>
    </div>
   <div class="mt-2">
        <button class="new-btn btn-primary btn" (click)="c('Close click')" >
            {{ 'claimSection.generalLabels.generalButtonLabels.ok' | translate}}
        </button>
        <br/><br/>
    </div>
</ng-template>
