import { Component, OnInit } from '@angular/core';
import { Claim } from '../../model/claim.model';
import { DocumentField } from '../../model/document-field';

@Component({
  template: ''
})
export abstract class DocumentFormBuilderComponent implements OnInit {

  documentFormClaimType: string;
  claim: Claim;
  requiredDocuments: DocumentField[];

  constructor(claim: Claim) {
    this.claim = claim;
   }

  ngOnInit() {
  }

  getRequiredDocuments(): DocumentField[]{
    return this.requiredDocuments;
  }

  abstract setRequiredDocuments();

  addDocumentWithSTP(documentField: DocumentField, stpRequired: boolean, contentSubType?: string): DocumentField {
    documentField.setStpRequired(stpRequired);
    documentField.setContentSubType(contentSubType);
    return documentField;
  }

}
