import {Component, Injector, OnInit} from "@angular/core";
import {FormBuilder} from "@angular/forms";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ClaimService} from "../../../services/claim.service";
import {Claim} from "../../../model/claim.model";
import {ClaimsBaseComponent} from "../../claim-base.component";
import { TransactionInfoService } from "src/app/services/transaction-info.service";
import { TransactionInfo } from "src/app/model/transaction-info.model";
import {PAYMENT_METHODS} from '../../../model/payment-methods.constant';


@Component({
    selector: 'payment-contact-summary',
    templateUrl: './payment-contact-summary.component.html',
    styleUrls: ['./payment-contact-summary.component.css']
})
export class PaymentContactSummary extends ClaimsBaseComponent implements OnInit {

    claim: Claim;
    transactionInfo: TransactionInfo;
    PAYMENT_METHODS = PAYMENT_METHODS;

    constructor(private modalService: NgbModal,
                private fb: FormBuilder,
                private claimService: ClaimService,
                private transactionInfoService: TransactionInfoService,
                private injector : Injector) {

        super(injector);
        this.claim = this.claimService.getClaim();
        this.transactionInfo  = this.transactionInfoService.getTransactionInfo();
    }

    returnYesOrNo(value: boolean) {
        if (value) {
            return this.translate.instant("claimSection.generalLabels.radioButtonLabels.yes");
        } else {
            if(!value){
                return this.translate.instant("claimSection.generalLabels.radioButtonLabels.no");
            }
        }
    }

    returnYesOrNoOther(value: boolean) {
        if (value) {
            return this.translate.instant("claimSection.generalLabels.radioButtonLabels.yesSend");
        } else {
            if(!value){
                return this.translate.instant("claimSection.generalLabels.radioButtonLabels.noSend");
            }
        }
    }
}