export class DocumentField {
    id: string;
    description: string;
    uploadedFileName: string;
    smartqField: string;
    isRequired: boolean;
    index: number;
    formDescription: string;
    formUrl: string;
    descriptionHtml: string; 
    isOriginal: boolean;
    riskId: string;
    stpRequired: boolean;
    contentSubType: string;
    
    constructor(id: string, description: string, smartqField: string, isRequired : boolean){
        this.id = id;
        this.description = description;
        this.smartqField = smartqField;
        this.isRequired = isRequired;
    }

    getId(): string {
        return this.id;
    }
    setId(id: string) {
        this.id = id;
    }
    getDescription(): string {
        return this.description;
    }
    setDescription(description: string) {
        this.description = description;
    }
    getUploadedFileName(): string {
        return this.uploadedFileName;
    }
    setUploadedFileName(uploadedFileName: string){
        this.uploadedFileName = uploadedFileName;
    }
    getSmartqField(): string {
        return this.smartqField;
    }
    setSmartqField(smartqField: string) {
        this.smartqField = smartqField;
    }
    getIsRequired() : boolean {
        return this.isRequired
    }
    setIsRequired(isRequired : boolean) {
         this.isRequired = isRequired;
    }
    getIndex(): number {
        return this.index;
    }
    setIndex(index: number) {
        this.index = index;
    }
    getFormUrl(): string {
        return this.formUrl;
    }
    setFormUrl(formUrl: string) {
        this.formUrl = formUrl;
    }
    getFormDescription(): string {
        return this.formDescription;
    }
    setFormDescription(formDescription: string) {
        this.formDescription = formDescription;
    }
    getDescriptionHtml(): string {
        return this.descriptionHtml;
    }
    setDescriptionHtml(descriptionHtml: string) {
        this.descriptionHtml = descriptionHtml;
    }
    getIsOriginal() : boolean {
        return this.isRequired
    }
    setIsOriginal(isOriginal : boolean) {
         this.isOriginal = isOriginal;
    }
    getRiskId(): string {
        return this.riskId;
    }
    setRiskId(riskId: string) {
        this.riskId = riskId;
    }
    getStpRequired() : boolean {
        return this.stpRequired
    }
    setStpRequired(stpRequired : boolean) {
        this.stpRequired = stpRequired;
    }
    getContentSubType(): string {
        return this.contentSubType;
    }
    setContentSubType(contentSubType: string) {
        this.contentSubType = contentSubType;
    }
}
