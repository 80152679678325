import {Component, Injector, OnInit, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from "@angular/router";
import { ClaimsBaseComponent } from 'src/app/claim-pages/claim-base.component';
import { TransactionInfoService } from 'src/app/services/transaction-info.service';
import { SideMenuService } from 'src/app/services/side-menu.service';
import { Claim } from 'src/app/model/claim.model';
import { ClaimService } from 'src/app/services/claim.service';
import { Others } from 'src/app/model/common/others.model';
import { TransactionInfo } from 'src/app/model/transaction-info.model';
import { UtilitiesService } from 'src/app/utilities/utilities.service';
import { PoliceReport } from 'src/app/model/motor/police-report.model';
import { takeUntil } from 'rxjs';
import { ClaimTypes } from 'src/app/model/claim-type.model';

@Component({
    selector: 'app-pa-others-not-sure',
    templateUrl: './pa-others-not-sure.component.html',
    styleUrls: ['./pa-claim-types.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class PaOthersNotSureComponent extends ClaimsBaseComponent implements OnInit {

    othersForm: FormGroup;
    policeReportForm: FormGroup;
    othersDetails: Others;
    policeReport: PoliceReport;
    claim: Claim;
    transactionInfo: TransactionInfo;
    showTotalBox = false;

    constructor(private fb: FormBuilder,
                private claimService: ClaimService,
                private transactionInfoService: TransactionInfoService,
                private sideMenuService: SideMenuService,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                private injector : Injector) {
        super(injector);
        this.transactionInfoService.getTransactionInfo().setCurrentClaimType(ClaimTypes[ClaimTypes.CLAIM_TYPE_PA_OTHERS]);
        this.claim = this.claimService.getClaim();
        this.othersDetails = this.claim.getClaimPersonalAccident().getOthers();
        this.policeReport = this.claim.getClaimPersonalAccident().getPoliceReport();
        this.transactionInfo = this.transactionInfoService.getTransactionInfo();
    }

    ngOnInit() {
        this.pushGAPageView();

        if (!this.isNotNullOrUndefinedStr(this.othersDetails.getEstAmountLossCurrency())){
            this.othersDetails.setEstAmountLossCurrency(UtilitiesService.getCountryCurrency(this.claim.getCountry()));
        }

        this.othersForm = this.fb.group({
            estAmountLoss: [this.othersDetails.getEstAmountLoss()],
            estAmountLossCurrency: [this.othersDetails.getEstAmountLossCurrency()],
        });

        this.policeReportForm = this.fb.group({
            hasReportedIncidentToPolice: [super.getBooleanString(this.policeReport.getHasReportedIncidentToPolice())],
            dateOfReport: [this.policeReport.getDateOfReport()],
            referenceNumber: [this.policeReport.getPoliceReportNumber()]
        });
    }

    ngAfterViewInit() {
         this.othersForm.valueChanges.subscribe(data => {
            this.othersDetails.setEstAmountLoss(super.getValueInComponent('estAmountLoss'));
            this.othersDetails.setEstAmountLossCurrency(this.othersForm.get('estAmountLossCurrency').value);             
        });

        this.policeReportForm.valueChanges.subscribe(data => {
            if (this.policeReportForm.get('hasReportedIncidentToPolice').value != null) {
                this.policeReport.setHasReportedIncidentToPolice(this.policeReportForm.get('hasReportedIncidentToPolice').value == 'true');
            }
            this.policeReport.setDateOfReport(super.getValueInComponent('dateOfReport'));
            this.policeReport.setPoliceReportNumber(super.getValueInComponent('referenceNumber'))
        });

        let self = this;
        
        setTimeout(() => {self.hidePoliceReport(self.getBooleanString(self.policeReport.getHasReportedIncidentToPolice()))},10);
        this.policeReportForm.get('hasReportedIncidentToPolice').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
            this.hidePoliceReport(data);
        });
    }

    hidePoliceReport(hasReportedIncidentToPolice) {
        if (hasReportedIncidentToPolice == 'true') {
            this.policeReportForm.get('dateOfReport').enable({onlySelf: false, emitEvent: false});
            this.policeReportForm.get('referenceNumber').enable({onlySelf: false, emitEvent: false});
        } else {
            this.policeReportForm.get('referenceNumber').reset();
            this.policeReportForm.get('dateOfReport').reset();
            this.policeReportForm.get('referenceNumber').disable({onlySelf: false, emitEvent: false});
            this.policeReportForm.get('dateOfReport').disable({onlySelf: false, emitEvent: false});
        }
    }

    goToNext() {
        if (this.validateForm()) {
            this.sideMenuService.emitClaimComplete({claimTypeId: 2, subMenuIndex: 0});
            this.sideMenuService.emitProcess(1, 0);
            this.sideMenuService.emitComplete('finishChooseClaim');
            this.router.navigate(["/claimform/anotherInsurance"], {
                relativeTo: this.activatedRoute
            });
        }
    }

    validateForm(): boolean {
        let otherFormValid = super.validateForm(this.othersForm);
        let policeReportFormValid = super.validateForm(this.policeReportForm);
        return otherFormValid && policeReportFormValid;
    }

    back() {
        let url: string = '/claimform/claimDetail';
        if (!this.claim.getClaimPersonalAccident().getHasEligibleClaimTypes()) {
            url = '/claimform/paDetailsOfAccident';
        }
        this.router.navigate([url], {
            relativeTo: this.activatedRoute
        });
    }

    showModal() {
        this.showTotalBox = true;
    }

    getData(msg) {
        this.showTotalBox = msg;
    }

    pushGAPageView() {
        let pageStep = this.transactionInfo.getGaPageStep() + ' – Others not sure';
        let vPath = this.transactionInfo.getGaVPath() + '/pa-others-not-sure';
        let stepNum = !this.claim.getClaimPersonalAccident().getHasEligibleClaimTypes() ?  5 : 6;
        (<any>window).dataLayer = (<any>window).dataLayer || [];
        (<any>window).dataLayer.push({
            'pageStep': pageStep,
            'vPath': vPath,
            'event': 'vpageview',
            'ecommerce': {
                'checkout': {
                    'actionField': {'step': stepNum}
                }
            }
        });
    }
}
