import {PAYMENT_METHODS} from './payment-methods.constant';

export const PaymentOptions =
    [
        {
            "id": PAYMENT_METHODS.FASTER_PAYMENT_SYSTEM,
            "name": "pagePaymentAndContact.fps.fps",
            "icon": "assets/images/payment/fps.svg",
            "selected": false
        },
        {
            "id": PAYMENT_METHODS.BANK_TRANSFER,
            "name": "pagePaymentAndContact.bankAcct.bankTransfer",
            "icon": "assets/images/payment/bank.svg",
            "selected": false
        }
    ];