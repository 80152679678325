<div class="bank-account-body" [ngClass]="{'mox-style': claim.isMox()}">
  <div class="prev-bar">
    <a (click)="back()" style="color: #0064c1" class="btn new-btn btn-secondary"><i class="fas fa-arrow-left"></i> {{'pagePaymentAndContact.bankAcct.back' | translate}} </a>
  </div>
  <ng-container *ngIf="claim.getServiceUnit() === 'GP'">
    <div class="col-12">
      <h5>{{'pagePaymentAndContact.bankAcct.title' | translate}}</h5>
      <div>
        <p>{{'pagePaymentAndContact.bankAcct.acctNoticeG400' | translate}}</p>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="claim.getServiceUnit() !== 'GP'">
    <ng-container *ngIf="!this.isFPSAllowed">
      <div class="col-12">
        <h5>{{'pagePaymentAndContact.bankAcct.title' | translate}}</h5>
        <div *ngIf="transactionInfo.isDomesticHelperProduct() || transactionInfo.isTravelProduct() || transactionInfo.isHomeProduct() ||
              transactionInfo.isEmployeeCompensationProduct()"><p>{{'pagePaymentAndContact.bankAcct.acctNotice' | translate}}</p></div>
        <div *ngIf="transactionInfo.isMotorProduct() || transactionInfo.isGenericClaim()"><p>{{'pagePaymentAndContact.bankAcct.acctNoticeMotor' | translate}}</p></div>
      </div>
    </ng-container>
    <ng-container *ngIf="this.isFPSAllowed">
      <div class="col-12">
        <h5>{{'pagePaymentAndContact.fps.modeOfPayment' | translate}}</h5>
        <p>{{'pagePaymentAndContact.fps.modeOfPaymentDesc' | translate}}</p>
      </div>
      <div class="col-12">
        <qnect-notification-messages [notifications]="notifications"></qnect-notification-messages>
      </div>
      <div class="row pay-box col-12">
        <div class = "col-lg-4 col-md-6 col-sm-6" *ngFor="let paymentItem of paymentOptions; let i = index"
             (click)="toggleActive(paymentItem)">
          <div class="cperson-wrapper text-center pay-wrapper" [class.active]="paymentItem.selected">
            <div class = "cpreson-outter pay-inner">
              <div>
                <img class="icon" style="width: 50px;height: 50px;" [src]="paymentItem['icon']" alt="">
                <h5 style="margin-top: 10px;">{{paymentItem.name | translate}}</h5>
              </div>
            </div>
          </div>
        </div>
      </div><BR>
    </ng-container>
      <div class="row col-12">
        <div class="col-lg-8 col-md-12">
          <form>
            <ng-container *ngIf="claim.getPaymentMethod() === PAYMENT_METHODS.FASTER_PAYMENT_SYSTEM">
              <div class="form-group">
                <qnect-ui #FPS_PROXY_ID_TYPE [fieldId]="'FPS_PROXY_ID_TYPE'" [formGroup]="fpsForm" name="fpsProxyIdType"></qnect-ui>
              </div>
              <ng-container *ngIf="claim.getProxyIdType()">
                <ng-container *ngIf="claim.getProxyIdType()?.toLowerCase() === 'mobile number'">
                  <div class="row" [ngClass]="{'field-verified': claim.getProxyIdVerified()}">
                    <div class="col-10 col-md-8">
                      <div class="form-group">
                        <label style="font-size: 18px;">{{ 'pagePaymentAndContact.fps.mobileNumber' | translate }}*</label>
                        <qnect-ui #FPS_MOBILE_NO [fieldId]="'FPS_MOBILE_NO'" [formGroup]="fpsForm" [prefix]="claim.getProxyCountryCode()" name="fpsMobileNo"></qnect-ui>
                          <small *ngIf="claim.getProxyIdVerified()" class="verified-summary"  style="font-size: 90%;">
                           {{'pagePaymentAndContact.fps.mobileVerified' | translate}}
                          </small>
                      </div>
                    </div>
                    <div class="col-2 col-md-4" style="padding-left: 0;">
                      <div *ngIf="!claim.getProxyIdVerified() && fpsForm.get('fpsMobileNo')?.value" class="form-group" style="padding-top:33px;">
                        <button class="btn btn-secondary" (click)="sendOtp()">{{'pagePaymentAndContact.fps.verify' | translate}}</button>
                      </div>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="claim.getProxyIdType()?.toLowerCase() === 'email'">
                  <div class="row" [ngClass]="{'field-verified': claim.getProxyIdVerified()}">
                    <div class="col-10 col-md-8">
                      <div class="form-group">
                        <label style="font-size: 18px;">{{ 'pagePaymentAndContact.fps.emailAddress' | translate }}*</label>
                        <qnect-ui #FPS_EMAIL [fieldId]="'FPS_EMAIL'" [formGroup]="fpsForm" name="fpsEmail"></qnect-ui>
                          <small *ngIf="claim.getProxyIdVerified()" class="verified-summary" style="font-size: 90%;">
                            {{'pagePaymentAndContact.fps.emailVerified' | translate}}
                          </small>
                      </div>
                    </div>
                    <div class="col-2 col-md-4" style="padding-left: 0;">
                      <div *ngIf="!claim.getProxyIdVerified() && fpsForm.get('fpsEmail')?.value" class="form-group align-items-center" style="padding-top:33px;">
                        <button class="btn btn-secondary" (click)="sendOtp()">{{'pagePaymentAndContact.fps.verify' | translate}}</button>
                      </div>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="claim.getProxyIdType()?.toLowerCase() === 'fps id'">
                  <div class="form-group">
                    <qnect-ui #FPS_ID [fieldId]="'FPS_ID'" [formGroup]="fpsForm" name="fpsId"></qnect-ui>
                  </div>
                  <div class="form-group">
                    <qnect-ui #CONFIRM_FPS_ID [fieldId]="'CONFIRM_FPS_ID'" [formGroup]="fpsForm" name="confirmFpsId" [matchFieldsValidator]="this.claim.getProxyId()"></qnect-ui>
                  </div>
                </ng-container>
                <div class="info-box">
                  <div class="info-content">
                    <i class="info-hits"></i>
                    <div class="info-text">
                      <p class="info-title">{{'pagePaymentAndContact.fps.infoTitle' | translate}}</p>
                      <p class="info-message">{{'pagePaymentAndContact.fps.infoMessage' | translate}}</p>
                    </div>
                  </div>
                </div>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="claim.getPaymentMethod() ===  PAYMENT_METHODS.BANK_TRANSFER">
              <div class="form-group">
                <qnect-ui #PYM01 [fieldId]="'PYM01'" [formGroup]="bankAccountForm" name="accountNameDirect" (focusout)="applyP400Format($event, 'accountNameDirect')" [disabled]="isMotorLodgedBy != ''" [dynamicOptions]="accountNameDirectList"></qnect-ui>
              </div>
              <div class="form-group">
                <qnect-ui #PYM03 [fieldId]="'PYM03'" [formGroup]="bankAccountForm" name="bankDirect" (focusout)="validateBankInput()"></qnect-ui>
              </div>
              <ng-container *ngIf="isNotNullOrUndefinedStr(claim.getBankDirect())">
                <ng-container *ngIf="showBranchCodeInstruction==true">
                  <div class="form-group">
                    <small id="how-policy-number" (click)="showBranchCodeMessage(true)">{{'home.howToFindBranchCode.findingBranchCode' | translate}}</small>
                  </div>
                  <div id="branchCodeMessage" class="col-lg-7 col-xl-7 col-md-7 col-sm-12" *ngIf="showBranchCodeMsg==true">
                    <div class="closePanel">
                      <span (click)="showBranchCodeMessage(false)">&times;</span>
                    </div>
                    <div>
                      <img class="bank-select-img" src="{{bankSelectImg}}">
                    </div>
                    <BR>
                    <div class="bank-select-desc" [innerHTML]="bankDescription"></div>
                  </div>
                </ng-container>
                <div class="form-group" *ngIf="country !== 'MYS'">
                  <qnect-ui #PYM18 [fieldId]="'PYM18'" [formGroup]="bankAccountForm" name="branchCode"></qnect-ui>
                </div>
                <div class="form-group">
                  <qnect-ui #PYM04 [fieldId]="'PYM04'" [formGroup]="bankAccountForm" name="accountNumberDirect"></qnect-ui>
                </div>
              </ng-container>

              <ng-container *ngIf="!(isMotorLodgedBy != '' && isMotorLodgedBy == 'repairer') && country?.toLowerCase() !== 'sgp'">
                <div>
                  <span>{{ 'pagePaymentAndContact.bankAcct.bankHelpText.desc2' | translate}} <a href="javascript:;" (click)="goToCheque()">{{ 'pagePaymentAndContact.bankAcct.bankHelpText.desc1' | translate}}</a></span>
                </div>
              </ng-container>
              <ng-container *ngIf="transactionInfo.isGenericClaim()">
                <div>
                  <span>{{ 'pagePaymentAndContact.bankAcct.bankHelpText.desc3' | translate}} <a href="javascript:;" (click)="goToOverseasBank()">{{ 'pagePaymentAndContact.bankAcct.bankHelpText.desc1' | translate}}</a></span>
                </div>
              </ng-container>
            </ng-container>
          </form>
        </div>
        <div class="col-lg-4 col-md-12">
          &nbsp;
        </div>
      </div>
  </ng-container>
  <div class="submit-bar">
    <abandon-button></abandon-button>
    <a href="javascript:;" (click)="showModal()" class="Tertiary"> {{'pagePaymentAndContact.bankAcct.save' | translate}}</a>
    <button (click)="goToNext()" class="btn new-btn btn-primary float-right"> {{'pagePaymentAndContact.bankAcct.next' | translate}} <i class="fas fa-arrow-right" ></i></button>
  </div>
  <app-save-box [showTotalBox]="showTotalBox" (childEvent)="getData($event)"></app-save-box>
</div>

<otp-box #modal_otp [otp]="otp" [maskedEmailAddress]="recipient" [showCloseIcon]="true" [showResendOtpLink]="true"
         [showOtpError]="showOtpError" [otpSms]="claim.getProxyIdType()?.toLowerCase() === 'mobile number'" (onVerifyOtpEmit)="verifyOtp($event)" (onSendOtpEmit)="sendOtp()"></otp-box>

<ng-template #modal_info let-c="close" let-d="dismiss">
  <div class="modal-body">
    <div class="modal-title"><h4>{{ infoMessage | translate }}</h4></div>
    <span *ngIf="subInfoMessage"><br/><p>{{ subInfoMessage | translate }}</p></span>
  </div>
  <div class="mt-4">
    <button class="new-btn btn-primary btn" (click)="c('Close click')" >
      {{'claimSection.generalLabels.generalButtonLabels.ok' | translate}}
    </button>
    <br/><br/>
  </div>
</ng-template>
