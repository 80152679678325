import {AfterViewInit, Directive, Input, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {UiComponent} from '../../ui.component';

@Directive({selector: '[matchFieldsValidator]'})
export class MatchFieldsValidatorDirective implements OnInit, AfterViewInit {

  @Input('matchFieldsValidator') inputRef: string;
  @Input() operator: string;
  inputComponent: UiComponent;

  constructor(private host: UiComponent) {
    this.inputComponent = host;
  }

  ngOnInit() {
    this.initializeMatchFieldsValidator();
  }

  ngAfterViewInit() {
    this.initializeMatchFieldsValidator();
  }

  initializeMatchFieldsValidator() {
    const inputComponentType = this.inputComponent.fieldConfig['type'];
    if (inputComponentType === 'textfield') {
      this.inputComponent.setupCustomValidatorList([this.validate()]);
      if (this.inputRef) {
        this.inputComponent.update();
      }
    } else {
        console.log('matchFieldsValidator - UI Component validator error: ' +
        this.inputComponent.fieldId + ' - ' + this.inputComponent.fieldConfig['name']);
    }
  }

  validate = () => {
    return (c: FormControl) => {
      if (!this.inputRef || !this.inputComponent.getValue()) {
        return null;
      }

      const isMismatch = this.inputRef.toLowerCase() !== this.inputComponent.getValue().toLowerCase();

      return isMismatch ? { 'matchValidator': true } : null;
    };
  }

}
