<div class="accidental-medical-expenses-container" [ngClass]="{'mox-style': claim.isMox()}">
  <div class="prev-bar">
     <a (click)="back()" style="color: #0064c1" class="btn new-btn btn-secondary"><em class="fas fa-arrow-left"></em> {{ 'claimSection.generalLabels.generalButtonLabels.back' | translate}} </a>
     <a (click)="back()" class="back-chevron"></a>
  </div>
  <div class="col-12">
     <h5>{{ 'paClaim.accidentalMedicalExpenses.title' | translate}}</h5>
     <br/>
  </div>
  <app-hospital-expenses #hospitalExpenses></app-hospital-expenses>
  <br/>
  <div class="divider"></div>
  <br/>
  <ng-container *ngIf="claim.getClaimPersonalAccident().getAccidentalMedicalExpenses().getWasAdmitted()">
     <app-outpatient-consult #outpatientConsultations></app-outpatient-consult>
     <br/>
     <div class="divider"></div>
     <br/>
  </ng-container>
  <div class="upload-doc-body">
     <div class="col-12 upload-doc-info">
        <div>
            <h5>{{ 'claimSection.generalLabels.uploadDocuments.uploadYourDocuments' | translate }}</h5>
        </div>
        <p *ngIf="!claim.isMox()" class="row-info">{{ 'claimSection.generalLabels.uploadDocuments.documentsNeeded1' | translate}}&nbsp;{{ 'claimSection.generalLabels.uploadDocuments.documentsNeeded2' | translate}}&nbsp;{{ 'pageSummaryClaim.claimItem.pleaseRetain' | translate}}</p>
        <p *ngIf="claim.isMox()" class="row-info">{{ 'pageSummaryClaim.claimItem.pleaseRetain' | translate}}</p>
     </div>
     <br>
     <app-pa-upload-doc #uploadDocs [wasAdmitted]="claim.getClaimPersonalAccident().getAccidentalMedicalExpenses().getWasAdmitted()"
     [clinicalExpenses]="claim.getClaimPersonalAccident().getAccidentalMedicalExpenses().getClinicalExpenses()"></app-pa-upload-doc>
     <div class="col-lg-3 col-xl-3 col-md-12"> &nbsp; </div>
  </div>
  <div class="submit-bar">
     <abandon-button></abandon-button>
     <a href="javascript:;" (click)="showModal()" class="Tertiary" [hidden]="claim.isMox()">{{ 'claimSection.generalLabels.generalButtonLabels.save' | translate}}</a>
     <button (click)="goToNext()" class="btn btn-primary float-right new-btn">&nbsp;{{ 'claimSection.generalLabels.generalButtonLabels.next' | translate}}&nbsp;<em class="fas fa-arrow-right"></em></button>
  </div>
  <app-save-box [showTotalBox]="showTotalBox" (childEvent)="getData($event)"></app-save-box>
</div>
