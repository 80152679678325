<div *ngIf="messages?.length > 0" class="col-md-12 alert alert-danger file-error error-notification">
  <div class="row">
    <div class="col-md-1 col-xs-2 text-center">
      <i class="fa fa-exclamation-circle notification-icon" aria-hidden="true"></i>
    </div>
    <div class="col-md-11 col-xs-10">
      <div *ngIf="showPreamble">Please correct the following errors:</div>
      <ul id="error-list" style="list-style-type: none; margin:0; padding:0;">
        <li *ngFor="let error of messages; let i=index">          
            <ng-container *ngIf="messages[i-1]?.fileErrorType!=error.fileErrorType">
              <ng-container *ngIf="i > 0"><br/></ng-container>
              &#8226; {{error.message | translate}} 
              <div style="word-break:break-all">
                &nbsp;&nbsp;&nbsp;&nbsp; - {{error.fileName}}
              </div>
            </ng-container>
            <ng-container *ngIf="messages[i-1]?.fileErrorType==error.fileErrorType">
              <div style="word-break:break-all">
                &nbsp;&nbsp;&nbsp;&nbsp; - {{error.fileName}}
              </div>
            </ng-container>
        </li>
      </ul>
    </div>
  </div>
</div>
