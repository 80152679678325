<div class="upload-doc-body">
  <div class="prev-bar">
    <a href="javascript:;" (click)="goBack()" class="btn new-btn btn-secondary"><em class="fas fa-arrow-left"></em> {{ 'claimSection.generalLabels.generalButtonLabels.back' | translate}} </a>
  </div>
  <div class="col-12">
    <h5>{{ 'claimSection.generalLabels.uploadDocuments.uploadYourDocuments' | translate}}</h5>
    <p>{{ 'claimSection.generalLabels.uploadDocuments.documentsNeeded1' | translate}}&nbsp;{{totalFile}}&nbsp;{{ 'claimSection.generalLabels.uploadDocuments.documentsNeeded2' | translate}}</p>
  </div>
  <div>
    <div class="col-lg-9 col-xl-8 col-md-12">

      <qnect-notification-messages [notifications]="notifications"></qnect-notification-messages><form [formGroup]="upLoadFileModel">
        <h5 *ngIf="this.multiClaimant.length > 0">{{this.claimService.getClaim().getClaimantName()}}</h5>
        <div class="form-group" *ngFor="let documentField of this.formBuilder.getRequiredDocuments(); let i = index;">
            <ng-container *ngIf="showMinorDocument(documentField, this.claimService.getClaim().getClaimantDateOfBirth())">
          <label for="original-time" [attr.for]="'fileToUpload' + i">{{documentField.description | translate}}</label>
            <div class="docRow">
              <input ng2FileSelect accept=".doc, .docx, .xls, .xlsx, .pdf, .txt, image/jpg, image/jpeg, image/png, image/gif, .avi, .mgg, .mp4, .mov, .wmv" [uploader]="uploader" type="file" (change)="fileUpload($event, documentField.id)" [attr.name]="'fileToUpload' + i" [attr.id]="'fileToUpload' + i">
              <label [attr.name]="documentField.id" [attr.for]="'fileToUpload' + i" ng2FileDrop  [uploader]="uploader" (mouseenter)="setDocType(documentField)" (fileOver)="setDocType(documentField)" (drop)="dropFile($event)" (onFileDrop)="fileDrop($event, documentField)" (change)="uploadDrop($event)" class="form-control col-lg-6 col-md-6 col-9 show-in-desktop"><i class="fas fa-upload"></i>{{ 'claimSection.generalLabels.uploadDocuments.dragAndDropHere' | translate}}</label>
              <label [attr.name]="documentField.id" [attr.for]="'fileToUpload' + i" ng2FileDrop  [uploader]="uploader" (mouseenter)="setDocType(documentField)" (fileOver)="setDocType(documentField)" (drop)="dropFile($event)" (onFileDrop)="fileDrop($event, documentField)" (change)="uploadDrop($event)" class="form-control col-9 show-in-mobile"><i class="fas fa-camera"></i> {{ 'claimSection.generalLabels.uploadDocuments.takeAPhoto' | translate}}</label>
              <button [attr.name]="documentField.id + 'rmv'" class="show-in-desktop" [ngStyle]="{'display':'none'}" (click)="removeDoc(documentField.id, false)"></button>
              <button [attr.name]="documentField.id + 'rmv'" class="show-in-mobile" [ngStyle]="{'display':'none'}" (click)="removeDoc(documentField.id, true)"></button>
            </div>
          <div class="row">
            </div>
            <div>
              <div class="form-group">
                <div *ngFor="let item of additionalDocs.get(documentField.id); let i = index;" >
                  <div ng-model="item">
                    <div class="docRow">
                      <input ng2FileSelect accept=".doc, .docx, .xls, .xlsx, .pdf, .txt, image/jpg, image/jpeg, image/png, image/gif, .avi, .mgg, .mp4, .mov, .wmv" [uploader]="uploader" (change)="fileUpload($event, documentField.id + i)" type="file" name="fileToUpload" [attr.id]="documentField.id + i">
                      <ng-container *ngIf="item.docFileName==''">
                        <label [attr.name]="documentField.id + i" [attr.for]="documentField.id + i" ng2FileDrop  [uploader]="uploader" (mouseenter)="setDocTypeIndexed(documentField, i)" (fileOver)="setDocTypeIndexed(documentField, i)" (drop)="dropFile($event)" (onFileDrop)="fileDropIndexed($event, documentField, i)" (change)="uploadDrop($event)" class="form-control col-lg-6 col-md-6 col-9 show-in-desktop"><i class="fas fa-upload"></i>{{ 'claimSection.generalLabels.uploadDocuments.dragAndDropHere' | translate}}</label>
                        <label [attr.name]="documentField.id + i" [attr.for]="documentField.id + i" ng2FileDrop  [uploader]="uploader" (mouseenter)="setDocTypeIndexed(documentField, i)" (fileOver)="setDocTypeIndexed(documentField, i)" (drop)="dropFile($event)" (onFileDrop)="fileDropIndexed($event, documentField, i)" (change)="uploadDrop($event)" class="form-control col-9 show-in-mobile"><i class="fas fa-camera"></i> {{ 'claimSection.generalLabels.uploadDocuments.takeAPhoto' | translate}}</label>
                      </ng-container>
                      <ng-container *ngIf="item.docFileName!=''">
                        <label [attr.name]="documentField.id + i" [attr.for]="documentField.id + i" ng2FileDrop  [uploader]="uploader" (mouseenter)="setDocTypeIndexed(documentField, i)" (fileOver)="setDocTypeIndexed(documentField, i)" (drop)="dropFile($event)" (onFileDrop)="fileDropIndexed($event, documentField, i)" (change)="uploadDrop($event)" class="form-control col-lg-6 col-md-6 col-9 show-in-desktop file-uploaded"><i class="fas fa-check"></i>{{item.docFileName}}</label>
                        <label [attr.name]="documentField.id + i" [attr.for]="documentField.id + i" ng2FileDrop  [uploader]="uploader" (mouseenter)="setDocTypeIndexed(documentField, i)" (fileOver)="setDocTypeIndexed(documentField, i)" (drop)="dropFile($event)" (onFileDrop)="fileDropIndexed($event, documentField, i)" (change)="uploadDrop($event)" class="form-control col-9 show-in-mobile"><i class="fas fa-check"></i>{{item.docFileName}})</label>
                      </ng-container>
                    <button [attr.name]="documentField.id + i + 'rmv'" class="show-in-desktop" [ngStyle]="{'display':'none'}" (click)="removeDoc(documentField.id + i, false)"></button>
                    <button [attr.name]="documentField.id + i + 'rmv'" class="show-in-mobile" [ngStyle]="{'display':'none'}" (click)="removeDoc(documentField.id + i, true)"></button>
                    </div>
                  </div>
                </div>
                <div class="addFile" (click)="addAdditionalFiles(documentField.id)">
                  <i class="fas fa-plus"></i>
                  <span>{{ 'claimSection.generalLabels.uploadDocuments.addFile' | translate}}</span>
                </div>
              </div>
            </div>
</ng-container>
            
        </div>

        <div class="row">
          <div class="col-md-12 col-sm-12">
            {{ 'claimSection.generalLabels.uploadDocuments.moreDocuments' | translate}}
            <a href="javascript:;" class="click-more" (click)="showAddDoc = !showAddDoc">{{ 'claimSection.generalLabels.uploadDocuments.uploadHere' | translate}}</a><BR><BR>
          </div>
        </div>
        <div [hidden]="!showAddDoc">
          <div class="form-group">
            <label for="delayed-time" for="fileToUpload3">{{ 'claimSection.generalLabels.uploadDocuments.additionalDocument' | translate}}</label>
            <div *ngFor="let item of otherUploadedDocs; let i = index;" >
              <div ng-model="item">
                <div class="docRow">
                  <input ng2FileSelect accept=".doc, .docx, .xls, .xlsx, .pdf, .txt, image/jpg, image/jpeg, image/png, image/gif, .avi, .mgg, .mp4, .mov, .wmv" [uploader]="uploader" (change)="fileUpload($event, 'OTHERS' + i)" type="file" name="fileToUpload" [attr.id]="'OTHERS' + i">
                  <ng-container *ngIf="item.docFileName==''">
                    <label [attr.name]="'OTHERS' + i" [attr.for]="'OTHERS' + i" ng2FileDrop  [uploader]="uploader" (mouseenter)="setDocTypeOthers(i)" (fileOver)="setDocTypeOthers(i)" (drop)="dropFile($event)" (onFileDrop)="fileDropOthers($event, i)" (change)="uploadDrop($event)" class="form-control col-lg-6 col-md-6 col-9 show-in-desktop"><i class="fas fa-upload"></i>{{ 'claimSection.generalLabels.uploadDocuments.dragAndDropHere' | translate}}</label>
                    <label [attr.name]="'OTHERS' + i" [attr.for]="'OTHERS' + i" ng2FileDrop  [uploader]="uploader" (mouseenter)="setDocTypeOthers(i)" (fileOver)="setDocTypeOthers(i)" (drop)="dropFile($event)" (onFileDrop)="fileDropOthers($event, i)" (change)="uploadDrop($event)" class="form-control col-9 show-in-mobile"><i class="fas fa-camera"></i> {{ 'claimSection.generalLabels.uploadDocuments.takeAPhoto' | translate}}</label>
                  </ng-container>
                  <ng-container *ngIf="item.docFileName!=''">
                    <label [attr.name]="'OTHERS' + i" [attr.for]="'OTHERS' + i" ng2FileDrop  [uploader]="uploader" (mouseenter)="setDocTypeOthers(i)" (fileOver)="setDocTypeOthers(i)" (drop)="dropFile($event)" (onFileDrop)="fileDropOthers($event, i)" (change)="uploadDrop($event)" class="form-control col-lg-6 col-md-6 col-9 show-in-desktop file-uploaded"><i class="fas fa-check"></i>{{item.docFileName}}</label>
                    <label [attr.name]="'OTHERS' + i" [attr.for]="'OTHERS' + i" ng2FileDrop  [uploader]="uploader" (mouseenter)="setDocTypeOthers(i)" (fileOver)="setDocTypeOthers(i)" (drop)="dropFile($event)" (onFileDrop)="fileDropOthers($event, i)" (change)="uploadDrop($event)" class="form-control col-9 show-in-mobile"><i class="fas fa-check"></i>{{item.docFileName}})</label>
                  </ng-container>
                <button [attr.name]="'OTHERS' + i + 'rmv'" class="show-in-desktop" [ngStyle]="{'display':'none'}" (click)="removeDoc('OTHERS' + i, false)"></button>
                <button [attr.name]="'OTHERS' + i + 'rmv'" class="show-in-mobile" [ngStyle]="{'display':'none'}" (click)="removeDoc('OTHERS' + i, true)"></button>
                </div>
              </div>
            </div>
            <div class="addFile" (click)="addAdditionalFile()">
              <i class="fas fa-plus"></i>
              <span>{{ 'claimSection.generalLabels.uploadDocuments.addFile' | translate}}</span>
            </div>
          </div>
        </div>
        <ng-container *ngIf="this.multiClaimant.length > 0">
          <ng-container  *ngFor="let claimant of this.multiClaimant; let k = index;">
          <h5>{{claimant.getClaimantName()}}</h5>
          <div class="form-group" *ngFor="let documentField of this.formBuilder.getRequiredDocuments(); let i = index;">
            <ng-container *ngIf="showMinorDocument(documentField, claimant.getClaimantDateOfBirth())">
            <label for="original-time" [attr.for]="'fileToUploadC_' + k + '_' + i ">{{documentField.description | translate}}</label>
              <div class="docRow">
            <input ng2FileSelect accept=".doc, .docx, .xls, .xlsx, .pdf, .txt, image/jpg, image/jpeg, image/png, image/gif, .avi, .mgg, .mp4, .mov, .wmv" [uploader]="uploader" type="file" (change)="fileUpload($event, documentField.id)" [attr.name]="'fileToUpload' + k" [attr.id]="'fileToUploadC_' + k + '_' + i">
                <label [attr.name]="getMultiClaimantDocId(documentField.id, claimant.claimantIdentifier, '')" 
                  [attr.for]="'fileToUploadC_' + k + '_' + i" 
                  ng2FileDrop  
                  [uploader]="uploader"
                  (mouseenter)="setDocTypeClaimant(documentField, claimant)" 
                  (fileOver)="setDocTypeClaimant(documentField, claimant)" 
                  (drop)="dropFile($event)" 
                  (onFileDrop)="fileDropClaimant($event, documentField, claimant)" 
                  (change)="uploadDrop($event)" 
                  class="form-control col-lg-6 col-md-6 col-9 show-in-desktop">
                  <i class="fas fa-upload"></i>{{ 'claimSection.generalLabels.uploadDocuments.dragAndDropHere' | translate}}
                </label>
                <label [attr.name]="getMultiClaimantDocId(documentField.id, claimant.claimantIdentifier, '')" 
                  [attr.for]="'fileToUploadC_' + k + '_' + i" 
                  ng2FileDrop  [uploader]="uploader" 
                  (mouseenter)="setDocTypeClaimant(documentField, claimant)" 
                  (fileOver)="setDocTypeClaimant(documentField, claimant)"
                  (drop)="dropFile($event)" 
                  (onFileDrop)="fileDrop($event, documentField, claimant)" 
                  (change)="uploadDrop($event)" 
                  class="form-control col-9 show-in-mobile">
                  <i class="fas fa-camera"></i> {{ 'claimSection.generalLabels.uploadDocuments.takeAPhoto' | translate}}
                </label>
                <button [attr.name]="getMultiClaimantDocId(documentField.id, claimant.claimantIdentifier, 'rmv')" 
                  class="show-in-desktop" [ngStyle]="{'display':'none'}"
                  (click)="removeDoc(getMultiClaimantDocId(documentField.id, claimant.claimantIdentifier, ''), false)">
                </button>
                <button [attr.name]="getMultiClaimantDocId(documentField.id, claimant.claimantIdentifier, 'rmv')" 
                  class="show-in-mobile" [ngStyle]="{'display':'none'}"
                  (click)="removeDoc(getMultiClaimantDocId(documentField.id, claimant.claimantIdentifier, ''), true)">
                </button>
              </div>
            <div class="row">
            </div>
          <div>
          <div class="form-group">
                <div *ngFor="let item of additionalDocsClaimant.get(getMultiClaimantDocId(documentField.id, claimant.getClaimantIdentifier(), '')); let i = index;" >
                  <div ng-model="item">
                      <div class="docRow">
                    <input ng2FileSelect accept=".doc, .docx, .xls, .xlsx, .pdf, .txt, image/jpg, image/jpeg, image/png, image/gif, .avi, .mgg, .mp4, .mov, .wmv" [uploader]="uploader" (change)="fileUpload($event, getMultiClaimantDocId(documentField.id, claimant.claimantIdentifier, i))" type="file" name="fileToUploadC" [attr.id]="getMultiClaimantDocId(documentField.id, claimant.claimantIdentifier, i)">
                    <ng-container *ngIf="item.docFileName==''">
                          <label [attr.name]="getMultiClaimantDocId(documentField.id, claimant.claimantIdentifier, i)" 
                            [attr.for]="getMultiClaimantDocId(documentField.id, claimant.claimantIdentifier, i)" 
                            ng2FileDrop  
                            [uploader]="uploader" 
                            (mouseenter)="setDocTypeIndexedClaimant(documentField, i, claimant)" 
                            (fileOver)="setDocTypeIndexedClaimant(documentField, i, claimant)" 
                            (drop)="dropFile($event)" 
                            (onFileDrop)="fileDropIndexedClaimant($event, documentField, i, claimant)" 
                            (change)="uploadDrop($event)" 
                            class="form-control col-lg-6 col-md-6 col-9 show-in-desktop">
                            <i class="fas fa-upload"></i>{{ 'claimSection.generalLabels.uploadDocuments.dragAndDropHere' | translate}}
                          </label>
                          <label [attr.name]="getMultiClaimantDocId(documentField.id, claimant.claimantIdentifier, i)" 
                            [attr.for]="getMultiClaimantDocId(documentField.id, claimant.claimantIdentifier, i)" 
                            ng2FileDrop  
                            [uploader]="uploader" 
                            (mouseenter)="setDocTypeIndexedClaimant(documentField, i, claimant)" 
                            (fileOver)="setDocTypeIndexedClaimant(documentField, i, claimant)" 
                            (drop)="dropFile($event)" 
                            (onFileDrop)="fileDropIndexedClaimant($event, documentField, i, claimant)" 
                            (change)="uploadDrop($event)" class="form-control col-9 show-in-mobile">
                            <i class="fas fa-camera"></i> {{ 'claimSection.generalLabels.uploadDocuments.takeAPhoto' | translate}}
                          </label>
                    </ng-container>
                    <ng-container *ngIf="item.docFileName!=''">
                          <label [attr.name]="getMultiClaimantDocId(documentField.id, claimant.claimantIdentifier, i)" 
                            [attr.for]="getMultiClaimantDocId(documentField.id, claimant.claimantIdentifier, i)" 
                            ng2FileDrop  
                            [uploader]="uploader" 
                            (mouseenter)="setDocTypeIndexedClaimant(documentField, i, claimant)" 
                            (fileOver)="setDocTypeIndexedClaimant(documentField, i, claimant)" 
                            (drop)="dropFile($event)" 
                            (onFileDrop)="fileDropIndexedClaimant($event, documentField, i, claimant)" 
                            (change)="uploadDrop($event)" 
                            class="form-control col-lg-6 col-md-6 col-9 show-in-desktop file-uploaded">
                            <i class="fas fa-check"></i>{{item.docFileName}}
                          </label>
                          <label [attr.name]="getMultiClaimantDocId(documentField.id, claimant.claimantIdentifier, i)" 
                            [attr.for]="getMultiClaimantDocId(documentField.id, claimant.claimantIdentifier, i)" 
                            ng2FileDrop  
                            [uploader]="uploader" 
                            (mouseenter)="setDocTypeIndexedClaimant(documentField, i, claimant)" 
                            (fileOver)="setDocTypeIndexedClaimant(documentField, i, claimant)" 
                            (drop)="dropFile($event)" 
                            (onFileDrop)="fileDropIndexedClaimant($event, documentField, i, claimant)" 
                            (change)="uploadDrop($event)" 
                            class="form-control col-9 show-in-mobile">
                            <i class="fas fa-check"></i>{{item.docFileName}})
                          </label>
                    </ng-container>
                      <button [attr.name]="getMultiClaimantDocId(documentField.id, claimant.claimantIdentifier, i + 'rmv')"
                        class="show-in-desktop" [ngStyle]="{'display':'none'}"
                        (click)="removeDoc(getMultiClaimantDocId(documentField.id, claimant.claimantIdentifier, i), false, claimant)">
                      </button>
                      <button [attr.name]="getMultiClaimantDocId(documentField.id, claimant.claimantIdentifier, i + 'rmv')" 
                        class="show-in-mobile" [ngStyle]="{'display':'none'}"
                        (click)="removeDoc(getMultiClaimantDocId(documentField.id, claimant.claimantIdentifier, i), true, claimant)">
                      </button>
                  </div>
                    </div>
                </div>
                <div class="addFile" (click)="addAdditionalFilesClaimant(documentField.id, claimant)">
                  <i class="fas fa-plus"></i>
                  <span>{{ 'claimSection.generalLabels.uploadDocuments.addFile' | translate}}</span>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
        
          <div class="row">
            <div class="col-md-12 col-sm-12">
              {{ 'claimSection.generalLabels.uploadDocuments.moreDocuments' | translate}}
              <a href="javascript:;" class="click-more" (click)="this.showAddDocs.set('OTHERS' + claimant.getClaimantIdentifier(), 'true')">{{ 'claimSection.generalLabels.uploadDocuments.uploadHere' | translate}}</a><BR><BR>
            </div>
          </div>
          <div [hidden]="this.showAddDocs.get('OTHERS' + (claimant.getClaimantIdentifier()))!='true'">
            <div class="form-group">
              <label for="delayed-time" for="fileToUploadC3 + claimant.getClaimantIdentifier()">{{ 'claimSection.generalLabels.uploadDocuments.additionalDocument' | translate}}</label>
              <div *ngFor="let item of otherUploadedDocsClaimant.get('OTHERS' + claimant.getClaimantIdentifier()); let i = index;" >
                <div ng-model="item">
                  <div class="docRow">
                  <input ng2FileSelect accept=".doc, .docx, .xls, .xlsx, .pdf, .txt, image/jpg, image/jpeg, image/png, image/gif, .avi, .mgg, .mp4, .mov, .wmv" [uploader]="uploader" (change)="fileUpload($event, 'OTHERS' + i)" type="file" [attr.name]="'fileToUploadC3' + i + '_' + claimant.getClaimantIdentifier()" [attr.id]="getMultiClaimantDocId('OTHERS', claimant.getClaimantIdentifier(), i)">
                  <ng-container *ngIf="item.docFileName==''">
                      <label [attr.name]="getMultiClaimantDocId('OTHERS', claimant.claimantIdentifier, i)" 
                        [attr.for]="getMultiClaimantDocId('OTHERS', claimant.claimantIdentifier, i)" 
                        ng2FileDrop  
                        [uploader]="uploader" 
                        (mouseenter)="setDocTypeOthersClaimant(i, claimant)" 
                        (fileOver)="setDocTypeOthersClaimant(i, claimant)" 
                        (drop)="dropFile($event)" 
                        (onFileDrop)="fileDropOthersClaimant($event, i, claimant)" 
                        (change)="uploadDrop($event)" 
                        class="form-control col-lg-6 col-md-6 col-9 show-in-desktop">
                        <i class="fas fa-upload"></i>{{ 'claimSection.generalLabels.uploadDocuments.dragAndDropHere' | translate}}
                      </label>
                      <label [attr.name]="getMultiClaimantDocId('OTHERS', claimant.claimantIdentifier, i)" 
                        [attr.for]="getMultiClaimantDocId('OTHERS', claimant.claimantIdentifier, i)" 
                        ng2FileDrop  
                        [uploader]="uploader" 
                        (mouseenter)="setDocTypeOthersClaimant(i, claimant)" 
                        (fileOver)="setDocTypeOthersClaimant(i, claimant)" 
                        (drop)="dropFile($event)" 
                        (onFileDrop)="fileDropOthersClaimant($event, i, claimant)" 
                        (change)="uploadDrop($event)" 
                        class="form-control col-9 show-in-mobile">
                        <i class="fas fa-camera"></i> {{ 'claimSection.generalLabels.uploadDocuments.takeAPhoto' | translate}}
                      </label>
                  </ng-container>
                  <ng-container *ngIf="item.docFileName!=''">
                      <label [attr.name]="getMultiClaimantDocId('OTHERS', claimant.claimantIdentifier, i)" 
                        [attr.for]="getMultiClaimantDocId('OTHERS', claimant.claimantIdentifier, i)" 
                        ng2FileDrop  
                        [uploader]="uploader" 
                        (mouseenter)="setDocTypeOthersClaimant(i, claimant)" 
                        (fileOver)="setDocTypeOthersClaimant(i, claimant)" 
                        (drop)="dropFile($event)" 
                        (onFileDrop)="fileDropOthersClaimant($event, i, claimant)" 
                        (change)="uploadDrop($event)" 
                        class="form-control col-lg-6 col-md-6 col-9 show-in-desktop file-uploaded">
                      <i class="fas fa-check"></i>{{item.docFileName}}
                      </label>
                      <label [attr.name]="getMultiClaimantDocId('OTHERS', claimant.claimantIdentifier, i)" 
                        [attr.for]="getMultiClaimantDocId('OTHERS', claimant.claimantIdentifier, i)" 
                        ng2FileDrop  [uploader]="uploader" 
                        (mouseenter)="setDocTypeOthersClaimant(i, claimant)" 
                        (fileOver)="setDocTypeOthersClaimant(i, claimant)" 
                        (drop)="dropFile($event)" 
                        (onFileDrop)="fileDropOthersClaimant($event, i, claimant)" 
                        (change)="uploadDrop($event)" 
                        class="form-control col-9 show-in-mobile">
                        <i class="fas fa-check"></i>{{item.docFileName}})
                      </label>
                  </ng-container>
                  <button [attr.name]="getMultiClaimantDocId('OTHERS', claimant.claimantIdentifier, i +'rmv')" 
                    class="show-in-desktop" [ngStyle]="{'display':'none'}"
                    (click)="removeDoc(getMultiClaimantDocId('OTHERS', claimant.claimantIdentifier, i), false)">
                  </button>
                  <button [attr.name]="getMultiClaimantDocId('OTHERS', claimant.claimantIdentifier, i +'rmv')" 
                    class="show-in-mobile" [ngStyle]="{'display':'none'}"
                    (click)="removeDoc(getMultiClaimantDocId('OTHERS', claimant.claimantIdentifier, i), true)">
                  </button>
                  </div>
                </div>
              </div>
              <div class="addFile" (click)="addAdditionalFileClaimant(claimant)">
                <i class="fas fa-plus"></i>
                <span>{{ 'claimSection.generalLabels.uploadDocuments.addFile' | translate}}</span>
              </div>
            </div>
          </div>
        </ng-container>
        </ng-container>
        <div class="row">
          <div class="col-md-12 col-sm-12">
            {{ 'pageSummaryClaim.claimItem.pleaseRetain' | translate}}
          </div>
        </div>
      </form>
    </div>
    <div class="col-lg-4 col-md-12">
      &nbsp;
    </div>
  </div>

  <div class="submit-bar">
    <abandon-button></abandon-button>
    <a href="javascript:;" (click)="showModal()" class="Tertiary"> {{ 'claimSection.generalLabels.generalButtonLabels.save' | translate}}</a>
    <button (click)="goToNext()" class="btn new-btn btn-primary float-right">&nbsp;{{ 'claimSection.generalLabels.generalButtonLabels.next' | translate}}&nbsp;<i class="fas fa-arrow-right" ></i></button>
  </div>
  <app-save-box [showTotalBox]="showTotalBox" (childEvent)="getData($event)"></app-save-box>
</div>

<ng-template #replace_duplicate let-c="close" let-d="dismiss">
  <div class="modal-header">
      <h5 class="modal-title">{{'pageSummaryClaim.claimItem.alreadyUploaded' | translate }}</h5>
      <button type="button" class="close" aria-label="Close"  (click)="abortReplace()">
          <span aria-hidden="true">&times;</span>
      </button>
  </div>
  <div class="modal-body">
      <p>{{'pageSummaryClaim.claimItem.alreadyUploaded' | translate }} {{this.getDuplicateFile().file.name}}</p>
      <p>{{'pageSummaryClaim.claimItem.replace' | translate }}</p>

  </div>
  <div class="modal-footer">
      <div class="col-md-6 text-right">
          <button type="button" class="btn btn-primary btn-sm" (click)="proceedReplace()">{{'pageSummaryClaim.claimItem.yes' | translate }}</button>
      </div>
      <div class="col-md-6 text-left">
          <button type="button" class="btn btn-default btn-sm" (click)="abortReplace()">{{'pageSummaryClaim.claimItem.no' | translate }}</button>
      </div>
  </div>
</ng-template>
<ng-template #alert_unuploaded_docs let-c="close" let-d="dismiss">
    <div class="alert-modal">
      <div class="modal-header">
        <h4 class="cursor-pointer modal-title">{{'claimSection.generalLabels.uploadDocuments.reqDocuments'  | translate}}</h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="text-justify">
          <p>{{'claimSection.generalLabels.uploadDocuments.youHaveNotUploaded'  | translate}} {{listOfUnuploadedDocs}} {{'claimSection.generalLabels.uploadDocuments.uploadToProced'  | translate}}</p>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-light" (click)="c('Close click')">{{'claimSection.generalLabels.generalButtonLabels.yes'  | translate}}</button>
        <button type="button" class="btn btn-light" (click)="continueToNext()">{{'claimSection.generalLabels.generalButtonLabels.no'  | translate}}</button>     
      </div>
    </div>
  </ng-template>
