import {BrowserModule} from "@angular/platform-browser";
import {NgModule, ErrorHandler, APP_INITIALIZER, Injector} from "@angular/core";
import {TranslateLoader, TranslateModule, TranslateService} from "@ngx-translate/core";
import {AppComponent} from "./app.component";
import {HeaderComponent} from "./header/header.component";
import {AppRoutingModule} from "./app-routing.module";
import {HomepageComponent} from "./homepage/homepage.component";
import {ClaimFormComponent} from "./claim-pages/claim-form.component";
import {SidemenuComponent} from "./claim-pages/sidemenu/sidemenu.component";
import {GetStartedComponent} from "./claim-pages/get-started/get-started.component";
import {SelectInsuredComponent} from "./claim-pages/select-insured/select-insured.component";
import {ClaimDetailComponent} from "./claim-pages/claim-detail/claim-detail.component";
import {SelectClaimComponent} from "./claim-pages/claim-detail/select-claim/select-claim.component";
import {DetailOfTravelDelayComponent} from "./claim-pages/claim-types-travel/travel-delay/detail-of-travel-delay/detail-of-travel-delay.component";
import {ReasonOfTravelDelayComponent} from "./claim-pages/claim-types-travel/travel-delay/reason-of-travel-delay/reason-of-travel-delay.component";
import {TravelExtralCostComponent} from "./claim-pages/claim-types-travel/travel-delay/travel-extral-cost/travel-extral-cost.component";
import {TravelSupportDocComponent} from "./claim-pages/claim-types-travel/travel-delay/travel-support-doc/travel-support-doc.component";
import {ReportTheLossComponent} from "./claim-pages/claim-types-travel/lost-personal-item/report-the-loss/report-the-loss.component";
import {LossSupportDocComponent} from "./claim-pages/claim-types-travel/lost-personal-item/support-doc/loss-support-doc.component";
import {DetailOfLossComponent} from "./claim-pages/claim-types-travel/lost-personal-item/detail-of-loss/detail-of-loss.component";
import {HttpClient, HttpClientModule} from "@angular/common/http";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {CalendarModule} from "primeng/calendar";
import {FileUploadModule} from "ng2-file-upload";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {TravelDelayComponent} from "./claim-pages/claim-types-travel/travel-delay/travel-delay.component";
import {LostPersonalItemComponent} from "./claim-pages/claim-types-travel/lost-personal-item/lost-personal-item.component";
import {UploadDocumentComponent} from "./claim-pages/claim-types-travel/travel-delay/travel-support-doc/upload-document/upload-document.component";
import {DetailsOfLostItemsComponent} from "./claim-pages/claim-types-travel/lost-personal-item/lost-items/details-of-lost-items.component";
import {BankFilterPipe} from "./claim-pages/payment-contact/bank-account/bank-filter.pipe";
import {LossUploadDocumentComponent} from "./claim-pages/claim-types-travel/lost-personal-item/support-doc/upload-document/loss-upload-document.component";
import {DamagePersonalItemComponent} from "./claim-pages/claim-types-travel/damage-personal-item/damage-personal-item.component";
import {DetailOfDamageComponent} from "./claim-pages/claim-types-travel/damage-personal-item/detail-of-damage/detail-of-damage.component";
import {DetailOfDamageItemsComponent} from "./claim-pages/claim-types-travel/damage-personal-item/detail-of-damage-items/detail-of-damage-items.component";
import {ReportTheDamageComponent} from "./claim-pages/claim-types-travel/damage-personal-item/report-the-damage/report-the-damage.component";
import {DamageSupportDocComponent} from "./claim-pages/claim-types-travel/damage-personal-item/damage-support-doc/damage-support-doc.component";
import {DamageUploadDocumentComponent} from "./claim-pages/claim-types-travel/damage-personal-item/damage-support-doc/damage-upload-document/damage-upload-document.component";
import {VisitedDoctorComponent} from "./claim-pages/claim-types-travel/visited-doctor/visited-doctor.component";
import {DetailOfIllnessComponent} from "./claim-pages/claim-types-travel/visited-doctor/detail-of-illness/detail-of-illness.component";
import {DetailsOfOverseaDoctorVisitComponent} from "./claim-pages/claim-types-travel/visited-doctor/oversea-doctor-visit/details-of-oversea-doctor-visit.component";
import {FollowUpTreatmentComponent} from "./claim-pages/claim-types-travel/visited-doctor/follow-up-treatment/follow-up-treatment.component";
import {MedicalBillsComponent} from "./claim-pages/claim-types-travel/visited-doctor/medical-bills/medical-bills.component";
import {FormatHKIDPipe} from "./claim-pages/select-insured/format-hkid.pipe";
import {BaggageDelayComponent} from "./claim-pages/claim-types-travel/baggage-delay/baggage-delay.component";
import {DetailsOfBaggageDelayComponent} from "./claim-pages/claim-types-travel/baggage-delay/details/details-of-baggage-delay.component";
import {EmergencyPurchasedItemComponent} from "./claim-pages/claim-types-travel/baggage-delay/emergency-items/emergency-purchased-item.component";
import {BaggageDelaySupportDocComponent} from "./claim-pages/claim-types-travel/baggage-delay/support-doc/baggage-delay-support-doc.component";
import {BaggageDelayUploadDocumentComponent} from "./claim-pages/claim-types-travel/baggage-delay/support-doc/baggage-delay-upload-document/baggage-delay-upload-document.component";
import {OtherClaimComponent} from "./claim-pages/claim-types-travel/other-claim/other-claim.component";
import {DetailsOfIncidentComponent} from "./claim-pages/claim-types-travel/other-claim/details-of-incident/details-of-incident.component";
import {DescOfIncidentComponent} from "./claim-pages/claim-types-travel/other-claim/desc-of-incident/desc-of-incident.component";
import {OtherClaimSupportDocComponent} from "./claim-pages/claim-types-travel/other-claim/other-claim-support-doc/other-claim-support-doc.component";
import {OtherClaimUploadDocumentComponent} from "./claim-pages/claim-types-travel/other-claim/other-claim-support-doc/other-claim-upload-document/other-claim-upload-document.component";
import {SaveBoxComponent} from "./save-box/save-box.component";
import {PaymentContactComponent} from "./claim-pages/payment-contact/payment-contact.component";
import {BankAccountComponent} from "./claim-pages/payment-contact/bank-account/bank-account.component";
import {SendChequeComponent} from "./claim-pages/payment-contact/send-cheque/send-cheque.component";
import {ProvideContactInfoComponent} from "./claim-pages/provide-contact-info/provide-contact-info.component";
import {MoreClaimTypeComponent} from "./claim-pages/more-claim-type/more-claim-type.component";
import {SummaryOfClaimComponent} from "./claim-pages/summary-of-claim/summary-of-claim.component";
import {CompleteComponent} from "./claim-pages/complete/complete.component";
import {ConfirmTravelPeriodComponent} from "./claim-pages/confirm-travel-period/confirm-travel-period.component";
import {VisitedDoctorSupportDocComponent} from "./claim-pages/claim-types-travel/visited-doctor/support-doc/visited-doctor-support-doc.component";
import {VisitedDoctorUploadDocumentComponent} from "./claim-pages/claim-types-travel/visited-doctor/support-doc/visited-doctor-upload-document/visited-doctor-upload-document.component";
import {StayInHospitalComponent} from "./claim-pages/claim-types-travel/stay-in-hospital/stay-in-hospital.component";
import {DetailOfHospitalIllnessComponent} from "./claim-pages/claim-types-travel/stay-in-hospital/hospital-illness/detail-of-hospital-illness.component";
import {DetailOfOverseaHospitalStayComponent} from "./claim-pages/claim-types-travel/stay-in-hospital/detail-of-oversea-hospital-stay/detail-of-oversea-hospital-stay.component";
import {HospitalFollowUpTreatmentComponent} from "./claim-pages/claim-types-travel/stay-in-hospital/follow-up-treatment/hospital-follow-up-treatment.component";
import {HospitalMedicalBillsComponent} from "./claim-pages/claim-types-travel/stay-in-hospital/medical-bills/hospital-medical-bills.component";
import {HospitalSupportDocComponent} from "./claim-pages/claim-types-travel/stay-in-hospital/support-doc/hospital-support-doc.component";
import {HospitalUploadDocumentComponent} from "./claim-pages/claim-types-travel/stay-in-hospital/support-doc/upload-document/hospital-upload-document.component";
import {PermissionGuard} from "./guard/permission.guard";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {TripCancellationComponent} from "./claim-pages/claim-types-travel/trip-cancellation/trip-cancellation.component";
import {DetailOfCancelTripComponent} from "./claim-pages/claim-types-travel/trip-cancellation/detail-of-cancel-trip/detail-of-cancel-trip.component";
import {LossCausedByCancellationComponent} from "./claim-pages/claim-types-travel/trip-cancellation/loss-caused-by-cancellation/loss-caused-by-cancellation.component";
import {CancelSupportDocComponent} from "./claim-pages/claim-types-travel/trip-cancellation/cancel-support-doc/cancel-support-doc.component";
import {CancelUploadDocumentComponent} from "./claim-pages/claim-types-travel/trip-cancellation/cancel-support-doc/cancel-upload-document/cancel-upload-document.component";
import {TripCurtailmentComponent} from "./claim-pages/claim-types-travel/trip-curtailment/trip-curtailment.component";
import {DetailOfTripCurtailmentComponent} from "./claim-pages/claim-types-travel/trip-curtailment/detail-of-trip-curtailment/detail-of-trip-curtailment.component";
import {LossCausedByCurtailmentComponent} from "./claim-pages/claim-types-travel/trip-curtailment/loss-caused-by-curtailment/loss-caused-by-curtailment.component";
import {CurtailmentSupportDocComponent} from "./claim-pages/claim-types-travel/trip-curtailment/curtailment-support-doc/curtailment-support-doc.component";
import {CurtailmentUploadDocumentComponent} from "./claim-pages/claim-types-travel/trip-curtailment/curtailment-support-doc/curtailment-upload-document/curtailment-upload-document.component";
import {ExtraCostComponent} from "./claim-pages/claim-types-travel/trip-curtailment/extra-cost/extra-cost.component";
import {RentalVehicleComponent} from "./claim-pages/claim-types-travel/rental-vehicle/rental-vehicle.component";
import {DetailOfIncidentComponent} from "./claim-pages/claim-types-travel/rental-vehicle/detail-of-incident/detail-of-incident.component";
import {VehicleSupportDocComponent} from "./claim-pages/claim-types-travel/rental-vehicle/vehicle-support-doc/vehicle-support-doc.component";
import {VehicleUploadDocumentComponent} from "./claim-pages/claim-types-travel/rental-vehicle/vehicle-support-doc/vehicle-upload-document/vehicle-upload-document.component";
import {TravelReroutingComponent} from "./claim-pages/claim-types-travel/travel-rerouting/travel-rerouting.component";
import {ReroutingDetailComponent} from "./claim-pages/claim-types-travel/travel-rerouting/rerouting-detail/rerouting-detail.component";
import {ReroutingUploadDocumentComponent} from "./claim-pages/claim-types-travel/travel-rerouting/rerouting-support-doc/rerouting-upload-document/rerouting-upload-document.component";
import {MissEventComponent} from "./claim-pages/claim-types-travel/miss-event/miss-event.component";
import {DetailOfMissEventComponent} from "./claim-pages/claim-types-travel/miss-event/detail-of-miss-event/detail-of-miss-event.component";
import {LossOfMissEventComponent} from "./claim-pages/claim-types-travel/miss-event/loss-of-miss-event/loss-of-miss-event.component";
import {MissEventSuppoortDocComponent} from "./claim-pages/claim-types-travel/miss-event/miss-event-suppoort-doc/miss-event-suppoort-doc.component";
import {MissEventUploadDocumentComponent} from "./claim-pages/claim-types-travel/miss-event/miss-event-suppoort-doc/miss-event-upload-document/miss-event-upload-document.component";
import {MissConnectComponent} from "./claim-pages/claim-types-travel/miss-connect/miss-connect.component";
import {DetailOfMissConnectComponent} from "./claim-pages/claim-types-travel/miss-connect/detail-of-miss-connect/detail-of-miss-connect.component";
import {LossOfMissConnectComponent} from "./claim-pages/claim-types-travel/miss-connect/loss-of-miss-connect/loss-of-miss-connect.component";
import {MissConnectSupportDocComponent} from "./claim-pages/claim-types-travel/miss-connect/miss-connect-support-doc/miss-connect-support-doc.component";
import {MissConnectUploaDocumentComponent} from "./claim-pages/claim-types-travel/miss-connect/miss-connect-support-doc/miss-connect-uploa-document/miss-connect-uploa-document.component";
import {PersonalAccidentComponent} from "./claim-pages/claim-types-travel/personal-accident/personal-accident.component";
import {DetailOfAccidentComponent} from "./claim-pages/claim-types-travel/personal-accident/detail-of-accident/detail-of-accident.component";
import {DetailOfOverseaHospitalComponent} from "./claim-pages/claim-types-travel/personal-accident/detail-of-oversea-hospital/detail-of-oversea-hospital.component";
import {AccidentSupportDocComponent} from "./claim-pages/claim-types-travel/personal-accident/accident-support-doc/accident-support-doc.component";
import {AccidentUploadDocumentComponent} from "./claim-pages/claim-types-travel/personal-accident/accident-support-doc/accident-upload-document/accident-upload-document.component";
import {PersonalLiabilityComponent} from "./claim-pages/claim-types-travel/personal-liability/personal-liability.component";
import {LiablitySupportDocComponent} from "./claim-pages/claim-types-travel/personal-liability/support-doc/liablity-support-doc.component";
import {LiablityUploadDocumentComponent} from "./claim-pages/claim-types-travel/personal-liability/support-doc/upload-document/liablity-upload-document.component";
import {AccidentFollowUpTreamentComponent} from "./claim-pages/claim-types-travel/personal-accident/accident-follow-up-treament/accident-follow-up-treament.component";
import {AccidentMedicalBillsComponent} from "./claim-pages/claim-types-travel/personal-accident/accident-medical-bills/accident-medical-bills.component";
import {LiabilityDetailOfIncidentComponent} from "./claim-pages/claim-types-travel/personal-liability/detail-of-incident/liability-detail-of-incident.component";
import {LiabilityDescOfIncidentComponent} from "./claim-pages/claim-types-travel/personal-liability/desc-of-incident/liability-desc-of-incident.component";
import {UiModule} from "./ui/ui.module";
import {UtilitiesModule} from "./utilities/utilities.module";
import {CoreModule} from "./core/core.module";
import {ClaimService} from "./services/claim.service";
import {ClaimBootComponent} from "./boot/claim-boot.component";
import {TransactionInfoService} from "./services/transaction-info.service";
import {StoredDataService} from "./services/stored-data-service";
import {ReroutingSupportDocComponent} from "./claim-pages/claim-types-travel/travel-rerouting/rerouting-support-doc/rerouting-support-doc.component";
import {IntegrationService} from "./services/integrationservice";
import {DocumentHelperService} from "./services/document-helper.service";
import {SummaryStayInHospitalComponent} from "./claim-pages/summary-of-claim/travel-sections-summary/summary-stay-in-hospital/summary-stay-in-hospital.component";
import {SummaryVisitedADoctorComponent} from "./claim-pages/summary-of-claim/travel-sections-summary/summary-visited-a-doctor/summary-visited-a-doctor.component";
import {SummaryTravelDelayComponent} from "./claim-pages/summary-of-claim/travel-sections-summary/summary-travel-delay/summary-travel-delay.component";
import {SummaryOtherClaimComponent} from "./claim-pages/summary-of-claim/travel-sections-summary/summary-other-claim/summary-other-claim.component";
import {SummaryTripCancellation} from "./claim-pages/summary-of-claim/travel-sections-summary/summary-trip-cancellation/summary-trip-cancellation.component";
import {AnotherInsuranceComponent} from "./claim-pages/another-insurance/another-insurance.component";
import {SummaryRentalVehicleComponent} from "./claim-pages/summary-of-claim/travel-sections-summary/summary-rental-vehicle/summary-rental-vehicle.component";
import {SummaryPersonalLiabilityComponent} from "./claim-pages/summary-of-claim/travel-sections-summary/summary-personal-liability/summary-personal-liability.component";
import {SummaryPersonalAccidentComponent} from "./claim-pages/summary-of-claim/travel-sections-summary/summary-personal-accident/summary-personal-accident.component";
import {SummaryTravelRerouteComponent} from "./claim-pages/summary-of-claim/travel-sections-summary/summary-travel-reroute/summary-travel-reroute.component";
import {SummaryLostPersonalItemComponent} from "./claim-pages/summary-of-claim/travel-sections-summary/summary-lost-personal-item/summary-lost-personal-item.component";
import {SummaryDamagePersonalItemComponent} from "./claim-pages/summary-of-claim/travel-sections-summary/summary-damage-personal-item/summary-damage-personal-item.component";
import {SummaryDeclarationsComponent} from "./claim-pages/summary-of-claim/terms-and-conditions/summary-declarations/summary-declarations.component";
import {SummaryMissConnectionComponent} from "./claim-pages/summary-of-claim/travel-sections-summary/summary-miss-connection/summary-miss-connection.component";
import {SummaryTripCurtailmentComponent} from "./claim-pages/summary-of-claim/travel-sections-summary/summary-trip-curtailment/summary-trip-curtailment.component";
import {SummaryMissedEventComponent} from "./claim-pages/summary-of-claim/travel-sections-summary/summary-missed-event/summary-missed-event.component";
import {SummaryBaggageDelayComponent} from "./claim-pages/summary-of-claim/travel-sections-summary/summary-baggage-delay/summary-baggage-delay.component";
import {AnotherInsuranceSummary} from "./claim-pages/summary-of-claim/another-insurance-summary/another-insurance-summary.component";
import {PaymentContactSummary} from "./claim-pages/summary-of-claim/payment-contact-summary/payment-contact-summary.component";
import {DocumentFormFactoryService} from "./services/document-form-factory.service";
import {AutoSaveService} from "./services/autosave.service";
import {TravelSectionsSummaryComponent} from "./claim-pages/summary-of-claim/travel-sections-summary/travel-sections-summary.component";
import {LocationStrategy, PathLocationStrategy} from "@angular/common";
import {ClaimTypeItemService} from "./services/claim-type-item-service";
import {ClaimTypeItemHelperService} from "./services/claim-type-item-helper.service"
import { ClaimDocumentsComponent } from "./claim-pages/claim-documents/claim-documents.component";
import {PageService} from "./services/page.service";
import {MeansOfTransportationComponent} from "./claim-pages/claim-types-travel/travel-rerouting/means-of-transportation/means-of-transportation.component";
import { FooterComponent } from "./footer/footer.component";
import {ClinicalExpensesComponent} from "./claim-pages/claim-types-domestic-helper/clinical-expenses/clinical-expenses.component";
import {ClinicalExpensesDetailComponent} from "./claim-pages/claim-types-domestic-helper/clinical-expenses/clinical-expense-detail/clinical-expenses-detail.component";
import {ClinicalExpensesConditionsComponent} from "./claim-pages/claim-types-domestic-helper/clinical-expenses/clinical-expense-conditions/clinical-expenses-conditions.component";
import {ClinicalExpensesVisitComponent} from "./claim-pages/claim-types-domestic-helper/clinical-expenses/clinical-expense-visit/clinical-expenses-visit.component";
import {ClinicalExpenseUploadDocumentComponent} from "./claim-pages/claim-types-domestic-helper/clinical-expenses/clinic-expense-support-doc/clinical-expense-upload-document.component";
import {SummaryClinicalExpenseComponent} from "./claim-pages/summary-of-claim/domestic-helper-sections-summary/summary-clinical-expense/summary-clinical-expense.component";
import {DentalExpensesComponent} from "./claim-pages/claim-types-domestic-helper/dental-expenses/dental-expenses.component";
import {DetailsOfInjuryIllnessComponent} from "./claim-pages/claim-types-domestic-helper/dental-expenses/details-of-injury-illness/details-of-injury-illness.component";
import {DentalExpenseIncurred} from "./claim-pages/claim-types-domestic-helper/dental-expenses/dental-expenses-incurred/dental-expenses-incurred.component";
import {DentalExpenseUploadDocumentComponent} from "./claim-pages/claim-types-domestic-helper/dental-expenses/dental-expense-support-doc/dental-expense-upload-document.component";
import {DomesticHelperSectionsSummaryComponent} from "./claim-pages/summary-of-claim/domestic-helper-sections-summary/domestic-helper-sections-summary.component";
import {SummaryDentalExpenseComponent} from "./claim-pages/summary-of-claim/domestic-helper-sections-summary/summary-dental-expense/summary-dental-expense.component";
import {EmployeeCompensationComponent} from "./claim-pages/claim-types-domestic-helper/employee-compensation/employee-compensation.component";
import {EmployeeCompensationDetailComponent} from "./claim-pages/claim-types-domestic-helper/employee-compensation/employee-compensation-detail/employee-compensation-detail.component";
import {EmployeeCompensationUploadDocumentComponent} from "./claim-pages/claim-types-domestic-helper/employee-compensation/employee-compensation-support-doc/employee-compensation-upload-document.component";
import {HelpersLiabilityComponent} from "./claim-pages/claim-types-domestic-helper/helpers-liability/helpers-liability.component";
import {HelpersLiabilityDetailComponent} from "./claim-pages/claim-types-domestic-helper/helpers-liability/helpers-liability-detail/helpers-liability-detail.component";
import {HelpersLiabilityIncidentComponent} from "./claim-pages/claim-types-domestic-helper/helpers-liability/helpers-liability-incident/helpers-liability-incident.component";
import {HelpersLiabilityReportingPolicyComponent} from "./claim-pages/claim-types-domestic-helper/helpers-liability/helpers-liability-reporting-policy/helpers-liability-reporting-policy.component";
import {DishonestyHelperComponent} from "./claim-pages/claim-types-domestic-helper/dishonesty-helper/dishonesty-helper.component";
import {DishonestyHelperDetailComponent} from "./claim-pages/claim-types-domestic-helper/dishonesty-helper/dishonesty-helper-detail/dishonesty-helper-detail.component";
import {DishonestyHelperUploadDocumentComponent} from "./claim-pages/claim-types-domestic-helper/dishonesty-helper/dishonesty-helper-support-doc/dishonesty-helper-upload-document.component";
import {SummaryDishonestyHelperComponent} from "./claim-pages/summary-of-claim/domestic-helper-sections-summary/summary-dishonesty-helper/summary-dishonesty-helper.component";
import {PlanHelperService} from "./services/plan-helper.service";
import {PlanInfoService} from "./services/plan-info.service";
import { SummaryEmployeeCompensationComponent } from "src/app/claim-pages/summary-of-claim/domestic-helper-sections-summary/summary-employee-compensation/summary-employee-compensation.component";
import {OtherClaimDMHComponent} from "./claim-pages/claim-types-domestic-helper/other-claim/other-claim.component";
import {OtherClaimDMHDetailComponent} from "./claim-pages/claim-types-domestic-helper/other-claim/other-claim-detail/other-claim-detail.component";
import {OtherClaimDMHUploadDocumentComponent} from "./claim-pages/claim-types-domestic-helper/other-claim/other-claim-support-doc/other-claim-upload-document.component";
import {SummaryOtherClaimDMHComponent} from "./claim-pages/summary-of-claim/domestic-helper-sections-summary/summary-other-claim-dmh/summary-other-claim-dmh.component";
import {HelpersLiabilityUploadDocumentComponent} from "./claim-pages/claim-types-domestic-helper/helpers-liability/helpers-liabiity-support-doc/helpers-liability-upload-document.component";
import {SummaryHelpersLiabilityComponent} from "./claim-pages/summary-of-claim/domestic-helper-sections-summary/summary-helpers-liability/summary-helpers-liability.component";
import { RepatriationComponent } from './claim-pages/claim-types-domestic-helper/repatriation/repatriation.component';
import { DetailsOfRepatriationComponent } from './claim-pages/claim-types-domestic-helper/repatriation/details-of-repatriation/details-of-repatriation.component';
import { RepatriationUploadDocumentComponent } from './claim-pages/claim-types-domestic-helper/repatriation/repatriation-upload-document/repatriation-upload-document.component';
import { RepatriationExtraCostsComponent } from './claim-pages/claim-types-domestic-helper/repatriation/repatriation-extra-costs/repatriation-extra-costs.component';
import { SummaryRepatriationComponent } from './claim-pages/summary-of-claim/domestic-helper-sections-summary/summary-repatriation/summary-repatriation.component';
import { ErrorsService } from "./utilities/errors/errors.service";
import { ErrorsHandler } from "./utilities/errors/errors-handler";
import {HospitalizationHelperComponent} from "./claim-pages/claim-types-domestic-helper/hospitalization-helper/hospitalization-helper.component";
import {HospitalizationHelperDetailComponent} from "./claim-pages/claim-types-domestic-helper/hospitalization-helper/hospitalization-helper-detail/hospitalization-helper-detail.component";
import {HospitalizationHelperUploadDocumentComponent} from "./claim-pages/claim-types-domestic-helper/hospitalization-helper/hospitalization-helper-support-doc/hospitalization-helper-upload-document.component";
import { SummaryHospitalizationHelperComponent } from './claim-pages/summary-of-claim/domestic-helper-sections-summary/summary-hospitalization-helper/summary-hospitalization-helper.component';
import { DeathAndPermanentDisabilityComponent } from "src/app/claim-pages/claim-types-domestic-helper/death-and-permanent-disability/death-and-permanent-disability.component";
import { DeathAndPermanentDisabilityDetailComponent } from "src/app/claim-pages/claim-types-domestic-helper/death-and-permanent-disability/death-and-permanent-disability-detail/death-and-permanent-disability-detail.component";
import { DeathAndPermanentDisabilityUploadDocumentComponent } from "src/app/claim-pages/claim-types-domestic-helper/death-and-permanent-disability/death-and-permanent-disability-support-doc/death-and-permanent-disability-upload-document.component";
import { SummaryDeathAndPermanentDisabilityComponent } from "src/app/claim-pages/summary-of-claim/domestic-helper-sections-summary/summary-death-and-permanent-disability/summary-death-and-permanent-disability.component";
import {GoogleAnalyticsService} from "./services/google-analytics.service";
import {FlightStatusService} from "./services/flight-status.service"
import {UICountryService} from './ui/ui-country.service';
import {ConfirmVehicleDetailsComponent} from './claim-pages/motor/confirm-vehicle-details/confirm-vehicle-details.component';
import {WindscreenDetailComponent} from './claim-pages/motor/claim-types-motor/windscreen/windscreen-detail/windscreen-detail.component';
import {WindscreenLocationComponent} from './claim-pages/motor/claim-types-motor/windscreen/windscreen-location/windscreen-location.component';
import {MotorHelperService} from './services/motor-helper.service';
import {AbandonButtonComponent} from "./abandon/abandon-button.component";
import {MotorSectionsSummaryComponent} from './claim-pages/summary-of-claim/motor-sections-summary/motor-sections-summary.component';
import {SummaryWindscreenComponent} from './claim-pages/summary-of-claim/motor-sections-summary/summary-windscreen/summary-windscreen.component';
import {WindscreenComponent} from "./claim-pages/motor/claim-types-motor/windscreen/windscreen.component";
import {WindscreenUploadDocumentComponent} from './claim-pages/motor/claim-types-motor/windscreen/windscreen-upload-document/windscreen-upload-document.component';
import {SummaryVehicleDetailsComponent} from './claim-pages/summary-of-claim/motor-sections-summary/summary-vehicle-details/summary-vehicle-details.component';
import {AdditionalClaimantComponent} from './claim-pages/additional-claimant/additional-claimant.component';
import {UtilitiesService} from './utilities/utilities.service';
import { MultiClaimDocumentsComponent } from './claim-pages/multi-claim-documents/multi-claim-documents.component';
import {ClaimStatusSummaryComponent} from './search-claim/claim-status-summary/claim-status-summary.component';
import {ClaimSearchService} from './services/claim-search.service';
import {ClaimSearchHomepageComponent} from './search-claim/claim-search-homepage/claim-search-homepage.component';
import { MotorAccidentComponent } from './claim-pages/motor/claim-types-motor/motor-accident-collision/motor-accident.component';
import { MAPoliceReportComponent } from './claim-pages/motor/claim-types-motor/motor-accident-collision/police-report/police-report.component';
import { MotorAccidentDetailComponent } from './claim-pages/motor/claim-types-motor/motor-accident-collision/motor-accident-detail/motor-accident-detail.component';
import { ThirdPartyDetailsComponent } from './claim-pages/motor/claim-types-motor/motor-accident-collision/third-party-details/damage-details.component';
import { MotorAccidentUploadDocumentComponent } from './claim-pages/motor/claim-types-motor/motor-accident-collision/motor-accident-support-doc/motor-accident-upload-document.component';
import { MotorDriverDetailComponent } from './claim-pages/motor/claim-types-motor/motor-accident-collision/motor-driver-detail/motor-driver-detail.component';
import { VehicleDamageDetailComponent } from './claim-pages/motor/claim-types-motor/motor-accident-collision/vehicle-damage-detail/vehicle-damage-detail.component';
import { TheftComponent } from './claim-pages/motor/claim-types-motor/theft/theft.component';
import { TheftPoliceReportComponent } from "./claim-pages/motor/claim-types-motor/theft/theft-police-report/theft-police-report.component";
import { TheftIncidentAccidentDetailsComponent } from "./claim-pages/motor/claim-types-motor/theft/theft-incident-accident-details/theft-incident-accident-details.component";
import { TheftUploadComponent } from './claim-pages/motor/claim-types-motor/theft/theft-upload/theft-upload.component';
import { SummaryTheftComponent } from './claim-pages/summary-of-claim/motor-sections-summary/summary-theft/summary-theft.component';
import { SummaryAccidentCollissionComponent } from './claim-pages/summary-of-claim/motor-sections-summary/summary-collission/summary-accident-collission.component';
import {DepreciationService} from './services/depreciation.service';
import { AgmCoreModule } from '@agm/core';
import { CanDeactivateGuard } from "./guard/candeactivate.guard";
import { ErrorPageComponent } from "./errorpage/errorpage.component";
import { GenericComponent } from "./claim-pages/generic/generic.component";
import { GenericClaimDetailComponent } from "./claim-pages/generic/claim-detail/generic-claim-detail.component";
import { GenericSupportDocumentComponent } from "./claim-pages/generic/generic-support-doc/generic-support-document.component";
import { GenericSectionsSummaryComponent } from "./claim-pages/summary-of-claim/generic-sections-summary/generic-sections-summary.component";
import {ClaimProductService} from "./services/claim-product.service";
import { GenericDocumentService } from "./services/generic-document-service";
import { ConfirmIncidentDetailsComponent } from "./claim-pages/confirm-incident-details/confirm-incident-details.component";
import { WeatherDetailsComponent } from "./claim-pages/home/claim-types-home/weather/weather-details/weather-details.component";
import { WeatherComponent } from "./claim-pages/home/claim-types-home/weather/weather.component";
import {PersonalAccidentDetailsComponent} from "./claim-pages/home/personal-accident/personal-accident-details.component";
import {GenericResponsibilityComponent} from "./claim-pages/home/generic-responsibility/generic-responsibility.component";
import { WaterComponent } from "./claim-pages/home/claim-types-home/water/water.component";
import { WaterDetailsComponent } from "./claim-pages/home/claim-types-home/water/water-details/water-details.component";
import { ContentsBuildingDetailsComponent } from "./claim-pages/home/contents-building/contents-building.component";
import { HomeItemsDetailsComponent } from "./claim-pages/home/home-items/home-items-details.component";
import { AccidentalDamageComponent } from "./claim-pages/home/claim-types-home/accidental-damage/accidental-damage.component";
import { DomesticEmployerComponent } from "./claim-pages/home/domestic-employer/domestic-employer.component";
import { HomeHelperService } from "./services/home-helper.service";
import { VandalismComponent } from "./claim-pages/home/claim-types-home/vandalism/vandalism.component";
import { FireComponent } from "./claim-pages/home/claim-types-home/fire/fire.component";
import { BurglaryComponent } from "./claim-pages/home/claim-types-home/burglary/burglary.component";
import { DynamicRoutingService } from "./services/dynamic-routing.service";
import { TpLossDetailsComponent } from "./claim-pages/home/third-party-liability/loss-details.component";
import { HomeOthersComponent } from "./claim-pages/home/claim-types-home/others/others.component";
import { SummaryHomeDetailsComponent } from "./claim-pages/summary-of-claim/home-sections-summary/summary-home-details/summary-home-details.component";
import { SummaryWaterDamageComponent } from "./claim-pages/summary-of-claim/home-sections-summary/summary-water-damage/summary-water-damage.component";
import { HomeSectionsSummaryComponent } from "./claim-pages/summary-of-claim/home-sections-summary/home-sections-summary.component";
import {SummaryResponsibilityComponent} from './claim-pages/summary-of-claim/home-sections-summary/summary-responsibility/summary-responsibility.component';
import {SummaryPersonalAccidentDetailsComponent} from './claim-pages/summary-of-claim/home-sections-summary/summary-personal-accident-details/summary-personal-accident-details.component';
import { SummaryLiabilityComponent } from "./claim-pages/summary-of-claim/home-sections-summary/summary-liability/summary-liability.component";
import { SummaryDomesticEmployerComponent } from "./claim-pages/summary-of-claim/home-sections-summary/summary-domestic-employer/summary-domestic-employer.component";
import { SummarySupportingDocsComponent } from "./claim-pages/summary-of-claim/home-sections-summary/summary-supporting-docs/summary-supporting-docs.component";
import { HomeUploadDocumentComponent } from "./claim-pages/home/home-support-doc/home-upload-document.component";
import { SummaryOtherDamageComponent } from "./claim-pages/summary-of-claim/home-sections-summary/summary-other-damage/summary-other-damage.component";
import { SummaryLostPersonalItemsComponent } from "./claim-pages/summary-of-claim/home-sections-summary/summary-lost-personal-items/summary-lost-personal-items.component";
import { OtherDetailsComponent } from "./claim-pages/home/claim-types-home/others/other-details/other-details.component";
import { SummaryDamagedPersonalItemsComponent } from "./claim-pages/summary-of-claim/home-sections-summary/summary-damaged-personal-items/summary-damaged-personal-items.component";
import { SummaryWeatherComponent } from "./claim-pages/summary-of-claim/home-sections-summary/summary-weather/summary-weather.component";
import { SummaryContentsBuildingComponent } from "./claim-pages/summary-of-claim/home-sections-summary/summary-contents-building/summary-contents-building.component";
import { AuthorityReportComponent } from "./claim-pages/home/authority-report/authority-report.component";
import { SummaryAuthorityReportComponent } from "./claim-pages/summary-of-claim/home-sections-summary/summary-authority-report/summary-authority-report.component";
import { SummaryHomeItemsComponent } from "./claim-pages/summary-of-claim/home-sections-summary/summary-home-items/summary-home-items.component";
import { DamagedItemsDetailsComponent } from "./claim-pages/home/claim-types-home/damaged-personal-item/damaged-items-details/damaged-items-details.component";
import { LostDetailsComponent } from "./claim-pages/home/claim-types-home/lost-personal-item/lost-details/lost-details.component";
import { LostItemsDetailsComponent } from "./claim-pages/home/claim-types-home/lost-personal-item/lost-items/lost-items-details.component";
import { LostReportComponent } from "./claim-pages/home/claim-types-home/lost-personal-item/lost-report/lost-report.component";
import { HomeDamagedPersonalItemComponent } from "./claim-pages/home/claim-types-home/damaged-personal-item/home-damaged-personal-item.component";
import { HomeLostPersonalItemComponent } from "./claim-pages/home/claim-types-home/lost-personal-item/home-lost-personal-item.component";
import { DomesticEmployerClaimComponent } from "./claim-pages/home/claim-types-home/domestic-employer-claim/domestic-employer-claim.component";
import { PersonalAccidentClaimComponent } from "./claim-pages/home/claim-types-home/personal-accident-claim/personal-accident-claim.component";
import { ThirdPartyLiabilityClaimComponent } from './claim-pages/home/claim-types-home/third-party-liability-claim/third-party-liability-claim.component';
import { HttpLoaderFactory, ApplicationInitializerFactory } from "./translation.config";
import { PayOverseasBankComponent } from "./claim-pages/payment-contact/overseas-bank/pay-overseas-bank.component";
import { AccidentDetailsComponent } from "./claim-pages/personal-accident/accident-details/accident-details.component";
import { InjuryDetailsComponent } from "./claim-pages/personal-accident/injury-details/injury-details.component";
import { AccidentalMedicalExpensesComponent } from "./claim-pages/personal-accident/claim-types/accidental-medical-expenses/accidental-medical-expenses.component";
import { HospitalExpensesComponent } from "./claim-pages/personal-accident/claim-types/accidental-medical-expenses/hospital/hospital-expenses.component";
import { HospitalCashComponent } from "./claim-pages/personal-accident/claim-types/hospital-cash/hospital-cash.component";
import { HospitalCashDetailsComponent } from "./claim-pages/personal-accident/claim-types/hospital-cash/hospital-cash-details/hospital-cash-details.component";
import { ICUDetailsComponent } from "./claim-pages/personal-accident/claim-types/accidental-medical-expenses/icu-details/icu-details.component";
import { AccidentalMedicalExpensesSupportDocComponent } from "./claim-pages/personal-accident/claim-types/accidental-medical-expenses/accidental-medical-expenses-support-doc/accidental-medical-expenses-support-doc.component";
import { AccidentalMedicalExpensesUploadDocumentComponent } from "./claim-pages/personal-accident/claim-types/accidental-medical-expenses/accidental-medical-expenses-support-doc/accidental-medical-expenses-upload/accidental-medical-expenses-upload-document.component";
import { SummaryAccidentalMedicalExpensesDetailsComponent } from "./claim-pages/summary-of-claim/personal-accident-sections-summary/summary-accidental-medical-expenses/summary-accidental-medical-expenses-details.component";
import { SummaryPersonalAccidentClaimDetailsComponent } from "./claim-pages/summary-of-claim/personal-accident-sections-summary/summary-personal-accident-details/summary-personal-accident-claim-details.component";
import { PersonalAccidentSectionsSummaryComponent } from "./claim-pages/summary-of-claim/personal-accident-sections-summary/personal-accident-sections-summary.component";
import { PaOtherDetailsComponent } from "./claim-pages/personal-accident/claim-types/others/details/pa-other-details.component";
import { PaOthersSupportDocComponent } from "./claim-pages/personal-accident/claim-types/others/others-support-doc/pa-others-support-doc.component";
import { PaOthersUploadDocumentComponent } from "./claim-pages/personal-accident/claim-types/others/others-support-doc/others-upload/pa-others-upload-document.component";
import { PaOthersComponent } from "./claim-pages/personal-accident/claim-types/others/pa-others.component";
import { PaAuthorityReportComponent } from "./claim-pages/personal-accident/claim-types/others/authority-report/authority-report.component";
import { SummaryPaOtherDetailsComponent } from "./claim-pages/summary-of-claim/personal-accident-sections-summary/summary-others/summary-pa-other-details.component";
import { HospitalCashSupportDocComponent } from "./claim-pages/personal-accident/claim-types/hospital-cash/hospital-cash-support-doc/hospital-cash-support-doc.component";
import { HospitalCashUploadDocumentComponent } from "./claim-pages/personal-accident/claim-types/hospital-cash/hospital-cash-support-doc/hospital-cash-upload/hospital-cash-upload-document.component";
import { SummaryHospitalCashDetailsComponent } from "./claim-pages/summary-of-claim/personal-accident-sections-summary/summary-hospital-cash/summary-hospital-cash-details.component";
import { HospitalExpenseHelperService } from "./services/hospital-expense-helper.service";
import { PersonalAccidentHelperService } from "./services/personal-accident-helper.service";
import {OutpatientConsultComponent} from './claim-pages/personal-accident/claim-types/accidental-medical-expenses/outpatient-consult/outpatient-consult.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import {Form2Component} from './claim-pages/employee-compensation/claim-types/form2/form2.component';
import {Form2AComponent} from './claim-pages/employee-compensation/claim-types/form2a/form2a.component';
import {Form2BComponent} from './claim-pages/employee-compensation/claim-types/form2b/form2b.component';
import {EmployerDetailsComponent} from './claim-pages/employee-compensation/employer-details/employer-details.component';
import {EmployeeDetailsComponent} from './claim-pages/employee-compensation/employee-details/employee-details.component';
import {ECAccidentDetailsComponent} from './claim-pages/employee-compensation/ec-accident-details/ec-accident-details.component';
import {ECUploadDocumentComponent} from './claim-pages/employee-compensation/ec-upload-document/ec-upload-document.component';
import {EmployeeCompensationSummaryComponent} from './claim-pages/summary-of-claim/employee-compensation-sections-summary/employee-compensation-summary.component';
import {SummaryEmployerDetailsComponent} from './claim-pages/summary-of-claim/employee-compensation-sections-summary/summary-employer-details/summary-employer-details.component';
import {SummaryForm2bComponent} from './claim-pages/summary-of-claim/employee-compensation-sections-summary/summary-form2b/summary-form2b.component';
import {OccupationalDiseaseComponent} from './claim-pages/employee-compensation/occupational-disease/occupational-disease.component';
import {EmployeeEarningsComponent} from './claim-pages/employee-compensation/employee-earnings/employee-earnings.component';
import {ECInsuranceDetailsComponent} from './claim-pages/employee-compensation/insurance-details/ec-insurance-details.component';
import {SummaryForm2aComponent} from './claim-pages/summary-of-claim/employee-compensation-sections-summary/summary-form2a/summary-form2a.component';
import {PeriodOfSickLeaveComponent} from './claim-pages/employee-compensation/period-of-sick-leave/period-of-sick-leave.component';
import {FatalCaseComponent} from './claim-pages/employee-compensation/fatal-case/fatal-case.component';
import {ECCompensationDetailsComponent} from './claim-pages/employee-compensation/ec-compensation-details/ec-compensation-details.component';
import { MatTabsModule } from '@angular/material/tabs';
import { NgSelectModule } from '@ng-select/ng-select';


import {ECInjuryDetailsComponent} from './claim-pages/employee-compensation/injury-details/ec-injury-details.component';
import {SummaryForm2Component} from './claim-pages/summary-of-claim/employee-compensation-sections-summary/summary-form2/summary-form2.component';
import {BodyPartsComponent} from './claim-pages/employee-compensation/injured-body-parts/body-parts/body-parts.component';
import {TabTableComponent} from './claim-pages/employee-compensation/injured-body-parts/tab/tab-table/tab-table.component';
import {ModalTableComponent} from './claim-pages/employee-compensation/injured-body-parts/modal/modal-table/modal-table.component';
import {FormatPipe} from './claim-pages/employee-compensation/injured-body-parts/body-parts/format.pipe';
import {RightViewModalComponent} from './claim-pages/employee-compensation/injured-body-parts/modal/right-view-modal/right-view-modal.component';
import {FrontViewModalComponent} from './claim-pages/employee-compensation/injured-body-parts/modal/front-view-modal/front-view-modal.component';
import {BackViewModalComponent} from './claim-pages/employee-compensation/injured-body-parts/modal/back-view-modal/back-view-modal.component';
import {LeftViewModalComponent} from './claim-pages/employee-compensation/injured-body-parts/modal/left-view-modal/left-view-modal.component';
import {FrontViewTabComponent} from './claim-pages/employee-compensation/injured-body-parts/tab/front-view-tab/front-view-tab.component';
import {BackViewTabComponent} from './claim-pages/employee-compensation/injured-body-parts/tab/back-view-tab/back-view-tab.component';
import {RightViewTabComponent} from './claim-pages/employee-compensation/injured-body-parts/tab/right-view-tab/right-view-tab.component';
import {LeftViewTabComponent} from './claim-pages/employee-compensation/injured-body-parts/tab/left-view-tab/left-view-tab.component';
import { ECAccidentPlaceComponent } from "./claim-pages/employee-compensation/ec-accident-place/ec-accident-place.component";
import { EcHelperService } from "./services/ec-helper.service";
import { ECSupplementaryInfoComponent } from "./claim-pages/employee-compensation/ec-accident-place/ec-supplementary-info.component";
import { NgOtpInputModule } from "ng-otp-input";
import { SignInstructionModalComponent } from "./utilities/components/sign-instruction-modal/sign-instuction-modal";
import { EcFormSelectionModalComponent } from "./utilities/components/ec-form-selection-modal/ec-form-selection-modal";
import {GenericSelectClaimComponent} from './claim-pages/generic/generic-select-claim/generic-select-claim.component';
import { ResetPasswordComponent } from "./reset-password/reset-password.component";
import { OtpBoxComponent } from "./otp-box/otp-box.component";
import { UserService } from "./services/user.service";
import { ForgotPasswordModalComponent } from "./utilities/components/forgot-password-modal/forgot-password-modal";
import {DocumentsToPrepareComponent} from './homepage/documents-to-prepare/documents-to-prepare.component';
import { MedicalCertificateComponent } from "./claim-pages/personal-accident/claim-types/accidental-medical-expenses/medical-certificate/medical-certificate.component";
import {OutpatientTreatmentComponent} from './claim-pages/employee-compensation/outpatient-treatment/outpatient-treatment.component';
import {AcknowledgementComponent} from './claim-pages/personal-accident/acknowledgment/acknowledgement.component';
import { PaDetailsOfAccidentComponent } from "./claim-pages/personal-accident/hkg/details-of-accident/details-of-accident.component";
import { PaBodyPartsComponent } from "./claim-pages/personal-accident/body-parts/pa-body-parts.component";
import { PaOthersNotSureComponent } from "./claim-pages/personal-accident/hkg/claim-types/pa-others-not-sure.component";
import { PaUploadDocComponent } from "./claim-pages/personal-accident/upload-docs/pa-upload-doc.component";
import { PaHospitalCashComponent } from "./claim-pages/personal-accident/hkg/claim-types/pa-hospital-cash.component";
import { PaAmeComponent } from "./claim-pages/personal-accident/hkg/claim-types/pa-ame.component";


@NgModule({
    declarations: [
        AppComponent,
        SummaryOtherClaimComponent,
        SummaryBaggageDelayComponent,
        SummaryStayInHospitalComponent,
        SummaryTravelDelayComponent,
        SummaryTripCancellation,
        SummaryPersonalLiabilityComponent,
        SummaryTravelRerouteComponent,
        SummaryLostPersonalItemComponent,
        SummaryDamagePersonalItemComponent,
        ClaimBootComponent,
        HeaderComponent,
        HomepageComponent,
        ClaimFormComponent,
        SidemenuComponent,
        GetStartedComponent,
        SelectInsuredComponent,
        ClaimDetailComponent,
        SelectClaimComponent,
        DetailOfTravelDelayComponent,
        ReasonOfTravelDelayComponent,
        TravelExtralCostComponent,
        TravelSupportDocComponent,
        ReportTheLossComponent,
        LossSupportDocComponent,
        DetailOfLossComponent,
        TravelDelayComponent,
        LostPersonalItemComponent,
        UploadDocumentComponent,
        DetailsOfLostItemsComponent,
        LossUploadDocumentComponent,
        DamagePersonalItemComponent,
        DetailOfDamageComponent,
        DetailOfDamageItemsComponent,
        ReportTheDamageComponent,
        DamageSupportDocComponent,
        DamageUploadDocumentComponent,
        VisitedDoctorComponent,
        DetailOfIllnessComponent,
        DetailsOfOverseaDoctorVisitComponent,
        FollowUpTreatmentComponent,
        MedicalBillsComponent,
        FormatHKIDPipe,
        BaggageDelayComponent,
        DetailsOfBaggageDelayComponent,
        EmergencyPurchasedItemComponent,
        BaggageDelaySupportDocComponent,
        BaggageDelayUploadDocumentComponent,
        OtherClaimComponent,
        DetailsOfIncidentComponent,
        DescOfIncidentComponent,
        OtherClaimSupportDocComponent,
        OtherClaimUploadDocumentComponent,
        SaveBoxComponent,
        PaymentContactComponent,
        BankAccountComponent,
        AnotherInsuranceComponent,
        SendChequeComponent,
        ProvideContactInfoComponent,
        MoreClaimTypeComponent,
        SummaryOfClaimComponent,
        CompleteComponent,
        BankFilterPipe,
        ConfirmTravelPeriodComponent,
        VisitedDoctorSupportDocComponent,
        VisitedDoctorUploadDocumentComponent,
        StayInHospitalComponent,
        DetailOfHospitalIllnessComponent,
        DetailOfOverseaHospitalStayComponent,
        HospitalFollowUpTreatmentComponent,
        HospitalMedicalBillsComponent,
        HospitalSupportDocComponent,
        HospitalUploadDocumentComponent,
        TripCancellationComponent,
        DetailOfCancelTripComponent,
        LossCausedByCancellationComponent,
        CancelSupportDocComponent,
        CancelUploadDocumentComponent,
        TripCurtailmentComponent,
        DetailOfTripCurtailmentComponent,
        LossCausedByCurtailmentComponent,
        CurtailmentSupportDocComponent,
        CurtailmentUploadDocumentComponent,
        ExtraCostComponent,
        RentalVehicleComponent,
        DetailOfIncidentComponent,
        VehicleSupportDocComponent,
        VehicleUploadDocumentComponent,
        TravelReroutingComponent,
        ReroutingDetailComponent,
        ReroutingUploadDocumentComponent,
        ReroutingSupportDocComponent,
        MissEventComponent,
        DetailOfMissEventComponent,
        LossOfMissEventComponent,
        MissEventSuppoortDocComponent,
        MissEventUploadDocumentComponent,
        MissConnectComponent,
        DetailOfMissConnectComponent,
        LossOfMissConnectComponent,
        MissConnectSupportDocComponent,
        MissConnectUploaDocumentComponent,
        PersonalAccidentComponent,
        DetailOfAccidentComponent,
        DetailOfOverseaHospitalComponent,
        AccidentSupportDocComponent,
        AccidentUploadDocumentComponent,
        PersonalLiabilityComponent,
        LiablitySupportDocComponent,
        LiablityUploadDocumentComponent,
        AccidentFollowUpTreamentComponent,
        AccidentMedicalBillsComponent,
        LiabilityDetailOfIncidentComponent,
        LiabilityDescOfIncidentComponent,
        TravelSectionsSummaryComponent,
        SummaryVisitedADoctorComponent,
        SummaryRentalVehicleComponent,
        SummaryPersonalAccidentComponent,
        SummaryDeclarationsComponent,
        SummaryMissConnectionComponent,
        SummaryTripCurtailmentComponent,
        SummaryMissedEventComponent,
        AnotherInsuranceSummary,
        PaymentContactSummary,
        ClaimDocumentsComponent,
        MeansOfTransportationComponent,
        FooterComponent,
        ClinicalExpensesComponent,
        ClinicalExpensesDetailComponent,
        ClinicalExpensesConditionsComponent,
        ClinicalExpensesVisitComponent,
        ClinicalExpenseUploadDocumentComponent,
        DentalExpensesComponent,
        DetailsOfInjuryIllnessComponent,
        DentalExpenseIncurred,
        DentalExpenseUploadDocumentComponent,
        SummaryClinicalExpenseComponent,
        DomesticHelperSectionsSummaryComponent,
        SummaryDentalExpenseComponent,
        EmployeeCompensationComponent,
        EmployeeCompensationDetailComponent,
        EmployeeCompensationUploadDocumentComponent,
        SummaryEmployeeCompensationComponent,
        HelpersLiabilityComponent,
        HelpersLiabilityDetailComponent,
        HelpersLiabilityIncidentComponent,
        HelpersLiabilityReportingPolicyComponent,
        HelpersLiabilityUploadDocumentComponent,
        SummaryHelpersLiabilityComponent,
        DishonestyHelperComponent,
        DishonestyHelperDetailComponent,
        DishonestyHelperUploadDocumentComponent,
        SummaryDishonestyHelperComponent,
        OtherClaimDMHComponent,
        OtherClaimDMHDetailComponent,
        OtherClaimDMHUploadDocumentComponent,
        SummaryOtherClaimDMHComponent,
        RepatriationComponent,
        DetailsOfRepatriationComponent,
        RepatriationUploadDocumentComponent,
        RepatriationExtraCostsComponent,
        SummaryRepatriationComponent,
        HospitalizationHelperComponent,
        HospitalizationHelperDetailComponent,
        HospitalizationHelperUploadDocumentComponent,
        SummaryHospitalizationHelperComponent,
        DeathAndPermanentDisabilityComponent,
        DeathAndPermanentDisabilityDetailComponent,
        DeathAndPermanentDisabilityUploadDocumentComponent,
        SummaryDeathAndPermanentDisabilityComponent,
        ConfirmVehicleDetailsComponent,
        WindscreenDetailComponent,
        WindscreenLocationComponent,
        AbandonButtonComponent,
        MotorSectionsSummaryComponent,
        SummaryWindscreenComponent,
        WindscreenComponent,
        WindscreenUploadDocumentComponent,
        SummaryVehicleDetailsComponent,
        AdditionalClaimantComponent,
        MultiClaimDocumentsComponent,
        ClaimSearchHomepageComponent,
        ClaimStatusSummaryComponent,
        TheftComponent,
        TheftPoliceReportComponent,
        TheftIncidentAccidentDetailsComponent,
        TheftUploadComponent,
        SummaryTheftComponent,
        MotorAccidentComponent,
        MAPoliceReportComponent,
        MotorAccidentDetailComponent,
        ThirdPartyDetailsComponent,
        MotorAccidentUploadDocumentComponent,
        MotorDriverDetailComponent,
        VehicleDamageDetailComponent,
        SummaryAccidentCollissionComponent,
        ErrorPageComponent,
        GenericComponent,
        GenericClaimDetailComponent,
        GenericSupportDocumentComponent,
        GenericSectionsSummaryComponent,
        ConfirmIncidentDetailsComponent,
        WeatherComponent,
        WeatherDetailsComponent,
        WaterComponent,
        WaterDetailsComponent,
        AccidentalDamageComponent,
        FireComponent,
        BurglaryComponent,
        VandalismComponent,
        HomeItemsDetailsComponent,
        PersonalAccidentDetailsComponent,
        GenericResponsibilityComponent,
        ContentsBuildingDetailsComponent,
        TpLossDetailsComponent,
        DomesticEmployerComponent,
        HomeOthersComponent,
        OtherDetailsComponent,
        HomeSectionsSummaryComponent,
        SummaryHomeDetailsComponent,
        SummaryWaterDamageComponent,
        SummaryPersonalAccidentDetailsComponent,
        SummaryResponsibilityComponent,
        SummaryLiabilityComponent,
        SummaryDomesticEmployerComponent,
        SummarySupportingDocsComponent,
        HomeUploadDocumentComponent,
        SummaryOtherDamageComponent,
        SummaryLostPersonalItemsComponent,
        SummaryDamagedPersonalItemsComponent,
        SummaryWeatherComponent,
        SummaryContentsBuildingComponent,
        AuthorityReportComponent,
        SummaryAuthorityReportComponent,
        SummaryHomeItemsComponent,
        HomeDamagedPersonalItemComponent,
        DamagedItemsDetailsComponent,
        HomeLostPersonalItemComponent,
        LostDetailsComponent,
        LostItemsDetailsComponent,
        LostReportComponent,
        DomesticEmployerClaimComponent,
        PersonalAccidentClaimComponent,
        ThirdPartyLiabilityClaimComponent,
        PayOverseasBankComponent,
        AccidentDetailsComponent,
        PaDetailsOfAccidentComponent,
        PaBodyPartsComponent,
        InjuryDetailsComponent,
        AccidentalMedicalExpensesComponent,
        HospitalExpensesComponent,
        HospitalCashComponent,
        HospitalCashDetailsComponent,
        ICUDetailsComponent,
        MedicalCertificateComponent,
        AccidentalMedicalExpensesSupportDocComponent,
        AccidentalMedicalExpensesUploadDocumentComponent,
        SummaryPersonalAccidentClaimDetailsComponent,
        SummaryAccidentalMedicalExpensesDetailsComponent,
        PersonalAccidentSectionsSummaryComponent,
        PaOthersComponent,
        PaOtherDetailsComponent,
        PaAuthorityReportComponent,
        PaOthersSupportDocComponent,
        PaOthersUploadDocumentComponent,
        SummaryPaOtherDetailsComponent,
        HospitalCashSupportDocComponent,
        HospitalCashUploadDocumentComponent,
        SummaryHospitalCashDetailsComponent,
        OutpatientConsultComponent,
        Form2Component,
        Form2AComponent,
        Form2BComponent,
        EmployeeDetailsComponent,
        ECAccidentPlaceComponent,
        ECSupplementaryInfoComponent,
        ECAccidentDetailsComponent,
        ECCompensationDetailsComponent,
        EmployerDetailsComponent,
        ECUploadDocumentComponent,
        EmployeeCompensationSummaryComponent,
        OccupationalDiseaseComponent,
        EmployeeEarningsComponent,
        ECInsuranceDetailsComponent,
        PeriodOfSickLeaveComponent,
        FatalCaseComponent,
        BodyPartsComponent,
        TabTableComponent,
        ModalTableComponent,
        FormatPipe,
        FrontViewModalComponent,
        BackViewModalComponent,
        LeftViewModalComponent,
        RightViewModalComponent,
        FrontViewTabComponent,
        BackViewTabComponent,
        LeftViewTabComponent,
        RightViewTabComponent,
        ECInjuryDetailsComponent,
        SummaryEmployerDetailsComponent,
        SummaryForm2bComponent,
        SummaryForm2aComponent,
        SummaryForm2Component,
        SignInstructionModalComponent,
        EcFormSelectionModalComponent,
        GenericSelectClaimComponent,
        ResetPasswordComponent,
        OtpBoxComponent,
        ForgotPasswordModalComponent,
        DocumentsToPrepareComponent,
        OutpatientTreatmentComponent,
        AcknowledgementComponent,
        PaOthersNotSureComponent,
        PaUploadDocComponent,
        PaHospitalCashComponent,
        PaAmeComponent

    ],
    imports: [
        CalendarModule,
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        MatTabsModule,
        NgSelectModule,
        FormsModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        NgbModule,
        UiModule,
        UtilitiesModule,
        CoreModule,
        FileUploadModule,
        NgOtpInputModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyDdl7bZJND8zd20CBjOIxmIk-mTRCLGTYc',
            libraries: ['places']
        }),
        NgMultiSelectDropDownModule.forRoot()
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: ApplicationInitializerFactory,
            deps: [ TranslateService, Injector ],
            multi: true
        },
        ClaimService,
        TransactionInfoService,
        StoredDataService,
        PermissionGuard,
        CanDeactivateGuard,
        DocumentHelperService,
        FlightStatusService,
        IntegrationService,
        DocumentFormFactoryService,
        AutoSaveService,
        ClaimTypeItemService,
        ClaimTypeItemHelperService,
        PageService,
        PlanHelperService,
        PlanInfoService,
        PathLocationStrategy,
        GoogleAnalyticsService,
        UICountryService,
        MotorHelperService,
        UtilitiesService,
        ClaimSearchService,
        DepreciationService,
        ClaimProductService,
        {provide: LocationStrategy, useClass: PathLocationStrategy},
        ErrorsService,
        {provide: ErrorHandler, useClass: ErrorsHandler},
        GenericDocumentService,
        HomeHelperService,
        DynamicRoutingService,
        HospitalExpenseHelperService,
        PersonalAccidentHelperService,
        EcHelperService,
        UserService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}