import {Component, Injector, OnDestroy, OnInit, QueryList, ViewChildren} from '@angular/core';
import {Subject} from "rxjs";
import {UiComponent} from "../ui/ui.component";
import {Claim} from "../model/claim.model";
import {ClaimService} from "../services/claim.service";
import {FormArray, FormGroup} from "@angular/forms";
import {TranslateService} from '@ngx-translate/core';
import {UtilitiesService} from '../utilities/utilities.service';
import {DatePipe} from '@angular/common';

@Component({
    template: ''
})
export abstract class ClaimsBaseComponent implements OnInit, OnDestroy {

    protected ngUnsubscribe: Subject<void> = new Subject<void>();

    @ViewChildren(UiComponent) fieldComponents: QueryList<UiComponent>;
    claim: Claim;
    translate: TranslateService;

    protected constructor(private injector2: Injector) {

        let claimService : ClaimService = this.injector2.get(ClaimService);
        this.claim = claimService.getClaim();
        this.translate = this.injector2.get(TranslateService);
    }

    ngOnInit() {
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    getValueInComponent(name: string): any {
        const uiComponent = this.getUIComponent(name);

        if (uiComponent) {
            if (uiComponent.fieldConfig && uiComponent.fieldConfig['type']) {
                const uiType = uiComponent.fieldConfig['type'];

                if (uiType === 'datepicker') {
                    return uiComponent.getDateValue();
                }
                if (uiType === 'datetimepicker') {
                    return uiComponent.getDateValue();
                }
                if (uiType === 'timepicker') {
                    return uiComponent.getTimeValue();
                }
                return uiComponent.getValue();
            }
        }

        return null;
    }

    getTextInComponent(name: string): any {

        const uiComponent = this.getUIComponent(name);

        if (uiComponent) {
            if (uiComponent.fieldConfig && uiComponent.fieldConfig['type']) {
                const uiType = uiComponent.fieldConfig['type'];
                if (uiType === 'dropdownSelect') {
                    let value = uiComponent.getSelectedTextDropDownSelect();
                    if (undefined != value) {
                       return this.translate.instant(value);
                    }
                    return uiComponent.getSelectedTextDropDownSelect();
                }
                return uiComponent.getValue();
            }
        }
        return "";
    }

    getSelectedRadioText(name: string): any {
        const uiComponent = this.getUIComponent(name);
        if (uiComponent) {
            if (uiComponent.fieldConfig && uiComponent.fieldConfig['type']) {
                const uiType = uiComponent.fieldConfig['type'];
                if (uiType === 'radio' || uiType === 'radiobutton') {
                    let value = uiComponent.getSelectedRadioText();
                    if (undefined != value) {
                       return this.translate.instant(value);
                    }
                    return uiComponent.getSelectedRadioText();
                }
                return uiComponent.getValue();
            }
        }
        return "";
    }
    getIndexedTextInComponent(name: string, index: number): any {

        const uiComponent = this.getUIComponentIndexed(name, index);

        if (!uiComponent?.fieldConfig) {
            return "";
        }

        const { type } = uiComponent.fieldConfig;
        if (type === 'dropdownSelect') {
            const value = uiComponent.getSelectedTextDropDownSelect();
            return value !== undefined ? this.translate.instant(value) : value;
        }

        return uiComponent.getValue();
    }

    getUIComponent(name: string): UiComponent {
        return this.fieldComponents.find((item, index) => {
            if (item.fieldConfig && item.fieldConfig['name']) {
                const uiName = item.fieldConfig['name'];
                if (uiName.toLowerCase() === name.toLowerCase()) {
                    return true;
                }
            }
        });
    }

    getIndexedValueInComponent(name: string, index: number): any {
        const uiComponent = this.getUIComponentIndexed(name, index);

        if (uiComponent) {
            if (uiComponent.fieldConfig && uiComponent.fieldConfig['type']) {
                const uiType = uiComponent.fieldConfig['type'];

                if (uiType === 'datepicker') {
                    return uiComponent.getDateValue();
                }
                if (uiType === 'datetimepicker') {
                    return uiComponent.getDateValue();
                }
                if (uiType === 'timepicker') {
                    return uiComponent.getTimeValue();
                }
                return uiComponent.getValue();
            }
        }

        return null;
    }


    getUIComponentIndexed(name: string, indexNumber: number): UiComponent {
        return this.fieldComponents.find((item) => {
            const uiName = item.fieldConfig?.name;
            return uiName && uiName.toLowerCase() === name.toLowerCase() && item.frmArrayIndex === String(indexNumber);
        }) || null;
    }

    getMultiSelectDropdownValue(name: string): any {
        const uiComponent = this.getUIComponent(name);

        if (uiComponent && uiComponent.fieldConfig['type']) {
            const uiType = uiComponent.fieldConfig['type'];
            if (uiType === 'dropdownMultiSelect') {
                let value: any[] = uiComponent.getMultiSelectDropdownValue();
                if (undefined != value) {
                    return value;
                }
                return uiComponent.getMultiSelectDropdownValue();
            }
            return uiComponent.getValue();
        }
        return [];
    }

    validateForm(formGroup: FormGroup): boolean {
        console.log("validateForm formGroup", formGroup);

        let formGroupValid = true;

        if (formGroup.enabled) {
            for (const key of Object.keys(formGroup.controls)) {
                //console.log("formGroup.get(key)", formGroup.get(key));

                let formControl = formGroup.get(key);
                if (formControl instanceof FormArray) {

                    let formArray = <FormArray>formControl;
                    for (const keyArray of Object.keys(formArray.controls)) {

                        let arrayGroup: FormGroup = <FormGroup>formArray.controls[keyArray];
                        for (const keyGroup of Object.keys(arrayGroup.controls)) {
                            let subFormControl = arrayGroup.get(keyGroup);
                            this.validateControl(keyGroup, subFormControl);
                        }

                        if (formGroupValid) {
                            formGroupValid = formGroup.valid;
                        }
                    }
                }
                else {
                    this.validateControl(key, formControl);
                    if (formGroupValid) {
                        formGroupValid = formGroup.valid;
                    }
                }
            }
            return formGroupValid;
        }

        return true;
    }

    validateControl(key, formControl) {
        if (!formControl.disabled) {
            formControl.markAsTouched();
            formControl.updateValueAndValidity({emitEvent: false});
            if (formControl.invalid) {
                console.error(key);
                console.error(formControl.errors);
            }
        }
    }

    //validate form fields on change -  side menu navigation
    validateFormOnChange(formGroup: FormGroup): boolean {
        //console.log("validateFormOnChange formGroup", formGroup);

        let formGroupValid = true;

        if (formGroup.enabled) {
            for (const key of Object.keys(formGroup.controls)) {
                //console.log(" validateFormOnChange formGroup.get(key)", formGroup.get(key));

                let formControl = formGroup.get(key);
                if (formControl instanceof FormArray) {

                    let formArray = <FormArray>formControl;
                    for (const keyArray of Object.keys(formArray.controls)) {

                        let arrayGroup: FormGroup = <FormGroup>formArray.controls[keyArray];
                        for (const keyGroup of Object.keys(arrayGroup.controls)) {
                            let subFormControl = arrayGroup.get(keyGroup);
                            this.validateControlOnChange(keyGroup, subFormControl);
                        }

                        if (formGroupValid) {
                            formGroupValid = formGroup.valid;
                        }
                    }
                } else {
                    this.validateControlOnChange(key, formControl);
                    if (formGroupValid) {
                        formGroupValid = formGroup.valid;
                    }
                }
            }
            return formGroupValid;
        }

        return true;
    }

    validateControlOnChange(key, formControl) {
        if (!formControl.disabled) {
            if (formControl.invalid) {
                //console.error(key);
                //console.error(formControl.errors);
            }
        }
    }

    getBooleanString(boolVar: boolean) {

        if (boolVar != null) {
            return boolVar ? "true" : "false";
        }
        else {
            return boolVar;
        }
    }

    isNotNullOrUndefined(val: any){
        return !UtilitiesService.isNullOrUndefined(val);
    }

    isNotNullOrUndefinedStr(val: string){
        return !UtilitiesService.isNullOrUndefined(val) && val !== '';
    }

    isNotNullOrUndefinedNum(val: number){
        return !UtilitiesService.isNullOrUndefined(val) && val.toString() !== '';
    }

    getFormattedDate(dt: Date) {
        if (dt) {
            return new DatePipe('en-US').transform(dt, "dd/MM/yyyy");
        }
        return "";
    }

    isNotEmptyObject(obj: any) {
        return !(obj && (Object.keys(obj).length === 0 ||
            !(Object.values(obj).some(value => !UtilitiesService.isNullOrUndefined(value) && value !== ''))));
    }

    setDefaultIfNullOrUndefined(value: string | null | undefined, defaultValue: string): string {
        return this.isNotNullOrUndefinedStr(value) ? value : defaultValue;
    }
}
