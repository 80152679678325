<div style="margin-top: 25px; margin-bottom: 25px;">
    <label>{{ 'ecClaim.compensationDetails.outpatientTreatment.title' | translate}}</label>
    <br/>
    <form [formGroup]="treatmentForm">
        <div formArrayName="items" *ngFor="let item of treatmentForm.get('items')['controls']; let i = index;">
            <div [formGroupName]="i">
                <div class="form-group click-more-content">
                    <label>{{'ecClaim.compensationDetails.outpatientTreatment.clinicTreatment' | translate}} &nbsp; {{i+1}}</label>
                    <button [hidden]="i==0" class="removeBtn" (click)="removeVisit(i)">
                        {{ 'claimSection.generalLabels.generalButtonLabels.remove' | translate}}
                    </button>
                    <br/>
                    <div class="form-group">
                        <qnect-ui [fieldId]="'EC_TREATMENT_DATE'"
                                  [frmArrayGroup]="treatmentForm"
                                  [frmArrayName]="'items'"
                                  [claimsDateValidator]="incidentDate"
                                  frmArrayIndex="{{i}}"
                                  name="treatmentDate"></qnect-ui>
                    </div>
                    <div class="form-group">
                        <qnect-ui [fieldId]="'EC_MEDICAL_EXPENSE'"
                                  [frmArrayGroup]="treatmentForm"
                                  [frmArrayName]="'items'"
                                  [prefix]="claim.getClaimEmployeeCompensation().getCurrency()"
                                  frmArrayIndex="{{i}}"
                                  name="medicalExpense"></qnect-ui>
                    </div>
                    <div class="form-group">
                        <qnect-ui [fieldId]="'EC_UNDER_CHINESE_PRACTITIONER'"
                                  [frmArrayGroup]="treatmentForm"
                                  [frmArrayName]="'items'"
                                  frmArrayIndex="{{i}}"
                                  name="isUnderChineseMedPractitioner"></qnect-ui>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <div *ngIf="treatmentForm.get('items')?.length &lt; 5" class="addFile">
        <a href="javascript:;" (click)="addVisit()">
            <em class="fas fa-plus"></em> {{ 'ecClaim.compensationDetails.outpatientTreatment.addVisitDetails' | translate}}
        </a>
    </div>
</div>